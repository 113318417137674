import React from 'react';
import Ajax from './../Ajax.js';
import Select2 from './../form/Select2';

var Mintatanterv = React.createClass({
  getInitialState() {
    return {
      opciok: []
    }
  },

  componentDidMount: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('/feltetel/mintatantervek/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        var tmp = [];
        data.minta_tantervek.map(function(minta_tanterv, i) {
          tmp.push({
            value: parseInt(minta_tanterv.id),
            label: minta_tanterv.nev
          })
        });
        obj.setState({opciok: tmp});
      }
    });
  },

  render: function() {
    return (
      <Select2 placeholder="- válassz -" name={`feltetel[ertek][p_${this.props.id}]`} value={this.props.ertek} title={this.props.label} options={this.state.opciok} validationError="Kötelező" required/>
    );
  }
});

Mintatanterv.contextTypes = {
  store: React.PropTypes.object
}

export default Mintatanterv;