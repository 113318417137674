export function login(id, fullname, group_id, group_name, avatar, shib_avatar, neptun) {
  return {
    type: 'USER_LOGIN',
    id,
    fullname,
    group_id,
    group_name,
    avatar,
    shib_avatar,
    neptun
  }
}

export function setData(id, fullname, group_id, group_name, avatar, shib_avatar, neptun) {
  return {
    type: 'USER_SET_DATA',
    id,
    fullname,
    group_id,
    group_name,
    avatar,
    shib_avatar,
    neptun
  }
}

export function userSetIdoszak(id, megnevezes, felev_kezdete, felev_vege) {
  return {
    type: 'USER_SET_IDOSZAK',
    id,
    megnevezes,
    felev_kezdete,
    felev_vege
  }
}

export function userSetOrarend(id, megnevezes, adatbazis) {
  return {
    type: 'USER_SET_ORAREND',
    id,
    megnevezes,
    adatbazis
  }
}

export function userSetKurzus(id, megjegyzes, tantargy_id, scroll, munkarend, tanszek_id, felvetel_id, csak_megjegyzeses, csak_orarend, szak_id, szakirany_id, felev_id, kerkif) {
  return {
    type: 'USER_SET_KURZUS',
    id,
    megjegyzes,
    tantargy_id,
    scroll,
    munkarend,
    tanszek_id,
    felvetel_id,
    csak_megjegyzeses,
    csak_orarend,
    szak_id,
    szakirany_id,
    felev_id,
    kerkif
  }
}

export function logout() {
  return {
    type: 'USER_LOGOUT'
  }
}

export function setConfigMenuOpen(menu_open) {
  return {
    type: 'SET_CONFIG_MENU_OPEN',
    menu_open
  }
}

export function setConfigServiceRun(run) {
  return {
    type: 'SET_CONFIG_SERVICE_RUN',
    run
  }
}

export function setOldalUzenet(tipus, uzenet) {
  return {
    type: 'SET_OLDAL_UZENET',
    tipus,
    uzenet
  }
}

export function setAjaxPreloader(show) {
  return {
    type: 'SET_AJAX_PRELOADER',
    show
  }
}