import React from 'react';
import Formsy from 'formsy-react';

const Colorpicker = React.createClass({
  mixins: [Formsy.Mixin],

  getInitialState: function() {
    return {
      colors: ["#ff0000","#ff4000","#ff8000","#ffbf00","#ffcc00","#ffff00","#bfff00","#80ff00","#40ff00","#00ff00","#00ff40","#00ff80","#00ffbf","#00ffff","#00bfff","#0080ff","#0040ff","#0000ff","#4000ff","#8000ff","#bf00ff","#ff00ff","#ff00bf","#ff0080","#ffffff","#000000"]
    }
  },

  setActiveColor: function(color) {
    this.setValue(color);

    if (this.props.onChange != undefined)
      this.props.onChange(this.getValue());
  },

  componentDidMount: function() {
    if (this.props.value != undefined)
      this.setValue(this.props.value);
  },

  render() {
    var _this = this;

    var className = 'form-group' + (this.props.className || ' ') +
      (this.showRequired() ? 'required' : this.showError() ? 'has-error' : '');

    var errorMessage = this.getErrorMessage();

    if (this.isFormSubmitted()) {
      if (!this.isValid()) {
        className = 'form-group has-error';
        errorMessage = this.getErrorMessage() ? this.getErrorMessage() : this.props.validationError;
      }
    }

    return (
      <div className={className}>
        {this.props.title != '' ? (<label className="form-label" htmlFor={this.props.name}>{this.props.title} {this.isRequired() ? '*' : null}</label>) : null}
        <div>
        {this.state.colors.map(function(color, i) {
            return (<Colorbox setActiveColor={_this.setActiveColor} color={color} activeColor={_this.getValue()} key={i}/>)
        })}
        </div>
        <span className="validation-error">{errorMessage}</span>
      </div>
    );
  }
});

export default Colorpicker;

var Colorbox = React.createClass({
  onClick: function() {
    this.props.setActiveColor(this.props.color);
  },

  render: function() {
    var classn = (this.props.activeColor == this.props.color) ? 'colorbox active' : 'colorbox';
    if (this.props.color == '#ffffff')
      classn = classn + ' white';

    return (
      <div onClick={this.onClick} className={classn} style={{'backgroundColor':this.props.color}}></div>
    );
  }
});