import React from 'react';
import Formsy from 'formsy-react';

var timeout;

const Input = React.createClass({
  mixins: [Formsy.Mixin],

  changeValue(event) {
    var self = this;

    this.setValue(event.currentTarget[this.props.type === 'checkbox' ? 'checked' : 'value']);

    if (this.props.onChange != undefined)
      this.props.onChange(event.currentTarget.value);

    if (this.props.intervalTypingCallback != undefined) {
      if (timeout != undefined)
        clearTimeout(timeout);

      timeout = setTimeout(function() {
        self.props.intervalTypingCallback(self.getValue());
      }, 1000);
    }
  },

  componentDidUpdate: function() {

  },

  componentDidMount: function() {
    var self = this;

    if (this.props.date) {
      $(this.refs.input).datepicker({
        onClose: function(date) {
          self.setValue(date);
          //$(self.refs.input).change();
        }
      });
    }

    if (this.props.datemy) {
      var year_range_past = (this.props.yearRangePast == undefined) ? 100 : this.props.yearRangePast;
      var year_range_future = (this.props.yearRangeFuture == undefined) ? 0 : this.props.yearRangeFuture;

      $(this.refs.input).datepicker({
        yearRange: "-" + year_range_past + ":+" + year_range_future,
        changeYear: true,
        changeMonth: true,
        onClose: function(date) {
          self.setValue(date);
        }
      });
    }

    if (this.props.time) {
      $(this.refs.input).timepicker({
        minTime: '06:00',
        maxTime: '20:00',
        timeFormat: 'H:i',
        step: '60',
      }).on('selectTime', function(e) {
        self.setValue($(this).val());
      });
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    if (this.getValue() != nextState._value || nextState._formSubmitted || this.props.value != nextProps.value)
      return true;
    else
      return false;
  },

  render() {
    var className = 'form-group' + (this.props.className || ' ') +
      (this.showRequired() ? 'required' : this.showError() ? 'has-error' : '');

    var errorMessage = this.getErrorMessage();

    if (this.isFormSubmitted()) {
      if (!this.isValid()) {
        className = 'form-group has-error';
        errorMessage = this.getErrorMessage() ? this.getErrorMessage() : this.props.validationError;
      }
    }

    return (
      <div className={className}>
        {this.props.title != '' ? (<label className="form-label" htmlFor={this.props.name}>{this.props.title} {this.isRequired() ? '*' : null}</label>) : null}

        {this.props.addonTitle != undefined ? (
          <div className="input-group">
            <input
              disabled={this.props.disabled ? true : false}
              className="form-control"
              ref="input"
              type={this.props.type || 'text'}
              name={this.props.name}
              onChange={this.changeValue}
              value={this.getValue()}
              checked={this.props.type === 'checkbox' && this.getValue() ? 'checked' : null}
            />
            <span className="input-group-addon">
              {this.props.addonTitle}
            </span>
          </div>)
        : (
          <input
            disabled={this.props.disabled ? true : false}
            ref="input"
            className="form-control"
            type={this.props.type || 'text'}
            name={this.props.name}
            onChange={this.changeValue}
            value={this.getValue()}
            checked={this.props.type === 'checkbox' && this.getValue() ? 'checked' : null}
          />
        )}
        <span className="validation-error">{errorMessage}</span>
      </div>
    );
  }
});

export default Input;