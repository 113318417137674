import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import Pagination from './Pagination';
import { Form } from 'formsy-react';
import Input from './form/Input';

var Feltetelek = React.createClass({
  getInitialState() {
    return {
      feltetelek: [],
      limit: 100,
      lap: 1,
      db: 0,
      kereso_megnevezes: ''
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {
  },

  getFeltetelek: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('feltetelek/' + localStorage.token, store, {
      method: "POST",
      async: true,
      data: {
        nev: this.refs.form.getModel().kereso_megnevezes,
        limit: this.state.limit,
        lap: this.state.lap
      },
      success: function(data) {
        obj.setState({feltetelek: data.feltetelek, db: data.db});
        jQueryHelper.setDropDownToggle(obj);
      }
    });
  },

  componentDidMount: function() {
    this.getFeltetelek();
    jQueryHelper.setFixedMenu(this);
    jQueryHelper.setDropDownToggle(this);
  },

  feltetelTorol: function(id) {
    var obj = this;
    var {store} = this.context;

    swal({
      title: "Biztos hogy törli a feltételt?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true
    },
    function(){
      Ajax.call('feltetel_torles/' + localStorage.token + '/' + id, store, {
        success: function(data) {
          obj.getFeltetelek();
        }
      });
    });
  },

  render: function() {
    var _this = this;

      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <Link className="btn btn-primary" to="/uj_feltetel/0"><i className="fa fa-plus"></i>Új feltétel</Link>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Feltételek
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Feltételek</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <Link className="btn btn-primary" to="/uj_feltetel/0"><i className="fa fa-plus"></i>Új feltétel</Link>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Feltételek</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                  <Form ref="form">
                    <Input intervalTypingCallback={() => { _this.setState({lap:1}, _this.getFeltetelek); }} ref="kereso_megnevezes" name="kereso_megnevezes" title="Megnevezés" value={this.state.kereso_megnevezes}/>
                  </Form>
                    <Pagination withCombo={true} cb={(lap) => { _this.setState({lap:lap}, _this.getFeltetelek); }} db={this.state.db} page={this.state.lap} limit={this.state.limit} />
                  {this.state.feltetelek.length > 0 ? (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Függvény</th>
                          <th>Megnevezés</th>
                          <th>Paraméterek</th>
                          <th>Sértés</th>
                          <th>Súly</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.feltetelek.map(feltetel => (
                         <tr key={feltetel.id}>
                            <td>{feltetel.fuggveny_megnevezes}</td>
                            <td>{feltetel.megnevezes}</td>
                            <td>{feltetel.parameter.map(function(p, i) { return (<div key={i} style={{'paddingBottom':'5px'}}>{p}</div>) })}</td>
                            <td>{feltetel.sertes}</td>
                            <td>{feltetel.suly}</td>
                            <td style={{"textAlign": "center"}}>
                              <ul className="icon-list">
                                <li className="dropdown"><a className="dropdown-toggle" href="javascript:void(0)"><i className="fa fa-gear"></i></a>
                                  <ul className="dropdown-menu">
                                    <li className="dropdown-header">Opciók</li>
                                    <li><Link to={`/feltetel/${feltetel.id}/0`}><i className="fa fa-pencil-square-o"></i>Szerkeszt</Link></li>
                                    <li><a onClick={() => this.feltetelTorol(feltetel.id)} href="javascript:void(0)"><i className="fa fa-trash"></i>Töröl</a></li>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>

                        ))
                      }
                      </tbody>
                    </table>
                    ) : (<div>Nem található feltétel.</div>)}
                    <Pagination cb={(lap) => { _this.setState({lap:lap}, _this.getFeltetelek); }} db={this.state.db} page={this.state.lap} limit={this.state.limit} />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

Feltetelek.contextTypes = {
  store: React.PropTypes.object
}

export default Feltetelek;