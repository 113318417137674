import React from 'react';
import Ajax from './../Ajax.js';
import Select2 from './../form/Select2';
import Select from './../form/Select';

var Kurzus = React.createClass({
  getInitialState() {
    return {
      opciok: []
    }
  },

  componentDidMount: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('/feltetel/kurzusok/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        var tmp = [];
        data.kurzusok.map(function(kurzus, i) {
          tmp.push({
            value: parseInt(kurzus.id),
            label: kurzus.azonosito
          })
        });
        obj.setState({opciok: tmp});
      }
    });
  },

  render: function() {
    return (
      <Select search={true} placeholder="- válassz -" name={`feltetel[ertek][p_${this.props.id}]`} value={this.props.ertek} title={this.props.label} options={this.state.opciok} validationError="Kötelező" required/>
    );
  }
});

Kurzus.contextTypes = {
  store: React.PropTypes.object
}

export default Kurzus;