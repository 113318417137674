import React from 'react';
import {Link} from 'react-router';
import Ajax from './Ajax.js';
import {Button, Modal} from 'react-bootstrap';

var LeftPanel = React.createClass({
  getInitialState() {
    return {
      showLogModal: false,
      log: []
    }
  },

  hideLogModal: function() {
    this.setState({
      showLogModal: false
    });
  },

  showLogModal: function() {
    this.setState({
      showLogModal: true
    });
  },

  shouldComponentUpdate: function(nextProps, nextState) {
    var url = nextProps.location.pathname.slice(1).split('/')[0];

    if (url != '') {
      if ($('li.'+url).parent('ul').hasClass('children')) {
        $('li.'+url).addClass('child-active');
        $('li.'+url).parents('li').addClass('active nav-active');
        $('li.'+url).parent('ul').show();
      } else {
        $('li.'+url).addClass('active');
      }
    } else {
      $('li.mentesitesek').addClass('active');
    }

    return true;
  },

  componentDidMount: function() {
    var obj = this;

    $(this.refs.left_panel_menu).find('.nav-parent').click(function(e) {
      if ($(this).hasClass('nav-active')) {
        $(this).removeClass('nav-active');
        $(this).find('.children').slideUp('fast');
      } else {
        $(obj.refs.left_panel_menu).find('.nav-parent').removeClass('nav-active');
        $(obj.refs.left_panel_menu).find('.children').slideUp('fast');
        $(this).addClass('nav-active');
        $(this).find('.children').slideDown('fast');
      }
    });
  },

  serviceStart: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/orkd_start', store, {
      method: 'GET',
      async: false,
      success: function(data) {
        if (data) {
          store.dispatch({type:'SET_CONFIG_SERVICE_RUN', run: true});
          swal('Siker!', 'Szolgáltatás elindult.', 'success');
        } else {
          swal('Hiba!', 'Nem sikerült elindítani a szolgáltatást.', 'error');
        }
      }
    });
  },

  serviceStop: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/orkd_stop', store, {
      method: 'GET',
      async: false,
      success: function(data) {
        if (data) {
          store.dispatch({type:'SET_CONFIG_SERVICE_RUN', run: false});
          swal('Siker!', 'Szolgáltatás leállt.', 'success');
        } else {
          swal('Hiba!', 'Nem sikerült leállítani a szolgáltatást.', 'error');
        }
      }
    });
  },

  showLog: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('get_service_log', store, {
      method: 'GET',
      async: false,
      success: function(data) {
        _this.setState({
          log: data
        }, function() {
          _this.showLogModal();
        });
      }
    });
  },

	render: function() {
    var avatar = 'anon.jpg';
    if(this.props.prop_loggedUser.avatar) {
      avatar = this.props.prop_loggedUser.avatar;
    } else if(this.props.prop_loggedUser.shib_avatar) {
      avatar = '';
    }
    var service_class = this.props.prop_config.serviceRun ? 'service-status run' : 'service-status';

		return (
      <div>
      <div ref="leftPanel" style={{'marginLeft':this.props.prop_config.menuOpen ? 0 : -220}} id="left-panel" className="left-panel">
        <div className="left-panel-inner">
          <div className="left-panel-profil">
            {avatar ? (
              <div className="avatar" style={{'backgroundImage': 'url(img/'+avatar+')'}}></div>
            ):(
              <div className="avatar" style={{'backgroundImage': 'url(data:image/jpeg;base64,'+this.props.prop_loggedUser.shib_avatar+')'}}></div>
            )}
            {this.props.prop_loggedUser.fullname}
            <span>{this.props.prop_loggedUser.group_name}</span>
            {(this.props.prop_loggedUser.aktiv_orarend != null && this.props.prop_loggedUser.group_id != 4) ? (
              <span className="orarend-megn">{this.props.prop_loggedUser.aktiv_orarend.megnevezes}</span>
            ) : (<span></span>)
            }
          </div>
          <ul className="left-panel-profil-menu">
          {/*<li><a href=""><i className="fa fa-gear"></i></a></li>*/}
            <li><Link to="logout"><i className="fa fa-power-off"></i></Link></li>
          </ul>
          <h5 className="sidebar-title">Menü</h5>
          <ul ref="left_panel_menu" id="left-panel-menu" className="left-panel-menu">
            <li className="felhasznalok felhasznalo"><Link to="/felhasznalok"><i className="fa fa-user"></i> <span>Felhasználó kezelő</span></Link></li>
            <li className="idoszakok"><Link to="/idoszakok"><i className="fa fa-clock-o"></i> <span>Időszakok, órarendek</span></Link></li>
            <li className="nav-parent"><a href="javascript:void(0)"><i className="fa fa-calendar-o"></i> <span>Kurzusok</span></a>
              <ul className="children">
                <li className="kiirando_kurzusok"><Link to="/kiirando_kurzusok">Kiírandó kurzusok</Link></li>
                <li className="kiirt_kurzusok"><Link to="/kiirt_kurzusok/0">Kiírt kurzusok</Link></li>
              </ul>
            </li>
            <li className="nav-parent"><a href="javascript:void(0)"><i className="fa fa-cogs"></i> <span>Erőforrások</span></a>
              <ul className="children">
                <li className="eroforrasok_epuletek eroforrasok_epulet eroforrasok_uj_epulet"><Link to="/eroforrasok_epuletek">Épület</Link></li>
                <li className="eroforrasok_oktatok eroforrasok_oktato eroforrasok_uj_oktato"><Link to="/eroforrasok_oktatok">Oktató</Link></li>
                <li className="eroforrasok_termek eroforrasok_terem eroforrasok_uj_terem"><Link to="/eroforrasok_termek">Terem</Link></li>
                <li className="eroforrasok_eszkozok eroforras_eszkoz eroforrasok_uj_eszkoz"><Link to="/eroforrasok_eszkozok">Eszköz</Link></li>
                <li className="eroforrasok_telephelyek eroforrasok_telephely eroforrasok_uj_telephely"><Link to="/eroforrasok_telephelyek">Telephely</Link></li>
              </ul>
            </li>
            <li className="nav-parent"><a href="javascript:void(0)"><i className="fa fa-coffee"></i> <span>Mentesítések</span></a>
              <ul className="children">
                <li className="mentesitesek_nappali mentesitesek_nappali_uj"><Link to="/mentesitesek_nappali/0">Nappali</Link></li>
                <li className="mentesitesek_levelezo mentesitesek_levelezo_uj"><Link to="/mentesitesek_levelezo/0">Levelező</Link></li>
              </ul>
            </li>
            <li className="feltetelek uj_feltetel feltetel"><Link to="/feltetelek"><i className="fa fa-sliders"></i> <span>Feltételek</span></Link></li>
            <li className="nav-parent"><a href="javascript:void(0)"><i className="fa fa-wrench"></i> <span>Órarend készítés</span></a>
              <ul className="children">
                <li className="orarend"><Link to="/orarend">Nappali</Link></li>
                <li className="orarend_levelezo"><Link to="/orarend_levelezo">Levelező</Link></li>
              </ul>
            </li>
            <li className="nav-parent"><a href="javascript:void(0)"><i className="fa fa-calendar"></i> <span>Órarendek</span></a>
              <ul className="children">
                <li className="sajat_orarend"><Link to="/sajat_orarend">Nappali</Link></li>
                <li className="sajat_orarend_levelezo"><Link to="/sajat_orarend_levelezo">Levelező</Link></li>
              </ul>
            </li>
    {/*
            <li className="nav-parent"><a href="javascript:void(0)"><i className="fa fa-pencil-square-o"></i> <span>Kérelmek</span></a>
              <ul className="children">
                <li className="kerelmek_bejovo kerelem"><Link to="/kerelmek_bejovo">Bejövő</Link></li>
                <li className="kerelmek_kimeno"><Link to="/kerelmek_kimeno">Kimenő</Link></li>
              </ul>
            </li>
            */}
            <li className="terem_tarsitas"><Link to="/terem_tarsitas"><i className="fa fa-compress"></i> <span>Terem társítás</span></Link></li>
            <li className="logout"><Link to="/logout"><i className="fa fa-power-off"></i> <span>Kilépés</span></Link></li>
          </ul>
        </div>
        {(this.props.prop_loggedUser.group_id == "1" || this.props.prop_loggedUser.group_id == "2") ? (
        <div className={service_class}>Szerviz státusz {this.props.prop_config.serviceRun ? <button style={{'marginLeft':'5px'}} onClick={this.serviceStop} className="btn btn-white small" type="button">Ki</button> : <button onClick={this.serviceStart} style={{'marginLeft':'5px'}} className="btn btn-white small" type="button">Be</button>} <button onClick={this.showLog} style={{'marginLeft':'5px'}} className="btn btn-white small" type="button">Log</button></div>
        ):("")}
      </div>
      <Modal show={this.state.showLogModal} onHide={this.hideLogModal}>
        <Modal.Header closeButton>
          <Modal.Title>Szerviz Log</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{'height':'500px','overflowY':'scroll'}}>
          {this.state.log.map(function(item, i) {
              return (<p key={i} style={{'borderBottom':'1px solid #e5e5e5', 'paddingTop':'5px', 'paddingBottom':'10px', 'paddingLeft':'20px','position':'relative'}}><i style={{'color':'#000000', 'fontSize':'14px', 'position':'absolute', 'left': '0px', 'top':'5px'}} className="fa fa-info-circle"></i>{item}</p>)
          })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.hideLogModal} type="button">Bezár</Button>
        </Modal.Footer>
      </Modal>
      </div>
    );
	}
});

LeftPanel.contextTypes = {
  store: React.PropTypes.object
}

export default LeftPanel;