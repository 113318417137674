import React from 'react';
import Ajax from './Ajax.js';
import utils from 'formsy-react/lib/utils';
import { Modal, Button, Tabs, Tab} from 'react-bootstrap';
import {Form} from 'formsy-react';
import Input from './form/Input';
import Select from './form/Select';
import Select2 from './form/Select2';

var drag_ora_id = 0;

var OraadoIdopontok = React.createClass({
  getInitialState() {
    return {
      idopont_modal_show: false,
      naptar: 1,
      idopontok: [],
      oraado_id: '',
      kotelezo_oraszam: 0,
      oraszam: 0,
      napok: [
        { value: '0', label: 'Hétfő' },
        { value: '1', label: 'Kedd' },
        { value: '2', label: 'Szerda' },
        { value: '3', label: 'Csütörtök' },
        { value: '4', label: 'Péntek' },
      ]
    }
  },

  componentWillMount: function() {

  },

  onChangeOraado: function(val) {
    var self = this;
    var {store} = this.context;
    //if(self.state.idopontok.length > 0) {
      $(self.refs.naptar).fullCalendar('removeEvents');
      $(self.refs.naptar).fullCalendar('rerenderEvents');
      $(self.refs.naptar2).fullCalendar('removeEvents');
      $(self.refs.naptar2).fullCalendar('rerenderEvents');
      self.setState({
        idopontok: []
      });
    //}
    this.state.oraado_id = val;
    Ajax.call('oraado_idopontok/' + localStorage.token + '/' + this.state.oraado_id, store, {
      async: false,
      success: function(data) {
        self.setState({
          idopontok: data.idopontok,
          kotelezo_oraszam: data.kotelezo_oraszam,
          oraszam: data.oraszam,
          periodusListaKezd: data.periodusok_kezd,
          periodusListaKezd1: data.periodusok_kezd1,
          periodusListaKezd2: data.periodusok_kezd2,
          periodusListaVeg: data.periodusok_veg,
          periodusListaVeg1: data.periodusok_veg1,
          periodusListaVeg2: data.periodusok_veg2
        });
        var events = new Array();
        var nap = '';

        data.idopontok.map(function(idopont,index){
          switch(idopont.day) {
              case 'hétfő':
                  nap = 0;
                  break;
              case 'kedd':
                  nap = 1;
                  break;
              case 'szerda':
                  nap = 2;
                  break;
              case 'csütörtök':
                  nap = 3;
                  break;
              case 'péntek':
                  nap = 4;
                  break;
              case 'szombat':
                  nap = 5;
                  break;
              case 'vasárnap':
                  nap = 6;
                  break;
          }

          var id = idopont.id;
          /*var hetfo = self.getMonday(new Date());
          var hetnap = new Date();
          hetnap.setDate(hetfo.getDate() + nap);*/
          var hetfo = new Date();
          hetfo.setDate(hetfo.getDate() - (hetfo.getDay() + 6) % 7);
          var hetnap = hetfo;
          hetnap.setDate(hetfo.getDate() + nap);
          var y = hetnap.getFullYear();
          var m = hetnap.getMonth()+1;
          if(m <= 9) m = "0"+m;
          var d = parseInt(hetnap.getDate());
          if(d <= 9) d = "0"+d;
          var st = idopont.start;
          var et = idopont.end;
          var start = y+'-'+m+'-'+d+' '+st;
          var end = y+'-'+m+'-'+d+' '+et;

          var filter1 = data.periodusok_kezd.filter(function (periodus) { return periodus.label == idopont.start });
          var filter2 = data.periodusok_veg.filter(function (periodus) { return periodus.label == idopont.end });

          var eventSource = new Object();
          eventSource.title = 'Lehetséges óratartás';
          eventSource.start = start;
          eventSource.end = end;
          eventSource.id = id;
          eventSource.editable = true;
          eventSource.nap = parseInt(nap);
          eventSource.kezd = parseInt(filter1[0].value)
          eventSource.veg = parseInt(filter2[0].value)

          events[index] = eventSource;
        });
        if(data.idopontok.length > 0) {
          $(self.refs.naptar).fullCalendar('addEventSource', events);
          $(self.refs.naptar).fullCalendar('rerenderEvents');
          $(self.refs.naptar2).fullCalendar('addEventSource', events);
          $(self.refs.naptar2).fullCalendar('rerenderEvents');
        }
      }
    });
  },

  componentDidUpdate: function(prevProps, prevState) {
    $(this.refs.naptar).fullCalendar('render');
    $(this.refs.naptar2).fullCalendar('render');
  },

  componentDidMount: function() {
    this.initFullCalendar();
  },

  initFullCalendar: function() {
    var self = this;
    var {store} = this.context;

    var matchingDaysBetween = function (start, end, test) {
      var days = [];
      for (var day = moment(start); day.isBefore(end); day.add(1, 'd')) {
          if (test(day)) {
              days.push(moment(day));
          }
      }
      return days;
    }

    $(this.refs.naptar).fullCalendar({

      //defaultDate: '2012-04-25',
      hiddenDays: [ 0, 6 ],
      minTime: "08:30:00",
      maxTime: "14:45:00",
      slotLabelInterval: { days:0, hours:0, minutes:5 },
      slotLabelFormat: "HH:mm",
      slotDuration: { days:0, hours:0, minutes:55 },
      snapDuration: { days:0, hours:0, minutes:55 },
      firstDay: 1,
      lang: 'hu',
      height: 'auto',
      header: {
        left: '',
        center: '',
        right: ''
      },
      defaultView: 'agendaWeek',
      editable: true,
      eventDurationEditable: false,
      columnFormat: 'dddd',
      dayRender: function(date, cell) {
        console.log(cell);
      },
      dayClick: function(date, jsEvent, view) {
        if(self.state.oraado_id) {
          var filter = self.state.periodusListaKezd1.filter(function (periodus) { return periodus.label == moment(date).format("HH") + ":"+ moment(date).format("mm") + ":00" });
          var aktveg = parseInt(filter[0].value)+1;
          if(parseInt(aktveg) > parseInt(self.state.periodusListaVeg1.length)-1) {
            //aktveg = parseInt(self.state.periodusListaVeg1.length)-1;
            aktveg = parseInt(self.state.periodusListaVeg1.length);
          }
          self.setState({
            aktnap: (parseInt(moment(date).isoWeekday())-1),
            aktkezd: parseInt(filter[0].value),
            aktveg: aktveg,
            idopont_modal_show: true,
            naptar: 1
          });
        }
      },

      eventDragStart: function(event, jsEvent, ui, view) {

      },

      // Drag befejezés
      eventDragStop: function(event, jsEvent, ui, view) {

      },

      // Belső esemény drop
      eventDrop: function(event, delta, revertFunc, jsEvent, ui, view) {
        var filter1 = self.state.periodusListaKezd1.filter(function (periodus) { return periodus.label == moment(event.start).format("HH") + ":"+ moment(event.start).format("mm") + ":00" });
        var filter2 = self.state.periodusListaVeg1.filter(function (periodus) { return periodus.label == moment(event.end).format("HH") + ":"+ moment(event.end).format("mm") + ":00" });
        var aktnap = (parseInt(moment(event.start).isoWeekday())-1);
        var aktkezd = parseInt(filter1[0].value);
        if(filter2.length > 0) {
          var aktveg = parseInt(filter2[0].value);
          self.state.aktnap = aktnap;
          self.state.aktkezd = aktkezd;
          self.state.aktveg = aktveg;
          self.state.aktid = event.id;
          self.state.dropstarted = true;
          self.idopontModalSave();
        } else {
          revertFunc();
        }
      },

      events: self.props.idopontok,
      allDaySlot: false,
      eventClick: function(event, jsEvent, view) {
        if (event.editable === true) {
          self.setState({
            aktnap: event.nap,
            aktkezd: event.kezd,
            aktveg: event.veg,
            aktid: event.id
          });
          var data = event;
          if ($(jsEvent.target).hasClass('delete')) {
            var eva = new Array();
            eva[0] = data.id;
            Ajax.call('oraado_idopontok/idopont_torles/' + localStorage.token, store, {
              async: false,
              method: 'POST',
              data: 'id='+data.id+'&tanar_id='+self.state.oraado_id,
              success: function(data) {
                self.setState({
                  oraszam: data.oraszam,
                  aktid: ''
                });
                $(self.refs.naptar).fullCalendar('removeEvents',eva);
                $(self.refs.naptar).fullCalendar('rerenderEvents');
              }
            });
          } else {
            self.setState({
              idopont_modal_show: true,
              naptar: 1
            });
          }
        }
      },
      eventAfterRender: function(event, element, view) {
        if (event.editable === true) {
          var c = $('<div class="calendar-event-btn delete"><i class="material-icons">close</i></div>');
          var m = $('<div class="calendar-event-btn modify"><i class="fa fa-info"></i></div>');
          $(element).append(c);
          $(element).append(m);
        }
      }
    });

    $(this.refs.naptar2).fullCalendar({

      //defaultDate: '2012-04-25',
      hiddenDays: [ 0, 6 ],
      minTime: "14:50:00",
      maxTime: "19:45:00",
      slotLabelInterval: { days:0, hours:0, minutes:5 },
      slotLabelFormat: "HH:mm",
      slotDuration: { days:0, hours:0, minutes:50 },
      snapDuration: { days:0, hours:0, minutes:50 },
      firstDay: 1,
      lang: 'hu',
      height: 'auto',
      header: {
        left: '',
        center: '',
        right: ''
      },
      defaultView: 'agendaWeek',
      editable: true,
      eventDurationEditable: false,
      columnFormat: 'dddd',
      dayRender: function(date, cell) {
        console.log(cell);
      },
      dayClick: function(date, jsEvent, view) {
        if(self.state.oraado_id) {
          var filter = self.state.periodusListaKezd.filter(function (periodus) { return periodus.label == moment(date).format("HH") + ":"+ moment(date).format("mm") + ":00" });
          var aktveg = parseInt(filter[0].value)+1;
          if(parseInt(aktveg) > parseInt(self.state.periodusListaVeg.length)-1) {
            aktveg = parseInt(self.state.periodusListaVeg.length)-1;
          }
          self.setState({
            aktnap: (parseInt(moment(date).isoWeekday())-1),
            aktkezd: parseInt(filter[0].value),
            aktveg: aktveg,
            idopont_modal_show: true,
            naptar: 2
          });
        }
      },

      eventDragStart: function(event, jsEvent, ui, view) {

      },

      // Drag befejezés
      eventDragStop: function(event, jsEvent, ui, view) {

      },

      // Belső esemény drop
      eventDrop: function(event, delta, revertFunc, jsEvent, ui, view) {
        var filter1 = self.state.periodusListaKezd.filter(function (periodus) { return periodus.label == moment(event.start).format("HH") + ":"+ moment(event.start).format("mm") + ":00" });
        var filter2 = self.state.periodusListaVeg.filter(function (periodus) { return periodus.label == moment(event.end).format("HH") + ":"+ moment(event.end).format("mm") + ":00" });
        var aktnap = (parseInt(moment(event.start).isoWeekday())-1);
        var aktkezd = parseInt(filter1[0].value);
        if(filter2.length > 0) {
          var aktveg = parseInt(filter2[0].value);
          self.state.aktnap = aktnap;
          self.state.aktkezd = aktkezd;
          self.state.aktveg = aktveg;
          self.state.aktid = event.id;
          self.state.dropstarted = true;
          self.idopontModalSave();
        } else {
          revertFunc();
        }
      },

      events: self.props.idopontok,
      allDaySlot: false,
      eventClick: function(event, jsEvent, view) {
        if (event.editable === true) {
          self.setState({
            aktnap: event.nap,
            aktkezd: event.kezd,
            aktveg: event.veg,
            aktid: event.id
          });
          var data = event;
          if ($(jsEvent.target).hasClass('delete')) {
            var eva = new Array();
            eva[0] = data.id;
            Ajax.call('oraado_idopontok/idopont_torles/' + localStorage.token, store, {
              async: false,
              method: 'POST',
              data: 'id='+data.id+'&tanar_id='+self.state.oraado_id,
              success: function(data) {
                self.setState({
                  oraszam: data.oraszam,
                  aktid: ''
                });
                $(self.refs.naptar2).fullCalendar('removeEvents',eva);
                $(self.refs.naptar2).fullCalendar('rerenderEvents');
              }
            });
          } else {
            self.setState({
              idopont_modal_show: true,
              naptar: 2
            });
          }
        }
      },
      eventAfterRender: function(event, element, view) {
        if (event.editable === true) {
          var c = $('<div class="calendar-event-btn delete"><i class="material-icons">close</i></div>');
          var m = $('<div class="calendar-event-btn modify"><i class="fa fa-info"></i></div>');
          $(element).append(c);
          $(element).append(m);
        }
      }
    });

  },

  componentWillUnMount: function() {
    //const {naptar} = this.refs;
    //$(naptar).fullCalendar('destroy');
  },

  idopontModalHide: function() {
    this.setState({
      idopont_modal_show: false,
      aktnap: '',
      aktkezd: '',
      aktveg: '',
      aktid: ''
    });
  },

  getMonday: function(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  },

  idopontModalSave: function() {
    var naptar = this.state.naptar;
    var {store} = this.context;
    if(!this.state.dropstarted) {
      var formData = this.refs.form.getModel();
    } else {
      var formData = new Object();
      formData.nap = this.state.aktnap;
      formData.kezd = this.state.aktkezd;
      formData.veg = this.state.aktveg;
    }
    formData.tanar_id = this.state.oraado_id;
    var self = this;
    if(this.state.aktid) {
      formData.id = this.state.aktid;
    }
    if(formData.veg >= formData.kezd) {
      Ajax.call('oraado_idopontok/uj_idopont/' + localStorage.token, store, {
        method: 'POST',
        async: false,
        data: formData,
        success: function(data) {
          var id = data.id;
          /*var hetfo = self.getMonday(new Date());
          var hetnap = new Date();
          hetnap.setDate(hetfo.getDate() + formData.nap);*/
          var hetfo = new Date();
          hetfo.setDate(hetfo.getDate() - (hetfo.getDay() + 6) % 7);
          var hetnap = hetfo;
          hetnap.setDate(hetfo.getDate() + formData.nap);
          var y = hetnap.getFullYear();
          var m = hetnap.getMonth()+1;
          if(m <= 9) m = "0"+m;
          var d = parseInt(hetnap.getDate());
          if(d <= 9) d = "0"+d;
          var st = self.state.periodusListaKezd[formData.kezd].label;
          var et = self.state.periodusListaVeg[formData.veg].label;
          var start = y+'-'+m+'-'+d+' '+st;
          var end = y+'-'+m+'-'+d+' '+et;

          var daySource = new Object();
          daySource.title = 'Lehetséges óratartás';
          daySource.start = start;
          daySource.end = end;
          daySource.id = id;
          daySource.editable = true;
          daySource.nap = parseInt(formData.nap);
          daySource.kezd = parseInt(formData.kezd);
          daySource.veg = parseInt(formData.veg);

          var day = new Array();
          day[0] = daySource;

          //console.log(daySource);

          if(self.state.aktid != '') {
            var eva = new Array();
            eva[0] = self.state.aktid;
            if(naptar == 1) {
              $(self.refs.naptar).fullCalendar('removeEvents', eva);
              $(self.refs.naptar).fullCalendar('rerenderEvents');
            } else {
              $(self.refs.naptar2).fullCalendar('removeEvents', eva);
              $(self.refs.naptar2).fullCalendar('rerenderEvents');
            }
          }

          self.setState({
            //idopontok: data.idopontok,
            idopont_modal_show: false,
            oraszam: data.oraszam,
            aktnap: '',
            aktkezd: '',
            aktveg: '',
            aktid: '',
            dropstarted: false
          });

          if(naptar == 1) {
            $(self.refs.naptar).fullCalendar('addEventSource', day);
            $(self.refs.naptar).fullCalendar('rerenderEvents');
          } else {
            $(self.refs.naptar2).fullCalendar('addEventSource', day);
            $(self.refs.naptar2).fullCalendar('rerenderEvents');
          }
        }
      });
    } else {
      self.setState({
        idopont_modal_show: false,
        aktnap: '',
        aktkezd: '',
        aktveg: '',
        aktid: '',
        dropstarted: false
      });
    }
  },

  render() {
    var _this = this;
    return (
      <section>
      <Form ref="form">
        <div className="form-group">
            <div className="row">
              <div className="col-xs-12">
                <Select2 placeholder="Keresés" name="oraado_id" value={this.state.oraado_id} options={this.props.oraadok} onChange={this.onChangeOraado} />
              </div>
            </div>
        </div>
      </Form>
      <section className="oraadok_idopontok">
      <p><i>Új időpont hozzáadásához kattintson a kívánt időpontra a naptárban</i></p>
      <p>Kötelezően megadandó órák száma: {this.state.kotelezo_oraszam}<br/>
      Hozzáadott órák száma: {this.state.oraszam}</p>
      <p>Még megadandó órák száma: {this.state.kotelezo_oraszam - this.state.oraszam > 0 ? (<span style={{color: 'red'}}>{(this.state.kotelezo_oraszam - this.state.oraszam)}<br/><i>Ha a hozzáadott órák száma kevesebb, mint a kötelező, akkor az időpontok nem lesznek figyelembe véve az órarend készítésekor!</i></span>):("0")}</p>
      <div ref="naptar"></div>
      <div ref="naptar2"></div>

      <Modal show={this.state.idopont_modal_show} onHide={this.idopontModalHide}>
        <Modal.Header closeButton>
          <Modal.Title>Óra adatok</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form ref="form" onValid={this.formValid} onInvalid={this.formInvalid}>
            <div className="row">
              <div className="col-xs-4">
                <Select key={0} name="nap" title="Nap" value={this.state.aktnap} options={this.state.napok} validations="isNumeric" validationError="Nap kötelező" required disabled="true"/>
              </div>
              <div className="col-xs-4">
                <Select key={1} name="kezd" title="Kezdő időpont" value={this.state.aktkezd} options={this.state.naptar == 1 ? this.state.periodusListaKezd1 : this.state.periodusListaKezd2} validations="isNumeric" validationError="Kezdő időpont kötelező" required />
              </div>
              <div className="col-xs-4">
                <Select key={2} name="veg" title="Vég időpont" value={this.state.aktveg} options={this.state.naptar == 1 ? this.state.periodusListaVeg1 : this.state.periodusListaVeg2} validations="isNumeric" validationError="Vég időpont kötelező" required />
              </div>
            </div>
          </Form>

        </Modal.Body>
        <Modal.Footer>
        <Button onClick={this.idopontModalHide} bsStyle="primary" type="button">Bezár</Button>
        <Button onClick={this.idopontModalSave} type="button">Mentés</Button>
        </Modal.Footer>
      </Modal>
      </section>
      </section>
    );
  }
});

OraadoIdopontok.contextTypes = {
  store: React.PropTypes.object
}

export default OraadoIdopontok;