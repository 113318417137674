import React from 'react';
import Formsy from 'formsy-react';
import utils from 'formsy-react/lib/utils';

const Checkbox = React.createClass({
  mixins: [Formsy.Mixin],

  changeValue(event) {
    if (this.props.onChange != undefined)
      this.props.onChange(event.currentTarget.value);
  },

  onClick() {
    if(this.getValue() == 1) {
      this.setValue(0);
    } else {
      if(this.props.boxClickable) {
        this.setValue(1);
      }
    }

    if (this.props.onClick != undefined)
      this.props.onClick(this.getValue());
  },

  componentDidUpdate: function(prevProps, prevState) {
    if (!utils.isSame(prevState, this.state) && this.props.onChange != undefined) {
      this.props.onChange(this.getValue());
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    if (this.getValue() != nextState._value || nextState._formSubmitted || this.props.value != nextProps.value)
      return true;
    else
      return false;
  },

  render() {
    return (
      <div className="form-group checkbox">
          <div onClick={this.onClick} key={this.props.name} className={"checkbox "+this.props.className}>
            <input onChange={this.changeValue} checked={this.state._value == 1 ? true : false} type="checkbox" name={this.props.name} value={this.getValue()} />
            <label htmlFor={this.props.name}>{this.props.label}</label>
          </div>
      </div>
    );
  }
});

export default Checkbox;