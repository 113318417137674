import React from 'react';
import Ajax from './../Ajax.js';
import Select2 from './../form/Select2';

var Szak = React.createClass({
  getInitialState() {
    return {
      opciok: [],
      opciok2: [],
      szak_val: '',
      szakirany_val: ''
    }
  },

  componentDidMount: function() {
    var url = '/orarend/szakok/';
    if(this.props.fgv_id == 15) url = '/orarend/levelezos_szakok/';
    this.getSzakok(url);
  },

  componentWillReceiveProps: function(nextProps) {
    if(nextProps.fgv_id != this.props.fgv_id) {
      var url = '/orarend/szakok/';
      if(nextProps.fgv_id == 15) url = '/orarend/levelezos_szakok/';
      this.getSzakok(url);
    }
  },

  getSzakok: function(url) {
    var obj = this;
    var {store} = this.context;

    Ajax.call(url + localStorage.token, store, {
      async: false,
      success: function(data) {
        if(data.szakok) {
          obj.state.opciok = data.szakok;
          data.szakok.map(function(szak, i) {
            if(parseInt(szak.value) == obj.props.ertek) {
              obj.state.szak_val = szak.value;
            }
          });
          if(obj.state.szak_val == '' && obj.props.ertek != '') {
            Ajax.call('/feltetel/szakszakiranybol/' + localStorage.token + '/' + obj.props.ertek, store, {
              async: false,
              success: function(data) {
                obj.state.szak_val = data.szak.id;

                
                Ajax.call('/orarend/szakiranyok/' + localStorage.token + '/' + data.szak.id, store, {
                  async: false,
                  success: function(data) {
                    obj.setState({opciok2: data.szakiranyok});
                    obj.state.szakirany_val = obj.props.ertek.toString();
                  }
                });
              }
            });
          }
        }
      }
    });
  },

  onChangeSzak: function(val) {
    var obj = this;
    var {store} = this.context;

    Ajax.call('/orarend/szakiranyok/' + localStorage.token + '/' + val, store, {
      async: false,
      success: function(data) {
        obj.setState({opciok2: data.szakiranyok});
      }
    });
  },

  render: function() {
    return (
      <section>
      <Select2 placeholder="- válassz -" name={`feltetel[ertek][p_${this.props.id}][0]`} value={this.state.szak_val} title={this.props.label} options={this.state.opciok} onChange={this.onChangeSzak} validationError="Kötelező" required/>
      <Select2 placeholder="- válassz -" name={`feltetel[ertek][p_${this.props.id}][1]`} value={this.state.szakirany_val} title='Szakirány' options={this.state.opciok2} />
      </section>
    );
  }
});

Szak.contextTypes = {
  store: React.PropTypes.object
}

export default Szak;