import React from 'react';
import Ajax from './../../Ajax.js';
import {Form} from 'formsy-react';
import { Modal, Button } from 'react-bootstrap';
import Select2 from './../../form/Select2';

var SzakszuroModal = React.createClass({
  getInitialState() {
    return {
      szakok: [],
      felhasznalo_szakok: []
    }
  },

  componentDidMount: function() {
    $('#szakszuro_modal').draggable({
      start: function( event, ui ) {
        $('#szakszuro_modal').addClass('startdrag');
      }
    });
    this.ablakPozicio();
    this.getSzakok();
    this.getFelhasznaloSzakok();
  },

  ablakPozicio: function() {
    $(this.refs.szakszuro_modal).css({
      'left': $(window).innerWidth() / 2 - parseInt($('.main-panel').css('padding-left')),
      'top': $(window).scrollTop() + $(window).innerHeight() / 2 - parseInt($('.main-panel').css('padding-top')) - 200
    });
  },

  felhasznaloSzakDb: function() {
    return this.state.felhasznalo_szakok.length;
  },

  getFelhasznaloSzakokFromState: function() {
    return this.state.felhasznalo_szakok;
  },

  ment: function() {
    var _this = this;
    var {store} = this.context;

    var form_data = this.refs.szak_szuro_form.getModel();

    Ajax.call('/orarend/felhasznalo_szak_ment/' + localStorage.token + '/' + this.props.loggedUser.id + '/1', store, {
      method: "POST",
      data: form_data,
      success: function(data) {
        _this.setState({
          felhasznalo_szakok: form_data.felhasznalo_szakok
        }, function() {
          _this.props.hide();
          _this.props.getKurzuslista();
        })
      }
    });
  },

  getSzakok: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('/orarend/levelezos_szakok/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        _this.setState({
          szakok: data.szakok
        });
      }
    });
  },

  getFelhasznaloSzakok: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('/orarend/felhasznalo_szakok_szureshez/' + localStorage.token + '/' + this.props.loggedUser.id + '/1', store, {
      async: false,
      success: function(data) {
        _this.setState({
          felhasznalo_szakok: data.szakok
        }, function() {
          _this.props.getKurzuslista();
        });
      }
    });
  },

  render: function() {
    return (
      <Modal show={this.props.szakszuro_modal} onHide={this.props.hide}>
        <Modal.Header closeButton>
          <Modal.Title>Szak előszűrő</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref="szak_szuro_form">
            <Select2 name="felhasznalo_szakok" value={this.state.felhasznalo_szakok} multi={true} title="Szakok" options={this.state.szakok} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.hide} type="button">Mégsem</Button>
          <button ref="preloader" onClick={this.ment} className="btn btn-primary">Ment</button>
        </Modal.Footer>
      </Modal>
    )
  }
});

SzakszuroModal.contextTypes = {
  store: React.PropTypes.object
};

export default SzakszuroModal;