import React from 'react';
import { Link, Router, Route, hashHistory } from 'react-router';

var Header = React.createClass({

  menuToggleClick: function() {
    if (this.props.prop_config.menuOpen) {
      this.props.setConfigMenuOpen(false);
      /*$(this.refs.header).addClass('small');
      $('.fixed-top-menu-outer').addClass('small');
      $('.main-panel').addClass('small');*/
    } else {
      this.props.setConfigMenuOpen(true);
      /*$(this.refs.header).removeClass('small');
      $('.fixed-top-menu-outer').removeClass('small');
      $('.main-panel').removeClass('small');*/
    }
  },

	render: function() {
		return (
    <header className="small" ref="header">
      <a href="#" className="logo"><img alt="" src="img/logo.png"/></a>
      <a {...this.props} onClick={this.menuToggleClick} id="menu-toggle" className="menu-toggle active"><i className="fa fa-bars"></i></a>
      <div className="ork">
        <h1><i className="material-icons">access_time</i>RKa<span> - Órarendkészítő </span><span className="smalltext">alkalmazás</span></h1>
        <p>A Széchenyi István Egyetem órarendkészítő felülete.</p>
      </div>
    </header>
    );
	}
});

export default Header;