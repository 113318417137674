import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import ArrayHelper from './ArrayHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import utils from 'formsy-react/lib/utils';
import Pagination from './Pagination';

var EroforrasokTelephelyek = React.createClass({
  getInitialState() {
    return {
      telephelyek: [],
      lap: 1,
      limit: 100,
      db: 0
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {
  },

  getTelephelyek: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('eroforrasok/telephelylista/' + localStorage.token, store, {
      method: "POST",
      data: {
        lap: this.state.lap,
        limit: this.state.limit
      },
      success: function(data) {
        obj.setState({telephelyek: data.telephelyek, db: data.db});
        jQueryHelper.setDropDownToggle(obj);
      }
    });
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
    jQueryHelper.setDropDownToggle(this);
    this.getTelephelyek();
  },

  telephelyExport: function() {
    var {store} = this.context;
    var obj = this;
    swal({
      title: "Telephely adatok tartós tárba mentése",
      text: "Az adatok mentése több másodpercet is igénybe vehet!",
      type: "info",
      showCancelButton: true,
      closeOnConfirm: false,
      confirmButtonText: "Folytatás",
      cancelButtonText: "Mégse",
      showLoaderOnConfirm: true
    }, function () {
      Ajax.call('eroforrasok/telephelyek_export/' + localStorage.token, store, {
        method: "POST",
        data: {
          token: localStorage.token
        },
        success: function(data) {
          if(data.status == "OK") {
            swal("Kész!", "A mentés sikerült!", "success");
          } else {
            swal("A mentés nem sikerült!", "Váratlan hiba történt, kérem próbálja újból!", "error");
            console.log(data.message);
          }
        }, error: function() {
          swal("A mentés nem sikerült!", "Váratlan hiba történt, kérem próbálja újból!", "error");
        }
      });
    });
  },

  telephelyImport: function() {
    var {store} = this.context;
    var obj = this;
    swal({
      title: "Telephely adatok szinkronizálása tartós tárból",
      text: "Az adatok szinkronizálása több másodpercet is igénybe vehet!",
      type: "info",
      showCancelButton: true,
      closeOnConfirm: false,
      confirmButtonText: "Folytatás",
      cancelButtonText: "Mégse",
      showLoaderOnConfirm: true
    }, function () {
      Ajax.call('eroforrasok/telephelyek_import/' + localStorage.token, store, {
        method: "POST",
        data: {
          token: localStorage.token
        },
        success: function(data) {
          if(data.status == "OK") {
            swal("Kész!", "A szinkronizálása sikerült!", "success");
            obj.getTelephelyek();
          } else {
            swal("A szinkronizálása nem sikerült!", "Váratlan hiba történt, kérem próbálja újból!", "error");
            console.log(data.message);
          }
        }, error: function() {
          swal("A szinkronizálása nem sikerült!", "Váratlan hiba történt, kérem próbálja újból!", "error");
        }
      });
    });
  },

  render: function() {
    var _this = this;

      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              {/*<Link className="btn btn-primary" to="/eroforrasok_uj_telephely"><i className="fa fa-plus"></i>Új telephely</Link>*/}
              <button onClick={this.telephelyExport} className="btn btn-primary" type="button"><i className="fa fa-cloud-upload"></i>Tartós tárba mentés</button>
              <button onClick={this.telephelyImport} className="btn btn-primary" type="button"><i className="fa fa-cloud-download"></i>Szinkronizálás</button>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Erőforrások
              </li>
              <li>
                Telephelyek
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Erőforrások, telephelyek</h1>
            </div>
            <div className="content-panel-top-btn-group">
              {/*<Link className="btn btn-primary" to="/eroforrasok_uj_telephely"><i className="fa fa-plus"></i>Új telephely</Link>*/}
              <button onClick={this.telephelyExport} className="btn btn-primary" type="button"><i className="fa fa-cloud-upload"></i>Tartós tárba mentés</button>
              <button onClick={this.telephelyImport} className="btn btn-primary" type="button"><i className="fa fa-cloud-download"></i>Szinkronizálás</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Telephelyek</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                  <Pagination cb={(lap) => { _this.setState({lap:lap}, _this.getTelephelyek); }} db={this.state.db} page={this.state.lap} limit={this.state.limit} />
                  {this.state.telephelyek.length > 0 ? (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Név</th>
                          <th>Rövid név</th>
                          <th>Kód</th>
                          <th>Város</th>
                          <th>Szótár</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.telephelyek.map(telephely => (
                         <tr key={telephely.id}>
                            <td>{telephely.nev}</td>
                            <td>{telephely.rovidnev}</td>
                            <td>{telephely.kod}</td>
                            <td>{telephely.varos}</td>
                            <td>{telephely.szotar_nev} ({telephely.real_id})</td>
                            <td style={{"textAlign": "center"}}>
                              <ul className="icon-list">
                                <li className="dropdown"><a className="dropdown-toggle" href="javascript:void(0)"><i className="fa fa-gear"></i></a>
                                  <ul className="dropdown-menu">
                                    <li className="dropdown-header">Opciók</li>
                                    <li><Link to={`/eroforrasok_telephely/${telephely.id}`}><i className="fa fa-pencil-square-o"></i>Szerkeszt</Link></li>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))
                      }
                      </tbody>
                    </table>

                    ) : (<div>Nem található telephely.</div>)}
                    <Pagination cb={(lap) => { _this.setState({lap:lap}, _this.getTelephelyek); }} db={this.state.db} page={this.state.lap} limit={this.state.limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

EroforrasokTelephelyek.contextTypes = {
  store: React.PropTypes.object
}

export default EroforrasokTelephelyek;
