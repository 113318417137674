import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button, Tabs, Tab} from 'react-bootstrap';
import { Form } from 'formsy-react';
import Select from './form/Select';
import Select2 from './form/Select2';
import Toggle from './form/Toggle';
import Input from './form/Input';

var KiirandoKurzusok = React.createClass({
  getInitialState() {
    return {
      kurzusok: [],
      tanszekek: [
            { value: '', label: '- válassz -' }
          ],
      felvetelTipusok: [
          { value: 'kotelezo', label: 'Kötelező' },
          { value: 'valaszthato', label: 'Választható' }
      ],
      munkarendek: [
        { value: '1', label: 'nappali' },
        { value: '2', label: 'levelező' }
      ],
      tanszek_id: '',
      felvetel_id: 'kotelezo',
      munkarend: 1,
      activeTab: 1,
      inaktivalasShow: false,
      importButtonShow: false,
      idoszakok: [],
      idoszak_id: 0
    }
  },

//  componentDidUpdate: function() {
//    var {store} = this.context;
//    if (!this.props.prop_loggedUser.aktiv_idoszak) {
//      store.dispatch({type: 'SET_OLDAL_UZENET', tipus: 'alert alert-danger', uzenet: 'Először válasszon időszakot!'});
//      hashHistory.push('/uzenet');
//    }
//  },

  componentWillMount: function() {
    var obj = this;
    var {store} = this.context;
    var activeTab = this.state.activeTab;
    var inaktivalasShow = false;
    var importButtonShow = false;
    var idoszak_id;

    //console.log(obj.props.prop_loggedUser.aktiv_idoszak);

    if(!obj.props.prop_loggedUser.aktiv_idoszak) {
      store.dispatch({type: 'SET_OLDAL_UZENET', tipus: 'alert alert-danger', uzenet: 'Először válasszon időszakot!'});
      hashHistory.push('/uzenet');
    } else {
      Ajax.call('get_user_idoszak_id/' + parseInt(this.props.prop_loggedUser.id), store, {
        async: false,
        success: function(data) {
          // Nincs beállítva időszak, akkor a loggedUser-ből veszi az időszakot
          if (data.idoszak_id == 0) {
            idoszak_id = obj.props.prop_loggedUser.aktiv_idoszak.id;
          } else {
            idoszak_id = data.idoszak_id;
          }
        }
      });

      Ajax.call('idoszakok_orarend/' + localStorage.token, store, {
        async: false,
        success: function(data) {
          var tmp = [];
          data.idoszakok.map(function(idoszak) {
            tmp.push({
              label: idoszak.megnevezes,
              value: parseInt(idoszak.id)
            });
          });
          obj.setState({
            idoszakok: tmp,
            idoszak_id: idoszak_id
          });
        }
      });

      Ajax.call('users/checkruleajax', store, {
        method: 'POST',
        async: false,
        data: {
          user_id: obj.props.prop_loggedUser.id,
          rule: "kiirando_kurzusok/inaktivalas"
        },
        success: function(data) {
          if(data.access == 'OK') {
            inaktivalasShow = true;
          }
        }
      });

      Ajax.call('users/checkruleajax', store, {
        method: 'POST',
        async: false,
        data: {
          user_id: obj.props.prop_loggedUser.id,
          rule: "kiirando_kurzusok/import"
        },
        success: function(data) {
          if(data.access == 'OK') {
            importButtonShow = true;
          }
        }
      });

      // Átmenetileg beállítja tokenbe a kiválasztott időszakhoz tartozó órarend adatbázis nevet
      if (idoszak_id != this.props.prop_loggedUser.aktiv_idoszak.id) {
        Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben_idoszakidbol/' + localStorage.token + '/' + idoszak_id, store, {
          async: false
        });
      }

      Ajax.call('kurzusok_tanszekek/' + localStorage.token + '/0/0/' + obj.props.prop_loggedUser.group_id + '/' + obj.props.prop_loggedUser.id, store, {
        async: false,
        success: function(data) {

          if(data.tanszekek) {
            data.tanszekek = [
              {
                value: '',
                label: '- válassz -'
              },
              ...data.tanszekek
            ];
          } else {
            data.tanszekek = [
              {
                value: '',
                label: '- válassz -'
              }
            ];
          }

          obj.setState({tanszekek: data.tanszekek, inaktivalasShow: inaktivalasShow, importButtonShow: importButtonShow});
        }
      });

      // Visszaállítja az átmenetileg beállított adatbázis nevet az eredetire
      Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben/' + localStorage.token + '/' + this.props.prop_loggedUser.aktiv_orarend.adatbazis, store, {
        async: false
      });
    }
  },

  componentDidMount: function() {
   this.setFixedMenu();
  },

  handleSelect: function (activeTab) {

    this.setState({
      activeTab: activeTab
    });

    this.getKurzusok(activeTab);

  },

  getKurzusok: function(activeTab) {

    var obj = this;
    var {store} = this.context;
    var munkarend = this.state.munkarend;

    if(activeTab == 1) {
      munkarend = 1;
    } else if (activeTab == 2) {
      munkarend = 2;
    }

    if (this.state.idoszak_id != 0 && this.state.idoszak_id != this.props.prop_loggedUser.aktiv_idoszak.id) {
      Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben_idoszakidbol/' + localStorage.token + '/' + this.state.idoszak_id, store, {
        async: false
      });
    }

    if(activeTab == 1 || activeTab == 2) {
      Ajax.call('kiirando_kurzusok/' + localStorage.token + '/' + munkarend + '/' + obj.state.idoszak_id + '/' + this.state.tanszek_id + '/' + this.state.felvetel_id, store, {
        success: function(data) {
          obj.setState({kurzusok:data.kurzusok, munkarend: munkarend});

          data.kurzusok.map(function(kurzus,index){
            kurzus.showReszletek = false;
          });

          if (obj.state.idoszak_id != 0) {
            Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_orarend.adatbazis, store, {
              async: false
            });
          }
        }
      });
    } else if(activeTab == 3) {
      Ajax.call('inaktivalt_kurzusok/' + localStorage.token + '/' + munkarend + '/' + obj.state.idoszak_id + '/' + this.state.tanszek_id + '/' + this.state.felvetel_id, store, {
        success: function(data) {
          obj.setState({kurzusok:data.kurzusok, munkarend: munkarend});

          if (obj.state.idoszak_id != 0) {
            Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_orarend.adatbazis, store, {
              async: false
            });
          }
        }
      });
    }
  },

  setFixedMenu: function() {
    var obj = this;
    $(window).scroll(function() {
      if ($(window).scrollTop() > 100) {
        $(obj.refs.fixed_top_menu_outer).addClass('active');
      } else {
        $(obj.refs.fixed_top_menu_outer).removeClass('active');
      }
    });
  },

  onChangeIdoszak: function(val) {
    var obj = this;
    var {store} = this.context;

    this.setState({'idoszak_id':val}, function() {

      // Átmenetileg beállítja tokenbe a kiválasztott időszakhoz tartozó órarend adatbázis nevet
      if (obj.state.idoszak_id != 0 && obj.state.idoszak_id != obj.props.prop_loggedUser.aktiv_idoszak.id) {
        Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben_idoszakidbol/' + localStorage.token + '/' + obj.state.idoszak_id, store, {
          async: false
        });
      }

      Ajax.call('idoszak_id_mentes_felhasznalohoz/' + parseInt(this.state.idoszak_id) + '/' + parseInt(this.props.prop_loggedUser.id), store, {
        async: false
      });

      Ajax.call('kurzusok_tanszekek/' + localStorage.token + '/0/0/' + obj.props.prop_loggedUser.group_id + '/' + obj.props.prop_loggedUser.id, store, {
        async: false,
        success: function(data) {

          if(data.tanszekek) {
            data.tanszekek = [
              {
                value: '',
                label: '- válassz -'
              },
              ...data.tanszekek
            ];
          } else {
            data.tanszekek = [
              {
                value: '',
                label: '- válassz -'
              }
            ];
          }

          obj.setState({tanszekek: data.tanszekek});
        }
      });

      // Visszaállítja az átmenetileg beállított adatbázis nevet az eredetire
      if (obj.state.idoszak_id != 0) {
        Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_orarend.adatbazis, store, {
          async: false
        });
      }

      obj.getKurzusok(obj.state.activeTab);
    });
  },

  onChangeTanszek: function(val) {
    var obj = this;
    var {store} = this.context;
    var activeTab = this.state.activeTab;

    if (this.state.idoszak_id != 0 && this.state.idoszak_id != this.props.prop_loggedUser.aktiv_idoszak.id) {
      Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben_idoszakidbol/' + localStorage.token + '/' + this.state.idoszak_id, store, {
        async: false
      });
    }

    var tanszek_id = val;
    if(activeTab != 3) {
      Ajax.call('kiirando_kurzusok/' + localStorage.token + '/' + this.state.munkarend + '/' + obj.state.idoszak_id + '/' + tanszek_id + '/' + this.state.felvetel_id, store, {
        async: false,
        success: function(data) {
          obj.setState({kurzusok:data.kurzusok, tanszek_id: tanszek_id});
        }
      });
    } else {
      Ajax.call('inaktivalt_kurzusok/' + localStorage.token + '/' + this.state.munkarend + '/' + obj.state.idoszak_id + '/' + tanszek_id + '/' + this.state.felvetel_id, store, {
        async: false,
        success: function(data) {
          obj.setState({kurzusok:data.kurzusok, tanszek_id: tanszek_id});
        }
      });
    }

    if (obj.state.idoszak_id != 0) {
      Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_orarend.adatbazis, store, {
        async: false
      });
    }
  },

  onChangeFelvetelTipus: function(val) {
    var obj = this;
    var {store} = this.context;
    var activeTab = this.state.activeTab;

    if (this.state.idoszak_id != 0 && this.state.idoszak_id != this.props.prop_loggedUser.aktiv_idoszak.id) {
      Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben_idoszakidbol/' + localStorage.token + '/' + this.state.idoszak_id, store, {
        async: false
      });
    }

    var felvetel_id = val
    if(activeTab != 3) {
      Ajax.call('kiirando_kurzusok/' + localStorage.token + '/' + this.state.munkarend + '/' + obj.state.idoszak_id + '/' + this.state.tanszek_id + '/' + felvetel_id, store, {
        async: false,
        success: function(data) {
          obj.setState({kurzusok:data.kurzusok, felvetel_id: felvetel_id});
        }
      });
    } else {
      Ajax.call('inaktivalt_kurzusok/' + localStorage.token + '/' + this.state.munkarend + '/' + obj.state.idoszak_id + '/' + this.state.tanszek_id + '/' + felvetel_id, store, {
        async: false,
        success: function(data) {
          obj.setState({kurzusok:data.kurzusok, felvetel_id: felvetel_id});
        }
      });
    }

    if (obj.state.idoszak_id != 0) {
      Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_orarend.adatbazis, store, {
        async: false
      });
    }
  },

  onChangeMunkarendek: function(val) {
    var obj = this;
    var {store} = this.context;
    var activeTab = this.state.activeTab;

    if (this.state.idoszak_id != 0 && this.state.idoszak_id != this.props.prop_loggedUser.aktiv_idoszak.id) {
      Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben_idoszakidbol/' + localStorage.token + '/' + this.state.idoszak_id, store, {
        async: false
      });
    }

    var munkarend = val;
    if(activeTab != 3) {
      Ajax.call('kiirando_kurzusok/' + localStorage.token + '/' + munkarend + '/' + obj.state.idoszak_id + '/' + this.state.tanszek_id + '/' + this.state.felvetel_id, store, {
        async: false,
        success: function(data) {
          obj.setState({kurzusok:data.kurzusok, munkarend: munkarend});
        }
      });
    } else {
      Ajax.call('inaktivalt_kurzusok/' + localStorage.token + '/' + munkarend + '/' + obj.state.idoszak_id + '/' + this.state.tanszek_id + '/' + this.state.felvetel_id, store, {
        async: false,
        success: function(data) {
          obj.setState({kurzusok:data.kurzusok, munkarend: munkarend});
        }
      });
    }

    if (obj.state.idoszak_id != 0) {
      Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_orarend.adatbazis, store, {
        async: false
      });
    }
  },

  dropdownIconClick: function(targykod) {
    var obj = this;
    var {store} = this.context;
    var kurzusok = this.state.kurzusok;

    if (this.state.idoszak_id != 0 && this.state.idoszak_id != this.props.prop_loggedUser.aktiv_idoszak.id) {
      Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben_idoszakidbol/' + localStorage.token + '/' + this.state.idoszak_id, store, {
        async: false
      });
    }

    kurzusok.map(function(kurzus,index){
      if (kurzus.targykod == targykod) {
        kurzus.showReszletek = kurzus.showReszletek ? false : true;
        if(kurzus.showReszletek) {
          Ajax.call('kiirando_kurzus_reszletek/' + localStorage.token + '/' + obj.state.idoszak_id + '/' + targykod, store, {
            async: false,
            success: function(data) {
              kurzus.reszletAdatok = data.reszletek;
              kurzus.reszletMessage = data.message;

              obj.setState({
                kurzusok: kurzusok
              });
            }, error: function() {
              kurzus.reszletek.message = 'Hiba a lekérdezés során!';

              obj.setState({
                kurzusok: kurzusok
              });
            }
          });
        } else {
          obj.setState({
            kurzusok: kurzusok
          });
        }
      }
    });

    if (obj.state.idoszak_id != 0) {
      Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_orarend.adatbazis, store, {
        async: false
      });
    }
  },

  toggleClick: function(value,targykod) {
    var obj = this;
    var {store} = this.context;

    if (this.state.idoszak_id != 0 && this.state.idoszak_id != this.props.prop_loggedUser.aktiv_idoszak.id) {
      Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben_idoszakidbol/' + localStorage.token + '/' + this.state.idoszak_id, store, {
        async: false
      });
    }

    Ajax.call('kiirando_kurzus_inaktivalas', store, {
      method: "POST",
      async: false,
      data: {
        felev_id: obj.state.idoszak_id,
        ertek: value,
        targykod: targykod,
        token: localStorage.token
      },
      success: function() {

      }, error: function() {

      }
    });

    if (obj.state.idoszak_id != 0) {
      Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_orarend.adatbazis, store, {
        async: false
      });
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillUpdate: function() {
  },

  importKurzusok: function() {
    var obj = this;
    var {store} = this.context;

    if(obj.props.prop_loggedUser.aktiv_idoszak.felev_kezdete == '0000-00-00') {
      swal("Az importálás nem indítható!", "A félév kezdete nincs megfelelően beállítva!", "error");
    } else if(obj.props.prop_loggedUser.aktiv_idoszak.id != obj.state.idoszak_id) {
      swal("Az importálás nem indítható!", "Csak az Időszakok, órarendek menüpontban kiválasztott időszakba lehet importálni!", "error");
    } else {
      swal({
        title: "Kiírandó kurzusok importálása",
        text: "A szükséges adatok neptun rendszerből történő importálása több percet is igénybe vehet!",
        type: "info",
        showCancelButton: true,
        closeOnConfirm: false,
        confirmButtonText: "Folytatás",
        cancelButtonText: "Mégse",
        showLoaderOnConfirm: true
      }, function () {
        Ajax.call('import_kiirando_kurzusok', store, {
          method: "POST",
          data: {
            felev_kezdete: obj.props.prop_loggedUser.aktiv_idoszak.felev_kezdete,
            token: localStorage.token
          },
          success: function(data) {
            if(data.status == "OK") {
              swal("Kész!", "Az importálás sikerült!", "success");
            } else {
              swal("Az importálás nem sikerült!", "Váratlan hiba történt, kérem próbálja újból!", "error");
              console.log(data.message);
            }
          }, error: function() {
            swal("Az importálás nem sikerült!", "Váratlan hiba történt, kérem próbálja újból!", "error");
          }
        });
      });
    }
  },

  generatePDF: function() {

    var obj = this;
    var {store} = this.context;

    var munkarend = this.state.munkarend;
    var tanszek_id = this.state.tanszek_id;
    var felvetel_id = this.state.felvetel_id;

    if (this.state.idoszak_id != 0 && this.state.idoszak_id != this.props.prop_loggedUser.aktiv_idoszak.id) {
      Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben_idoszakidbol/' + localStorage.token + '/' + this.state.idoszak_id, store, {
        async: false
      });
    }

    swal({
      title: "PDF fájl generálása",
      text: "A kiválasztott kurzusok számának függvényében a generálás több másodpercet is igénybe vehet!",
      type: "info",
      showCancelButton: true,
      closeOnConfirm: false,
      confirmButtonText: "Folytatás",
      cancelButtonText: "Mégse",
      showLoaderOnConfirm: true
    }, function (confirmed) {
      if (confirmed) {
        Ajax.call('kiirando_kurzusok_pdf/' + localStorage.token + '/' + munkarend + '/' + obj.state.idoszak_id + '/' + tanszek_id + '/' + felvetel_id, store, {
          success: function(data) {
            swal({
              title: "Sikeres generálás!",
              text: "A fájl megtekintéséhez kattintson a letöltés gombra!",
              type: "success",
              showCancelButton: true,
              confirmButtonClass: "btn-primary",
              confirmButtonText: "Letöltés",
              cancelButtonText: "Bezárás",
              closeOnConfirm: false,
              closeOnCancel: true
            },
            function(isConfirm){
              if (isConfirm) {
                window.open("/api/libraries/mpdf60/tmp/kiirando_kurzusok_export_"+data.url+".pdf");
              } else {
                Ajax.call('kiirando_kurzusok_deletepdf', store, {
                  method: "POST",
                  data: {
                    date: data.url,
                    token: localStorage.token
                  },
                  success: function() {

                  }, error: function() {

                  }
                });
              }
            });

            if (obj.state.idoszak_id != 0) {
              Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_orarend.adatbazis, store, {
                async: false
              });
            }

          }, error: function() {
            swal("A generálás nem sikerült!", "Váratlan hiba történt, kérem próbálja újból!", "error");

            if (obj.state.idoszak_id != 0) {
              Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_orarend.adatbazis, store, {
                async: false
              });
            }
          }
        });
      } else {
        if (obj.state.idoszak_id != 0) {
          Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_orarend.adatbazis, store, {
            async: false
          });
        }
      }
    });
  },

  render: function() {
    var obj = this;

    return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              {this.state.importButtonShow ? (
                <button onClick={this.importKurzusok} className="btn btn-primary" type="button"><i className="fa fa-sign-in"></i>Importálás</button>
              ):("")}
              <button onClick={this.generatePDF} className="btn btn-primary" type="button"><i className="fa fa-file-pdf-o"></i>Exportálás (PDF)</button>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal / Kurzusok
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Kiírandó kurzusok</h1>
            </div>
            <div className="content-panel-top-btn-group">
              {this.state.importButtonShow ? (
                <button onClick={this.importKurzusok} className="btn btn-primary" type="button"><i className="fa fa-sign-in"></i>Importálás</button>
              ):("")}
              <button onClick={this.generatePDF} id="btn-import" className="btn btn-primary" type="button"><i className="fa fa-file-pdf-o"></i>Exportálás (PDF)</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Kiírandó kurzusok</h4>
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-xs-12">
                    <Tabs defaultActiveKey={1} id="tabs" activeKey={this.state.activeTab} onSelect={this.handleSelect}>
                      <Tab eventKey={1} title={<span>Nappali</span>}>
                        {this.state.activeTab == 1 ? (
                        <KurzusLista {...this.state} eventKey={1} onChangeFelvetelTipus={this.onChangeFelvetelTipus} onChangeTanszek={this.onChangeTanszek} dropdownIconClick={this.dropdownIconClick} toggleClick={this.toggleClick} munkarend={this.state.munkarend} onChangeIdoszak={this.onChangeIdoszak} />
                         ) : ("")}
                      </Tab>
                      <Tab eventKey={2} title={<span>Levelező</span>}>
                        {this.state.activeTab == 2 ? (
                        <KurzusLista {...this.state} eventKey={2} onChangeFelvetelTipus={this.onChangeFelvetelTipus} onChangeTanszek={this.onChangeTanszek} dropdownIconClick={this.dropdownIconClick} toggleClick={this.toggleClick} munkarend={this.state.munkarend} onChangeIdoszak={this.onChangeIdoszak} />
                         ) : ("")}
                      </Tab>
                      {obj.state.inaktivalasShow == true ? (
                      <Tab eventKey={3} title={<span>Tárgyak inaktíválása</span>}>
                        {this.state.activeTab == 3 ? (
                        <KurzusLista {...this.state} eventKey={3} onChangeMunkarendek={this.onChangeMunkarendek} onChangeFelvetelTipus={this.onChangeFelvetelTipus} onChangeTanszek={this.onChangeTanszek} dropdownIconClick={this.dropdownIconClick} toggleClick={this.toggleClick} munkarend={this.state.munkarend} onChangeIdoszak={this.onChangeIdoszak} />
                        ) : ("")}
                      </Tab>
                      ):("")}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
});

KiirandoKurzusok.contextTypes = {
  store: React.PropTypes.object
}

var KurzusLista = React.createClass({
  getInitialState() {
    return {}
  },
  render() {
    var obj = this;

    return (
      <section>
        <div className="row">
          <div className="col-xs-6">
            <div className="panel-sub-heading">
              <h4>Szűrés</h4>
            </div>
          </div>
        </div>
      <Form ref="form">
        <div className="form-group">
          <div className="row">
            <div className="col-xs-3">
              <Select onChange={this.props.onChangeIdoszak} name="idoszak_id" value={this.props.idoszak_id} title="Időszak" options={this.props.idoszakok}/>
            </div>
          </div>
          {obj.props.eventKey != 3 ? (
            <div className="row">
              <div className="col-xs-6">
                <Select2 title="Tanszék" name="tanszek_id" value={this.props.tanszek_id} options={this.props.tanszekek} onChange={this.props.onChangeTanszek} />
              </div>
              <div className="col-xs-6">
                <Select title="Tárgy típusa" name="felvetel_id" value={this.props.felvetel_id} options={this.props.felvetelTipusok} onChange={this.props.onChangeFelvetelTipus} />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-xs-4">
                <Select2 title="Tanszék" name="tanszek_id" value={this.props.tanszek_id} options={this.props.tanszekek} onChange={this.props.onChangeTanszek} />
              </div>
              <div className="col-xs-4">
                <Select title="Munkarend" name="munkarend_id" value={this.props.munkarend} options={this.props.munkarendek} onChange={this.props.onChangeMunkarendek} />
              </div>
              <div className="col-xs-4">
                <Select title="Tárgy típusa" name="felvetel_id" value={this.props.felvetel_id} options={this.props.felvetelTipusok} onChange={this.props.onChangeFelvetelTipus} />
              </div>
            </div>
          )}
        </div>
      </Form>
      <div className="panel-sub-heading">
        <h4>Kiírandó kurzus lista</h4>
      </div>
      {this.props.kurzusok != null && this.props.kurzusok.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Tantárgy neve</th>
              <th>Tantárgy kód</th>
              <th>Elméleti órák</th>
              <th>Gyakorlati órák</th>
              <th>Labor órák</th>
              <th>Korábbi létszám</th>
              {obj.props.eventKey == 3 ? (<th>Látható</th>):(<th></th>)}
            </tr>
          </thead>

          {this.props.kurzusok.map(kurzus => (
              <Kurzusok eventKey={obj.props.eventKey} key={kurzus.targykod} kurzus={kurzus} dropdownIconClick={this.dropdownIconClick} toggleClick={this.toggleClick} {...this.props} munkarend={this.props.munkarend} />
          ))}

        </table>
      ) : (<div>Nem található a beállított feltételeknek megfelelő egyetlen kiírandó kurzus sem.</div>)}
      </section>
    )
  }
});

var Kurzusok = React.createClass({
  getInitialState() {
    return {}
  },
  render() {
    var obj = this;
    var className = this.props.kurzus.showReszletek ? 'fa fa-angle-up' : 'fa fa-angle-down';

    return (
      <tbody key={this.props.kurzus.targykod}>
      <tr>
        <td>{this.props.kurzus.targynev}</td>
        <td>{this.props.kurzus.targykod}</td>
        <td>{this.props.munkarend == 1 ? (this.props.kurzus.hetioraszam1) : (this.props.kurzus.felevesoraszam1)}</td>
        <td>{this.props.munkarend == 1 ? (this.props.kurzus.hetioraszam2) : (this.props.kurzus.felevesoraszam2)}</td>
        <td>{this.props.munkarend == 1 ? (this.props.kurzus.hetioraszam3) : (this.props.kurzus.felevesoraszam3)}</td>
        <td>{this.props.kurzus.osszletszam}</td>
        <td style={{"textAlign": "center"}}>
          <ul className="icon-list">
            {obj.props.eventKey != 3 ? (
              <li><a className="dropdown-toggle" href="javascript:void 0;" onClick={() => this.props.dropdownIconClick(this.props.kurzus.targykod)}><i className={className}></i></a></li>
            ) : (
              <Form>
                <Toggle name="kurzus.aktiv" toggleClick={this.toggleClick} onClick={(value) => this.props.toggleClick(value, this.props.kurzus.targykod)} value={this.props.kurzus.aktiv}/>
              </Form>
            )}
          </ul>
        </td>
      </tr>
      {obj.props.eventKey != 3 ? (
        <Reszletek kurzus={this.props.kurzus} eventKey={obj.props.eventKey} />
      ) : (null)}
      </tbody>
    )
  }
});

var Reszletek = React.createClass({
  getInitialState() {
    return {

    }
  },
  render() {
    return (
      (this.props.kurzus.showReszletek ? (
        <tr>
          <td className="detailsTr" colSpan="7">
            <table className="detailsTable">
                {this.props.kurzus.reszletMessage == 'OK' ? (
                    <KurzusReszletek key={this.props.kurzus.targykod} reszletAdatok={this.props.kurzus.reszletAdatok} kurzus={this.props.kurzus} eventKey={this.props.eventKey}/>
                ):(
                  <tbody>
                    <tr>
                      <td>{this.props.kurzus.reszletMessage}</td>
                    </tr>
                  </tbody>
                )}
            </table>
          </td>
        </tr>
      ):null)
    )
  }
});

var KurzusReszletek = React.createClass({
  getInitialState() {
    return {}
  },
  render() {
    var obj = this;

    return (
      <tbody key={this.props.kurzus.targykod}>
      {this.props.reszletAdatok.map(reszletAdat => (
        this.props.eventKey == reszletAdat.munkarend ? (
        <tr className={reszletAdat.kifuto == true ? ('kifuto') : ('nem_kifuto')} key={reszletAdat.mntkod}>
          <td>{reszletAdat.mntkod}</td>
          <td>{reszletAdat.mntneve}</td>
          <td>{reszletAdat.targyfelvtipusa_szoveg}</td>
          <td>{reszletAdat.kotelezofelev}. félév</td>
          <td>{reszletAdat.elozoletszam} hallgató</td>
        </tr>
        ):("")
      ))}
      </tbody>
    )
  }
});

export default KiirandoKurzusok;
