import React from 'react';
import Formsy from 'formsy-react';

var timeout;

const MultiDate = React.createClass({
  mixins: [Formsy.Mixin],

  changeValue(event) {
    var self = this;

    if (this.props.onChange != undefined)
      this.props.onChange(event.currentTarget.value);
  },

  componentDidUpdate: function() {

  },

  componentDidMount: function() {
    var _this = this;
    var dates;

    $(this.refs.mdate).multiDatesPicker({
      dateFormat: "yy-mm-dd",
      firstDay: 1,
      onSelect: function() {
        var value = $(_this.refs.mdate).multiDatesPicker('getDates');
        _this.setValue(value);
      },
    });

    if (Array.isArray(this.getValue()) && this.getValue().length > 0)
      this.addDates(_this.getValue());
  },

  addDates: function(dates) {
    //$(this.refs.mdate).multiDatesPicker('removeDates');
    var value = $(this.refs.mdate).multiDatesPicker('addDates', dates);
    this.setValue(dates);
  },

  render() {

    var className = 'form-group' + (this.props.className || ' ') +
      (this.showRequired() ? 'required' : this.showError() ? 'has-error' : '');

    var errorMessage = this.getErrorMessage();

    if (this.isFormSubmitted()) {
      if (!this.isValid()) {
        className = 'form-group has-error';
        errorMessage = this.getErrorMessage() ? this.getErrorMessage() : this.props.validationError;
      }
    }

    return (
      <div className={className}>
        {this.props.title != '' ? (<label className="form-label" htmlFor={this.props.name}>{this.props.title} {this.isRequired() ? '*' : null}</label>) : null}

        {this.props.addonTitle != undefined ? (
          <div className="input-group">
            <div ref="mdate"/>
            <input
              className="form-control"
              ref="input"
              type='hidden'
              name={this.props.name}
              onChange={this.changeValue}
              value={this.getValue()}
            />
            <span className="input-group-addon">
              {this.props.addonTitle}
            </span>
          </div>)
        : (
          <div>
            <div className="multidatepicker" ref="mdate"></div>
            <input
              disabled={this.props.disabled ? true : false}
              className="form-control"
              ref="input"
              type='hidden'
              name={this.props.name}
              onChange={this.changeValue}
              value={this.getValue()}
            />
          </div>
        )}
        <span className="validation-error">{errorMessage}</span>
      </div>
    );
  }
});

export default MultiDate;