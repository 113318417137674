import React from 'react';

var AjaxPreloader = React.createClass({
  getInitialState() {
    return {
    }
  },
  componentDidUpdate: function() {
    if (this.props.show == 1) {
      $.fn.spin.presets.preloader = {lines:13, length:0, width:3, radius:20, scale:.75, corners:1, opacity:.25, speed:1};
      $(this.refs.ajax_preloader).spin('preloader');
    }
  },
  render: function() {
    return (
      <div>
        {this.props.show == 1 ?
        (<div className="overlayer" style={{'left':'0px', 'top':'0px'}}>
          <div ref="ajax_preloader" className="preloader clearfix"></div>
        </div>) : null}
      </div>
    )
  }
});

export default AjaxPreloader;