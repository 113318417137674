import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import {Form} from 'formsy-react';
import Input from './../form/Input';
import Textarea from './../form/Textarea';
import Colorpicker from './../form/Colorpicker';
import Ajax from './../Ajax.js';
import {Link} from 'react-router';

var OraAdatokModal = React.createClass({
  getInitialState() {
    return {
      form_valid: false,
      toltes: false,
      napok: ['Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek']
    }
  },

  componentDidUpdate: function(prevProps, prevState) {

  },

  componentDidMount: function() {
    var _this = this;
    var {store} = this.context;

//    this.setState({
//      toltes: true
//    });
//
//    $.fn.spin.presets.preloader = {lines:13, length:0, width:3, radius:10, scale:.75, corners:1, opacity:.25, speed:1};
//    $(this.refs.preloader).spin('preloader');
//
//    setTimeout(function() {
//      Ajax.call('orarend/ora/' + localStorage.token + '/' + _this.props.ora_adatok.ora_id + '/' + _this.props.loggedUser.aktiv_orarend.id, store, {
//        async: false,
//        success: function(data) {
//          _this.props.oraAdatokBeallit(data.ora); // Külső state érték beállítása
//          _this.setState({
//            toltes: false
//          });
//        }
//      });
//    }, 1000);
  },

  formValid: function() {
    this.setState({
      form_valid: true
    });
  },

  formInvalid: function() {
    this.setState({
      form_valid: false
    });
  },

  modosit: function() {
    var _this = this;
    var {store} = this.context;

    this.refs.form.submit();

    if (this.state.form_valid) {
      var formData = this.refs.form.getModel();
      formData.ora_id = this.props.ora_adatok.ora_id;
      formData.terem_id = this.props.ora_adatok.terem_id;

      Ajax.call('orarend/ora_adat_modosit/' + localStorage.token, store, {
        method: 'POST',
        data: formData,
        success: function(data) {
          _this.props.oraAdatokModalHide();
          _this.props.nezetekNaptarFrissitese();
          _this.props.setOraMentes({mentes: false, idopont: '', ora_id: 0, terem_id: 0, terem_nev: 0});
        }
      });
    }
  },

  render() {
    var self = this;

    return (
      <Modal show={this.props.ora_adatok_modal} onHide={this.props.oraAdatokModalHide}>
        <Modal.Header closeButton>
          <Modal.Title>Óra adatok</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.toltes == false ?
          <Form ref="form" onValid={this.formValid} onInvalid={this.formInvalid}>
            <div className="row">
            {
              this.props.ora_adatok.sertes != undefined && this.props.ora_adatok.sertes.map(function(sertes, i) {
                return (
                  <div className="col-xs-12">
                    <div style={{'backgroundColor':sertes.szin,'color':sertes.szoveg_szin}} className="alert alert-danger"> <strong>{sertes.feltetel_megnevezes} sértés!</strong> {sertes.megnevezes}</div>
                  </div>
                )
              })
            }
            {
              this.props.ora_adatok.feltetelek != undefined && this.props.ora_adatok.feltetelek.map(function(feltetel) {
                var link = '/feltetel/'+feltetel.feltetel_id+'/0';
                return (
                  <div className="col-xs-12">
                    <div className="alert alert-warning"><Link target="_blank" to={link}>{feltetel.megnevezes}</Link></div>
                  </div>
                )
              })
            }

              <div className="col-xs-12">
                <Input disabled ref="kurzus" name="kurzus" value={this.props.ora_adatok.kurzus_azonosito} title="Kurzus azonosito"/>
              </div>
              <div className="col-xs-12">
                <Input disabled ref="tantargy" name="tantargy" value={this.props.ora_adatok.tantargy} title="Tantárgy"/>
              </div>
              {this.props.ora_adatok.szakok != null ? (
              <div className="col-xs-12">
                <div className="form-group">
                  <label className="form-label">Szakok</label>
                  <div className="form-control-text">{this.props.ora_adatok.szakok_kod}</div>
                </div>
              </div>): null}
              <div className="col-xs-4">
                <Input disabled date={true} ref="datum" name="datum" value={this.state.napok[this.props.ora_adatok.nap]} title="Nap"/>
              </div>
              <div className="col-xs-4">
                <Input disabled time={true} ref="kezdete" name="kezdete" value={this.props.ora_adatok.kezdete} title="Kezdete"/>
              </div>
              <div className="col-xs-4">
                <Input disabled time={true} ref="vege" name="vege" value={this.props.ora_adatok.vege} title="Vége"/>
              </div>
              <div className="col-xs-6">
                <Input disabled ref="terem" name="terem" value={this.props.ora_adatok.terem} title="Terem"/>
                <Button style={{'marginBottom':'30px'}} onClick={()=>{this.props.setOraMentes({mentes: false, ora_id: this.props.ora_adatok.ora_id}, function() { self.props.teremValasztoModalShow(); })}} type="button">Teremválasztás</Button>
              </div>
              <div className="col-xs-6">
                <Input disabled ref="max_korlat" name="max_korlat" value={this.props.ora_adatok.max_korlat} title="Létszám"/>
              </div>
              <div className="col-xs-12">
                <Input disabled ref="tanar" name="tanar" value={this.props.ora_adatok.tanar} title="Kurzushoz rendelt oktatók"/>
              </div>
              <div className="col-xs-12">
                <Input disabled ref="ora_tanar" name="ora_tanar" value={this.props.ora_adatok.ora_tanar} title="Órához rendelt oktatók"/>
              </div>

              {this.props.ora_adatok.osszevont_kurzus_azonositok != undefined && this.props.ora_adatok.osszevont_kurzus_azonositok != '' ? (
              <div className="col-xs-12">
                <Input disabled ref="osszevont_kurzusok" name="osszevont_kurzusok" value={this.props.ora_adatok.osszevont_kurzus_azonositok} title="Kapcsolódó kurzusok"/>
              </div>): null}

              <div className="col-xs-12">
                <Textarea ref="tantargy" name="megjegyzes" value={this.props.ora_adatok.megjegyzes} title="Megjegyzés"/>
              </div>
              <div className="col-xs-12">
                <Colorpicker ref="hatterszin" name="hatterszin" value={this.props.ora_adatok.hatterszin} title="Háttérszín"/>
              </div>
              <div className="col-xs-12">
                <Colorpicker ref="szovegszin" name="szovegszin" value={this.props.ora_adatok.szovegszin} title="Szövegszín"/>
              </div>
            </div>
          </Form>
           : <div className="preloader-outer"><div className="preloader no-abs" ref="preloader"><div className="txt">Töltés...</div></div></div>}

        </Modal.Body>
        <Modal.Footer>
        <Button onClick={this.props.oraAdatokModalHide} bsStyle="primary" type="button">Bezár</Button>
        {!this.state.toltes ? <Button onClick={this.modosit} type="button">Mentés</Button> : null}
        </Modal.Footer>
      </Modal>
    );
  }
});

OraAdatokModal.contextTypes = {
  store: React.PropTypes.object
}

export default OraAdatokModal;