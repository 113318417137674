import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import {Link,hashHistory} from 'react-router';
import Ajax from './Ajax.js';
import Auth from './Auth.js';
import { Form } from 'formsy-react';
import Input from './form/Input';
import Select from './form/Select';
import jQueryHelper from './jQueryHelper';
import Select2 from './form/Select2';
import Select2Ajax from './form/Select2Ajax';

var JogNode = React.createClass({
  getInitialState: function() {
    return {
      visible: true,
      tanszekek: []
      //szakok: []
    };
  },
  toggle: function() {
    this.setState({visible: !this.state.visible});
  },

  changeHandler: function() {
    this.props.jogValtas(this.props.node.id, this.refs.checkbox.checked);
  },

  render: function() {
    var childs = '';
    var ul_class_name = this.props.root ? 'tree' : '';
    var style = {};
    var expand_class = '';

    var obj = this;
    if (this.props.node.childs != null) {
      childs = this.props.node.childs.map(function(child, index) {
        return <li key={index}><JogNode {...obj.props} root={false} node={child} /></li>
      });
    }

    if (!this.state.visible) {
      style = {'display': 'none'};
      expand_class = 'fa fa-plus';
    } else {
      expand_class = 'fa fa-minus';
    }

    return (
      <div>
        <span onClick={this.toggle} className="expand-icon">{childs != '' && <i className={expand_class}></i>}</span>
        <span className="icon"><i className="fa fa-folder"></i></span>
        <div className="checkbox">
          <input ref="checkbox" {...this.props} onChange={this.changeHandler} checked={this.props.node.checked} id={this.props.node.reactid} type="checkbox" name={this.props.node.reactid} value="1"/>
          <label htmlFor={this.props.node.reactid}></label>
        </div>
        {this.props.node.title}

      {childs != '' &&
        <ul className={ul_class_name} style={style}>
          {childs}
        </ul>
      }
      </div>
    );
  },
});

/* ------------------------------------------------------- */

var Felhasznalo = React.createClass({
  getInitialState() {
    return {
      loaded: false,
      felhasznalo: {},
      jogok: [],
      csoportok: [],
      cimtar_userek: [],
      //szakok: [],
      formIsValid: false,
      opts: [{id: "cn=bartvi,ou=people,dc=sze,dc=hu", text: "Bartalos Viktória"}],
      selected_dn: ''
    }
  },

  componentWillMount: function() {
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentDidMount: function() {
    var obj = this;
    var {store} = this.context;

    jQueryHelper.setFixedMenu(this);

    if (obj.props.params.id == undefined) {
      obj.props.params.id = 0;
    }

    Ajax.call('user/' + obj.props.params.id + '/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        var csoportok = [{
            value: "0",
            label: '- válassz -'
        }];
        data.csoportok.map(function(csoport, i) {
          csoportok.push({
            value: csoport.id,
            label: csoport.name
          })
        });
        var cimtar_userek = [{
            value: "0",
            label: '- válassz -'
        }];
        data.cimtar_userek.map(function(cimtar_user, i) {
          cimtar_userek.push({
            value: cimtar_user.dn,
            label: cimtar_user.name + ' (' + cimtar_user.username + (cimtar_user.email?' - '+cimtar_user.email:'') + ')'
          })
        });

        var selected_dn = '';
        if(data.opts.length > 0) {
          selected_dn = data.opts[0].id;
        }

        obj.setState({felhasznalo:data.felhasznalo, tanszek_kodok:data.tanszek_kodok, hozzarendelt_szakok: data.szakok, jogok:data.jogok, csoportok:csoportok, cimtar_userek:cimtar_userek, loaded: true, opts: data.opts, selected_dn: selected_dn});
      }
    });

    Ajax.call('kurzusok_tanszekek/' + localStorage.token, store, {
        async: false,
        success: function(data) {
          obj.setState({tanszekek: data.tanszekek});
        }
      });

//    Ajax.call('orarend/osszes_szak/' + localStorage.token, store, {
//      async: false,
//      success: function(data) {
//        obj.setState({szakok: data.szakok});
//      }
//    });

  },

  componentWillReceiveProps: function(nextProps) {

  },

  inArray: function(needle, haystack) {
      var length = haystack.length;
      for(var i = 0; i < length; i++) {
          if(haystack[i] == needle) return true;
      }
      return false;
  },

  jogValtasTree: function(jog, id, checked) {
    var obj = this;

    if (jog.childs == undefined || jog.childs.length == 0)
      return;

    jog.childs.map(function(node, index) {
      if (obj.inArray(id, node.parents) || node.id == id) {
        node.checked = checked;
      }
      obj.jogValtasTree(node, id, checked);
    });
  },

  jogValtas: function(id, checked) {
    var jogok = this.state.jogok;
    var obj = this;

    jogok.map(function(node, index) {
      if (parseInt(node.id) == id) {
        node.checked = checked;
      }
      obj.jogValtasTree(node, id, checked);
    });

    this.setState({'jogok':jogok});
  },

  onCsoportValt: function(value) {
    var _this = this;
    var {store} = this.context;

    Ajax.call('users/group_rules/' + localStorage.token + '/' + value, store, {
      success: function(data) {
        _this.setState({jogok:data.jogok});
      }
    });
  },

  onCimtarUserValt: function(value) {
    var _this = this;
    var {store} = this.context;

    Ajax.call('users/getCimtarUser/' + localStorage.token + '/' + value, store, {
      aysnc: false,
      success: function(data) {
        _this.setState({felhasznalo:data.felhasznalo});
      }
    });
  },

  mentes: function() {
    var state = this.state;
    var {store} = this.context;
    var _this = this;

    this.refs.form.submit();

    if (this.state.formIsValid) {
      var formData = this.refs.form.getModel();

      Object.assign(formData.felhasznalo, {
        id: this.state.felhasznalo == false ? 0 : this.state.felhasznalo.id
      });

      Ajax.call('user/updateUser', store, {
        async: false,
        method: "POST",
        data: {
          felhasznalo: formData.felhasznalo,
          jogok: state.jogok,
          token: localStorage.token,
          tanszek_kodok: formData.tanszek_kodok
          //hozzarendelt_szakok: formData.hozzarendelt_szakok
        },
        success: function(data) {
          if (data.errors.length == 0) {
            hashHistory.push('/felhasznalok');

          } else {
            data.errors.map(function(error, i) {
              _this.refs.form.updateInputsWithError(error);
            });
          }
        }
      });
    }
  },

  render: function() {
    var dn_disabled = false;
    if(this.state.selected_dn) {
      dn_disabled = true;
    }
		return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <button onClick={this.mentes} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Felhasználó
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Felhasználó kezelő</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <button onClick={this.mentes} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Felhasználó</h4>
              </div>

              {this.state.loaded &&
              <div className="panel-body">
                <Form ref="form" onValid={() => {this.setState({formIsValid:true})}} onInvalid={() => {this.setState({formIsValid:false})}}>
                  <div className="row">
                    <div className="col-xs-6">
                      <div className="panel-sub-heading">
                        <h4>Adatok</h4>
                      </div>
                      <Select2Ajax onChange={this.onCimtarUserValt} id="felhasznalo_dn" name="felhasznalo.dn" title="Címtár felhasználó" url="cimtar_users" opts={this.state.opts} disabled={dn_disabled} ></Select2Ajax>
                      {/*<Select2 onChange={this.onCimtarUserValt} ref="dn"  name="felhasznalo.dn" value={this.state.felhasznalo?this.state.felhasznalo.dn:"0"} title="Címtár felhasználó" options={this.state.cimtar_userek} />*/}
                      <Input ref="fullname" name="felhasznalo.fullname" title="Név" value={this.state.felhasznalo.fullname} validationError="Kötelező" required />
                      <Input ref="username" name="felhasznalo.username" title="Felhasználó név" value={this.state.felhasznalo.username} validationError="Kötelező" required />
                      <Input ref="email" name="felhasznalo.email" title="E-mail" value={this.state.felhasznalo.email} validationError="Kötelező" required />
                      <Input ref="password" name="felhasznalo.password" title="Új jelszó" validationError="Kötelező" />
                      <Input ref="password_" name="felhasznalo.password_" title="Új jelszó újra" validationError="Kötelező" />
                      <Select onChange={this.onCsoportValt} name="felhasznalo.group_id" value={this.state.felhasznalo.group_id} title="Csoport" options={this.state.csoportok} validationError="Kötelező" required/>
                      <Select2 name="tanszek_kodok" value={this.state.tanszek_kodok} multi={true} title="Kapcsolódó tanszékek" options={this.state.tanszekek} />
                      {/*<Select2 name="hozzarendelt_szakok" value={this.state.hozzarendelt_szakok} multi={true} title="Szakok" options={this.state.szakok} />*/}
                    </div>
                    <div className="col-xs-4 col-xs-offset-1">
                      <div className="panel-sub-heading">
                        <h4>Jogosultságok</h4>
                      </div>
                    {this.state.jogok.length > 0 &&
                      <JogNode jogValtas={this.jogValtas} root={true} node={this.state.jogok[0]} />
                    }
                    </div>
                  </div>
                </Form>
              </div>
              }
            </div>
          </div>
        </div>
      </div>

    );
	}
});

Felhasznalo.contextTypes = {
  store: React.PropTypes.object
}

export default Felhasznalo;