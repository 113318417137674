import Auth from './Auth.js';
import {hashHistory} from 'react-router';

module.exports = {
  xhr: '',
  abort: function() {
    this.xhr.abort();
  },
  call: function(url, store, user_options) {
      var _this = this;

      // Verzió ellenőrzés
      $.ajax({
        url: store.getState().config.apiUrl + 'getVersion',
        method: 'post',
        async: user_options.async == undefined ? true : user_options.async,
        data: {
          version: store.getState().config.version
        },
        dataType: 'json',
        success: function(response) {
          if (response.status == 'ERROR') {
            swal('Figyelem', response.msg, 'error');
            return;
          } else {
            _this.callajax(url, store, user_options);
          }
        }
      });
    },
  callajax: function(url, store, user_options) {
    var options = {
      method: 'GET',
      dataType: 'json',
      data: {},
      async: true,
      preloader: false,
      fullurl: false,
      success: function() {},
      error: function() {}
      //always: function() {}
    };

    //var options = Object.assign({}, options, user_options);
    var options = $.extend({}, options, user_options);

    console.log("AJAX HÍVÁS: " + url);

    try {
      if (url == undefined)
        throw 'Url hiányzik';

//      if (options.preloader) {
//        store.dispatch({type:'SET_AJAX_PRELOADER', show: true});
//      }
//
//      $(document).ajaxStop(function() {
//        store.dispatch({type:'SET_AJAX_PRELOADER', show: false});
//      });

      var _this = this;

      this.xhr = $.ajax({
        method: options.method,
        url: (options.fullurl?'':store.getState().config.apiUrl) + url,
        async: options.async,
        data: options.data,
        timeout: 2700000,
        dataType: options.dataType,
//        always: function (response) {
//          options.always(response);
//        },
        error: options.error,
        success: function(response) {
          //options.always(response);

          if (response.status == "WITHOUT_TOKEN") {
            console.log("AJAX RESPONSE: WITHOUT_TOKEN");
            options.success(response.data);

          } else if (response.status == "OK") {
            console.log("AJAX RESPONSE: OK > CALLBACK (új token: "+response.token+")");
            Auth.setToken(response.token);
            options.success(response.data);

          } else if (response.status == "NO_ACCESS") {
            console.log("AJAX RESPONSE: NO_ACCESS > ÜZENET OLDALRA UGRÁS")
            Auth.setToken(response.token);
            store.dispatch({type: 'SET_OLDAL_UZENET', tipus: 'alert alert-danger', uzenet: response.message});
            hashHistory.push('/uzenet');

          } else if (response.status == "MISSING_TOKEN") {
            console.log("AJAX RESPONSE: MISSING_TOKEN > ÜZENET OLDALRA UGRÁS")
            store.dispatch({type: 'SET_OLDAL_UZENET', tipus: 'alert alert-danger', uzenet: response.message});
            hashHistory.push('/uzenet');

          } else if (response.status == "BADTOKEN") {
            console.log("AJAX RESPONSE: BADTOKEN > ÜZENET OLDALRA UGRÁS")
            store.dispatch({type: 'SET_OLDAL_UZENET', tipus: 'alert alert-danger', uzenet: response.message});
            hashHistory.push('/uzenet');

          } else if (response.status == "DATABASE_ERROR") {
            console.log("AJAX RESPONSE: DATABASE_ERROR > ÜZENET OLDALRA UGRÁS")
            Auth.setToken(response.token);
            store.dispatch({type: 'SET_OLDAL_UZENET', tipus: 'alert alert-danger', uzenet: response.message});
            hashHistory.push('/uzenet');

          } else if (response.status == "NINCS_ORAREND_VALASZTVA") {
            console.log("AJAX RESPONSE: NINCS_ORAREND_VALASZTVA > ÜZENET OLDALRA UGRÁS")
            Auth.setToken(response.token);
            store.dispatch({type: 'SET_OLDAL_UZENET', tipus: 'alert alert-danger', uzenet: response.message});
            hashHistory.push('/uzenet');

          } else if (response.status == "ERROR") {
            console.log("AJAX RESPONSE: ERROR > ÜZENET OLDALRA UGRÁS")
            Auth.setToken(response.token);
            store.dispatch({type: 'SET_OLDAL_UZENET', tipus: 'alert alert-danger', uzenet: response.message});
            hashHistory.push('/uzenet');
          }
        }
      });

    } catch(error) {
      //alert(error);
    }
  }
};
