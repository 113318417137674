import React from 'react';
import Ajax from './../../Ajax.js';
import {Form} from 'formsy-react';

var Elrakottorak = React.createClass({
  getInitialState() {
    return {
      orak: []
    }
  },

  orakLekerese: function() {
    var {store} = this.context;
    var _this = this;

    Ajax.call('orarend_szerk/elrakott_orak/' + localStorage.token + '/' + this.props.kurzus_id + '/1', store, {
      async: false,
      success: function(data) {
        _this.setState({
          orak: data.orak
        });
      }
    });
  },

  beallitasAlaphelyzetre: function() {
    this.setState({
      orak: [],
      lap: 1,
      db: 0
    });
  },

  componentDidMount: function() {

  },

  componentDidUpdate: function() {

  },

  render() {
    var _this = this;

    return (
      <div>
      {/*<div className="panel-sub-heading"><h4>Elrakott órák</h4></div>*/}
        {this.state.orak.length > 0 ? (
          <table className="table table-bordered orarendszerk-kurzuslista-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Terem</th>
                <th>Nap</th>
                <th>Kezdet</th>
                <th>Vég</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
          {this.state.orak.map(function(ora, i) {
              var tr_class = (ora.ora_id == _this.props.ora_id) ? "aktiv" : "";

              return (
               <tr className={tr_class} key={ora.ora_id}>
                  <td onClick={()=>{_this.props.elrakottOraSorClick(parseInt(ora.ora_id), parseInt(ora.terem_id), ora.nap, ora.kezdete, ora.vege, parseInt(ora.hossz), ora.terem, ora.kurzus_kod, ora.tantargy)}}>{ora.ora_id}</td>
                  <td onClick={()=>{_this.props.elrakottOraSorClick(parseInt(ora.ora_id), parseInt(ora.terem_id), ora.nap, ora.kezdete, ora.vege, parseInt(ora.hossz), ora.terem, ora.kurzus_kod, ora.tantargy)}}>{ora.terem}</td>
                  <td onClick={()=>{_this.props.elrakottOraSorClick(parseInt(ora.ora_id), parseInt(ora.terem_id), ora.nap, ora.kezdete, ora.vege, parseInt(ora.hossz), ora.terem, ora.kurzus_kod, ora.tantargy)}}>{ora.nap}</td>
                  <td onClick={()=>{_this.props.elrakottOraSorClick(parseInt(ora.ora_id), parseInt(ora.terem_id), ora.nap, ora.kezdete, ora.vege, parseInt(ora.hossz), ora.terem, ora.kurzus_kod, ora.tantargy)}}>{ora.kezdete}</td>
                  <td onClick={()=>{_this.props.elrakottOraSorClick(parseInt(ora.ora_id), parseInt(ora.terem_id), ora.nap, ora.kezdete, ora.vege, parseInt(ora.hossz), ora.terem, ora.kurzus_kod, ora.tantargy)}}>{ora.vege}</td>
                  <td><button onClick={()=>{_this.props.elrakottOraTorlese(parseInt(ora.ora_id),parseInt(ora.kurzus_id))}} className="btn small btn-white only-icon" type="button"><i className="fa fa-trash"></i></button></td>
                </tr>
              );
          })}
            </tbody>
          </table>
          ) : <div>Nem található elrakott óra.</div>}
      </div>
    )
  }
});

Elrakottorak.contextTypes = {
  store: React.PropTypes.object
};

export default Elrakottorak;