import React from 'react';
import Ajax from './../Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import { Form } from 'formsy-react';
import Input from './../form/Input';
import InputHidden from './../form/InputHidden';
import Select from './../form/Select';
import Toggle from './../form/Toggle';
import utils from 'formsy-react/lib/utils';

var IdoszakModal = React.createClass({
  getInitialState() {
    return {
      show: false,
      valid_form: false,
      idoszak: {
        id: 0,
        megnevezes: '',
        neptun_azonosito: '',
        felev_kezdete: '',
        felev_vege: '',
        kurzuskiirasi_idoszak_kezdete: '',
        kurzuskiirasi_idoszak_vege: ''
      },
    }
  },

  componentDidUpdate: function() {

  },

  ment: function() {
    var _this = this;
    var {store} = this.context;

    this.refs.form.submit();

    if (this.state.valid_form) {
      var formData = this.refs.form.getModel();

      Ajax.call('idoszak_ment/' + localStorage.token, store, {
        method: "POST",
        data: formData,
        success: function(data) {
          if (data.errors.length > 0) {
            data.errors.map(function(error, i) {
              _this.refs.form.updateInputsWithError(error);
            });
          } else {
            _this.hide();
            swal('Siker!', 'Az időszak adatai módosultak.', 'success');
          }
        }
      });
    }
  },

  show: function(id) {
    var {store} = this.context;
    var _this = this;

    this.setState({
      show: true
    }, function() {
      Ajax.call('idoszak/' + localStorage.token + '/' + id, store, {
        success: function(data) {
          if (data === false) {
            swal('Hiba!', 'Nem található az időszak.', 'error');
            _this.hide();

          } else {
            _this.setState({
              idoszak: data
            });
          }
        }
      });

    });
  },

  hide: function() {
    this.setState({
      show: false
    });
  },

  adatModosit: function(mezo, ertek) {
    var idoszak = this.state.idoszak;
    idoszak[mezo] = ertek;
    this.setState({
      idoszak: idoszak
    });
  },

  render: function() {
    return (
    <div>
      {this.state.show &&
      <Modal show={this.state.show} onHide={this.hide}>
        <Modal.Header closeButton>
          <Modal.Title>Időszak</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref="form" onValid={() => {this.setState({valid_form: true})}} onInvalid={() => {this.setState({valid_form: false})}}>
            <InputHidden name="idoszak.id" ref="id" value={this.state.idoszak.id}/>
            <Input onChange={(value) => this.adatModosit('neptun_azonosito', value)} name="idoszak.neptun_azonosito" ref="neptun_azonosito" title="Neptun azonosító" value={this.state.idoszak.neptun_azonosito} validationError="Kötelező" />
            <Input onChange={(value) => this.adatModosit('megnevezes', value)} name="idoszak.megnevezes" ref="megnevezes" title="Megnevezés" value={this.state.idoszak.megnevezes} validationError="Kötelező" required/>
            <Input date={true} onChange={(value) => this.adatModosit('felev_kezdete', value)} name="idoszak.felev_kezdete" ref="felev_kezdete" title="Félév kezdete" value={this.state.idoszak.felev_kezdete} validationError="Kötelező" required />
            <Input date={true} onChange={(value) => this.adatModosit('felev_vege', value)} name="idoszak.felev_vege" ref="felev_vege" title="Szorgalmi időszak vége" value={this.state.idoszak.felev_vege} validationError="Kötelező" required />
            <Input onChange={(value) => this.adatModosit('kurzuskiirasi_idoszak_kezdete', value)} date={true} name="idoszak.kurzuskiirasi_idoszak_kezdete" ref="kurzuskiirasi_idoszak_kezdete" title="Kurzus kiírási időszak kezdete" value={this.state.idoszak.kurzuskiirasi_idoszak_kezdete} validationError="Kötelező" required />
            <Input onChange={(value) => this.adatModosit('kurzuskiirasi_idoszak_vege', value)} date={true} name="idoszak.kurzuskiirasi_idoszak_vege" ref="kurzuskiirasi_idoszak_vege" title="Kurzus kiírási időszak vége" value={this.state.idoszak.kurzuskiirasi_idoszak_vege} validationError="Kötelező" required />
            <Input onChange={(value) => this.adatModosit('mentesites_felvitel_kezdete', value)} date={true} name="idoszak.mentesites_felvitel_kezdete" ref="mentesites_felvitel_kezdete" title="Mentesítés felvitel kezdete" value={this.state.idoszak.mentesites_felvitel_kezdete} validationError="Kötelező" required />
            <Input onChange={(value) => this.adatModosit('mentesites_felvitel_vege', value)} date={true} name="idoszak.mentesites_felvitel_vege" ref="mentesites_felvitel_vege" title="Mentesítés felvitel vége" value={this.state.idoszak.mentesites_felvitel_vege} validationError="Kötelező" required />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.hide} type="button">Mégsem</Button>
          <button ref="preloader" onClick={this.ment} className="btn btn-primary">Módosít</button>
        </Modal.Footer>
      </Modal>
      }
    </div>)
  }
});

IdoszakModal.contextTypes = {
  store: React.PropTypes.object
}

export default IdoszakModal;