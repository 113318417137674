import React from 'react';
import Iframe from 'react-iframe';
import { Link } from 'react-router';
import Auth from './Auth.js';

var Logout = React.createClass({
  // Renderelés után
  componentDidMount() {
    // Kijelentkeztetés
    Auth.logout()

    // State-ben a user adatok törlése
    this.props.logout();
  },

  render() {
    return (
      <div>
        <Iframe url={location.protocol+"//"+location.host+"/Shibboleth.sso/Logout"} width="0px" height="0px" />
        <div className="login-panel">
          <div className="login-panel-head">
            <h1><i className="material-icons">access_time</i>RKa<span> - Órarendkészítő </span><span className="smalltext">alkalmazás</span></h1>
            <p>A Széchenyi István Egyetem órarendkészítő felülete.</p>
          </div>
          <div className="login-panel-body">
            <h2>Kijelentkezés</h2>
            <p>Ön sikeresen kijelentkezett rendszerükből.</p>
            <Link to="/login" className="btn btn-primary">Szeretnék újra bejelentkezni</Link>
          </div>
        </div>
      </div>
    );
  }
})

export default Logout;