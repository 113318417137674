import React from 'react';
import Ajax from './../Ajax.js';
import Toggle from './../form/Toggle';

var Ismetlodes = React.createClass({
  getInitialState() {
    return {
    }
  },

  componentDidMount: function() {
    this.props.onChange(this.props.ertek);
  },

  onChange: function(val) {
    this.props.onChange(val);
  },

  render: function() {
    return (
      <Toggle onChange={this.onChange} name={`feltetel[ertek][p_${this.props.id}]`} value={this.props.ertek} title={this.props.label}/>
    );
  }
});

Ismetlodes.contextTypes = {
  store: React.PropTypes.object
}

export default Ismetlodes;