import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button, Tabs, Tab} from 'react-bootstrap';
import { Form } from 'formsy-react';
import Select from './form/Select';
import Select2 from './form/Select2';
import Toggle from './form/Toggle';
import Input from './form/Input';
import InputHidden from './form/InputHidden';
import Feltetel from './Feltetel';
import jQueryHelper from './jQueryHelper';

Formsy.addValidationRule('isSelect2Empty', function (values, value) {
  return ((value == undefined || value.length == 0) ? false : true);
});

var KiirtKurzusok = React.createClass({
  getInitialState() {
    return {
      kurzusok: [],
      tanszekek: [
            { value: '', label: '- válassz -' }
          ],
      szakok: [],
      szakiranyok: [{value: 'null', label:'Nincs kiválasztva'}],
      felevek: [],
      felvetelTipusok: [
          { value: 'null', label: 'Összes' },
          { value: 'kotelezo', label: 'Kötelező' },
          { value: 'valaszthato', label: 'Választható' }
      ],
      munkarendek: [
        { value: '1', label: 'nappali' },
        { value: '2', label: 'levelező' }
      ],
      tanszek: {tanszek_id: 'null'},
      felvetelTipus: {felvetel_id: 'null'},
      munkarend: 1,
      activeTab: 1,
      reszlet_modal_show: false,
      nyitott_targyak: [],
      csak_megjegyzeses: 0,
      csak_orarend: 1,
      szak_id: 'null',
      szakirany_id: 'null',
      felev_id: 'null',
      tanarlista: [],
      formIsValid: false,
      feltetelek: [],
      kerkif: '',
      mintatantervek: ''
    }
  },

  componentWillMount: function() {
    var obj = this;
    var {store} = this.context;
    var activeTab = this.state.activeTab;

    if(!obj.props.prop_loggedUser.aktiv_idoszak) {
      store.dispatch({type: 'SET_OLDAL_UZENET', tipus: 'alert alert-danger', uzenet: 'Először válasszon időszakot!'});
      hashHistory.push('/uzenet');
    } else {
      Ajax.call('kurzusok_tanszekek/' + localStorage.token + '/true', store, {
        async: false,
        success: function(data) {

          data.tanszekek = [
            {
              value: 'null',
              label: 'Összes'
            },
            ...data.tanszekek
          ];

          obj.setState({tanszekek: data.tanszekek});
        }
      });

      var munkarend = obj.state.munkarend;
      if(obj.props.prop_loggedUser.kivalasztott_kurzus && obj.props.prop_loggedUser.kivalasztott_kurzus.munkarend) {
        munkarend = obj.props.prop_loggedUser.kivalasztott_kurzus.munkarend;
        activeTab = obj.props.prop_loggedUser.kivalasztott_kurzus.munkarend;
      }
      var tanszek_id = obj.state.tanszek.tanszek_id;
      if(obj.props.prop_loggedUser.kivalasztott_kurzus && obj.props.prop_loggedUser.kivalasztott_kurzus.tanszek_id) {
        tanszek_id = obj.props.prop_loggedUser.kivalasztott_kurzus.tanszek_id;
      }
      var felvetel_id = obj.state.felvetelTipus.felvetel_id;
      if(obj.props.prop_loggedUser.kivalasztott_kurzus && obj.props.prop_loggedUser.kivalasztott_kurzus.felvetel_id) {
        felvetel_id = obj.props.prop_loggedUser.kivalasztott_kurzus.felvetel_id;
      }
      var csak_megjegyzeses = obj.state.csak_megjegyzeses;
      if(obj.props.prop_loggedUser.kivalasztott_kurzus && obj.props.prop_loggedUser.kivalasztott_kurzus.csak_megjegyzeses) {
        csak_megjegyzeses = obj.props.prop_loggedUser.kivalasztott_kurzus.csak_megjegyzeses;
      }
      var csak_orarend = obj.state.csak_orarend;
      if(obj.props.prop_loggedUser.kivalasztott_kurzus && obj.props.prop_loggedUser.kivalasztott_kurzus.csak_orarend) {
        csak_orarend = obj.props.prop_loggedUser.kivalasztott_kurzus.csak_orarend;
      }
      var szak_id = obj.state.szak_id;
      if(obj.props.prop_loggedUser.kivalasztott_kurzus && obj.props.prop_loggedUser.kivalasztott_kurzus.szak_id) {
        szak_id = obj.props.prop_loggedUser.kivalasztott_kurzus.szak_id;
      }
      var szakirany_id = obj.state.szakirany_id;
      if(obj.props.prop_loggedUser.kivalasztott_kurzus && obj.props.prop_loggedUser.kivalasztott_kurzus.szakirany_id) {
        szakirany_id = obj.props.prop_loggedUser.kivalasztott_kurzus.szakirany_id;
      }
      var felev_id = obj.state.felev_id;
      if(obj.props.prop_loggedUser.kivalasztott_kurzus && obj.props.prop_loggedUser.kivalasztott_kurzus.felev_id) {
        felev_id = obj.props.prop_loggedUser.kivalasztott_kurzus.felev_id;
      }
      var kerkif = obj.state.kerkif;
      if(obj.props.prop_loggedUser.kivalasztott_kurzus && obj.props.prop_loggedUser.kivalasztott_kurzus.kerkif) {
        kerkif = obj.props.prop_loggedUser.kivalasztott_kurzus.kerkif;
      }

      Ajax.call('kiirt_kurzusok/' + localStorage.token + '/' + munkarend + '/' + obj.props.prop_loggedUser.aktiv_idoszak.felev_kezdete + '/' + tanszek_id + '/' + felvetel_id + '/' + csak_megjegyzeses + '/' + csak_orarend + '/' + szak_id + '/' + szakirany_id + '/' + felev_id, store, {
        async: false,
        method: 'POST',
        data: {kerkif: kerkif},
        success: function(data) {

          var nyitott_targyak = obj.state.nyitott_targyak;
          data.kurzusok.map(function(kurzus,index){
            if(obj.props.params.redirected == 1 && obj.props.prop_loggedUser.kivalasztott_kurzus.tantargy_id == kurzus.id) {
              kurzus.showReszletek = true;
              nyitott_targyak.push(kurzus.id);
              Ajax.call('kiirt_kurzus_reszletek/' + localStorage.token + '/' + kurzus.id + '/' + obj.props.prop_loggedUser.aktiv_idoszak.felev_kezdete, store, {
                async: false,
                success: function(data) {
                  kurzus.reszletAdatok = data.reszletek;
                  kurzus.reszletMessage = data.message;
                  var kurzus_sor = data.reszletek.find((el) => {
                    return el.id == obj.props.prop_loggedUser.kivalasztott_kurzus.id
                  });
                  if(kurzus_sor) {
                    obj.reszletClick(kurzus_sor.id,kurzus_sor.max_korlat,kurzus_sor.tanarok,kurzus_sor.tanar_idk,kurzus_sor.megjegyzes,kurzus_sor.megjegyzes_ok,obj.props.prop_loggedUser.kivalasztott_kurzus.tantargy_id,kurzus_sor.feltetelek,kurzus_sor.mintatantervek);
                  }
                }, error: function() {
                  kurzus.reszletek.message = 'Hiba a lekérdezés során!';
                }
              });
            } else {
              kurzus.showReszletek = false;
            }
          });

          var tanszek = obj.state.tanszek;
          tanszek.tanszek_id = tanszek_id;
          var felvetelTipus = obj.state.felvetelTipus;
          felvetelTipus.felvetel_id = felvetel_id;

          obj.setState({
             kurzusok: data.kurzusok,
             tanarlista: data.tanarlista,
             nyitott_targyak: nyitott_targyak,
             munkarend: munkarend,
             tanszek: tanszek,
             felvetelTipus: felvetelTipus,
             csak_megjegyzeses: csak_megjegyzeses,
             csak_orarend: csak_orarend,
             szak_id: szak_id,
             szakirany_id: szakirany_id,
             felev_id: felev_id,
             kerkif: kerkif,
             activeTab: activeTab
          });
        }
      });
    }
  },

  componentDidMount: function() {
    this.setFixedMenu();

    var _this = this;
    var {store} = this.context;

    _this.getSzakok(this.state.munkarend);
    _this.getFelevek();

    if(this.props.params.redirected == 1) {
      window.scroll(0, this.props.prop_loggedUser.kivalasztott_kurzus.scroll);
    }
  },

  /**
   * Szakok lekérdezése
   *
   */
  getSzakok: function(val) {
    var {store} = this.context;
    var _this = this;

    var ajax = 'orarend/szakok/';
    if(val && (val == 2)) {
      ajax = 'orarend/levelezos_szakok/';
    }

    Ajax.call(ajax + localStorage.token, store, {
      async: false,
      success: function(data) {
        var szakok = [{value: 'null', label:'Összes'}, ...data.szakok];

        _this.setState({szakok: szakok});
      }
    });
  },

  /**
   * Félévek lekérdezése
   *
   */
  getFelevek: function() {
    var {store} = this.context;
    var _this = this;

    Ajax.call('orarend/felevek/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        var felevek = [{value: 'null', label:'Összes'}, ...data.felevek];

        _this.setState({felevek: felevek});
      }
    });
  },

  /**
   * Szakirányok lekérdezése
   *
   */
  getSzakiranyok: function(szak_id) {
    var {store} = this.context;
    var _this = this;

    Ajax.call('orarend/szakiranyok/' + localStorage.token + '/' + szak_id, store, {
      async: false,
      success: function(data) {
        var szakiranyok = [{value: 'null', label:'Nincs kiválasztva'}, ...data.szakiranyok];

        _this.setState({szakiranyok: szakiranyok});
      }
    });
  },

  handleSelect: function (activeTab) {

    this.setState({
      activeTab: activeTab
    });

    var obj = this;
    var {store} = this.context;
    var tanszek = this.state.tanszek;
    var felvetelTipus = this.state.felvetelTipus;
    var munkarend = this.state.munkarend;

    if(activeTab == 1) {
      munkarend = 1;
    } else if (activeTab == 2) {
      munkarend = 2;
    }

    this.setState({
      munkarend: munkarend, szak_id: 'null', szakirany_id: 'null', felev_id: 'null'
    });

    obj.getSzakok(munkarend);

    var tanszek_id = tanszek.tanszek_id;
    var felvetel_id = felvetelTipus.felvetel_id;
    if(activeTab == 1 || activeTab == 2) {
      Ajax.call('kiirt_kurzusok/' + localStorage.token + '/' + munkarend + '/' + obj.props.prop_loggedUser.aktiv_idoszak.felev_kezdete + '/' + tanszek_id + '/' + felvetel_id + '/' + this.state.csak_megjegyzeses + '/' + this.state.csak_orarend + '/' + this.state.szak_id + '/' + this.state.szakirany_id + '/' + this.state.felev_id, store, {
        async: false,
        method: 'POST',
        data: {kerkif: obj.state.kerkif},
        success: function(data) {
          obj.setState({kurzusok:data.kurzusok,tanarlista:data.tanarlista});
        }
      });
    }
  },

  setFixedMenu: function() {
    var obj = this;
    $(window).scroll(function() {
      if ($(window).scrollTop() > 100) {
        $(obj.refs.fixed_top_menu_outer).addClass('active');
      } else {
        $(obj.refs.fixed_top_menu_outer).removeClass('active');
      }
    });
  },

  getKiirtKurzusok: function(val,stateName,toState) {
    var obj = this;
    var {store} = this.context;

    var munkarend = this.state.munkarend;
    var tanszek_id = this.state.tanszek.tanszek_id;
    var felvetel_id = this.state.felvetelTipus.felvetel_id;
    var csak_megjegyzeses = this.state.csak_megjegyzeses;
    var csak_orarend = this.state.csak_orarend;
    var szak_id = this.state.szak_id;
    var szakirany_id = this.state.szakirany_id;
    var felev_id = this.state.felev_id;
    var kerkif = this.state.kerkif;

    switch(stateName) {
    case 'tanszek':
        tanszek_id = val;
        break;
    case 'felvetelTipus':
        felvetel_id = val;
        break;
    case 'csak_megjegyzeses':
        csak_megjegyzeses = val;
        break;
    case 'csak_orarend':
        csak_orarend = val;
        break;
    case 'szak_id':
        szak_id = val;
        szakirany_id = 'null';
        break;
    case 'szakirany_id':
        szakirany_id = val;
        break;
    case 'felev_id':
        felev_id = val;
        break;
    case 'kerkif':
        kerkif = val;
        break;
    default:
        alert('error: bad stateName');
    }

    Ajax.call('kiirt_kurzusok/' + localStorage.token + '/' + munkarend + '/' + obj.props.prop_loggedUser.aktiv_idoszak.felev_kezdete + '/' + tanszek_id + '/' + felvetel_id + '/' + csak_megjegyzeses + '/' + csak_orarend + '/' + szak_id + '/' + szakirany_id + '/' + felev_id, store, {
      async: false,
      method: 'POST',
      data: {kerkif: kerkif},
      success: function(data) {
        if(stateName == 'szak_id') {
          obj.setState({kurzusok:data.kurzusok, tanarlista: data.tanarlista, [stateName]: toState, szakirany_id: 'null'});
        } else {
          obj.setState({kurzusok:data.kurzusok, tanarlista: data.tanarlista, [stateName]: toState});
        }
      }
    });
  },

  onChangeTanszek: function(val) {
    var tanszek = this.state.tanszek;
    tanszek.tanszek_id = val;

    this.getKiirtKurzusok(val,'tanszek',tanszek);
  },

  onChangeFelvetelTipus: function(val) {
    var felvetelTipus = this.state.felvetelTipus;
    felvetelTipus.felvetel_id = val;

    this.getKiirtKurzusok(val,'felvetelTipus',felvetelTipus);
  },

  onClickCsakMegjegyzes: function(val) {
    var csak_megjegyzeses = (val == 1 ? 0 : 1);

    this.getKiirtKurzusok(csak_megjegyzeses,'csak_megjegyzeses',csak_megjegyzeses);
  },

  onClickCsakOrarend: function(val) {
    var csak_orarend = (val == 1 ? 0 : 1);

    this.getKiirtKurzusok(csak_orarend,'csak_orarend',csak_orarend);
  },

  onChangeSzak: function(val) {
    this.getSzakiranyok(val);
    this.getKiirtKurzusok(val,'szak_id',val);
  },

  onChangeSzakirany: function(val) {
    this.getKiirtKurzusok(val,'szakirany_id',val);
  },

  onChangeFelev: function(val) {
    this.getKiirtKurzusok(val,'felev_id',val);
  },

  typeMegnevezes: function(val) {
    this.getKiirtKurzusok(val, 'kerkif', val);
  },

  dropdownIconClick: function(tantargy_id) {
    var obj = this;
    var {store} = this.context;
    var kurzusok = this.state.kurzusok;
    var nyitott_targyak = this.state.nyitott_targyak;


    kurzusok.map(function(kurzus,index){
      if (kurzus.id == tantargy_id) {
        kurzus.showReszletek = kurzus.showReszletek ? false : true;
        if(kurzus.showReszletek) {
          nyitott_targyak.push(tantargy_id);
          Ajax.call('kiirt_kurzus_reszletek/' + localStorage.token + '/' + tantargy_id + '/' + obj.props.prop_loggedUser.aktiv_idoszak.felev_kezdete, store, {
            async: false,
            success: function(data) {
              kurzus.reszletAdatok = data.reszletek;
              kurzus.reszletMessage = data.message;

              obj.setState({
                kurzusok: kurzusok,
                nyitott_targyak: nyitott_targyak
              });
            }, error: function() {
              kurzus.reszletek.message = 'Hiba a lekérdezés során!';

              obj.setState({
                kurzusok: kurzusok,
                nyitott_targyak: nyitott_targyak
              });
            }
          });
        } else {
          var index = nyitott_targyak.indexOf(tantargy_id);
          if (index >= 0) {
            nyitott_targyak.splice(index, 1);
          }
          obj.setState({
            kurzusok: kurzusok,
            nyitott_targyak: nyitott_targyak
          });
        }
      }
    });
  },

  reszletClick: function(kurzus_id,letszam,tanarok,tanar_idk,megjegyzes,megjegyzes_ok,tantargy_id,feltetelek,mintatantervek) {
    var obj = this;
    var {store} = this.context;

    this.setState({
      reszlet_modal_show: true,
      kurzus_id: kurzus_id,
      letszam: letszam,
      tanarok: tanarok,
      tanar_idk: tanar_idk,
      megjegyzes: megjegyzes,
      megjegyzes_ok: megjegyzes_ok,
      tantargy_id: tantargy_id,
      feltetelek: feltetelek,
      mintatantervek: mintatantervek
    });
  },

  reszletModalHide: function() {
    this.setState({
      reszlet_modal_show: false,
      kurzus_id: '',
      letszam: '',
      tanarok: '',
      tanar_idk: [],
      megjegyzes: '',
      megjegyzes_ok: '',
      tantargy_id: '',
      feltetelek: [],
      mintatantervek: ''
    });
  },

  reszletModalSave: function() {
    var obj = this;
    var {store} = this.context;
    var formData = this.refs.form.getModel();
    formData.token = localStorage.token;

    this.refs.form.submit();

    if(this.state.formIsValid) {

      Ajax.call('kiirt_kurzusok/reszlet_ment', store, {
        method: 'POST',
        async: false,
        data: formData,
        success: function(data) {
          Ajax.call('kiirt_kurzusok/' + localStorage.token + '/' + obj.state.munkarend + '/' + obj.props.prop_loggedUser.aktiv_idoszak.felev_kezdete + '/' + obj.state.tanszek.tanszek_id + '/' + obj.state.felvetelTipus.felvetel_id + '/' + obj.state.csak_megjegyzeses + '/' + obj.state.csak_orarend + '/' + obj.state.szak_id + '/' + obj.state.szakirany_id + '/' + obj.state.felev_id, store, {
            method: 'POST',
            data: {kerkif: obj.state.kerkif},
            async: false,
            success: function(data) {

              var nyitva = -1;
              data.kurzusok.map(function(kurzus,index){
                nyitva = -1;
                nyitva = obj.state.nyitott_targyak.indexOf(kurzus.id);
                if(nyitva > -1) {
                  kurzus.showReszletek = true;
                  Ajax.call('kiirt_kurzus_reszletek/' + localStorage.token + '/' + kurzus.id + '/' + obj.props.prop_loggedUser.aktiv_idoszak.felev_kezdete, store, {
                    async: false,
                    success: function(data) {
                      kurzus.reszletAdatok = data.reszletek;
                      kurzus.reszletMessage = data.message;
                    }, error: function() {
                      kurzus.reszletek.message = 'Hiba a lekérdezés során!';
                    }
                  });
                } else {
                  kurzus.showReszletek = false;
                }
              });

              obj.setState({
                 kurzusok: data.kurzusok,
                 tanarlista: data.tanarlista
              });
            }
          });
        }
      });

      this.setState({
        reszlet_modal_show: false,
        kurzus_id: '',
        letszam: '',
        megjegyzes: '',
        megjegyzes_ok: ''
      });

    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  forwardToFeltetel: function() {
    var {store} = this.context;
    var scroll = $(window).scrollTop();
    //this.props.userSetKurzus(this.state.kurzus_id, this.state.megjegyzes);
    store.dispatch({type:'USER_SET_KURZUS', id: this.state.kurzus_id, megjegyzes: this.state.megjegyzes, tantargy_id: this.state.tantargy_id, scroll: scroll, feltetelek: this.state.feltetelek, munkarend: this.state.munkarend, tanszek_id: this.state.tanszek.tanszek_id, felvetel_id: this.state.felvetelTipus.felvetel_id, csak_megjegyzeses: this.state.csak_megjegyzeses, csak_orarend: this.state.csak_orarend, szak_id: this.state.szak_id, szakirany_id: this.state.szakirany_id, felev_id: this.state.felev_id, kerkif: this.state.kerkif});
    hashHistory.push('/uj_feltetel/1')
  },

  forwardToFeltetelSzerk: function(id) {
    var {store} = this.context;
    var scroll = $(window).scrollTop();
    store.dispatch({type:'USER_SET_KURZUS', id: this.state.kurzus_id, megjegyzes: this.state.megjegyzes, tantargy_id: this.state.tantargy_id, scroll: scroll, feltetelek: this.state.feltetelek, munkarend: this.state.munkarend, tanszek_id: this.state.tanszek.tanszek_id, felvetel_id: this.state.felvetelTipus.felvetel_id, csak_megjegyzeses: this.state.csak_megjegyzeses, csak_orarend: this.state.csak_orarend, szak_id: this.state.szak_id, szakirany_id: this.state.szakirany_id, felev_id: this.state.felev_id, kerkif: this.state.kerkif});
    hashHistory.push('/feltetel/'+id+'/1')
  },

  formValid: function() {
    this.setState({
      formIsValid: true
    });
  },

  formInvalid: function() {
    this.setState({
      formIsValid: false
    });
  },

  toggleClick: function(value,kurzusid) {
    console.log(kurzusid);
    var obj = this;
    var {store} = this.context;

    Ajax.call('kiirt_kurzus_inaktivalas', store, {
      method: "POST",
      data: {
        ertek: value,
        kurzusid: kurzusid,
        token: localStorage.token
      },
      success: function() {

      }, error: function() {

      }
    });
  },

	render: function() {
    var obj = this;

		return (
      <section>
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal / Kurzusok
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Kiírt kurzusok</h1>
            </div>
            <div className="content-panel-top-btn-group">
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Kiírt kurzusok</h4>
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-xs-12">
                    <Tabs defaultActiveKey={1} id="tabs" activeKey={this.state.activeTab} onSelect={this.handleSelect}>
                      <Tab eventKey={1} title={<span>Nappali</span>}>
                        {this.state.activeTab == 1 ? (
                        <KurzusLista {...this.state} eventKey={1} csak_megjegyzeses={this.state.csak_megjegyzeses} csak_orarend={this.state.csak_orarend} szak_id={this.state.szak_id} szakirany_id={this.state.szakirany_id} felev_id={this.state.felev_id} kerkif={this.state.kerkif} szakok={this.state.szakok} szakiranyok={this.state.szakiranyok} felevek={this.state.felevek} onChangeFelvetelTipus={this.onChangeFelvetelTipus} onChangeTanszek={this.onChangeTanszek} dropdownIconClick={this.dropdownIconClick} reszletClick={this.reszletClick} onClickCsakMegjegyzes={this.onClickCsakMegjegyzes} onClickCsakOrarend={this.onClickCsakOrarend} onChangeSzak={this.onChangeSzak} onChangeSzakirany={this.onChangeSzakirany} onChangeFelev={this.onChangeFelev} typeMegnevezes={this.typeMegnevezes} munkarend={this.state.munkarend} toggleClick={this.toggleClick} />
                        ) : ("")}
                      </Tab>
                      <Tab eventKey={2} title={<span>Levelező</span>}>
                        {this.state.activeTab == 2 ? (
                        <KurzusLista {...this.state} eventKey={2} csak_megjegyzeses={this.state.csak_megjegyzeses} csak_orarend={this.state.csak_orarend} szak_id={this.state.szak_id} szakirany_id={this.state.szakirany_id} felev_id={this.state.felev_id} kerkif={this.state.kerkif} szakok={this.state.szakok} szakiranyok={this.state.szakiranyok} felevek={this.state.felevek} onChangeFelvetelTipus={this.onChangeFelvetelTipus} onChangeTanszek={this.onChangeTanszek} dropdownIconClick={this.dropdownIconClick} reszletClick={this.reszletClick} onClickCsakMegjegyzes={this.onClickCsakMegjegyzes} onClickCsakOrarend={this.onClickCsakOrarend} onChangeSzak={this.onChangeSzak} onChangeSzakirany={this.onChangeSzakirany} onChangeFelev={this.onChangeFelev} typeMegnevezes={this.typeMegnevezes} munkarend={this.state.munkarend} toggleClick={this.toggleClick} />
                        ) : ("")}
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="kurzus_reszletek_modal" show={this.state.reszlet_modal_show} onHide={this.reszletModalHide}>
        <Modal.Header closeButton>
          <Modal.Title>Kurzus adatok</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <section>
          {this.state.megjegyzes ? (
          <div className="kurzus_megjegyzes_box">
            Órarendi igény:<br/>
            <i>{this.state.megjegyzes}</i>
          </div>
          ): null}

          <Form ref="form" onValid={this.formValid} onInvalid={this.formInvalid}>
            <div className="kurzus_adatok">
              {this.state.megjegyzes ? (
              <div>Megjegyzéssel kapcsolatos teendők elvégezve:
              <Toggle name="kurzus_rendben" value={this.state.megjegyzes_ok} />
              </div>
              ) : null}
              <div className="kurzus_feltetelek">
                Kurzushoz felvitt feltételek:<br/>
                {this.state.feltetelek && this.state.feltetelek.length > 0 ? (
                  this.state.feltetelek.map(feltetel => (
                    <section key={feltetel.feltetel_id}><a href="javascript:void(0)" onClick={() => this.forwardToFeltetelSzerk(feltetel.feltetel_id)}>{feltetel.megnevezes}</a><br/></section>
                  ))
                ):(
                  <i>Nincs a kurzushoz rögzített feltétel.</i>
                )}
              </div>
              {/*<Select2 name="tanar_idk" value={this.state.tanar_idk} multi={true} title="Oktató" options={this.state.tanarlista} validationError="Oktató kötelező" validations="isSelect2Empty" />*/}
              <div>
                {this.state.mintatantervek && this.state.mintatantervek.length > 0 ? (
                  <Nulla_mintatantervek key={this.state.kurzus_id} mintatantervek={this.state.mintatantervek} />
                ):("")}
              </div>
              <hr/>
              <Select2 name="tanar_idk" value={this.state.tanar_idk} multi={true} title="Oktató" options={this.state.tanarlista} />
              <Input name="kurzus_letszam" title="Létszám" value={this.state.letszam} validationError="Létszám kötelező" validations="isInt" required />
              <InputHidden name="kurzus_id" value={this.state.kurzus_id}/>
            </div>
            <div className="button_div">
              <Button onClick={this.forwardToFeltetel} bsStyle="primary" type="button">Új feltétel</Button>
            </div>
          </Form>
          </section>

        </Modal.Body>
        <Modal.Footer>
        <Button onClick={this.reszletModalHide} bsStyle="primary" type="button">Bezár</Button>
        <Button onClick={this.reszletModalSave} type="button">Mentés</Button>
        </Modal.Footer>
      </Modal>
      </section>
    );
	}
});

KiirtKurzusok.contextTypes = {
  store: React.PropTypes.object
}

var KurzusLista = React.createClass({
  getInitialState() {
    return {}
  },
  render() {
    var obj = this;
    var emptyList = "Nem található a beállított feltételeknek megfelelő egyetlen kiírt kurzus sem.";

    //if(this.props.tanszek.tanszek_id == 'null' && (this.props.szak_id == 'null' || this.props.felev_id == 'null')) {
    if(this.props.tanszek.tanszek_id == 'null' && this.props.szak_id == 'null') {
      emptyList = "A listázáshoz először tanszéket VAGY szakot kell választani!";
    }
    return (
      <section>
        <div className="row">
          <div className="col-xs-6">
            <div className="panel-sub-heading">
              <h4>Szűrés</h4>
            </div>
          </div>
        </div>
      <Form ref="form">
        <div className="form-group">
          <div className="row">
            <div className="col-xs-4">
              <Select2 title="Tanszék" name="tanszek_id" value={this.props.tanszek.tanszek_id} options={this.props.tanszekek} onChange={this.props.onChangeTanszek} />
            </div>
            <div className="col-xs-4">
              <Select title="Tárgy típusa" name="felvetel_id" value={this.props.felvetelTipus.felvetel_id} options={this.props.felvetelTipusok} onChange={this.props.onChangeFelvetelTipus} />
            </div>
             <div style={{"paddingTop": "5px"}} className="col-xs-2">
              <Toggle title="Csak igénnyel rendelkezők" name="csak_megjegyzeses" value={this.props.csak_megjegyzeses} onClick={this.props.onClickCsakMegjegyzes} />
            </div>
            <div style={{"paddingTop": "5px"}} className="col-xs-2">
              <Toggle title=" Csak órarendbe kerülők" name="csak_orarend" value={this.props.csak_orarend} onClick={this.props.onClickCsakOrarend} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4">
              <Select2 onChange={this.props.onChangeSzak} name="szak_id" value={this.props.szak_id} title="Szak" options={this.props.szakok}/>
            </div>
            <div className="col-xs-4">
              <Select onChange={this.props.onChangeSzakirany} name="szakirany_id" value={this.props.szakirany_id} title="Szakirány" options={this.props.szakiranyok}/>
            </div>
            <div className="col-xs-2">
              <Select onChange={this.props.onChangeFelev} name="felev_id" value={this.props.felev_id} title="Félév" options={this.props.felevek}/>
            </div>
            <div className="col-xs-2">
              <Input intervalTypingCallback={this.props.typeMegnevezes} ref="kereso_megnevezes" name="kereso_megnevezes" title="Név/kód" value={this.props.kerkif}/>
            </div>
          </div>
        </div>
      </Form>
      <div className="panel-sub-heading">
        <h4>Kiírt kurzus lista</h4>
      </div>
      {this.props.kurzusok && this.props.kurzusok.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Tantárgy neve</th>
              <th>Tantárgy kód</th>
              <th></th>
            </tr>
          </thead>

          {this.props.kurzusok.map(kurzus => (
              <Kurzusok eventKey={obj.props.eventKey} key={kurzus.id} kurzus={kurzus} reszletClick={this.reszletClick} toggleClick={this.props.toggleClick} dropdownIconClick={this.dropdownIconClick} toggleClick={this.toggleClick} {...this.props} munkarend={this.props.munkarend} />
          ))}

        </table>
      ) : (<div>{emptyList}</div>)}
      </section>
    )
  }
});

var Kurzusok = React.createClass({
  getInitialState() {
    return {}
  },
  render() {
    var obj = this;
    var className = this.props.kurzus.showReszletek ? 'fa fa-angle-up' : 'fa fa-angle-down';
    var megjegyzesClass = '';
    if(this.props.kurzus.megjegyzes > 0 && this.props.kurzus.megjegyzes_ok == 1) {
      megjegyzesClass = "targy_megjegyzes_ok";
    } else if(this.props.kurzus.megjegyzes > 0) {
      megjegyzesClass = "targy_megjegyzes";
    }

    return (
      <tbody>
      <tr className={megjegyzesClass}>
        <td>{this.props.kurzus.nev}</td>
        <td>{this.props.kurzus.kod}</td>
        <td style={{"textAlign": "center"}}>
          <ul className="icon-list">
            <li><a className="dropdown-toggle" href="javascript:void 0;" onClick={() => this.props.dropdownIconClick(this.props.kurzus.id)}><i className={className}></i></a></li>
          </ul>
        </td>
      </tr>
        <Reszletek kurzus={this.props.kurzus} reszletClick={this.props.reszletClick} toggleClick={this.props.toggleClick} munkarend={this.props.munkarend} />
      </tbody>
    )
  }
});

var Reszletek = React.createClass({
  getInitialState() {
    return {

    }
  },
  render() {
    return (
      (this.props.kurzus.showReszletek ? (
        <tr>
          <td className="detailsTr" colSpan="7">
            <table className="detailsTable">
                {this.props.kurzus.reszletMessage == 'OK' ? (
                    <KurzusReszletek key={this.props.kurzus.id} reszletAdatok={this.props.kurzus.reszletAdatok} kurzus={this.props.kurzus} reszletClick={this.props.reszletClick} toggleClick={this.props.toggleClick} munkarend={this.props.munkarend} />
                ):(
                  <tbody>
                    <tr>
                      <td>{this.props.kurzus.reszletMessage}</td>
                    </tr>
                  </tbody>
                )}
            </table>
          </td>
        </tr>
      ):null)
    )
  }
});

var KurzusReszletek = React.createClass({
  getInitialState() {
    return {}
  },
  render() {
    var obj = this;

    return (
      <tbody key={this.props.kurzus.kod}>
      {this.props.reszletAdatok.map(reszletAdat => (
        <ReszletSor key={reszletAdat.id} reszletAdat={reszletAdat} kurzus={this.props.kurzus} reszletClick={this.props.reszletClick} toggleClick={this.props.toggleClick} munkarend={this.props.munkarend} />
      ))}
      </tbody>
    )
  }
});

var ReszletSor = React.createClass({
  getInitialState() {
    return {}
  },
  render() {
    var obj = this;
    var megjegyzesClass = '';
    if(this.props.reszletAdat.megjegyzes && this.props.reszletAdat.megjegyzes_ok == 1) {
      megjegyzesClass = "kurzus_megjegyzes_ok";
    } else if(this.props.reszletAdat.megjegyzes) {
      megjegyzesClass = "kurzus_megjegyzes";
    }

    return (
      <tr className={megjegyzesClass} key={this.props.reszletAdat.id} href="javascript:void 0;">
        <td style={{"cursor": "pointer"}} onClick={() => this.props.reszletClick(this.props.reszletAdat.id,this.props.reszletAdat.max_korlat,this.props.reszletAdat.tanarok,this.props.reszletAdat.tanar_idk,this.props.reszletAdat.megjegyzes,this.props.reszletAdat.megjegyzes_ok,this.props.kurzus.id,this.props.reszletAdat.feltetelek,this.props.reszletAdat.mintatantervek)}>{this.props.reszletAdat.kurzus_tipus}</td>
        <td style={{"cursor": "pointer"}} onClick={() => this.props.reszletClick(this.props.reszletAdat.id,this.props.reszletAdat.max_korlat,this.props.reszletAdat.tanarok,this.props.reszletAdat.tanar_idk,this.props.reszletAdat.megjegyzes,this.props.reszletAdat.megjegyzes_ok,this.props.kurzus.id,this.props.reszletAdat.feltetelek,this.props.reszletAdat.mintatantervek)}>{this.props.reszletAdat.kod}</td>
        <td style={{"maxWidth": "200px", "cursor": "pointer"}} onClick={() => this.props.reszletClick(this.props.reszletAdat.id,this.props.reszletAdat.max_korlat,this.props.reszletAdat.tanarok,this.props.reszletAdat.tanar_idk,this.props.reszletAdat.megjegyzes,this.props.reszletAdat.megjegyzes_ok,this.props.kurzus.id,this.props.reszletAdat.feltetelek,this.props.reszletAdat.mintatantervek)}>{this.props.reszletAdat.tanarok ? (this.props.reszletAdat.tanarok):("nincs oktató megadva")}</td>
        <td style={{"cursor": "pointer"}} onClick={() => this.props.reszletClick(this.props.reszletAdat.id,this.props.reszletAdat.max_korlat,this.props.reszletAdat.tanarok,this.props.reszletAdat.tanar_idk,this.props.reszletAdat.megjegyzes,this.props.reszletAdat.megjegyzes_ok,this.props.kurzus.id,this.props.reszletAdat.feltetelek,this.props.reszletAdat.mintatantervek)}>max létszám: {this.props.reszletAdat.max_korlat}</td>
        <td style={{"cursor": "pointer"}} onClick={() => this.props.reszletClick(this.props.reszletAdat.id,this.props.reszletAdat.max_korlat,this.props.reszletAdat.tanarok,this.props.reszletAdat.tanar_idk,this.props.reszletAdat.megjegyzes,this.props.reszletAdat.megjegyzes_ok,this.props.kurzus.id,this.props.reszletAdat.feltetelek,this.props.reszletAdat.mintatantervek)}>{this.props.munkarend == 1 ? ("heti " + this.props.reszletAdat.oraszam_per_het + " óra") : (this.props.reszletAdat.oraszam_per_felev + " óra a félévben")}</td>
        <td style={{"cursor": "pointer"}}  onClick={() => this.props.reszletClick(this.props.reszletAdat.id,this.props.reszletAdat.max_korlat,this.props.reszletAdat.tanarok,this.props.reszletAdat.tanar_idk,this.props.reszletAdat.megjegyzes,this.props.reszletAdat.megjegyzes_ok,this.props.kurzus.id,this.props.reszletAdat.feltetelek,this.props.reszletAdat.mintatantervek)}>bontás: {this.props.reszletAdat.bontas}</td>
        <td style={{"cursor": "pointer"}}  onClick={() => this.props.reszletClick(this.props.reszletAdat.id,this.props.reszletAdat.max_korlat,this.props.reszletAdat.tanarok,this.props.reszletAdat.tanar_idk,this.props.reszletAdat.megjegyzes,this.props.reszletAdat.megjegyzes_ok,this.props.kurzus.id,this.props.reszletAdat.feltetelek,this.props.reszletAdat.mintatantervek)}>terem igény: {this.props.reszletAdat.terem_tipus_igeny}</td>
        <td style={{"cursor": "pointer"}}  onClick={() => this.props.reszletClick(this.props.reszletAdat.id,this.props.reszletAdat.max_korlat,this.props.reszletAdat.tanarok,this.props.reszletAdat.tanar_idk,this.props.reszletAdat.megjegyzes,this.props.reszletAdat.megjegyzes_ok,this.props.kurzus.id,this.props.reszletAdat.feltetelek,this.props.reszletAdat.mintatantervek)}>
          <table className="detailsTable">
            <Mintatantervek key={this.props.reszletAdat.id} mintatantervAdatok={this.props.reszletAdat.mintatantervek} kurzus={this.props.kurzus}/>
          </table>
        </td>
        <td style={{"textAlign": "center"}}>
          <Form>
            <Toggle name="kurzus.aktiv" title="Aktív" toggleClick={this.toggleClick} onClick={(value) => this.props.toggleClick(value, this.props.reszletAdat.id)} value={this.props.reszletAdat.aktiv}/>
          </Form>
        </td>
      </tr>
    )
  }
});

var Mintatantervek = React.createClass({
  getInitialState() {
    return {}
  },
  render() {
    var obj = this;

    return (
      <tbody key={this.props.kurzus.kod}>
      {this.props.mintatantervAdatok.map(reszletAdat => (
        <tr key={reszletAdat.kid}>
          <td>{reszletAdat.kod}</td>
          <td>{reszletAdat.nev}</td>
          <td>{reszletAdat.felev}. félév</td>
        </tr>
      ))}
      </tbody>
    )
  }
});

var Nulla_mintatantervek = React.createClass({
  getInitialState() {
    return {
      van_nulla: false
    }
  },

  componentDidMount: function() {
    var obj = this;

    this.props.mintatantervek.map(function(mintatanterv, i) {
      if(mintatanterv.neptun_felev != undefined && mintatanterv.neptun_felev == 0) {
        obj.state.van_nulla = true;
      }
    });
  },

  render() {
    var obj = this;

    return (
      <section>
      {this.state.van_nulla ? (
        <section>
          <div style={{"marginBottom": "15px"}}>Neptunban 0-ra állított kurzus félévek módosítása:</div>
          <Null_mintatanterv_lista mintatantervek={this.props.mintatantervek}/>
        </section>
      ):("")}
      </section>
    )
  }
});

var Null_mintatanterv_lista = React.createClass({
  getInitialState() {
    return {}
  },
  render() {
    var obj = this;

    return (
      <section>
      {this.props.mintatantervek.map(function(mintatanterv, i) {
        return mintatanterv.neptun_felev == 0 ? (
          <section>
            <InputHidden name={`felev[${i}][tanterv_id]`} title="" value={mintatanterv.tanterv_id} />
            <InputHidden name={`felev[${i}][targy_id]`} title="" value={mintatanterv.targy_id} />
            <Input name={`felev[${i}][ertek]`} title={mintatanterv.nev} value={mintatanterv.felev} validationError="Félév kötelező" validations="isInt" required />
          </section>
        ):("")
      })}
      </section>
    )
  }
});

export default KiirtKurzusok;