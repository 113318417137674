import React from 'react';
import Ajax from './../../Ajax.js';
import {Form} from 'formsy-react';

var LoggolasModal = React.createClass({
  getInitialState() {
    return {
      csoportok: []
    }
  },

  frissit: function() {
    var {store} = this.context;
    var _this = this;

    Ajax.call('get_undos_redos/' + localStorage.token + '/' + this.props.loggedUser.id + '/all', store, {
      async: false,
      success: function(data) {
        _this.setState({
          csoportok: data.csoportok
        }, function() {
          _this.ablakPozicio();
        });
      }
    });
  },

  componentDidMount: function() {
    $('#log_modal').draggable({
      start: function( event, ui ) {
        $('#log_modal').addClass('startdrag');
      }
    });
    this.ablakPozicio();
  },

  ablakPozicio: function() {
    var top;

    if ($(this.refs.log_modal).height() > $(window).innerHeight()) {
      top = -$('.panel-body').offset().top + $(this.refs.log_modal).height() / 2;
    } else {
      top = Math.round($(window).scrollTop() + $(window).innerHeight() / 2 - parseInt($('.main-panel').css('padding-top')) - 200);
    }

    $(this.refs.log_modal).css({
      'left': $(window).innerWidth() / 2 - parseInt($('.main-panel').css('padding-left')),
      'top': top
    });
  },

  componentDidUpdate: function() {
    var _this = this;

    $('.log-item-btn.undo-btn').click(function() {
      _this.props.undo($(this).data('id'));
    });

    $('.log-item-btn.redo-btn').click(function() {
      _this.props.redo($(this).data('id'));
    });
  },

  render() {
    var _this = this;
    var tmp = "";

    if (this.state.csoportok.length > 0) {
      this.state.csoportok.map(function(csoport, i) {
        csoport.muveletek.map(function(muvelet, j) {
          if (muvelet.tabla == "orak") {
            var uj = '';
            var regi = '';

            if (csoport.tipus == "undo") {
              if (muvelet.db_adatok.regi_terem == '-') {
                regi = 'Elrakatlan';
                uj = muvelet.db_adatok.uj_nap + ", " + muvelet.db_adatok.uj_ora_kezdet + " (" + muvelet.db_adatok.uj_terem + ")";

              } else if (muvelet.db_adatok.uj_terem == '-') {
                uj = 'Elrakatlan';
                regi = muvelet.db_adatok.regi_nap + ", " + muvelet.db_adatok.regi_ora_kezdet + " (" + muvelet.db_adatok.regi_terem + ")";

              } else {
                uj = muvelet.db_adatok.uj_nap + ", " + muvelet.db_adatok.uj_ora_kezdet + " (" + muvelet.db_adatok.uj_terem + ")";
                regi = muvelet.db_adatok.regi_nap + ", " + muvelet.db_adatok.regi_ora_kezdet + " (" + muvelet.db_adatok.regi_terem + ")";
              }

              tmp += "<div class=\"orarend-szerk-log-item\"><div class=\"datum\">" + muvelet.idopont + "</div><button data-id=\"" + csoport.id + "\" class=\"log-item-btn undo-btn btn btn-white only-icon\" type=\"button\"><i class=\"fa fa-undo\"></i></button><span>" + muvelet.db_adatok.tantargy + "</span>" + regi + "<i class=\"fa fa-arrow-circle-right\"></i>" + uj + "</div>";

            } else if (csoport.tipus == "redo") {
              if (muvelet.db_adatok.regi_terem == '-') {
                regi = 'Elrakatlan';
                uj = muvelet.db_adatok.uj_nap + ", " + muvelet.db_adatok.uj_ora_kezdet + " (" + muvelet.db_adatok.uj_terem + ")";

              } else if (muvelet.db_adatok.uj_terem == '-') {
                uj = 'Elrakatlan';
                regi = muvelet.db_adatok.regi_nap + ", " + muvelet.db_adatok.regi_ora_kezdet + " (" + muvelet.db_adatok.regi_terem + ")";

              } else {
                uj = muvelet.db_adatok.uj_nap + ", " + muvelet.db_adatok.uj_ora_kezdet + " (" + muvelet.db_adatok.uj_terem + ")";
                regi = muvelet.db_adatok.regi_nap + ", " + muvelet.db_adatok.regi_ora_kezdet + " (" + muvelet.db_adatok.regi_terem + ")";
              }

              tmp += "<div class=\"orarend-szerk-log-item\"><div class=\"datum\">" + muvelet.idopont + "</div><button data-id=\"" + csoport.id + "\" class=\"log-item-btn redo-btn btn btn-white only-icon\" type=\"button\"><i class=\"fa fa-undo\"></i></button><span>" + muvelet.db_adatok.tantargy + "</span>" + uj + "<i class=\"fa fa-arrow-circle-right\"></i>" + regi + "</div>";
            }
          }
        });
      });
    } else {
      tmp = "Nem található művelet.";
    }

    return (
      <div ref="log_modal" id="log_modal" className="modal-content no-overlay">
        <div className="modal-header">
          <button onClick={this.props.hide} type="button" className="close"><span aria-hidden="true">×</span></button>
          <h4 className="modal-title">Műveletek</h4>
        </div>
        <div className="modal-body">
          <div className="orarend-szerk-log" style={{'marginTop':'0px'}}>
            <div className="button-group">
              <button ref="undo_btn" onClick={()=>{this.props.lastUndo()}} className="btn btn-white only-icon" type="button"><i className="fa fa-undo"></i></button>
              <button ref="redo_btn" style={{'marginLeft':'2px'}} onClick={()=>{this.props.lastRedo()}} className="btn btn-white only-icon" type="button"><i className="fa fa-repeat"></i></button>
            </div>
            <div className="orarend-szerk-log-items" dangerouslySetInnerHTML={{__html: tmp}}></div>
          </div>
        </div>
      </div>
    )
  }
});

LoggolasModal.contextTypes = {
  store: React.PropTypes.object
};

export default LoggolasModal;