import React from 'react';
import Ajax from './../Ajax.js';
import {Form} from 'formsy-react';
import Input from './../form/Input';
import Select from './../form/Select';
import Select2 from './../form/Select2';
import utils from 'formsy-react/lib/utils';

var SertesekModal = React.createClass({
  getInitialState() {
    return {
      orak: []
    }
  },

  componentDidMount: function() {
    $('#sertesek_modal').draggable({
      start: function() {
        $('#sertesek_modal').addClass('startdrag');
      }
    });
  },

  componentDidUpdate: function(prevProps, prevState) {
    if (!utils.isSame(prevState, this.state)) {
      $('#sertesek_modal').css({'height':'auto'});

      // Ha kisebb az ablak magassága, mint a képernyő, akkor középre rakja
      var top_pos = $(window).scrollTop() + $(window).innerHeight() / 2;
      if (top_pos - ($('#sertesek_modal').height() / 2) < 0) {
        top_pos = $('#sertesek_modal').height() / 2;
      }

      $('#sertesek_modal').css({
        'top': top_pos
      });
    }
  },

  getOrak: function(szak_id, szakirany_id, felev_id, oktato_id) {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/orak/' + localStorage.token, store, {
      method: 'POST',
      async: false,
      data: {
        aktiv_orarend_id: this.props.loggedUser.aktiv_orarend.id,
        felev_kezdete: this.props.loggedUser.aktiv_idoszak.felev_kezdete,
        szak_id: szak_id,
        szakirany_id: szakirany_id,
        felev_id: felev_id,
        oktato_id: oktato_id
      },
      success: function(data) {
        _this.setState({
          orak: data.orak
        })
      }
    });
  },

  render() {
    var _this = this;

    var felevKezdet = moment(_this.props.loggedUser.aktiv_idoszak.felev_kezdete, "YYYY-MM-DD");
    var d = felevKezdet.day() - 1;
    var dt = moment(_this.props.loggedUser.aktiv_idoszak.felev_kezdete, "YYYY-MM-DD").subtract(d, 'days');
    moment.locale('hu');

    var db = 0;
    if (this.state.orak.length > 0) {
      this.state.orak.map(function(ora, i) {
        if (ora.sertes != undefined)
          db++;
      });
    }

    return (
      <div>
      <div id="sertesek_modal" className="modal-content no-overlay" style={{'width':'auto'}}>
        <div className="modal-header">
          <button onClick={() => { this.props.hide(); }} type="button" className="close"><span aria-hidden="true">×</span></button>
          <h4 className="modal-title">Sértések</h4>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-xs-12">
            {db.length > 0 ? (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Név</th>
                  <th>Kurzus</th>
                  <th>Időpont</th>
                  <th>Szakok</th>
                  <th>Sértés</th>
                </tr>
              </thead>
              <tbody>
            {this.state.orak.map(function(ora, i) {
                if (ora.sertes == undefined)
                  return false;

                var datum_tol = moment(dt.format("YYYY-MM-DD") + " " + ora.kezdete, "YYYY-MM-DD HH:mm:ss");
                datum_tol = datum_tol.add(parseInt(ora.nap), 'd');

                var nap = moment(datum_tol).format('dddd');

                return (
                  <tr key={i}>
                    <td>
                      {ora.tantargy}
                    </td>
                    <td>
                      {ora.kurzus_azonosito}
                    </td>
                    <td>
                      {ora.kezdete} - {ora.vege} ({nap})
                    </td>
                    <td>
                      {ora.szakok}
                    </td>
                    <td>
                      <div style={{"color":ora.sertes.szin}}>{ora.sertes.megnevezes}</div>
                    </td>
                  </tr>
                )
            })}
              </tbody>
            </table>): <div>Nem található sértés.</div>}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button onClick={() => { this.props.hide(); }} type="button" className="btn btn-default">Bezár</button>
        </div>
      </div>
      </div>
     )
  }
});

SertesekModal.contextTypes = {
  store: React.PropTypes.object
};

export default SertesekModal;