import React from 'react';
import {render} from 'react-dom';
import { Link, Router, Route, IndexRoute, withRouter, hashHistory } from 'react-router';
import Home from './Home.js';
import About from './About.js';
import Contact from './Contact.js';
import NotFound from './NotFound.js';
import Auth from './Auth.js';
import Login from './Login.js';
import Logout from './Logout.js';
import Main from './Main.js';
import Felhasznalo from './Felhasznalo.js';
import Felhasznalok from './Felhasznalok.js';
import Idoszakok from './Idoszakok.js';
import KiirandoKurzusok from './KiirandoKurzusok.js';
import KiirtKurzusok from './KiirtKurzusok.js';
import UzenetOldal from './UzenetOldal.js';
import EroforrasokOktatok from './EroforrasokOktatok.js';
import EroforrasokOktato from './EroforrasokOktato.js';
import EroforrasokEszkozok from './EroforrasokEszkozok.js';
import EroforrasokEszkoz from './EroforrasokEszkoz.js';
import EroforrasokTermek from './EroforrasokTermek.js';
import EroforrasokTerem from './EroforrasokTerem.js';
import EroforrasokEpuletek from './EroforrasokEpuletek.js';
import EroforrasokEpulet from './EroforrasokEpulet.js';
import MentesitesekNappali from './MentesitesekNappali.js';
import MentesitesNappali from './MentesitesNappali.js';
import MentesitesekLevelezo from './MentesitesekLevelezo.js';
import MentesitesLevelezo from './MentesitesLevelezo.js';
import Feltetelek from './Feltetelek.js';
import Feltetel from './Feltetel.js';
import Orarend from './Orarend.js';
import OrarendLevelezo from './OrarendLevelezo.js';
import KerelmekKimeno from './KerelmekKimeno.js';
import KerelmekBejovo from './KerelmekBejovo.js';
import Kerelem from './Kerelem.js';
import TeremTarsitas from './TeremTarsitas.js';
import EroforrasokTelephely from './EroforrasokTelephely.js';
import EroforrasokTelephelyek from './EroforrasokTelephelyek.js';
import SajatOrarend from './SajatOrarend.js';
import SajatOrarendLevelezo from './SajatOrarendLevelezo.js';

import {createStore, compse} from 'redux';
import {syncHistoryWithStore} from 'react-router-redux';
import rootReducer from './../reducers/index';
import {Provider} from 'react-redux';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actionCreators from './../actions/actioncreator.js';
import Ajax from './Ajax.js';

const defaultState = {
  config: {
    apiUrl: 'api/',
    menuOpen: true,
    serviceRun: false,
    ajaxPreloaderShow: false,
    version: '1.0.2021.07.06.13.00'
  },
  oldalUzenet: {
    tipus: '',
    uzenet: ''
  },
  loggedUser: {
    id: '',
    avatar: '',
    fullname: '',
    group_id: '',
    group_name: '',
    aktiv_idoszak: {
      id: 0,
      megnevezes: '',
      felev_kezdete: '',
      felev_vege: ''
    },
    aktiv_orarend: {
      id: 0,
      megnevezes: '',
      adatbazis: ''
    },
    kivalasztott_kurzus: {
      id: 0,
      megjegyzes: '',
      tantargy_id: 0,
      scroll: 0
    },
    rules: []
  }
};

const store = createStore(rootReducer, defaultState);
const history = syncHistoryWithStore(hashHistory, store);

function mapStateToProps(state) {
  return {
    prop_loggedUser: state.loggedUser,
    prop_config: state.config,
    prop_oldalUzenet: state.oldalUzenet
  }
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const App = connect(mapStateToProps, mapDispachToProps)(Main);

function requireAuth(nextState, replace, callback) {
  Auth.checkToken(function(response) {
    console.log("Útvonal:" + nextState.location.pathname);
    if (response.status == "OK") {
      Auth.setToken(response.token);
      console.log(response.token + " CHECKTOKEN onEnter-re (OK)");

      // F5 miatt vissza írja a felhasználó admin State állapotát
      var user = response.data.user;

      if(this) {
        console.log(this.props.prop_loggedUser);
      }

      store.dispatch({type:'USER_SET_DATA', id: user.id, fullname: user.fullname, group_id: user.group_id, group_name: user.group_name, avatar: user.avatar, shib_avatar: user.shib_avatar, neptun: user.neptun, rules: user.rules});

      if (user.orarend_id != null && user.orarend_id != '') {
        store.dispatch({type:'USER_SET_IDOSZAK', id: user.idoszak_id, megnevezes: user.idoszak_megnevezes, felev_kezdete: user.felev_kezdete, felev_vege: user.felev_vege});
        store.dispatch({type:'USER_SET_ORAREND', id: user.orarend_id, megnevezes: user.orarend_megnevezes, adatbazis: user.adatbazis_megnevezes});
      } else {
        Ajax.call('alapertelm_orarend/' + localStorage.token, store, {
          async: false,
          success: function(data) {
            if (data != false) {
              store.dispatch({type:'USER_SET_IDOSZAK', id: data.idoszak_id, megnevezes: data.idoszak_megnevezes, felev_kezdete: data.felev_kezdete, felev_vege: data.felev_vege});
              store.dispatch({type:'USER_SET_ORAREND', id: data.orarend_id, megnevezes: data.orarend_megnevezes, adatbazis: data.adatbazis_megnevezes});
            }
          }
        });
      }

      Ajax.call('check_service_running', store, {
        method: 'GET',
        async: true,
        success: function(data) {
          store.dispatch({type:'SET_CONFIG_SERVICE_RUN', run: data});
        }
      });

    } else {
      console.log(response.token + " CHECKTOKEN onEnter-re (!!NEMOK)");
      Auth.deleteToken();
    }

    if (!Auth.loggedIn()) {
      callback(replace({pathname: '/login'}));

      /*
      replace({
        pathname: '/login',
        state: { nextPathname: nextState.location.pathname }
      })*/
    } else {
      callback();
    }
  });
}

const router = (
  <Provider store={store}>
    <Router history={history}>
      <Route path="/" component={App}>
        <IndexRoute onEnter={requireAuth} component={Felhasznalok} />
        <Route path="login" component={Login} />
        <Route path="logout" component={Logout} />
        <Route onEnter={requireAuth} path="/felhasznalok" component={Felhasznalok} />
          <Route onEnter={requireAuth} path="/felhasznalo/:id" component={Felhasznalo}/>
          <Route onEnter={requireAuth} path="/uj_felhasznalo" component={Felhasznalo}/>
        <Route onEnter={requireAuth} path="/idoszakok" component={Idoszakok} />
        <Route onEnter={requireAuth} path="/kiirando_kurzusok" component={KiirandoKurzusok} />
        <Route onEnter={requireAuth} path="/kiirt_kurzusok/:redirected" component={KiirtKurzusok} />
        <Route onEnter={requireAuth} path="/eroforrasok_oktatok" component={EroforrasokOktatok} />
          <Route onEnter={requireAuth} path="/eroforrasok_oktato/:id" component={EroforrasokOktato}/>
          <Route onEnter={requireAuth} path="/eroforrasok_uj_oktato" component={EroforrasokOktato}/>
        <Route onEnter={requireAuth} path="/eroforrasok_eszkozok" component={EroforrasokEszkozok} />
          <Route onEnter={requireAuth} path="/eroforrasok_uj_eszkoz" component={EroforrasokEszkoz} />
          <Route onEnter={requireAuth} path="/eroforras_eszkoz/:id" component={EroforrasokEszkoz} />
        <Route onEnter={requireAuth} path="/eroforrasok_termek" component={EroforrasokTermek} />
          <Route onEnter={requireAuth} path="/eroforrasok_uj_terem" component={EroforrasokTerem} />
          <Route onEnter={requireAuth} path="/eroforrasok_terem/:id" component={EroforrasokTerem} />
        <Route onEnter={requireAuth} path="/eroforrasok_epuletek" component={EroforrasokEpuletek} />
          <Route onEnter={requireAuth} path="/eroforrasok_epulet/:id" component={EroforrasokEpulet} />
          <Route onEnter={requireAuth} path="/eroforrasok_uj_epulet" component={EroforrasokEpulet} />
        <Route onEnter={requireAuth} path="/eroforrasok_telephelyek" component={EroforrasokTelephelyek} />
          <Route onEnter={requireAuth} path="/eroforrasok_telephely/:id" component={EroforrasokTelephely} />
          <Route onEnter={requireAuth} path="/eroforrasok_uj_telephely" component={EroforrasokTelephely} />
        <Route onEnter={requireAuth} path="/orarend" component={Orarend} />
        <Route onEnter={requireAuth} path="/orarend_levelezo" component={OrarendLevelezo} />
        <Route onEnter={requireAuth} path="/sajat_orarend" component={SajatOrarend} />
        <Route onEnter={requireAuth} path="/sajat_orarend_levelezo" component={SajatOrarendLevelezo} />
        <Route onEnter={requireAuth} path="/mentesitesek_nappali/:tanar_id" component={MentesitesekNappali} />
          <Route onEnter={requireAuth} path="/mentesitesek_nappali_uj/:tanar_id" component={MentesitesNappali} />
          <Route onEnter={requireAuth} path="/mentesites_nappali/:id" component={MentesitesNappali} />
        <Route onEnter={requireAuth} path="/mentesitesek_levelezo/:tanar_id" component={MentesitesekLevelezo} />
          <Route onEnter={requireAuth} path="/mentesitesek_levelezo_uj/:tanar_id" component={MentesitesLevelezo} />
          <Route onEnter={requireAuth} path="/mentesites_levelezo/:id" component={MentesitesLevelezo} />
        <Route onEnter={requireAuth} path="/feltetelek" component={Feltetelek} />
          <Route onEnter={requireAuth} path="/feltetel/:id/:redirected" component={Feltetel} />
          <Route onEnter={requireAuth} path="/uj_feltetel/:redirected" component={Feltetel} />
        <Route onEnter={requireAuth} path="/kerelmek_bejovo" component={KerelmekBejovo} />
          <Route onEnter={requireAuth} path="/kerelem/:id" component={Kerelem} />
        <Route onEnter={requireAuth} path="/kerelmek_kimeno" component={KerelmekKimeno} />
        <Route onEnter={requireAuth} path="/uzenet" component={UzenetOldal} />
        <Route onEnter={requireAuth} path="/terem_tarsitas" component={TeremTarsitas} />
        <Route path="*" component={NotFound} />
      </Route>
    </Router>
  </Provider>
);

render(router, document.getElementById('main'));

