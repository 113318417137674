import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button, Tabs, Tab} from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import InputHidden from './form/InputHidden';
import Select from './form/Select';
import Select2 from './form/Select';
import Textarea from './form/Textarea';
import Toggle from './form/Toggle';

Formsy.addValidationRule('isSelect2Empty', function (values, value) {
  return ((value == undefined || value.length == 0) ? false : true);
});

var EroforrasokTelephely = React.createClass({
  getInitialState() {
    return {
      telephely: {
        id: '',
        nev: '',
        rovidnev: '',
        kod: '',
        varos_id: '',
        real_id: ''
      },
      varosok: [],
      szotar_telephelyek: [],
      periodusok: [],
      formIsValid: false
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {
    var obj = this;
    var {store} = this.context;

    if (obj.props.params.id == undefined) {
      obj.props.params.id = 0;
    }

    Ajax.call('eroforrasok/telephely/' + localStorage.token + '/' + obj.props.params.id, store, {
      success: function(data) {
        data.varosok = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.varosok
        ];

        data.szotar_telephelyek = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.szotar_telephelyek
        ];

        obj.setState({
          varosok: data.varosok,
          szotar_telephelyek: data.szotar_telephelyek,
          periodusok: data.periodusok
        });

        if (data.telephely != undefined) {
          obj.setState({telephely: data.telephely});
        }
      }
    });
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
  },

  componentDidUpdate: function() {

  },

  onMent: function() {
    var self = this;

    this.refs.form.submit();

    if (this.state.formIsValid) {
      var formData = this.refs.form.getModel();

      var obj = this;
      var {store} = this.context;

      formData.telephely.id = this.state.telephely.id;

      Ajax.call('eroforrasok/telephely/ment/' + localStorage.token, store, {
        method: "POST",
        data: {
          data: formData
        },
        success: function(data) {
          hashHistory.push('/eroforrasok_telephelyek');
        }
      });
    }
  },

  onValid: function() {
    this.setState({
      formIsValid: true
    });
  },

  onInvalid: function() {
    this.setState({
      formIsValid: false
    });
  },

  render: function() {
      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Erőforrások
              </li>
              <li>
                Telephelyek
              </li>
              <li>
                Telephely
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Erőforrások, telephely</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Telephely</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                    <Form ref="form" onValid={this.onValid} onInvalid={this.onInvalid}>
                      <Tabs defaultActiveKey={1} id="tabs">
                        <Tab eventKey={1} title={<span>Adatlap</span>}>
                          <Input name="telephely.nev" title="Név" value={this.state.telephely.nev} validationError="Név kötelező" required />
                          <Input name="telephely.rovidnev" title="Rövidnév" value={this.state.telephely.rovidnev}/>
                          <Input name="telephely.kod" title="Kód" value={this.state.telephely.kod}/>
                          <Select name="telephely.varos_id" title="Város" value={this.state.telephely.varos_id} options={this.state.varosok}/>
                          <Select name="telephely.real_id" title="Szótár" value={this.state.telephely.real_id} options={this.state.szotar_telephelyek}/>
                        </Tab>
                        <Tab eventKey={2} title={<span>Periódus távolságok</span>}>
                          {this.state.periodusok.length > 0 && this.state.periodusok.map(function(periodus, i) {
                              return (<PeriodusTavolsag key={i} index={i} data={periodus}/>)
                          })}
                        </Tab>
                      </Tabs>
                    </Form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

EroforrasokTelephely.contextTypes = {
  store: React.PropTypes.object
}

export default EroforrasokTelephely;

  var PeriodusTavolsag = React.createClass({
  getInitialState() {
    return {

    }
  },

  render() {
    return (
      <div className="col-xs-6">
        <div className="form-group">
          <label className="form-label">{this.props.data.nev}</label>
        </div>
        <InputHidden name={`p[${this.props.index}][id]`} value={this.props.data.id}/>
        <Input addonTitle="Periódus" title="" name={`p[${this.props.index}][periodus]`} value={this.props.data.periodus} validationError="Csak szám lehet" validations="isNumeric"/>
      </div>
    );
  }
});