import React from 'react';
import Ajax from './../Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import { Form } from 'formsy-react';
import Input from './../form/Input';
import Select from './../form/Select';
import Toggle from './../form/Toggle';
import utils from 'formsy-react/lib/utils';

var UjOrarendModal = React.createClass({
  getInitialState() {
    return {
      show: false,
      orarendek: [],
      masol: 0,
      orarend_id: '',
      ora_elrakasok: 0,
      feltetelek: 1,
      mentesitesek: 1
    }
  },

  componentDidUpdate: function() {

  },

  getOrarendek: function() {
    var {store} = this.context;
    var _this = this;

    Ajax.call('orarendek/' + localStorage.token + '/' + this.props.loggedUser.aktiv_idoszak.id, store, {
      success: function(data) {
        var tmp = [];
        data.orarendek.map(function(orarend, i) {
          tmp.push({
            value: orarend.id,
            label: orarend.megnevezes
          });
        });
        _this.setState({
          orarendek: tmp,
          orarend_id: tmp[0].value
        })
      }
    });
  },

  show: function() {
    var _this = this;

    this.setState({
      show: true
    }, function() {
      _this.getOrarendek();
    });
  },

  hide: function() {
    this.setState({
      show: false
    });
  },

  ujOrarendLetrehozas: function() {
    var {store} = this.context;
    var _this = this;

    $.fn.spin.presets.preloader = {lines:13, length:0, width:3, radius:10, scale:.75, corners:1, opacity:.25, speed:1};
    $(_this.refs.preloader).addClass('with-preloader');
    $(_this.refs.preloader).spin('preloader');

    var data = this.refs.uj_orarend_form.getModel();
    var kivalasztott_idoszak_id = 0;

    this.props.idoszakok.map(function(idoszak, i) {
      if (idoszak.kivalasztott == 1)
        kivalasztott_idoszak_id = idoszak.id;
    });

    data.idoszak_id = kivalasztott_idoszak_id;

    Ajax.call('uj_orarend/' + localStorage.token, store, {
      method: "POST",
      data: data,
      success: function(data) {
        if (data.success) {
          _this.props.idoszakValasztasClick(kivalasztott_idoszak_id);
          swal('Siker!', 'Új órarend létrehozása sikeres.', 'success');
          _this.hide();
        } else {
          swal('Hiba!', 'Nem sikerült az órarendet létrehozni. (' + data.hiba + ')', 'error');
        }
      }
    });
  },

  render: function() {
    return (
    <div>
      {this.state.show &&
      <Modal show={this.state.show} onHide={this.hide}>
        <Modal.Header closeButton>
          <Modal.Title>Új órarend</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref="uj_orarend_form">
            <Toggle onChange={(value) => this.setState({masol:value})} name="masol" title="Órarendet másol?" value={this.state.masol}/>
            {this.state.masol ?
            (<div>
             <Select onChange={(value) => this.setState({orarend_id:value})} name="orarend_id" title="Másolandó órarend" value={this.state.orarend_id} options={this.state.orarendek} />
             <Toggle onChange={(value) => this.setState({ora_elrakasok:value})} name="ora_elrakasok" ref="ora_elrakasok" title="Óra elrakások áthozása" value={this.state.ora_elrakasok}/>
             <Toggle onChange={(value) => this.setState({feltetelek:value})} name="feltetelek" ref="feltetelek" title="Feltételek áthozása" value={this.state.feltetelek}/>
             <Toggle onChange={(value) => this.setState({mentesitesek:value})} name="mentesitesek" ref="mentesitesek" title="Mentesítések áthozása" value={this.state.mentesitesek}/>
             </div>
            ):null
            }

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.hide} type="button">Mégsem</Button>
          <button ref="preloader" onClick={this.ujOrarendLetrehozas} className="btn btn-primary">Létrehoz</button>
        </Modal.Footer>
      </Modal>
      }
    </div>)
  }
});

UjOrarendModal.contextTypes = {
  store: React.PropTypes.object
}

export default UjOrarendModal;