import React from 'react';
import Ajax from './../Ajax.js';
import Select2 from './../form/Select2';

var Periodus = React.createClass({
  getInitialState() {
    return {
      opciok: []
    }
  },

  componentDidMount: function() {
    var url = '/feltetel/periodusok/';
    if(this.props.fgv_id == 13 || this.props.fgv_id == 14 || this.props.fgv_id == 15 || this.props.fgv_id == 19 || this.props.fgv_id == 20 || this.props.fgv_id == 21) url = '/feltetel/levelezos_periodusok/';
    this.getPeriodus(url,this.props.altipus,this.props.levelezos_nap,this.props.ismetlodes);
  },

  componentWillReceiveProps: function(nextProps) {
    if(nextProps.fgv_id != this.props.fgv_id || nextProps.levelezos_nap != this.props.levelezos_nap) {
      var url = '/feltetel/periodusok/';
      if(nextProps.fgv_id == 13 || nextProps.fgv_id == 14 || nextProps.fgv_id == 15 || this.props.fgv_id == 19 || this.props.fgv_id == 20 || this.props.fgv_id == 21) url = '/feltetel/levelezos_periodusok/';
      this.getPeriodus(url,nextProps.altipus,nextProps.levelezos_nap, nextProps.ismetlodes);
    }
  },

  getPeriodus: function(url,altipus,levelezos_nap,ismetlodes) {
    var obj = this;
    var {store} = this.context;

    Ajax.call(url + localStorage.token + '/' + altipus + '/' + levelezos_nap + '/' + ismetlodes, store, {
      async: false,
      success: function(data) {
        var tmp = [];
        data.periodusok.map(function(periodus, i) {
          tmp.push({
            value: parseInt(periodus.periodus),
            label: periodus.nev
          })
        });
        obj.setState({opciok: tmp});
      }
    });
  },

  render: function() {
    return (
      <Select2 placeholder="- válassz -" name={`feltetel[ertek][p_${this.props.id}]`} value={this.props.ertek} title={this.props.label} options={this.state.opciok} validationError="Kötelező" required/>
    );
  }
});

Periodus.contextTypes = {
  store: React.PropTypes.object
}

export default Periodus;