import React from 'react';
import Ajax from './../Ajax.js';
import Select2 from './../form/Select2';

var Eszkoz = React.createClass({
  getInitialState() {
    return {
      opciok: [],
      value: ''
    }
  },

  componentDidMount: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('feltetel/eszkoz/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        var tmp = [];
        data.eszkozok.map(function(eszkoz, i) {
          tmp.push({
            value: parseInt(eszkoz.id),
            label: eszkoz.megnevezes
          })
        });
        obj.setState({opciok: tmp});
      }
    });

    Ajax.call('eroforrasok/szotarIdToEszkozId/' + localStorage.token + '/' + this.props.ertek, store, {
      async: false,
      success: function(data) {
        if(data.eszkoz_id) {
          obj.setState({
            value: parseInt(data.eszkoz_id)
          })
        }
      }
    });
  },

  render: function() {
    return (
      <Select2 placeholder="- válassz -" name={`feltetel[ertek][p_${this.props.id}]`} value={this.state.value} title={this.props.label} options={this.state.opciok} validationError="Kötelező" required/>
    );
  }
});

Eszkoz.contextTypes = {
  store: React.PropTypes.object
}

export default Eszkoz;