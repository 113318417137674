import React from 'react';
import Ajax from './../../Ajax.js';
import {Form} from 'formsy-react';
import Toggle from './../../form/Toggle';
import Select from './../../form/Select';

var TeremLampazasModal = React.createClass({
  getInitialState() {
    return {
      terem: '',
      akt_terem_id: '',
      idopontok: [],
      tantargy: 1,
      oktato: 1,
      szakirany: 1
    }
  },

  componentDidMount: function() {
    $('#lampazas_modal').draggable({
      start: function( event, ui ) {
        $('#lampazas_modal').addClass('startdrag');
      }
    });

    this.state.akt_terem_id = this.props.terem_id;

    this.ablakPozicio();
    this.getIdopontok();
    this.getTeremAdatok();
  },

  toSmallSize: function() {
    $('#lampazas_modal').addClass('smallsize');
  },

  componentDidUpdate: function() {
    var _this = this;

    $('.foglalt').mouseover(function() {
      var nap_id = parseInt($(this).data('nap'));
      var periodus_id = parseInt($(this).data('periodus'));
      var p = '';

      _this.state.idopontok.map(function(nap, i) {
        if (nap.nap_id == nap_id) {
          nap.periodusok.map(function(periodus, i) {
            if (periodus.periodus == periodus_id) {
              p = periodus;
            }
          });
        }
      });

      if (p != '') {
        if ($('.calendar-tooltip').length == 0) {
          var text = '';

          if (p.foglaltsag.foglalt) {
            p.foglaltsag.utkozes.map(function(utkozes, i) {
              var text_arr = [];

              text_arr.push('Sértés: ' + utkozes.sertes.megnevezes);

              if (utkozes.kurzus_azon != undefined)
                text_arr.push('Kurzus azonosító: ' + utkozes.kurzus_azon);

              if (utkozes.szak != undefined)
                text_arr.push('Szak: ' + utkozes.szak.nev + (utkozes.felev?' (' + utkozes.felev + '. félév)':''));

              if (utkozes.tanterv != undefined)
                text_arr.push('Tanterv: ' + utkozes.tanterv.kod + ' (' + utkozes.tanterv.felev + '. félév)');

              if (utkozes.tanar != undefined)
                text_arr.push('Oktató: ' + utkozes.tanar.nev + ' (' + utkozes.tanar.neptun + ')');

              if (utkozes.feltetel_megnevezes != undefined)
                text_arr.push('Megjegyzés: ' + utkozes.feltetel_megnevezes);

              text += '<span>' + text_arr.join('<br>') + '</span>';
            });
          }

          var tooltip = $('<div class="calendar-tooltip">'+text+'</div>');
          $('body').append(tooltip);
          _this.tooltipPozicioBeallit($(tooltip));

          $('body').mousemove(function(event) {
            _this.tooltipPozicioBeallit($(tooltip));
          });
        }
      }
    });

    $('.foglalt').mouseout(function() {
      $('.calendar-tooltip').remove();
    });
  },

  tooltipPozicioBeallit: function(tooltip) {
    var top = (event.clientY - ($(tooltip).outerHeight() + 30)) + $(window).scrollTop();
    var left = event.pageX - $(tooltip).width() / 2 - 7;

    if ( (event.clientY - $(tooltip).outerHeight()) < 0 ) {
      top = $(window).scrollTop();
      left = event.pageX - $(tooltip).width() - 50;
    }

    $(tooltip).css({
      left: left,
      top: top
    });
  },

  ablakPozicio: function() {
    var top;

    if ($('#lampazas_modal').height() > $(window).innerHeight()) {
      top = -$('.panel-body').offset().top + $('#lampazas_modal').height() / 2;
    } else {
      top = Math.round($(window).scrollTop() + $(window).innerHeight() / 2 - parseInt($('.main-panel').css('padding-top')) - 200);
    }

    $(this.refs.lampazas_modal).css({
      'left': $(window).innerWidth() / 2 - parseInt($('.main-panel').css('padding-left')),
      'top': top
    });

    $(this.refs.lampazas_modal).removeClass('startdrag');
    $(this.refs.lampazas_modal).css({
      'height': 'auto'
    });
  },

  getIdopontok: function(value,tipus) {
    var _this = this;
    var {store} = this.context;

    var tantargy = this.state.tantargy;
    var oktato = this.state.oktato;
    var szakirany = this.state.szakirany;

    if(tipus == 'tantargy') {
      tantargy = value;
    } else if(tipus == 'oktato') {
      oktato = value;
    } else if (tipus == 'szakirany') {
      szakirany = value;
    }

    Ajax.call('orarend_szerk/terem_lampazas_idopontok_levelezo/' + localStorage.token + '/' + this.props.kurzus_id + '/' + tantargy + '/' + oktato + '/' + szakirany + '/' + this.props.idoszak_kezdete + '/' + this.state.akt_terem_id, store, {
      async: false,
      success: function(data) {
        _this.setState({
          idopontok: data.idopontok
        });
      }
    });
  },

  getTeremAdatok: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend_szerk/terem_adatok/' + localStorage.token + '/' + this.state.akt_terem_id, store, {
      async: false,
      success: function(data) {
        _this.setState({
          terem: data.teremAdatok
        });
      }
    });
  },

  onChangeTerem: function() {
    this.getTeremAdatok();
    this.getIdopontok();
  },

  render() {
    var _this = this;
    var tmp = "<tr>";
    var het = 1;

    tmp += "<td class=\"nap\">Péntek</td>";
    {this.state.idopontok[0] != undefined && this.state.idopontok[0].periodusok.map(function(periodus, i) {
        tmp +="<td class=\"periodus\">"+periodus.kezdete+"-"+periodus.vege+"</td>";
    })}
    tmp += "<td class=\"nap\">Szombat</td>";
    {this.state.idopontok[1] != undefined && this.state.idopontok[1].periodusok.map(function(periodus, i) {
        tmp +="<td class=\"periodus\">"+periodus.kezdete+"-"+periodus.vege+"</td>";
    })}
    tmp += "</tr>";

    tmp += "<tr>";
    {this.state.idopontok.map(function(nap, i) {
      if (i % 2 == 0 && i != 0) {
        tmp += "</tr><tr>";
        het++;
      }
      tmp = tmp + "<td class=\"datum\"><span>"+het+". hét</span>"+nap.datum+"</td>";
      {nap.periodusok.map(function(periodus, j) {
        var text = "";
        var class_name = "";
        var van_utkozes = false;

        if (periodus.foglaltsag.foglalt == true) {
          class_name = " foglalt ";
          periodus.foglaltsag.utkozes.map(function(utkozes, i) {
            if (van_utkozes && class_name.indexOf('tobbszoros') == -1)
              class_name += ' tobbszoros ';

            van_utkozes = true;

            if (class_name.indexOf(utkozes.sertes.class_definicio) == -1) {
              class_name += ' ' + utkozes.sertes.class_definicio + ' ';
              text +="<div class=\"info\">"+utkozes.sertes.megnevezes+"</div>";
            }
          });
        } else {
          class_name = " noclick ";
        }

        tmp = tmp + "<td class=\"blokk "+class_name+"\" data-nap=\""+nap.nap_id+"\" data-periodus=\""+periodus.periodus+"\">"+text+"</td>";
      })}
    })}
    tmp += "</tr>";

    var teremLista = [{
        value: "0",
        label: '- válassz -'
    }];
    _this.props.termek.map(function(terem, i) {
      teremLista.push({
        value: terem.terem_id,
        label: terem.kod
      })
    });

    return (
      <div ref="lampazas_modal" id="lampazas_modal" className="modal-content no-overlay">
        <div className="modal-header">
        {/*<button onClick={this.toSmallSize} type="button" className="close"><span>-</span></button>*/}
          <button onClick={this.props.hide} type="button" className="close"><span aria-hidden="true">×</span></button>
          <h4 className="modal-title">Terem foglaltság megtekintése</h4>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-xs-2">
              <div className="row">
                <div className="col-xs-12">
                  <div className="form-group">
                    <label className="form-label">Kód</label>
                    <div className="form-control-text">{this.state.terem != '' && this.state.terem.terem_alapadatok.kod}</div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <div className="form-group">
                    <label className="form-label">Telephely</label>
                    <div className="form-control-text">{this.state.terem != '' && this.state.terem.terem_alapadatok.telephely}</div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <div className="form-group">
                    <label className="form-label">Elhelyezkedés</label>
                    <div className="form-control-text">{this.state.terem != '' && this.state.terem.terem_alapadatok.elhelyezkedes}</div>
                  </div>
                </div>
                <div className="col-xs-6">
                  <div className="form-group">
                    <label className="form-label">Kapacitás</label>
                    <div className="form-control-text">{this.state.terem != '' && this.state.terem.terem_alapadatok.kapacitas} fő</div>
                  </div>
                </div>
              </div>
              <Form ref="form">
                <div className="row">
                  <div className="col-xs-12">
                    <Select onChange={(value)=>{this.setState({akt_terem_id:value}, _this.onChangeTerem);}} name="terem_id" title="Terem" options={teremLista} value={this.state.akt_terem_id} />
                  </div>
                </div>
              </Form>
            </div>
            <div className="col-xs-10">
              <table className="orarend-szerk-lampazas-tabla">
                <tbody dangerouslySetInnerHTML={{__html: tmp}}>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
     )
  }
});

TeremLampazasModal.contextTypes = {
  store: React.PropTypes.object
};

export default TeremLampazasModal;