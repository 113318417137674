import React from 'react';
import Ajax from './../Ajax.js';

var timeout;
var maxfitness = 15000;

var OrarendGeneralasStatusz = React.createClass({
  getInitialState() {
    return {
      latszik: false,
      szazalek: Math.round((maxfitness - 10) / maxfitness) * 100,
      maxfitness_beallitva: false,
      fitness: 15000,
      orak: 0,
      joOrak: 0,
      futott: false
    }
  },

  componentDidMount: function() {
    this.statuszLekerdezes();
  },

  leallit: function() {
    var _this = this;
    var {store} = this.context;

    swal({
      title: "Leállítja az órarend generálást?",
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
    },
    function() {
      setTimeout(function() {
        Ajax.call('orarend/generalas_leallitas/' + _this.props.loggedUser.aktiv_orarend.id, store, {
          method: 'GET',
          success: function(data) {
            if (data.success === false) {
              swal('Hiba!', data.error, 'error');

            } else {
              _this.setState({
                latszik: false,
                szazalek: '0%',
                fitness: 15000,
                orak: 0,
                joOrak: 0
              });

              if (timeout != undefined)
                clearTimeout(timeout);

              swal('Siker!', 'Az órarend generálás adatbázisban rögzült. ', 'success');
            }
          }
        });
      }, 500);
    });
  },

  serviceStart: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/orkd_start', store, {
      method: 'GET',
      async: false,
      success: function(data) {
        if (data) {
          swal('Siker!', 'Szolgáltatás elindult.', 'success');
        } else {
          swal('Hiba!', 'Nem sikerült elindítani a szolgáltatást.', 'error');
        }
      }
    });
  },

  serviceStop: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/orkd_stop', store, {
      method: 'GET',
      async: false,
      success: function(data) {
        if (data) {
          swal('Siker!', 'Szolgáltatás leállt.', 'success');
        } else {
          swal('Hiba!', 'Nem sikerült leállítani a szolgáltatást.', 'error');
        }
      }
    });
  },

  serviceRestart: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/orkd_restart', store, {
      method: 'GET',
      async: false,
      success: function(data) {
        if (data) {
          swal('Siker!', 'Szolgáltatás újraindult.', 'info');
        } else {
          swal('Hiba!', 'Nem sikerült újraindítani a szolgáltatást.', 'error');
        }
      }
    });
  },

  statuszLekerdezes: function () {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/generalas_lekerdezese', store, {
      method: 'GET',
      async: false,
      success: function(data) {
        if (data !== false) {
          if (data.state == "started" || data.state == "running") {
            _this.setState({
              futott: true
            });
            if (!_this.state.maxfitness_beallitva && data.data.maxFitness != undefined && data.data.maxFitness != 0) {
              maxfitness = data.data.maxFitness;

              _this.setState({
                maxfitness_beallitva: true
              });
            }

            if(_this.state.maxfitness_beallitva) {
              _this.setState({
                latszik: true,
                fitness: data.data.fitness,
                // szazalek: (((maxfitness - data.data.fitness) / maxfitness) * 100) + '%',
                szazalek: (data.data.classesGood / data.data.classesTotal * 100) + '%',
                orak: data.data.classesTotal,
                joOrak: data.data.classesGood
              });
            } else {
              _this.setState({
                latszik: true,
                fitness: 'Adatok importálása folyamatban',
                szazalek: '0%',
                orak: 0,
                joOrak: 0
              });
            }

            timeout = setTimeout(_this.statuszLekerdezes, 5000);

          } else if (data.state == "finished" || data.state == "idle") {
            if (timeout != undefined)
              clearTimeout(timeout);

            if(_this.state.futott) {
              _this.setState({
                futott: false
              });
              Ajax.call('orarend/orarend_mentese', store, {
                method: 'GET',
                async: false,
                success: function(data) {}
              });
            }

            _this.setState({
              latszik: false,
              fitness: 15000,
              szazalek: '0%',
              orak: 0,
              joOrak: 0
            });
          }

        } else if (data == false) {
          if (timeout != undefined)
            clearTimeout(timeout);

          _this.setState({
            latszik: false,
            fitness: 15000,
            szazalek: '0%',
            orak: 0,
            joOrak: 0
          });
        }
      }
    });
  },

  render() {
    return (
      <div>
        {this.state.latszik ?
        <div className="overlayer">
          <div className="overlayer-progress-outer">
            <h1>Órarend generálás folyamatban...<span className="fitness">(Hibamutató: {this.state.fitness})</span></h1>
            <div className="progress">
              <div className="progress-bar progress-bar-success progress-bar-striped" role="progressbar" style={{"width": this.state.szazalek}}>{this.state.joOrak}/{this.state.orak} elrakott óra</div>
            </div>
            <div style={{'textAlign':'center'}}>
              <button onClick={this.leallit} className="btn btn-primary" type="button"><i className="fa fa-hand-paper-o"></i>Generálás leállítása</button>
              {/*<button style={{'marginLeft':'20px'}} onClick={this.serviceStart} className="btn btn-white" type="button"><i className="fa fa-play-circle"></i>START</button>
              <button style={{'marginLeft':'5px'}} onClick={this.serviceStop} className="btn btn-white" type="button"><i className="fa fa-stop-circle"></i>STOP</button>
              <button style={{'marginLeft':'5px'}} onClick={this.serviceRestart} className="btn btn-white" type="button"><i className="fa fa-refresh"></i>RESTART</button>*/}
            </div>
          </div>
        </div> : null
        }
      </div>
    )
  }
});

OrarendGeneralasStatusz.contextTypes = {
  store: React.PropTypes.object
}

export default OrarendGeneralasStatusz;