import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button, Tabs, Tab} from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import ArrayHelper from './ArrayHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import InputHidden from './form/InputHidden';
import Select from './form/Select';
import Textarea from './form/Textarea';

var KerelmekKimeno = React.createClass({
  getInitialState() {
    return {
      kerelmek: [],
      ujKerelemModal: false,
      ujKerelemFormValid: false
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillReceiveProps: function() {

  },

  componentWillMount: function() {
    this.getKerelmek();
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
    jQueryHelper.setDropDownToggle(this);
  },

  getKerelmek: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('kerelmek/kimeno/' + localStorage.token + '/' + this.props.prop_loggedUser.id, store, {
      success: function(data) {
        obj.setState({kerelmek: data.kerelmek});
        jQueryHelper.setDropDownToggle(obj);
      }
    });
  },

  kerelemTorol: function(id) {
    var obj = this;
    var {store} = this.context;

    swal({
      title: "Biztos hogy törli a kérelmet?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true,
      animation: false
    },
    function() {
      Ajax.call('kerelmek/torol_kuldo/' + localStorage.token + '/' + id, store, {
        success: function(data) {
          var tmp = ArrayHelper.removeItemByFieldAndValue(obj.state.kerelmek, 'id', id);

          obj.setState({
            kerelmek: tmp
          });
        }
      });
    });
  },

  ujKerelemModalHide: function() {
    this.setState({
      ujKerelemModal: false
    });
  },

  ujKerelemModalShow: function() {
    this.setState({
      ujKerelemModal: true
    });
  },

  ujKerelemFormValid: function() {
    this.setState({
      ujKerelemFormValid: true
    });
  },

  ujKerelemFormInValid: function() {
    this.setState({
      ujKerelemFormValid: false
    });
  },

  ujKerelem: function() {
    var obj = this;
    var {store} = this.context;
    var self = this;

    this.refs.uj_kerelem_form.submit();

    if (this.state.ujKerelemFormValid) {
      var formData = this.refs.uj_kerelem_form.getModel();
      formData.kerelem.kuldo_userid = this.props.prop_loggedUser.id;

      Ajax.call('kerelem/ment/' + localStorage.token, store, {
        data: formData,
        method: "POST",
        success: function(data) {
          self.ujKerelemModalHide();
          self.getKerelmek();
        }
      });
    }
  },

  render: function() {
      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <Link className="btn btn-primary" to="/eroforrasok_uj_epulet"><i className="fa fa-plus"></i>Új kérelem</Link>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Kérelmek
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Kérelmek</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <button onClick={this.ujKerelemModalShow} className="btn btn-primary"><i className="fa fa-plus"></i>Új kérelem</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Kérelmek</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                  {this.state.kerelmek.length > 0 ? (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Tárgy</th>
                          <th>Üzenet</th>
                          <th style={{"textAlign": "center"}}>Státusz</th>
                          <th>Megjegyzés</th>
                          <th>Létrehozva</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.kerelmek.map(function(kerelem, i) {
                          var statusz = '';

                          switch(parseInt(kerelem.statusz_id)) {
                            case 1:
                              statusz = <div style={{"color":"red"}}><i style={{"marginRight":"5px", "fontSize":"14px"}} className="fa fa-close"></i>{kerelem.statusz}</div>;
                              break;

                            case 2:
                              statusz = <div style={{"color":"orange"}}><i style={{"marginRight":"5px", "fontSize":"14px"}} className="fa fa-question"></i>{kerelem.statusz}</div>;
                              break;

                            case 3:
                              statusz = <div style={{"color":"green"}}><i style={{"marginRight":"5px", "fontSize":"14px"}} className="fa fa-check"></i>{kerelem.statusz}</div>;
                              break;
                          }

                         return (
                         <tr key={kerelem.id}>
                            <td>{kerelem.targy}</td>
                            <td>{kerelem.uzenet}</td>
                            <td style={{"textAlign": "center"}}>{statusz}</td>
                            <td>{kerelem.megjegyzes}</td>
                            <td>{kerelem.letrehozva}</td>
                          </tr>
                                 );
                      })}
                      </tbody>
                    </table>
                    ) : (<div>Nem található kérelem.</div>)}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        <Modal show={this.state.ujKerelemModal} onHide={this.ujKerelemModalHide}>
          <Modal.Header closeButton>
            <Modal.Title>Új kérelem létrehozása</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form ref="uj_kerelem_form" onValid={this.ujKerelemFormValid} onInvalid={this.ujKerelemFormInValid}>
              <Input name="kerelem.targy" title="Tárgy" validationError="Tárgy kötelező" required />
              <Textarea name="kerelem.uzenet" title="Üzenet" validationError="Üzenet kötelező" required />
            </Form>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.ujKerelemModalHide} type="button">Mégsem</Button>
            <Button onClick={this.ujKerelem} bsStyle="primary">Létrehoz</Button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
});

KerelmekKimeno.contextTypes = {
  store: React.PropTypes.object
}

export default KerelmekKimeno;