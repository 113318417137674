import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import ArrayHelper from './ArrayHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import Pagination from './Pagination';

var EroforrasokEpuletek = React.createClass({
  getInitialState() {
    return {
      epuletek: [],
      kereso_megnevezes: "",
      lap: 1,
      db: 0,
      limit: 100
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {

  },

  getEpuletek: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('eroforrasok/epuletek/' + localStorage.token, store, {
      method: "POST",
      data: {
        nev: this.refs.form.getModel().kereso_megnevezes,
        limit: this.state.limit,
        lap: this.state.lap
      },
      success: function(data) {
        obj.setState({epuletek: data.epuletek, db: data.db});
        jQueryHelper.setDropDownToggle(obj);
      }
    });
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
    jQueryHelper.setDropDownToggle(this);
    this.getEpuletek();
  },

  epuletTorol: function(id) {
    var obj = this;
    var {store} = this.context;

    swal({
      title: "Biztos hogy törli az épületet?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true
    },
    function() {
      Ajax.call('eroforrasok/epulet/torles/' + localStorage.token + '/' + id, store, {
        success: function(data) {
          var tmp = ArrayHelper.removeItemByFieldAndValue(obj.state.epuletek, 'id', id);

          obj.setState({
            epuletek: tmp
          });
        }
      });
    });
  },

  render: function() {
    var _this = this;

      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        {/*<div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <Link className="btn btn-primary" to="/eroforrasok_uj_epulet"><i className="fa fa-plus"></i>Új épület</Link>
            </div>
          </div>
        </div>*/}

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Erőforrások
              </li>
              <li>
                Épület
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Erőforrások, épület lista</h1>
            </div>
            <div className="content-panel-top-btn-group">
              {/*<Link className="btn btn-primary" to="/eroforrasok_uj_epulet"><i className="fa fa-plus"></i>Új épület</Link>*/}
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Épület lista</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                  <Form ref="form">
                    <Input intervalTypingCallback={() => { _this.setState({lap:1}, _this.getEpuletek); }} ref="kereso_megnevezes" name="kereso_megnevezes" title="Megnevezés" value={this.state.kereso_megnevezes}/>
                  </Form>
                  <Pagination cb={(lap) => { _this.setState({lap:lap}, _this.getEpuletek); }} db={this.state.db} page={this.state.lap} limit={this.state.limit} />

                  {this.state.epuletek.length > 0 ? (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Név</th>
                          <th>Kód</th>
                          <th>Telephely</th>
                          <th>Szélesség</th>
                          <th>Hosszúság</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.epuletek.map(epulet => (
                         <tr key={epulet.id}>
                            <td>{epulet.nev}</td>
                            <td>{epulet.kod}</td>
                            <td>{epulet.telephely}</td>
                            <td>{epulet.lat}</td>
                            <td>{epulet.lon}</td>
                            <td style={{"textAlign": "center"}}>
                              <ul className="icon-list">
                                <li className="dropdown"><a className="dropdown-toggle" href="javascript:void(0)"><i className="fa fa-gear"></i></a>
                                  <ul className="dropdown-menu">
                                    <li className="dropdown-header">Opciók</li>
                                    <li><Link to={`/eroforrasok_epulet/${epulet.id}`}><i className="fa fa-pencil-square-o"></i>Szerkeszt</Link></li>
                                    <li><a onClick={() => this.epuletTorol(epulet.id)} href="javascript:void(0)"><i className="fa fa-trash"></i>Töröl</a></li>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))
                      }
                      </tbody>
                    </table>
                    ) : (<div>Nem található épület.</div>)}
                  <Pagination cb={(lap) => { _this.setState({lap:lap}, _this.getEpuletek); }} db={this.state.db} page={this.state.lap} limit={this.state.limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

EroforrasokEpuletek.contextTypes = {
  store: React.PropTypes.object
}

export default EroforrasokEpuletek;