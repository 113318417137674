import React from 'react';
import Ajax from './../Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import { Form } from 'formsy-react';
import Input from './../form/Input';
import Select2 from './../form/Select2';
import utils from 'formsy-react/lib/utils';
import Toggle from './../form/Toggle';

var SpecialisBeallitasokModal = React.createClass({
  getInitialState() {
    return {
      show: false,
      valid_form: false,
      szakok: [],
      szak_id: [],
      kivalasztott_idoszak_id: 0,
      orarend_id: 0,
      nappali_orarend_publikus: false,
      levelezos_orarend_publikus: false
    }
  },

  componentDidUpdate: function () {

  },

  ment: function () {
    var { store } = this.context;
    var _this = this;

    var formData = this.refs.form.getModel();

    Ajax.call('specialis_beallitasok_mentes/' + localStorage.token, store, {
      method: "POST",
      data: {
        szak_id: formData.szak_id,
        orarend_id: _this.state.orarend_id,
        nappali_orarend_publikus: formData.nappali_orarend_publikus,
        levelezos_orarend_publikus: formData.levelezos_orarend_publikus
      },
      success: function (data) {
        if (data.result == true) {
          _this.hide();
          swal('Siker!', 'Speciális beállítások mentve.', 'success');
        } else {
          swal('Hiba!', data.hiba, 'error');
        }
      }
    });
  },

  show: function (orarend_id, kivalasztott_idoszak_id) {
    var { store } = this.context;
    var _this = this;

    this.setState({
      show: true,
      kivalasztott_idoszak_id: kivalasztott_idoszak_id,
      orarend_id: orarend_id
    }, function () {
      Ajax.call('specialis_beallitasok/' + localStorage.token + '/' + _this.state.orarend_id, store, {
        success: function (data) {
          if (data.result) {
            _this.setState({
              szakok: data.szakok,
              szak_id: data.szak_id,
              nappali_orarend_publikus: data.nappali_orarend_publikus,
              levelezos_orarend_publikus: data.levelezos_orarend_publikus
            });
          } else {
            swal('Hiba!', data.hiba, 'error');
          }
        }
      });
    });
  },

  hide: function () {
    var _this = this;

    var id = this.state.kivalasztott_idoszak_id;

    this.setState({
      show: false,
      szakok: [],
      szak_id: [],
      orarend_id: 0,
      kivalasztott_idoszak_id: 0,
      nappali_orarend_publikus: 0,
      levelezos_orarend_publikus: 0
    }, function () {
      _this.props.idoszakValasztasClick(id);
    });
  },

  render: function () {
    var _this = this;

    return (
      <div>
        {this.state.show &&
          <Modal show={this.state.show} onHide={this.hide}>
            <Modal.Header closeButton>
              <Modal.Title>Speciális beállítások</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form ref="form" onValid={() => { this.setState({ valid_form: true }) }} onInvalid={() => { this.setState({ valid_form: false }) }}>
                <Select2 multi={true} onChange={(value) => { _this.setState({ szak_id: value }) }} placeholder="Keresés" name="szak_id" title="Péntek reggeltől beosztható szakok" value={this.state.szak_id} options={this.state.szakok} />
                <Toggle name="nappali_orarend_publikus" title="Nappali órarend publikus" value={this.state.nappali_orarend_publikus} />
                <Toggle name="levelezos_orarend_publikus" title="Levelezős órarend publikus" value={this.state.levelezos_orarend_publikus} />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.hide} type="button">Mégsem</Button>
              <button ref="preloader" onClick={this.ment} className="btn btn-primary">Módosít</button>
            </Modal.Footer>
          </Modal>
        }
      </div>)
  }
});

SpecialisBeallitasokModal.contextTypes = {
  store: React.PropTypes.object
}

export default SpecialisBeallitasokModal;