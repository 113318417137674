//var React = require('react');
//var Header = require('./Header.js');
//var LeftPanel = require('./LeftPanel.js');

import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';

var About = React.createClass({
  componentDidMount: function() {
  },
  componentWillMount: function() {
    //this.props.route.pageTitle = "Rólunk";

    //this.props.setPageTitle("Rólunk");

    /*
    this.props.setState({
      pageTitle: "Rólunk"
    });*/

  },
  componentWillUpdate: function() {
    //this.props.setPageTitle("Rólunk");

    /*
    alert("WillUpdate");
    if (this.onRouteChanged) {
      //alert("OK");
    }
    */
    /*
    this.props.route.pageTitle = "Rólunk";
    this.props.route.setUserState({
      pageTitle: "Rólunk"
    });
    */
    /*
    this.setState({
      pageTitle: "Rólunk"
    })
    */

   //
  },
	render: function() {
		return (
      <div>
        <Header/>
        <LeftPanel {...this.props}/>
        <div id="main-panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Rólunk
              </li>
              <li><a href="#" className="active">Form Elements</a></li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Rólunk</h1>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Rólunk</h4>
              </div>
              <div className="panel-body">
                Rólunk
              </div>
            </div>
          </div>
        </div>
      </div>

    );
	}
});

export default About;
//module.exports = About;