import React from 'react';
import Ajax from './../Ajax.js';
import Select from './../form/Select';

var Felev = React.createClass({
  getInitialState() {
    return {
      opciok: []
    }
  },

  componentDidMount: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('orarend/felevek/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        var tmp = [];
        data.felevek.map(function(felev, i) {
          tmp.push({
            value: parseInt(felev.value),
            label: felev.label
          })
        });
        obj.setState({opciok: tmp});
      }
    });
  },

  

  render: function() {
    return (
      <Select name={`feltetel[ertek][p_${this.props.id}]`} value={this.props.ertek?this.props.ertek:1} title={this.props.label} options={this.state.opciok}/>
    );
  }
});

Felev.contextTypes = {
  store: React.PropTypes.object
}

export default Felev;