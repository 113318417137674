import React from 'react';
import Ajax from './../Ajax.js';
import Toggle from './../form/Toggle';

var Kethetente = React.createClass({
  getInitialState() {
    return {
    }
  },

  render: function() {
    return (
      <Toggle name={`feltetel[ertek][p_${this.props.id}]`} value={this.props.ertek} title={this.props.label}/>
    );
  }
});

Kethetente.contextTypes = {
  store: React.PropTypes.object
}

export default Kethetente;