import React from 'react';
import Ajax from './../../Ajax.js';
import {Form} from 'formsy-react';
import Kurzuslista from './Kurzuslista.js';
import Teremlista from './Teremlista.js';
import Elrakottorak from './Elrakottorak.js';
import Naptar from './Naptar.js';
import KurzusAdatokModal from './KurzusAdatokModal.js';
import LampazasModal from './LampazasModal.js';
import TeremLampazasModal from './TeremLampazasModal.js';
import Loggolas from './Loggolas.js';
import LoggolasModal from './LoggolasModal.js';
import SzakszuroModal from './SzakszuroModal.js';
import KapcsolodoKurzusok from './KapcsolodoKurzusok.js';
import SzakszuroCsoportModal from './SzakszuroCsoportModal.js';

var Szerkeszto = React.createClass({
  getInitialState() {
    return {
      kurzus_id: 0,
      ora_id: 0,
      terem_id: 0,
      elrakott_terem_id: 0,
      nap: 0,
      kezdete: 0,
      hossz: 0,
      terem_megn: '',
      elrakott_terem_megn: '',
      kurzus_kod: '',
      tantargy_megn: '',
      kurzus_adatok_modal: false,
      kurzus_adatok_modal_kurzus_id: 0,
      naptar_modal: false,
      lampazas_modal: false,
      lampazas_modal_kurzus_id: 0,
      szakszuro_modal: false,
      szakszuro_csoport_modal: false,
      szakszuro_csoport_id: 0,
      teremLampazas_modal: false,
      teremLampazas_modal_terem_id: 0,
      termek: [],
      loggolas_modal: false
    }
  },

  kurzusSorClick: function(id) {
    var _this = this;

    this.setState({
      kurzus_id: id,
      terem_id: 0,
      ora_id: 0,
    }, function() {
      this.oraAdatokStateTorles();

      this.refs.elrakottorak.beallitasAlaphelyzetre();
      this.refs.elrakottorak.orakLekerese();

      this.refs.teremlista.termekLekerese(id);

      // Lekéri egy elrakatlan órát és beállítja az adatait a state-be
      this.elrakatlanOra(id, function(ora) {
        _this.setState({
          ora_id: ora.ora_id,
          tantargy_megn: ora.tantargy,
          kurzus_kod: ora.kurzus_kod,
          elrakott_terem_id: 0,
          elrakott_terem_megn: '',
          hossz: parseInt(ora.hossz)
        });
      });
    });
  },

  elrakottOraSorClick: function(ora_id, terem_id, nap, kezdete, vege, hossz, terem_megn, kurzus_kod, tantargy_megn) {
    var _this = this;

    var elrakott_ora_het = moment(nap).diff(moment(this.props.loggedUser.aktiv_idoszak.felev_kezdete), 'weeks') + 1;

    this.setState({
      ora_id: ora_id,
      terem_id: terem_id,
      elrakott_terem_id: terem_id,
      nap: nap,
      kezdete: kezdete,
      vege: vege,
      hossz: hossz,
      terem_megn: terem_megn,
      elrakott_terem_megn: terem_megn,
      kurzus_kod: kurzus_kod,
      tantargy_megn: tantargy_megn,
      naptar_modal: true
    }, function() {
      _this.refs.teremlista.termekLekerese(_this.state.kurzus_id);
      _this.refs.naptar.hetBeallitas(elrakott_ora_het);
    });
  },

  elrakatlanOraBeallitas: function(ora_id, terem_id, hossz, terem_megn, kurzus_kod, tantargy_megn) {
    var _this = this;

    this.setState({
      ora_id: ora_id,
      terem_id: terem_id,
      elrakott_terem_id: terem_id,
      hossz: hossz,
      terem_megn: terem_megn,
      kurzus_kod: kurzus_kod,
      tantargy_megn: tantargy_megn,
      elrakott_terem_id: "",
      elrakott_terem_megn: "",
      naptar_modal: true
    }, function() {
      _this.refs.teremlista.termekLekerese(_this.state.kurzus_id);
      _this.refs.naptar.frissites();
    });
  },

  teremSorClick: function(id, terem_megn) {
    var _this = this;

    this.setState({
      terem_id: id,
      terem_megn: terem_megn,
      naptar_modal: true
    }, function() {
      if (typeof _this.refs.naptar != "undefined")
        _this.refs.naptar.frissites();
    });
  },

  oraAdatokStateTorles: function() {
    this.setState({
      ora_id: 0,
      nap: 0,
      kezdete: '',
      vege: '',
      hossz: 0,
      terem_megn: '',
      elrakott_terem_megn: '',
      tantargy_megn: ''
    });
  },

  elrakatlanOra: function(kurzus_id, cb) {
    var {store} = this.context;
    var _this = this;

    Ajax.call('orarend_szerk/elrakatlan_ora/' + localStorage.token + '/' + kurzus_id + '/1', store, {
      async: false,
      success: function(data) {
        if ($.isFunction(cb)) {
          cb(data.ora);
        }
      }
    });
  },

  idopontClick: function(hossz, ora_id, terem_id, nap, periodus, kezdete, datum) {
    var {store} = this.context;
    var _this = this;

    Ajax.call('orarend_szerk/ora_idopont_mentes/' + localStorage.token + '/1/' + hossz + '/' + ora_id + '/' + terem_id + '/' + nap + '/' + periodus + '/' + 'null', store, {
      async: false,
      success: function(data) {
        if (data === true) {
          $('.blokk').unbind("click");

          swal('Siker', 'Óra elrakása sikeres.', 'success');

          _this.setState({
            nap: datum,
            kezdete: kezdete
          });

          if ($('.calendar-tooltip').length > 0)
            $('.calendar-tooltip').remove();

          _this.refs.kurzuslista.kurzusLista(true);
          _this.refs.elrakottorak.orakLekerese();
          _this.refs.loggolas.frissit();

          if (typeof _this.refs.naptar != "undefined") {
            _this.refs.naptar.frissites();
            _this.refs.naptar.getOrakComboba();
          }

        } else {
          swal('Hiba', data, 'error');
        }
      }
    });
  },

  elrakottOraTorlese: function(ora_id, kurzus_id) {
    var {store} = this.context;
    var _this = this;

    swal({
      title: "Biztos hogy törli az elrakott órát?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true,
      animation: false
    },
    function() {
      Ajax.call('orarend_szerk/elrakott_ora_torles/' + localStorage.token + '/' + ora_id, store, {
        success: function(data) {
          _this.refs.kurzuslista.elrakottOraSzamCsokkentese(kurzus_id, data.ora_hossz);
          _this.refs.loggolas.frissit();
          _this.hideNaptarModal();
          _this.kurzusSorClick(kurzus_id);
          setTimeout(function() { swal('Siker!', 'Az óra törölve.', 'success'); }, 100);
        }
      });
    });
  },

  componentDidMount: function() {

  },

  componentDidUpdate: function() {

  },

  showKurzusAdatokModal: function(kurzus_id) {
    this.setState({
      kurzus_adatok_modal: true,
      kurzus_adatok_modal_kurzus_id: kurzus_id
    });
  },

  hideKurzusAdatokModal: function() {
    this.setState({
      kurzus_adatok_modal: false,
      kurzus_adatok_modal_kurzus_id: 0
    });
  },

  swapKurzusAdatokModal: function(kurzus_id) {
    this.setState({
      kurzus_adatok_modal: false,
      kurzus_adatok_modal_kurzus_id: 0
    }, function() {
      this.showKurzusAdatokModal(kurzus_id)
    });
  },

  hideNaptarModal: function() {
    this.setState({
      naptar_modal: false
    });
  },

  showLampazasModal: function(kurzus_id) {
    this.setState({
      lampazas_modal: true,
      lampazas_modal_kurzus_id: kurzus_id
    });
  },

  hideLampazasModal: function() {
    this.setState({
      lampazas_modal: false,
      lampazas_modal_kurzus_id: 0
    });
  },

  swapLampazasModal: function(kurzus_id) {
    this.setState({
      lampazas_modal: false,
      lampazas_modal_kurzus_id: 0
    }, function() {
      this.showLampazasModal(kurzus_id)
    });
  },

  showTeremLampazasModal: function(terem_id,termek) {
    this.setState({
      teremLampazas_modal: true,
      teremLampazas_modal_terem_id: terem_id,
      termek: termek
    });
  },

  hideTeremLampazasModal: function() {
    this.setState({
      teremLampazas_modal: false,
      teremLampazas_modal_terem_id: 0,
      termek: []
    });
  },

  swapTeremLampazasModal: function(terem_id,termek) {
    this.setState({
      teremLampazas_modal: false,
      teremLampazas_modal_terem_id: 0
    }, function() {
      this.showTeremLampazasModal(terem_id,termek)
    });
  },

  showSzakszuroModal: function() {
    var _this = this;

    this.setState({
      szakszuro_modal: true
    }, function() {
      _this.refs.szakszuro_modal.getSzakok(2);
      _this.refs.szakszuro_modal.getFelhasznaloSzakok();
    });
  },

  hideSzakszuroModal: function() {
    var _this = this;

    this.setState({
      szakszuro_modal: false
    }, function() {
      _this.refs.kurzuslista.getSzakok(2);
      _this.refs.kurzuslista.getSzakiranyok(0);
      _this.refs.kurzuslista.szakIDTorles();
      _this.refs.kurzuslista.szakiranyIDTorles();
    });
  },

  showSzakszuroCsoportModal: function() {
    var _this = this;

    this.setState({
      szakszuro_csoport_modal: true
    }, function() {
      _this.refs.szakszuro_csoport_modal.getCsoportokEsSzakok();
    });
  },

  hideSzakszuroCsoportModal: function() {
    var _this = this;

    this.setState({
      szakszuro_csoport_modal: false
    }, function() {
    });
  },

  showLoggolasModal: function() {
    var _this = this;

    this.setState({
      loggolas_modal: true
    }, function() {
      _this.refs.loggolas_modal.frissit();
    });
  },

  hideLoggolasModal: function() {
    this.setState({
      loggolas_modal: false
    }, function() {

    });
  },

  lastUndo: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('get_last_undo/' + localStorage.token + '/' + _this.props.loggedUser.id, store, {
      success: function(data) {
        if (data.muveletek.length == 0)
          return false;

        swal({
          title: "Végrehajtja a műveletet?",
          type: "warning",
          html: true,
          text: data.text,
          customClass: 'undo-info',
          showCancelButton: true,
          confirmButtonText: "Igen",
          cancelButtonText: "Mégsem",
          closeOnConfirm: true,
          animation: false
        },
        function() {
          Ajax.call('undo/' + localStorage.token + '/' + _this.props.loggedUser.id + '/0', store, {
            success: function(data) {
              _this.refs.kurzuslista.kurzusLista();
              _this.refs.elrakottorak.orakLekerese();
              _this.refs.loggolas.frissit();
              _this.hideLoggolasModal();

              if (typeof _this.refs.naptar != "undefined")
                _this.refs.naptar.frissites();
            }
          });
        });
      }
    });
  },

  undo: function(csoport_id) {
    var _this = this;
    var {store} = this.context;

    swal({
      title: "Végrehajtja a műveletet?",
      type: "warning",
      html: true,
      //text: data.text,
      customClass: 'undo-info',
      showCancelButton: true,
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true,
      animation: false
    },
    function() {
      Ajax.call('undo/' + localStorage.token + '/' + _this.props.loggedUser.id + '/' + csoport_id, store, {
        success: function(data) {
          _this.refs.kurzuslista.kurzusLista();
          _this.refs.elrakottorak.orakLekerese();
          _this.refs.loggolas.frissit();
          _this.hideLoggolasModal();

          if (typeof _this.refs.naptar != "undefined")
            _this.refs.naptar.frissites();
        }
      });
    });
  },

  getKurzuslista: function() {
    this.refs.kurzuslista.kurzusLista();
  },

  getFelhasznaloSzakok: function() {
    return this.refs.szakszuro_csoport_modal.getFelhasznaloSzakokFromState();
  },

  setFelhasznaloSzakok: function(arr, csoport_id, szures, cb) {
    this.setState({
      szakszuro_csoport_id: csoport_id
    });
    this.refs.kurzuslista.setSzakok(arr, szures, cb);
  },

  lastRedo: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('get_last_redo/' + localStorage.token + '/' + _this.props.loggedUser.id, store, {
      success: function(data) {
        if (data.muveletek.length == 0)
          return;

        swal({
          title: "Végrehajtja a műveletet?",
          type: "warning",
          text: data.text,
          html: true,
          showCancelButton: true,
          customClass: 'undo-info',
          confirmButtonText: "Igen",
          cancelButtonText: "Mégsem",
          closeOnConfirm: true,
          animation: false
        },
        function() {
          Ajax.call('redo/' + localStorage.token + '/' + _this.props.loggedUser.id + '/0', store, {
            success: function(data) {
              _this.refs.kurzuslista.kurzusLista();
              _this.refs.elrakottorak.orakLekerese();
              _this.refs.loggolas.frissit();
              _this.hideLoggolasModal();

              if (typeof _this.refs.naptar != "undefined")
                _this.refs.naptar.frissites();
            }
          });
        });
      }
    });
  },

  redo: function(csoport_id) {
    var _this = this;
    var {store} = this.context;

    swal({
      title: "Végrehajtja a műveletet?",
      type: "warning",
      //text: data.text,
      html: true,
      showCancelButton: true,
      customClass: 'undo-info',
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true,
      animation: false
    },
    function() {
      Ajax.call('redo/' + localStorage.token + '/' + _this.props.loggedUser.id + '/' + csoport_id, store, {
        success: function(data) {
          _this.refs.kurzuslista.kurzusLista();
          _this.refs.elrakottorak.orakLekerese();
          _this.refs.loggolas.frissit();
          _this.hideLoggolasModal();

          if (typeof _this.refs.naptar != "undefined")
            _this.refs.naptar.frissites();
        }
      });
    });
  },

  render() {
    var _this = this;

    return (
      <div className="orarend-szerk">
      <div className="row orarend-szerk-row">
        <div className="col-xs-4 col">
          <Teremlista loggedUser={this.props.loggedUser} ora_id={this.state.ora_id} terem_id={this.state.terem_id} teremSorClick={this.teremSorClick} swapTeremLampazasModal={this.swapTeremLampazasModal} ref="teremlista"/>
          <Loggolas showLoggolasModal={this.showLoggolasModal} undo={this.undo} redo={this.redo} lastUndo={this.lastUndo} lastRedo={this.lastRedo} loggedUser={this.props.loggedUser} ref="loggolas"/>
        </div>
        <div className="col-xs-8 col">
          <Kurzuslista getFelhasznaloSzakok={this.getFelhasznaloSzakok} showSzakszuroModal={this.showSzakszuroModal} showSzakszuroCsoportModal={this.showSzakszuroCsoportModal} loggedUser={this.props.loggedUser} swapLampazasModal={this.swapLampazasModal} swapKurzusAdatokModal={this.swapKurzusAdatokModal} kurzus_id={this.state.kurzus_id} kurzusSorClick={this.kurzusSorClick} elrakottOraSorClick={this.elrakottOraSorClick} ref="kurzuslista"/>
          <Elrakottorak kurzus_id={this.state.kurzus_id} ora_id={this.state.ora_id} elrakottOraSorClick={this.elrakottOraSorClick} elrakottOraTorlese={this.elrakottOraTorlese} ref="elrakottorak"/>
          <KapcsolodoKurzusok kurzusSorClick={this.kurzusSorClick} kurzus_id={this.state.kurzus_id} ref="kapcsolodo_kurzusok"/>
        </div>
      </div>
      {this.state.ora_id != undefined && this.state.ora_id != 0 && this.state.naptar_modal ?
      <Naptar elrakatlanOraBeallitas={this.elrakatlanOraBeallitas} elrakottOraSorClick={this.elrakottOraSorClick} hide={this.hideNaptarModal} idopontClick={this.idopontClick} kurzus_id={this.state.kurzus_id} ora_id={this.state.ora_id} elrakott_terem_id={this.state.elrakott_terem_id} terem_id={this.state.terem_id} nap={this.state.nap} kezdete={this.state.kezdete} hossz={this.state.hossz} elrakott_terem_megn={this.state.elrakott_terem_megn} terem_megn={this.state.terem_megn} kurzus_kod={this.state.kurzus_kod} tantargy_megn={this.state.tantargy_megn} loggedUser={this.props.loggedUser} ref="naptar"/>
      : null}
      {this.state.kurzus_adatok_modal ?
      <KurzusAdatokModal ref="kurzus_adatok_modal" kurzus_id={this.state.kurzus_adatok_modal_kurzus_id} show={this.showKurzusAdatokModal} hide={this.hideKurzusAdatokModal} idoszak_kezdete={this.props.loggedUser.aktiv_idoszak.felev_kezdete} />
      : null}
      {this.state.lampazas_modal ?
      <LampazasModal ref="lampazas_modal" idoszak_kezdete={this.props.loggedUser.aktiv_idoszak.felev_kezdete} kurzus_id={this.state.lampazas_modal_kurzus_id} show={this.showLampazasModal} hide={this.hideLampazasModal}/>
      : null}
      {this.state.teremLampazas_modal ?
      <TeremLampazasModal ref="terem_lampazas_modal" idoszak_kezdete={this.props.loggedUser.aktiv_idoszak.felev_kezdete} termek={this.state.termek} terem_id={this.state.teremLampazas_modal_terem_id} show={this.showTeremLampazasModal} hide={this.hideTeremLampazasModal}/>
      : null}
      {/*<SzakszuroModal getKurzuslista={this.getKurzuslista} szakszuro_modal={this.state.szakszuro_modal} show={this.showSzakszuroModal} hide={this.hideSzakszuroModal} loggedUser={this.props.loggedUser} ref="szakszuro_modal"/>*/}

      <SzakszuroCsoportModal getKurzuslista={this.getKurzuslista} szakszuro_csoport_id={this.state.szakszuro_csoport_id} szakszuro_csoport_modal={this.state.szakszuro_csoport_modal} show={this.showSzakszuroCsoportModal} hide={this.hideSzakszuroCsoportModal} setFelhasznaloSzakok={this.setFelhasznaloSzakok} loggedUser={this.props.loggedUser} ref="szakszuro_csoport_modal"/>

      {this.state.loggolas_modal ?
      <LoggolasModal hide={this.hideLoggolasModal} undo={this.undo} redo={this.redo} lastUndo={this.lastUndo} lastRedo={this.lastRedo} loggedUser={this.props.loggedUser} ref="loggolas_modal"/>
      : null}

      </div>
    )
  }
});

Szerkeszto.contextTypes = {
  store: React.PropTypes.object
};

export default Szerkeszto;