import React from 'react';
import Ajax from './../../Ajax.js';
import {Form} from 'formsy-react';
import Pagination from './../../Pagination';
import Input from './../../form/Input';

var KapcsolodoKurzusok = React.createClass({
  getInitialState() {
    return {
      kurzusok: []
    }
  },

  componentDidMount: function() {
    this.getKurzusok(this.props.kurzus_id);
  },

  componentWillReceiveProps: function(nextProps) {
    if (this.props.kurzus_id != nextProps.kurzus_id) {
      this.getKurzusok(nextProps.kurzus_id);
    }
  },

  componentDidUpdate: function() {
  },

  getKurzusok: function(id) {
    var {store} = this.context;
    var _this = this;

    Ajax.call('orarend_szerk/kapcsolodo_kurzusok_levelezo/' + localStorage.token + '/' + id, store, {
      async: false,
      success: function(data) {
        _this.setState({
          kurzusok: data.kurzusok
        });
      }
    });
  },

  render() {
    var _this = this;

    return (
      <div>
        {this.state.kurzusok.length > 0 ? (
          <table id="teremlista-tabla" className="table table-bordered orarendszerk-kurzuslista-table">
            <thead>
              <tr>
                <th style={{'width':'200px'}}>Kurzus</th>
                <th>Időpont</th>
                <th style={{'width':'270px'}}>Tanár</th>
              </tr>
            </thead>
            <tbody>
          {this.state.kurzusok.map(function(kurzus, i) {
              var idopontok = '';

              if (kurzus.idopont != null)
                idopontok = '<div>' + kurzus.idopont.replace('#', '</div><div>') + '</div>';

              return (
               <tr key={kurzus.id}>
                  <td onClick={()=>{_this.props.kurzusSorClick(parseInt(kurzus.id))}}>{kurzus.kurzus_azonosito}</td>
                  <td onClick={()=>{_this.props.kurzusSorClick(parseInt(kurzus.id))}} dangerouslySetInnerHTML={{__html: idopontok}}></td>
                  <td onClick={()=>{_this.props.kurzusSorClick(parseInt(kurzus.id))}}>{kurzus.tanar}</td>
                </tr>
              );
          })}
            </tbody>
          </table>
          ) : null}
      </div>
    )
  }
});

KapcsolodoKurzusok.contextTypes = {
  store: React.PropTypes.object
};

export default KapcsolodoKurzusok;