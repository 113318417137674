import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory } from 'react-router';
import Ajax from './Ajax.js';
import { Form } from 'formsy-react';
import Input from './form/Input';
import Select from './form/Select';
import jQueryHelper from './jQueryHelper';

var EroforrasokOktato = React.createClass({
  getInitialState() {
    return {
      oktato: [],
      tanszekek: [],
      formIsValid: false
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('eroforrasok/tanszekek/' + localStorage.token, store, {
      success: function(data) {
        var tanszekek = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.tanszekek
        ];

        obj.setState({tanszekek: tanszekek});

        if (obj.props.params.id != undefined) {
          Ajax.call('eroforrasok/oktato/' + localStorage.token + '/' + obj.props.params.id, store, {
            success: function(data) {
              obj.setState({oktato: data.oktato});
            }
          });
        }
      }
    });
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
  },

  onMent: function() {
    var self = this;

    this.refs.form.submit();

    if (this.state.formIsValid) {
      var formData = this.refs.form.getModel();

      var obj = this;
      var {store} = this.context;

      Object.assign(formData.oktato, {
        id: this.state.oktato.id
      });

      Ajax.call('/eroforrasok/oktato/ment/' + localStorage.token, store, {
        method: "POST",
        data: {
          oktato: formData.oktato
        },
        success: function(data) {
          if (data.errors.length == 0) {
            hashHistory.push('/eroforrasok_oktatok');
          } else {
            data.errors.map(function(error, i) {
              self.refs.form.updateInputsWithError(error);
            });
          }
        }
      });
    }
  },

  onValid: function() {
    this.setState({
      formIsValid: true
    });
  },

  onInvalid: function() {
    this.setState({
      formIsValid: false
    });
  },

  render: function() {
      var neptun_disabled = this.state.oktato.id == undefined ? false : true;

      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Erőforrások
              </li>
              <li>
                Oktató
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Erőforrások, oktató adatlap</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Oktató adatlap</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                    <Form ref="form" onValid={this.onValid} onInvalid={this.onInvalid}>
                      <Input name="oktato.nev" title="Név" value={this.state.oktato.nev} validationError="Név kötelező" required />
                      {/*<Select name="oktato.tanszek_id" value={this.state.oktato.tanszek_id} title="Tanszék" validations="isNumeric" validationError="Megadás kötelező" options={this.state.tanszekek} required/>*/}
                      <Input name="oktato.neptun" disabled={neptun_disabled} value={this.state.oktato.neptun} title="Neptunkód" validationError="Neptunkód kötelező" required />
                    </Form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

EroforrasokOktato.contextTypes = {
  store: React.PropTypes.object
}

export default EroforrasokOktato;