import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';

var UzenetOldal = React.createClass({
  getInitialState() {
    return {

    }
  },
	render: function() {
		return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div id="main-panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Üzenet
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Üzenet</h1>
            </div>

            <div className="panel">
             <div className="panel-heading">
               <h4>Üzenet</h4>
             </div>
             <div className="panel-body">
               <div className={this.props.prop_oldalUzenet.tipus} role="alert"> {this.props.prop_oldalUzenet.uzenet}</div>
             </div>
            </div>
          </div>
        </div>
      </div>
    );
	}
});

export default UzenetOldal;