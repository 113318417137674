import React from 'react';
import Ajax from './../../Ajax.js';
import {Form} from 'formsy-react';
import Pagination from './../../Pagination';
import Input from './../../form/Input';
import Toggle from './../../form/Toggle';
import Select from './../../form/Select';
import Select2 from './../../form/Select2';

var Kurzuslista = React.createClass({
  getInitialState() {
    return {
      kurzusok: [],
      telephelyek: [{
        value: 'null',
        label: 'Összes'
      }],
      lap: 1,
      db: 0,
      limit: 50,
      kerkif: '',
      elrakott: 1,
      elrakatlan: 1,
      oktatok: [],
      oktato_id: 0,
      szakok: [],
      szakiranyok: [{value: 'null', label:'Nincs kiválasztva'}],
      felevek: [],
      szak_id: 'null',
      szakirany_id: 'null',
      felev_id: 'null',
      telephely_id: 'null',
      felvetelTipus_id: 'null',
      terem_eszkoz_igeny: 0,
      kethetente: 0,
      nem_kert_termet: 0,
      felhasznalo_szak_szures: false,
      felvetelTipusok: [
          { value: 'null', label: 'Összes' },
          { value: 'kotelezo', label: 'Kötelező' },
          { value: 'valaszthato', label: 'Választható' }
      ],
      rendezes_oszlopok: [
        {
          value: 'tmp_kurzuslista.kurzus_azonosito',
          label: 'Kód'
        },
        {
          value: 'tmp_kurzuslista.tantargy',
          label: 'Megnevezés'
        },
        {
          value: 'tmp_kurzuslista.max_korlat',
          label: 'Fő'
        },
        {
          value: 'osszes_ora_hossz',
          label: 'Összes óra'
        },
        {
          value: 'elrakott_orak',
          label: 'Elrakott óra'
        },
        {
          value: 'tmp_kurzuslista.szakok_kod',
          label: 'Szakok név'
        },
        {
          value: 'tmp_kurzuslista.szak_db',
          label: 'Szakok db'
        },
        {
          value: 'tmp_kurzuslista.oktatok',
          label: 'Oktatók név'
        },
        {
          value: 'tmp_kurzuslista.oktato_db',
          label: 'Oktatók db'
        },
      ],
      rendezes_iranyok: [
        {
          value: 'asc',
          label: 'Növekvő'
        },
        {
          value: 'desc',
          label: 'Csökkenő'
        },
      ],
      rendezes_oszlop: 'kurzus_azonosito',
      rendezes_irany: 'asc'
    }
  },

  componentDidMount: function() {
    //this.kurzusLista();
    this.oktatoLista();
    this.getSzakok();
    this.getFelevek();
    this.getAlapertelmezettSzuro();
  },

  componentDidUpdate: function() {
    $('#kurzus_lista_tabla tbody tr').eq(0).find('td').each(function(i, td) {
        $(td).width( $('#kurzus_lista_tabla thead').find('tr').find('th').eq(i).width() );
    });

    var st = 0;
    var sd = false;

    $('#kurzus_lista_tabla').mouseover(function() {
      st = $('body').scrollTop();
      sd = true;
    });

    $('#kurzus_lista_tabla').mouseleave(function() {
      sd = false;
    });

    $(window).scroll(function() {
      if (sd)
        $('body').scrollTop(st);
    });
  },

  szakIDTorles: function() {
    this.setState({
      szak_id: 'null'
    });
  },

  szakiranyIDTorles: function() {
    this.setState({
      szakirany_id: 'null'
    });
  },

  oktatoLista: function(nev) {
    var {store} = this.context;
    var _this = this;

    if (nev == undefined)
      nev = '';

    Ajax.call('orarend_szerk/oktatok/' + localStorage.token, store, {
      method: "POST",
      data: {
        nev: nev
      },
      async: false,
      success: function(data) {
        _this.setState({
          oktatok: data.oktatok
        });
      }
    });
  },

  setSzakok: function(arr, szures, cb) {
    this.setState({
      szakok: arr,
      szak_id: null,
      szakirany_id: 'null',
      felhasznalo_szak_szures: szures
    }, function() {
      if (cb != undefined)
        cb();
    });
  },

  getAlapertelmezettSzuro: function() {
    var {store} = this.context;
    var _this = this;

    Ajax.call('orarend_szerk/get_alapertelmezett_szuro/' + localStorage.token + '/' + _this.props.loggedUser.id + '/0', store, {
      async: false,
      success: function(data) {
        if (data.aktiv_csoport_id != 0 && data.kivalasztott_szakok.length > 0) {
          _this.setSzakok([{value: 'null', label:'Összes'}, ...data.kivalasztott_szakok], true);
        }
      }
    });
  },

  kurzusLista: function(elozo_scrolltopra) {
    var {store} = this.context;
    var _this = this;

    var szak_id = [];

    $(this.refs.kurzus_frissit_btn_i).addClass('fa-spin');

    if (_this.state.szak_id == "null" || _this.state.szak_id == "" || _this.state.szak_id == null) {
      this.state.szakok.map(function(szak, i) {
        if (szak.value != null)
          szak_id.push(szak.value);
      });

    } else {
      szak_id = [_this.state.szak_id];
    }

    var scrolltop = (elozo_scrolltopra != undefined) ? $('#kurzus_lista_tabla tbody').scrollTop() : 0;

    setTimeout(function() {
      Ajax.call('orarend_szerk/kurzuslista/' + localStorage.token, store, {
        method: 'POST',
        async: false,
        preloader: true,
        data: {
          lap: _this.state.lap,
          limit: _this.state.limit,
          kerkif: _this.state.kerkif,
          elrakott: _this.state.elrakott,
          elrakatlan: _this.state.elrakatlan,
          oktato_id: _this.state.oktato_id,
          szak_id: szak_id,
          szakirany_id: _this.state.szakirany_id,
          felev_id: _this.state.felev_id,
          telephely_id: _this.state.telephely_id,
          felvetelTipus_id: _this.state.felvetelTipus_id,
          felev_kezdete: _this.props.loggedUser.aktiv_idoszak.felev_kezdete,
          rendezes_oszlop: _this.state.rendezes_oszlop,
          rendezes_irany: _this.state.rendezes_irany,
          levelezos: 0,
          kethetente: _this.state.kethetente,
          terem_eszkoz_igeny: _this.state.terem_eszkoz_igeny,
          nem_kert_termet: _this.state.nem_kert_termet
        },
        success: function(data) {
          $(_this.refs.kurzus_frissit_btn_i).removeClass('fa-spin');
          data.telephelyek = [
            {
              value: 'null',
              label: 'Összes'
            },
            ...data.telephelyek
          ];

          _this.setState({
            db: data.db,
            kurzusok: data.kurzusok,
            telephelyek: data.telephelyek
          }, function() {
            $('#kurzus_lista_tabla tbody').scrollTop(scrolltop);
          });
        }
      });
    }, 100);
  },

  elrakottOraSzamCsokkentese: function(kurzus_id, ora_hossz) {
    this.state.kurzusok.map(function(kurzus, i) {
      if (parseInt(kurzus.kurzus_id) == kurzus_id) {
        kurzus.elrakott_orak = parseInt(kurzus.elrakott_orak) - 1;
        kurzus.elrakott_ora_hossz = parseInt(kurzus.elrakott_ora_hossz) - ora_hossz;
      }
    });
  },

  /**
   * Szakok lekérdezése
   *
   */
  getSzakok: function() {
    var {store} = this.context;
    var _this = this;

    Ajax.call('/orarend/szakok/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        if (data.szakok.length > 0) {
          _this.setState({
            szakok: data.szakok
          });
        }
      }
    });
  },

  /**
   * Félévek lekérdezése
   *
   */
  getFelevek: function() {
    var {store} = this.context;
    var _this = this;

    Ajax.call('orarend/felevek/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        var felevek = [{value: 'null', label:'Összes'}, ...data.felevek];

        _this.setState({felevek: felevek});
      }
    });
  },

  /**
   * Szakirányok lekérdezése
   *
   */
  getSzakiranyok: function(szak_id) {
    var {store} = this.context;
    var _this = this;

    Ajax.call('orarend/szakiranyok/' + localStorage.token + '/' + szak_id, store, {
      async: false,
      success: function(data) {
        var szakiranyok = [{value: 'null', label:'Nincs kiválasztva'}, ...data.szakiranyok];

        _this.setState({szakiranyok: szakiranyok});
      }
    });
  },

  onChangeSzak: function() {
    this.getSzakiranyok(this.state.szak_id);
    this.kurzusLista();
  },

  onChangeSzakirany: function() {
    this.kurzusLista();
  },

  onChangeFelev: function() {
    this.kurzusLista();
  },

  render() {
    var _this = this;

    var szuro_class = this.state.felhasznalo_szak_szures == false ? "btn btn-white only-icon small" : "btn btn-white only-icon small btn-green";

    return (
      <div>
      {/*<div className="panel-sub-heading"><h4>Kurzusok</h4></div>*/}
        <Form ref="form">
          <div className="row orarend-szerk-row">
            <div className="col-xs-2 col">
              <Input intervalTypingCallback={(value) => { _this.setState({lap:1, kerkif:value}, _this.kurzusLista); }} ref="kerkif" name="kerkif" title="Keresés" value={this.state.kerkif}/>
            </div>
            <div className="col-xs-6 col">
              <Toggle onChange={(value)=>{this.setState({lap:1, kethetente:value}, _this.kurzusLista);}} name="kethetente" title="Kéthetente" value={this.state.kethetente}/>
              <Toggle onChange={(value)=>{this.setState({lap:1, elrakott:value}, _this.kurzusLista);}} name="elrakott" title="Elrakott" value={this.state.elrakott}/>
              <Toggle onChange={(value)=>{this.setState({lap:1, elrakatlan:value}, _this.kurzusLista);}} name="elrakatlan" title="Elrakatlan" value={this.state.elrakatlan}/>
              <Toggle onChange={(value)=>{this.setState({lap:1, terem_eszkoz_igeny:value}, _this.kurzusLista);}} name="terem_eszkoz_igeny" title="Terem, eszköz igény" value={this.state.terem_eszkoz_igeny}/>
              <Toggle onChange={(value)=>{this.setState({lap:1, nem_kert_termet:value}, _this.kurzusLista);}} name="nem_kert_termet" title="Nem kért termet" value={this.state.nem_kert_termet}/>
            </div>
            {/*<div className="col-xs-4">
              <Select2 placeholder="Keresés" onInputChange={(value)=>{this.oktatoLista(value)}} onChange={(value)=>{this.setState({oktato_id:value}, _this.kurzusLista);}} multi={false} name="oktato_id" value={this.state.oktato_id} title="Oktató" options={this.state.oktatok}/>
            </div>*/}
            <div className="col-xs-1 col">
              <button style={{'padding':'10px','margin-top':'15px'}} ref="kurzus_frissit_btn" onClick={_this.kurzusLista} className="btn btn-white only-icon" type="button"><i ref="kurzus_frissit_btn_i" className="fa fa-refresh"></i></button>
            </div>
            <div className="col-xs-3 col">
              <div style={{'float': 'right','padding-right':'4px'}}>Leszűrt kurzusok száma: {this.state.db} db</div>
              <Pagination style={{'top': '0px'}} comboWithNav={true} infoHide={true} cb={(lap) => { _this.setState({lap:lap}, _this.kurzusLista); }} db={this.state.db} page={this.state.lap} limit={this.state.limit} />
            </div>
          </div>
        </Form>
        <div className="orarendszerk-kurzuslista">
        {this.state.kurzusok.length > 0 ? (
          <table id="kurzus_lista_tabla" style={{'width':'1400px'}} className="table table-bordered orarendszerk-kurzuslista-table fixed-head">
            <thead style={{'width':'1378px'}}>
              <tr>
                <th style={{'width':'30px', 'textAlign':'center'}}></th>
                <th style={{'width':'30px'}}></th>
                <th style={{'width':'160px'}}>Kód</th>
                <th style={{'width':'400px'}}>Megnevezés</th>
                <th style={{'width':'50px', 'textAlign':'center'}}>Fő</th>
                <th style={{'width':'60px', 'textAlign':'center'}}>Órák</th>
                <th style={{'width':'300px'}}>Szakok</th>
                <th style={{'width':'378px'}}>Oktatók</th>
              </tr>
            </thead>
            <tbody>
            {this.state.kurzusok.map(function(kurzus, i) {
              var tr_class = (kurzus.kurzus_id == _this.props.kurzus_id) ? "aktiv" : "";
              tr_class = tr_class + " " + ((kurzus.terem_igeny == 1) ? "terem_igeny" : "");
              var info_class = kurzus.van_feltetel == 1 ? 'info feltetel' : 'info';
              var jelleg_class = 'orarend-szerk-valaszthato_jelleg';

              if(kurzus.egy_szakon_kotelezo == 1) {
                if(kurzus.kotelezo) {
                  jelleg_class = 'orarend-szerk-egy_szakon_kotelezo_jelleg';
                } else if(kurzus.szakiranyon_kotelezo) {
                  jelleg_class = 'orarend-szerk-egy_szakiranyon_kotelezo_jelleg';
                }
              } else if(kurzus.tobb_szakon_kotelezo == 1) {
                if(kurzus.kotelezo) {
                  jelleg_class = 'orarend-szerk-tobb_szakon_kotelezo_jelleg';
                } else if(kurzus.szakiranyon_kotelezo) {
                  jelleg_class = 'orarend-szerk-tobb_szakiranyon_kotelezo_jelleg';
                }
              }

              if (parseInt(kurzus.elrakott_orak) == parseInt(kurzus.osszes_ora))
                tr_class += " osszes-elrakva";

              var key = kurzus.kurzus_azonosito + kurzus.kurzus_id;

              return (
               <tr className={tr_class} key={key}>
                  <td className={jelleg_class} style={{'textAlign':'center'}}><a onClick={()=>{_this.props.swapKurzusAdatokModal(parseInt(kurzus.kurzus_id))}} className={info_class} href="javascript:void(0)"><i className="fa fa-info-circle"></i></a></td>
                  <td style={{'textAlign':'center'}}><a onClick={()=>{_this.props.swapLampazasModal(parseInt(kurzus.kurzus_id))}} className="lampazas" href="javascript:void(0)"><i className="fa fa-lightbulb-o"></i></a></td>
                  <td className="kurzus_azonosito" onClick={()=>{_this.props.kurzusSorClick(parseInt(kurzus.kurzus_id))}}>{kurzus.kurzus_azonosito}</td>
                  <td className="tantargy" onClick={()=>{_this.props.kurzusSorClick(parseInt(kurzus.kurzus_id))}}>{kurzus.tantargy}</td>
                  <td style={{'textAlign':'center'}} onClick={()=>{_this.props.kurzusSorClick(parseInt(kurzus.kurzus_id))}}>{kurzus.max_korlat}</td>
                  <td style={{'textAlign':'center'}} onClick={()=>{_this.props.kurzusSorClick(parseInt(kurzus.kurzus_id))}}><a className={kurzus.elrakott_ora_hossz !=kurzus.osszes_ora_hossz ? "orahossz sotet" : "orahossz"} title={`${kurzus.elrakott_orak} / ${kurzus.osszes_ora}`}>{kurzus.elrakott_ora_hossz} / {kurzus.osszes_ora_hossz}</a></td>
                  <td onClick={()=>{_this.props.kurzusSorClick(parseInt(kurzus.kurzus_id))}}>{kurzus.szakok_kod}</td>
                  <td onClick={()=>{_this.props.kurzusSorClick(parseInt(kurzus.kurzus_id))}}>{kurzus.oktatok}</td>
                </tr>
              );
            })}
            <tr>
              <td colSpan="8">
                <Pagination style={{'margin':'10px 10px','position':'inherit','top':'0px','float':'none'}} comboWithNav={true} infoHide={true} cb={(lap) => { _this.setState({lap:lap}, _this.kurzusLista); }} db={this.state.db} page={this.state.lap} limit={this.state.limit} />
              </td>
            </tr>
            </tbody>
          </table>
          ): <div>Nem található kurzus...</div>}
        </div>
        <Form ref="form">
          <div className="row orarend-szerk-row">
            <div className="col-xs-4 col">
              <Select2 onChange={(value)=>{this.setState({lap:1, szak_id:value, szakirany_id: 'null', felev_id: (value == '' ? 'null' : _this.state.felev_id)}, _this.onChangeSzak);}} name="szak_id" value={this.state.szak_id} title="Szak" options={this.state.szakok}/>
            </div>
            <div className="col-xs-1 col">
              <button onClick={this.props.showSzakszuroCsoportModal} style={{'marginTop':'22px'}} ref="szak_szuro_btn" className={szuro_class} type="button"><i className="fa fa-filter"></i></button>
            </div>
            <div className="col-xs-5 col">
              <Select onChange={(value)=>{this.setState({lap:1, szakirany_id:value}, _this.onChangeSzakirany);}} name="szakirany_id" value={this.state.szakirany_id} title="Szakirány" options={this.state.szakiranyok}/>
            </div>
            <div className="col-xs-2 col">
              <Select onChange={(value)=>{this.setState({lap:1, felev_id:value}, _this.onChangeFelev);}} name="felev_id" value={this.state.felev_id} title="Félév" options={this.state.felevek}/>
            </div>
          </div>
          <div className="row orarend-szerk-row">
            <div className="col-xs-4 col">
              <Select onChange={(value)=>{this.setState({lap:1, telephely_id:value}, _this.kurzusLista);}} name="telephely_id" value={this.state.telephely_id} title="Helyszín" options={this.state.telephelyek}/>
            </div>
            <div className="col-xs-4 col">
              <Select onChange={(value)=>{this.setState({lap:1, felvetelTipus_id:value}, _this.kurzusLista);}} name="felvetelTipus_id" value={this.state.felvetelTipus_id} title="Felvétel típus" options={this.state.felvetelTipusok}/>
            </div>
            <div className="col-xs-2 col">
              <Select onChange={(value)=>{this.setState({lap:1, rendezes_oszlop:value}, _this.kurzusLista);}} name="rendezes" value={this.state.rendezes_oszlop} title="Rendezés" options={this.state.rendezes_oszlopok}/>
            </div>
            <div className="col-xs-2 col">
              <Select onChange={(value)=>{this.setState({lap:1, rendezes_irany:value}, _this.kurzusLista);}} name="rendezes_irany" value={this.state.rendezes_irany} title="Sorrend" options={this.state.rendezes_iranyok}/>
            </div>
          </div>
        </Form>
        <div className="clearfix">

        </div>
      </div>
    )
  }
});

Kurzuslista.contextTypes = {
  store: React.PropTypes.object
};

export default Kurzuslista;