import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory } from 'react-router';
import Ajax from './Ajax.js';
import jQueryHelper from './jQueryHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';

var Felhasznalok = React.createClass({
  getInitialState() {
    return {
      felhasznalok: [],
      kereso_nev: ''
    }
  },

  componentWillMount: function() {

  },

  getUsers: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('users/' + localStorage.token + '/' + 0 + '/' + this.refs.form.getModel().kereso_nev, store, {
      success: function(data) {
        _this.setState({felhasznalok:data.felhasznalok});
        jQueryHelper.setFixedMenu(_this);
        jQueryHelper.setDropDownToggle(_this);
      }
    });
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
    jQueryHelper.setDropDownToggle(this);
    this.getUsers();
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  felhasznaloTorol: function(id) {
    var _this = this;
    var {store} = this.context;

    swal({
      title: "Biztos hogy törli a felhasználót?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true,
      animation: false
    },
    function(){
      Ajax.call('users/delete/' + localStorage.token + '/' + id, store, {
        success: function(data) {
          //_this.componentWillMount();
          _this.getUsers();
        }
      });
    });
  },

	render: function() {
		return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <Link to="/uj_felhasznalo" className="btn btn-primary" type="button"><i className="fa fa-plus"></i>Új felhasználó</Link>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Felhasználók
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Felhasználó kezelő</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <Link to="/uj_felhasznalo" className="btn btn-primary" type="button"><i className="fa fa-plus"></i>Új felhasználó</Link>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Felhasználók</h4>
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-xs-12">
                    <Form ref="form">
                      <Input intervalTypingCallback={this.getUsers} ref="kereso_nev" name="kereso_nev" title="Kereső név" value={this.state.kereso_nev}/>
                    </Form>
                    {this.state.felhasznalok.length > 0 ? (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Név</th>
                          <th>Felhasználó név</th>
                          <th>E-mail</th>
                          <th>Beosztás</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.felhasznalok.map(felhasznalo => (
                        (this.props.prop_loggedUser.id != felhasznalo.id ? (
                         <tr key={felhasznalo.id}>
                            <td>{felhasznalo.fullname}</td>
                            <td>{felhasznalo.username}</td>
                            <td>{felhasznalo.email}</td>
                            <td>{felhasznalo.group_name}</td>
                            <td style={{"textAlign": "center"}}>
                              <ul className="icon-list">
                                <li className="dropdown"><a className="dropdown-toggle" href="javascript:void(0)"><i className="fa fa-gear"></i></a>
                                  <ul className="dropdown-menu">
                                    <li className="dropdown-header">Opciók</li>
                                    <li><Link to={`/felhasznalo/${felhasznalo.id}`}><i className="fa fa-pencil-square-o"></i>Szerkeszt</Link></li>
                                    <li><a onClick={() => this.felhasznaloTorol(felhasznalo.id)} href="javascript:void(0)"><i className="fa fa-trash"></i>Töröl</a></li>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                          ):null)
                        ))}
                      </tbody>
                    </table>
                    ): "Nem található felhasználó"}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    );
	}
});

Felhasznalok.contextTypes = {
  store: React.PropTypes.object
}

export default Felhasznalok;