import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import Pagination from './Pagination';

var EroforrasokOktatok = React.createClass({
  getInitialState() {
    return {
      oktatok: [],
      kereso_nev: '',
      oktatokSzama: 0,
      page: 1,
      limit: 100
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {
  },

  getOktatok: function() {
    var obj = this;
    var {store} = this.context;
    var search = 'all';
    if(this.refs.form.getModel().kereso_nev) {
      search = this.refs.form.getModel().kereso_nev;
    }

    Ajax.call('eroforrasok/oktatok/' + localStorage.token + '/' + search + '/' + this.state.limit + '/' + this.state.page, store, {
      success: function(data) {
        obj.setState({oktatok: data.oktatok, oktatokSzama: data.okatokDb});
        jQueryHelper.setDropDownToggle(obj);
      }
    });
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
    jQueryHelper.setDropDownToggle(this);
    this.getOktatok();
  },

  oktatoTorol: function(id) {
    var obj = this;
    var {store} = this.context;

    swal({
      title: "Biztos hogy törli az oktatót?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true
    },
    function(){
      Ajax.call('eroforrasok/oktato/torol/' + localStorage.token + '/' + id, store, {
        success: function(data) {
          var oktatok = obj.state.oktatok;

          oktatok.map(function(oktato, index) {
            if (oktato.id == id)
              oktato.torolve = 1;
          });

          obj.setState({
            oktatok: oktatok
          });
        }
      });
    });
  },

  render: function() {
      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        {/*<div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <button className="btn btn-primary" type="button"><i className="fa fa-sign-in"></i>Importálás</button>
              <Link className="btn btn-primary" to="/eroforrasok_uj_oktato"><i className="fa fa-plus"></i>Új oktató</Link>
            </div>
          </div>
        </div>*/}

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Erőforrások
              </li>
              <li>
                Oktatók
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Erőforrások, oktató lista</h1>
            </div>
            <div className="content-panel-top-btn-group">
              {/*<button className="btn btn-primary" type="button"><i className="fa fa-sign-in"></i>Importálás</button>
              <Link className="btn btn-primary" to="/eroforrasok_uj_oktato"><i className="fa fa-plus"></i>Új oktató</Link>*/}
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Oktató lista</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                  <Form ref="form">
                    <Input intervalTypingCallback={() => {this.setState({page:1}, this.getOktatok);}} ref="kereso_nev" name="kereso_nev" title="Név" value={this.state.kereso_nev}/>
                  </Form>

                  {this.state.oktatok.length > 0 ? (
                    <section>
                    <Pagination limit={this.state.limit} db={this.state.oktatokSzama} page={this.state.page} cb={(page) => {this.setState({page:page}, this.getOktatok);}}/>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Név</th>
                          <th>Tanszék</th>
                          <th>Neptun kód</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.oktatok.map(oktato => (
                        (oktato.torolve == 0 ? (
                         <tr key={oktato.id}>
                            <td>{oktato.nev}</td>
                            <td>{oktato.tanszek ? (oktato.tanszek):("-")}</td>
                            <td>{oktato.neptun}</td>
                            <td style={{"textAlign": "center"}}>
                              <ul className="icon-list">
                                <li className="dropdown"><a className="dropdown-toggle" href="javascript:void(0)"><i className="fa fa-gear"></i></a>
                                  <ul className="dropdown-menu">
                                    <li className="dropdown-header">Opciók</li>
                                    <li><Link to={`/eroforrasok_oktato/${oktato.id}`}><i className="fa fa-pencil-square-o"></i>Szerkeszt</Link></li>
                                    <li><a onClick={() => this.oktatoTorol(oktato.id)} href="javascript:void(0)"><i className="fa fa-trash"></i>Töröl</a></li>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                          ):null)
                        ))
                      }
                      </tbody>
                    </table>
                    <Pagination limit={this.state.limit} db={this.state.oktatokSzama} page={this.state.page} cb={(page) => {this.setState({page:page}, this.getOktatok);}}/>
                    </section>
                    ) : (<div>Nem található oktató.</div>)}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

EroforrasokOktatok.contextTypes = {
  store: React.PropTypes.object
}

export default EroforrasokOktatok;