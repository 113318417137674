import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import Select from './form/Select';
import Select2 from './form/Select';
import Textarea from './form/Textarea';
import Toggle from './form/Toggle';

Formsy.addValidationRule('isSelect2Empty', function (values, value) {
  return ((value == undefined || value.length == 0) ? false : true);
});

Formsy.addValidationRule('isEmpty', function (values, value) {
  return ((value == '' || value == 0 || value == null) ? false : true);
});

var EroforrasokTerem = React.createClass({
  getInitialState() {
    return {
      terem: {
        id: '',
        kod: '',
        nev: '',
        telephely_id: '',
        epulet_id: '',
        kapacitas: 0,
        megjegyzes: '',
        hasznalhato: 0,
        teremfoglalashoz: 0,
        szotar_id: 0,
        fiktiv: 0
      },
      telephelyek: [],
      epuletek: [],
      szotar_termek: [],
      formIsValid: false
    }
  },



  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {
    var obj = this;
    var {store} = this.context;

    if (obj.props.params.id == undefined) {
      obj.props.params.id = 0;
    }

    Ajax.call('eroforrasok/terem/' + localStorage.token + '/' + obj.props.params.id, store, {
      success: function(data) {
        data.telephelyek = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.telephelyek
        ];

        data.epuletek = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.epuletek
        ];

        data.szotar_termek = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.szotar_termek
        ];

        obj.setState({
          telephelyek: data.telephelyek,
          epuletek: data.epuletek,
          tipusok: data.tipusok,
          szotar_termek: data.szotar_termek
        });

        if (data.terem != undefined) {
          obj.setState({terem: data.terem});
        }
      }
    });
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
  },

  componentDidUpdate: function() {

  },

  onMent: function() {
    var self = this;

    this.refs.form.submit();

    if (this.state.formIsValid) {
      var formData = this.refs.form.getModel();

      var obj = this;
      var {store} = this.context;

      formData.terem.id = this.state.terem.id;

      Ajax.call('/eroforrasok/terem/ment/' + localStorage.token, store, {
        method: "POST",
        data: {
          data: formData
        },
        success: function(data) {
          hashHistory.push('/eroforrasok_termek');
        }
      });
    }
  },

  onTelephelyChange: function(value) {
    var obj = this;
    var {store} = this.context;

    Ajax.call('eroforrasok/terem/epuletek/' + localStorage.token + '/' + value, store, {
      success: function(data) {
        data.epuletek = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.epuletek
        ];

        obj.setState({epuletek: data.epuletek});

        var terem = obj.state.terem;
        terem.epulet_id = "0";
        obj.setState({terem: terem});
      }
    });
  },

  szotarTeremValt: function(value) {
    this.state.terem.szotar_id = value;
  },

  onValid: function() {
    this.setState({
      formIsValid: true
    });
  },

  onInvalid: function() {
    this.setState({
      formIsValid: false
    });
  },

  render: function() {
      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Erőforrások
              </li>
              <li>
                Terem
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Erőforrások, terem</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Terem</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                    <Form ref="form" onValid={this.onValid} onInvalid={this.onInvalid}>
                      <Input name="terem.nev" title="Név" value={this.state.terem.nev} validationError="Név kötelező" required />
                      <Select onChange={this.onTelephelyChange} name="terem.telephely_id" title="Telephely" value={this.state.terem.telephely_id} options={this.state.telephelyek} validationError="Telephely kötelező" validations="isEmpty" required />
                      <Select name="terem.epulet_id" title="Épület" value={this.state.terem.epulet_id} options={this.state.epuletek} validationError="Épület kötelező" validations="isEmpty" required />
                      <Input name="terem.kod" title="Kód" value={this.state.terem.kod} validationError="Kód kötelező" required />
                      <Input name="terem.kapacitas" title="Kapacítás" validations="isNumeric" value={this.state.terem.kapacitas} validationError="Kapacítás kötelező" required />
                      <Textarea name="terem.megjegyzes" title="Megjegyzés" value={this.state.terem.megjegyzes} />
                      <Toggle name="terem.hasznalhato" title="Használható" value={this.state.terem.hasznalhato}/>
                      <Toggle name="terem.teremfoglalashoz" title="Teremfoglaláshoz" value={this.state.terem.teremfoglalashoz}/>
                      <Toggle name="terem.generalashoz" title="Generáláshoz használható" value={this.state.terem.generalashoz_hasznalhato}/>
                      <Toggle name="terem.fiktiv" title="Fiktív" value={this.state.terem.fiktiv}/>
                      <Select2 placeholder="Keresés" onChange={this.szotarTeremValt} name="terem.szotar_id" title="Szótár terem" value={this.state.terem.szotar_id} options={this.state.szotar_termek} />
                    </Form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

EroforrasokTerem.contextTypes = {
  store: React.PropTypes.object
}

export default EroforrasokTerem;