import React from 'react';
import AjaxPreloader from './AjaxPreloader';

var Main = React.createClass({
  render: function() {
    return (
      <div>
        {React.cloneElement(this.props.children, this.props)}
        <AjaxPreloader show={this.props.prop_config.ajaxPreloaderShow} ref="ajax_preloader"/>
      </div>
    );
  }
});

export default Main;