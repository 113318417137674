import React from 'react';
import Ajax from './../../Ajax.js';
import {Form} from 'formsy-react';
import Button from 'react-bootstrap';
import Input from './../../form/Input';
import Select from './../../form/Select';

var Naptar = React.createClass({
  getInitialState() {
    return {
      kezdo_datum: '',
      zaro_datum: '',
      het: 1,
      max_het: 0,
      napok: [],
      combo_orak: []
    }
  },

  componentDidMount: function() {
    this.setState({
      kezdo_datum: this.props.loggedUser.aktiv_idoszak.felev_kezdete,
      zaro_datum: this.props.loggedUser.aktiv_idoszak.felev_vege,
      max_het: moment(this.props.loggedUser.aktiv_idoszak.felev_vege).diff(moment(this.props.loggedUser.aktiv_idoszak.felev_kezdete), 'weeks') + 1
    });

    $('#naptar_modal').draggable({
      start: function( event, ui ) {
        $('#naptar_modal').addClass('startdrag');
      },
    });

    this.ablakPozicio();
    this.getOrakComboba();
  },

  getOrakComboba: function() {
    var {store} = this.context;
    var _this = this;

    Ajax.call('orarend_szerk/kurzus_orak/' + localStorage.token + '/' + this.props.kurzus_id + '/1', store, {
      async: false,
      success: function(data) {
        var tmp = [];
        data.orak.map(function(ora) {
          tmp.push({
            "value": ora.id,
            "label": ora.id + ' - ' + (ora.terem_id == null || ora.terem_id == '' ? 'elrakatlan ' : 'elrakott ') + (ora.terem_kod == null || ora.terem_kod == '' ? '' : ' (' + ora.terem_kod +')')
          });
        });

        _this.setState({
          combo_orak: tmp
        });
      }
    });
  },

  componentDidUpdate: function() {
    var _this = this;

    $('.blokk').click(function() {
      _this.props.idopontClick($(this).data('hossz'), $(this).data('oraid'), $(this).data('teremid'), $(this).data('nap'), $(this).data('periodus'), $(this).data('kezdete'), $(this).data('datum'));
    });

    $('.foglalt').mouseover(function() {
      var nap_id = parseInt($(this).data('nap'));
      var periodus_id = parseInt($(this).data('periodus'));
      var p = '';

      _this.state.napok.map(function(nap, i) {
        if (nap.nap_id == nap_id) {
          nap.periodusok.map(function(periodus, i) {
            if (periodus.periodus == periodus_id) {
              p = periodus;
            }
          });
        }
      });

      if (p != '') {
        if ($('.calendar-tooltip').length == 0) {
          var text = '';

          if (p.foglaltsag.foglalt) {
            p.foglaltsag.utkozes.map(function(utkozes, i) {
              var text_arr = [];

              text_arr.push((utkozes.sertes.megnevezes == 'A kiválasztott óra!'?'':'Sértés: ') + utkozes.sertes.megnevezes);

              if (utkozes.kurzus_azon != undefined)
                text_arr.push('Kurzus azonosító: ' + utkozes.kurzus_azon);

              if (utkozes.szak != undefined)
                text_arr.push('Szak: ' + utkozes.szak.nev + (utkozes.felev?' (' + utkozes.felev + '. félév)':''));

              if (utkozes.tanterv != undefined)
                text_arr.push('Tanterv: ' + utkozes.tanterv.kod + ' (' + utkozes.tanterv.felev + '. félév)');

              if (utkozes.tanar != undefined)
                text_arr.push('Oktató: ' + utkozes.tanar.nev + ' (' + utkozes.tanar.neptun + ')');

              if (utkozes.terem != undefined)
                text_arr.push('Terem: ' + utkozes.terem.nev);

              if (utkozes.feltetel_megnevezes != undefined)
                text_arr.push('Megjegyzés: ' + utkozes.feltetel_megnevezes);

              text += '<span>' + text_arr.join('<br>') + '</span>';
            });
          }

          var tooltip = $('<div class="calendar-tooltip fixed">'+text+'</div>');
          $('body').append(tooltip);

          _this.tooltipPozicioBeallit($(tooltip));

          $('body').mousemove(function(event) {
            _this.tooltipPozicioBeallit($(tooltip));
          });
        }
      }
    });

    $('.foglalt').mouseout(function() {
      $('.calendar-tooltip').remove();
    });
  },

  tooltipPozicioBeallit: function(tooltip) {
    var top = (event.clientY - ($(tooltip).outerHeight() + 30)) + $(window).scrollTop();
    var left = event.pageX - $(tooltip).width() / 2 - 7;

    if ( (event.clientY - $(tooltip).outerHeight()) < 0 ) {
      top = $(window).scrollTop();
      left = event.pageX - $(tooltip).width() - 50;
    }

    $(tooltip).css({
      left: left,
      top: top
    });
  },

  ablakPozicio: function() {
    var top;

    if ($('#naptar_modal').height() > $(window).innerHeight()) {
      top = -$('.panel-body').offset().top + $('#naptar_modal').height() / 2;
    } else {
      top = Math.round($(window).scrollTop() + $(window).innerHeight() / 2 - parseInt($('.main-panel').css('padding-top')) - 200);
    }

    $(this.refs.naptar_modal).css({
      'left': Math.round($(window).innerWidth() / 2 - parseInt($('.main-panel').css('padding-left'))),
      'top': top
    });

    $(this.refs.lampazas_modal).removeClass('startdrag');
    $(this.refs.lampazas_modal).css({
      'height': 'auto'
    });
  },

  hetNovelesClick: function() {
    var het = this.state.het;
    het++;

    if (het > this.state.max_het)
      het = this.state.max_het;

    this.setState({
      het: het
    }, this.frissites);
  },

  hetCsokkentesClick: function() {
    var het = this.state.het;
    het--;
    if (het < 1)
      het = 1;

    this.setState({
      het: het
    }, this.frissites);
  },

  utolsoHetClick: function() {
    this.setState({
      het: this.state.max_het
    }, this.frissites);
  },

  elsoHetClick: function() {
    this.setState({
      het: 1
    }, this.frissites);
  },

  hetBeallitas: function(het) {
    het = parseInt(het);

    if (isNaN(het))
      het = 1;

    if (het < 1)
      het = 1;

    if (het > this.state.max_het)
      het = this.state.max_het;

    this.setState({
      het: het
    }, this.frissites);
  },

  oraComboValtoztatas: function(ora_id) {
    var {store} = this.context;
    var _this = this;
    var elrakott_ora = false;

    // Ha elrakott órát választott ki a combóban, akkor azt szimulálja, mintha az elrakott órák egy során kattintott volna
    Ajax.call('orarend_szerk/elrakott_orak/' + localStorage.token + '/' + this.props.kurzus_id + '/1', store, {
      async: false,
      success: function(data) {
        data.orak.map(function(ora) {
          if (parseInt(ora.ora_id) == parseInt(ora_id)) {
            _this.props.elrakottOraSorClick(ora.ora_id, ora.terem_id, ora.nap, ora.kezdete, ora.vege, ora.hossz, ora.terem, ora.kurzus_kod, ora.tantargy);
            elrakott_ora = true;
          }
        });

        if (elrakott_ora == false) {
          Ajax.call('orarend_szerk/elrakatlan_orak/' + localStorage.token + '/' + _this.props.kurzus_id + '/1', store, {
            async: false,
            success: function(data) {
              data.orak.map(function(ora) {
                if (parseInt(ora.ora_id) == parseInt(ora_id)) {
                  _this.props.elrakatlanOraBeallitas(ora_id, _this.props.terem_id, ora.hossz, _this.props.terem_megn, ora.kurzus_kod, ora.tantargy);
                }
              });
            }
          });
        }
      }
    });
  },

  frissites: function() {
    if (this.props.kurzus_id != 0 && this.props.ora_id != 0 && this.props.terem_id != 0) {
      var {store} = this.context;
      var _this = this;

      var kezdo_datum = moment(this.state.kezdo_datum).add(this.state.het - 1, 'week');
      var zaro_datum = moment(this.state.kezdo_datum).add(this.state.het, 'week');
      var tmp = [];

      Ajax.call('orarend_szerk/idopont_fogl_levelezo/' + localStorage.token + '/' + this.props.ora_id + '/' + this.props.kurzus_id + '/' + this.props.terem_id + '/' + this.props.loggedUser.aktiv_idoszak.felev_kezdete, store, {
        async: false,
        success: function(data) {
          data.map(function(nap, i) {
            //if ( moment(nap.datum).isAfter(moment(kezdo_datum)) && moment(nap.datum).isBefore(moment(zaro_datum))) {
              tmp.push(nap);
            //}
          });
          _this.setState({
            napok: tmp
          });
        }
      });
    }
  },

  render() {
    var _this = this;

    var tmp = "<tr>";
    var het = 1;

    tmp += "<td class=\"nap\">Péntek</td>";
    {this.state.napok[0] != undefined && this.state.napok[0].periodusok.map(function(periodus, i) {
        tmp +="<td class=\"periodus\">"+periodus.kezdete+"-"+periodus.vege+"</td>";
    })}
    tmp += "<td class=\"nap\">Szombat</td>";
    {this.state.napok[1] != undefined && this.state.napok[1].periodusok.map(function(periodus, i) {
        tmp +="<td class=\"periodus\">"+periodus.kezdete+"-"+periodus.vege+"</td>";
    })}
    tmp += "</tr>";

    tmp += "<tr>";
    {this.state.napok.map(function(nap, i) {
      if (i % 2 == 0 && i != 0) {
        tmp += "</tr><tr>";
        het++;
      }
      tmp = tmp + "<td class=\"datum\"><span>"+het+". hét</span>"+nap.datum+"</td>";
      {nap.periodusok.map(function(periodus, j) {
        var info_text = '';
        var class_name = '';
        var van_utkozes = false;

        if (periodus.foglaltsag.foglalt) {
          /*if (_this.props.nap == nap.datum && _this.props.kezdete == periodus.kezdete) {
            class_name += ' jeloltnap ';
          }*/
          class_name += ' foglalt ';

          periodus.foglaltsag.utkozes.map(function(utkozes, i) {
            if (van_utkozes && class_name.indexOf('tobbszoros') == -1)
              class_name += ' tobbszoros ';

            van_utkozes = true;

            if (class_name.indexOf(utkozes.sertes.class_definicio) == -1 || utkozes.sertes.class_definicio == 'jeloltnap') {
              class_name += ' ' + utkozes.sertes.class_definicio + ' ';
              info_text += '<div class="info">'+utkozes.sertes.megnevezes+'</div>';
            }
          });
        }

        tmp = tmp + "<td class=\"blokk"+class_name+"\" data-hossz=\""+_this.props.hossz+"\" data-datum=\""+nap.datum+"\" data-kezdete=\""+periodus.kezdete+"\" data-oraid=\""+_this.props.ora_id+"\" data-teremid=\""+_this.props.terem_id+"\" data-nap=\""+nap.nap_id+"\" data-periodus=\""+periodus.periodus+"\">"+info_text+"</td>";
      })}
    })}
    tmp += "</tr>";

    var terem = '';
    if(this.props.terem_megn && this.props.elrakott_terem_megn && this.props.terem_megn != this.props.elrakott_terem_megn) {
      terem = 'Áthelyezés: '+this.props.elrakott_terem_megn+' > '+this.props.terem_megn;
    } else if(this.props.terem_megn) {
      terem = this.props.terem_megn;
    } else if (this.props.elrakott_terem_megn) {
      terem = this.props.elrakott_terem_megn;
    }

    return (
    <div ref="naptar_modal" id="naptar_modal" className="modal-content no-overlay" style={{'width':'1161px'}}>
      <div className="modal-header">
        <button onClick={this.props.hide} type="button" className="close"><span aria-hidden="true">×</span></button>
        <h4 className="modal-title">Időpont választás</h4>
      </div>
      <div className="modal-body">
        <Form>
          <Select onChange={this.oraComboValtoztatas} value={this.props.ora_id} name="elrakatlan_ora" title="Órák" options={this.state.combo_orak}/>
        </Form>

        <div className="orarendszerk-naptar-fejlec" style={{'marginBottom':'15px'}}>
          <div className="terem">{terem}</div>
          {this.props.tantargy_megn != '' ?
          (<div className="ora">
            <div className="kurzuskod">{this.props.kurzus_kod}</div>
            <div className="tantargy_megn">{this.props.tantargy_megn}</div>
          </div>) : null}
        </div>


      {this.props.terem_id != 0 ? (
        <div>
        {/*
          <div className="orarendszerk-naptar-het-valaszto">
            <button onClick={this.elsoHetClick} className="btn btn-white only-icon small" style={{'marginRight':'5px'}} type="button"><i className="fa fa-angle-double-left"></i></button>
            <button onClick={this.hetCsokkentesClick} className="btn btn-white only-icon small" type="button"><i className="fa fa-angle-left"></i></button>
            <div className="hetkijelzo">
              <Form>
                <Input style={{'width':'50px'}} name="het" intervalTypingCallback={(value) => { _this.hetBeallitas(value); }} value={this.state.het}/>
              </Form>
            </div>
            <button onClick={this.hetNovelesClick} className="btn btn-white only-icon small" type="button"><i className="fa fa-angle-right"></i></button>
            <button onClick={this.utolsoHetClick} className="btn btn-white only-icon small" style={{'marginLeft':'5px'}} type="button"><i className="fa fa-angle-double-right"></i></button>
          </div>
          */}
          <table className="orarend-szerk-lampazas-tabla">
            <tbody dangerouslySetInnerHTML={{__html: tmp}}>
            </tbody>
          </table>

        {/*
          <div className="row orarend-szerk-row">
          {this.state.napok.map(function(nap, i) {
              moment.locale('hu');

             return (
              <div key={i} className="col-xs-6 col">
                <div className="orarendszerk-naptar-napkijelzo">{nap.datum} <span className="nap">({moment(nap.datum).format("ddd")})</span></div>
              </div>
             ) ;
          })}
          </div>

          <div className="row orarend-szerk-row">
            {this.state.napok.map(function(nap, i) {
                return (
                  <div key={i} className="col-xs-6 col">
                    {nap.periodusok.map(function(periodus, i) {
                        var cn = "orarendszerk-naptar-elem";

                        if (periodus.foglaltsag.foglalt) {
                          cn = cn + " foglalt";
                        }

                      return (
                          <div key={i} onClick={()=>{_this.props.idopontClick(_this.props.ora_id, _this.props.terem_id, nap.nap_id, periodus.periodus)}} className={cn}>
                            {periodus.kezdete} - {periodus.vege}
                            {$.isArray(periodus.foglaltsag.utkozes) && periodus.foglaltsag.utkozes.map(function(utkozes, i) {
                               return (<div className="info">{utkozes}</div>)
                            })}
                          </div>
                      )
                    })}
                  </div>
                )
            })}
          </div>
          */}

        </div>
        ) : null}

      </div></div>
    )
  }
});

Naptar.contextTypes = {
  store: React.PropTypes.object
};

export default Naptar;