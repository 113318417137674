function oldaluzenetReducer(state = [], action) {
  switch (action.type) {
    case "SET_OLDAL_UZENET":
      return {
        tipus: action.tipus,
        uzenet: action.uzenet
      };
    break;

    default:
      return state;
  }
}

export default oldaluzenetReducer;