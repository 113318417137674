import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import UjOrarendModal from './idoszakok/UjOrarendModal';
import IdoszakModal from './idoszakok/IdoszakModal';
import LevelezoNapModal from './idoszakok/LevelezoNapModal';
import ImportalasStatusz from './idoszakok/ImportalasStatusz';
import SpecialisBeallitasokModal from './idoszakok/SpecialisBeallitasokModal';
import utils from 'formsy-react/lib/utils';

var Idoszakok = React.createClass({
  getInitialState() {
    return {
      idoszakok: [],
      orarendek: [],
      valid_form: false,
      kivalasztott_idoszak_id: 0,
      ujIdoszakModalShow: false,
      ujIdoszakFormValid: false
    }
  },

  componentWillMount: function () {
    this.getIdoszakok();
  },

  getIdoszakok: function () {
    var obj = this;
    var { store } = this.context;

    Ajax.call('idoszakok/' + localStorage.token, store, {
      success: function (data) {
        if (obj.props.prop_loggedUser.aktiv_idoszak != null) {
          data.idoszakok.map(function (idoszak, i) {
            idoszak.aktiv = (idoszak.id == obj.props.prop_loggedUser.aktiv_idoszak.id) ? 1 : 0;
            idoszak.kivalasztott = (idoszak.id == obj.props.prop_loggedUser.aktiv_idoszak.id) ? 1 : 0;
            if (idoszak.kivalasztott == 1) {
              obj.setState({ kivalasztott_idoszak_id: idoszak.id });
            }
          });
        }

        obj.setState({ idoszakok: data.idoszakok });

        if (obj.props.prop_loggedUser.aktiv_idoszak != null) {
          Ajax.call('orarendek/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_idoszak.id, store, {
            success: function (data) {
              data.orarendek.map(function (orarend, i) {
                orarend.aktiv = (orarend.id == obj.props.prop_loggedUser.aktiv_orarend.id) ? 1 : 0;
              });

              obj.setState({ orarendek: data.orarendek });
              jQueryHelper.setDropDownToggle(obj);
            }
          });
        }
      }
    });
  },

  componentDidMount: function () {
    jQueryHelper.setFixedMenu(this);
    jQueryHelper.setDropDownToggle(this);
  },

  componentWillUpdate: function () {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  idoszakValasztasClick: function (id) {
    var obj = this;
    var { store } = this.context;

    Ajax.call('orarendek/' + localStorage.token + '/' + id, store, {
      success: function (data) {
        if (obj.props.prop_loggedUser.aktiv_orarend != null) {
          data.orarendek.map(function (orarend, i) {
            orarend.aktiv = (orarend.id == obj.props.prop_loggedUser.aktiv_orarend.id) ? 1 : 0;
          });
        }

        obj.state.idoszakok.map(function (idoszak, i) {
          idoszak.kivalasztott = (idoszak.id == id) ? 1 : 0;
        });

        obj.setState({ orarendek: data.orarendek, kivalasztott_idoszak_id: id });
        jQueryHelper.setDropDownToggle(obj);
      }
    });
  },

  orarendValasztasClick: function (id) {
    var obj = this;
    var { store } = this.context;

    Ajax.call('orarend/' + localStorage.token + '/' + id, store, {
      success: function (data) {
        var orarend = data.orarend;
        obj.props.userSetIdoszak(orarend.idoszak_id, orarend.idoszak_megnevezes);
        obj.props.userSetOrarend(orarend.id, orarend.megnevezes, orarend.adatbazis_megnevezes);

        Ajax.call('/user/aktiv_orarend/' + localStorage.token + '/' + obj.props.prop_loggedUser.id + '/' + obj.props.prop_loggedUser.aktiv_orarend.id, store, {
          success: function (data) {
            var orarendek = obj.state.orarendek;
            var idoszakok = obj.state.idoszakok;

            orarendek.map(function (orarend, i) {
              if (orarend.id == obj.props.prop_loggedUser.aktiv_orarend.id) {
                orarend.aktiv = 1;
              } else {
                orarend.aktiv = 0;
              }
            });
            obj.setState({ orarendek: orarendek });

            idoszakok.map(function (idoszak, i) {
              if (idoszak.id == obj.props.prop_loggedUser.aktiv_idoszak.id) {
                idoszak.aktiv = 1;
              } else {
                idoszak.aktiv = 0;
              }
            });
            obj.setState({ idoszakok: idoszakok });

            swal("Sikeres!", "Kiválasztott órarend beállítva!", "success");
          }
        });
      }
    });
  },

  orarendSzerkesztClick: function (id) {
    var obj = this;
    var { store } = this.context;

    Ajax.call('orarend/' + localStorage.token + '/' + id, store, {
      success: function (data) {
        var orarend = data.orarend;
        obj.props.userSetIdoszak(orarend.idoszak_id, orarend.idoszak_megnevezes);
        obj.props.userSetOrarend(orarend.id, orarend.megnevezes, orarend.adatbazis_megnevezes);

        Ajax.call('/user/aktiv_orarend/' + localStorage.token + '/' + obj.props.prop_loggedUser.id + '/' + obj.props.prop_loggedUser.aktiv_orarend.id, store, {
          success: function (data) {
            hashHistory.push('/orarend');
          }
        });
      }
    });
  },

  ujIdoszakModalShow: function () {
    this.setState({ ujIdoszakModalShow: true });
  },

  ujIdoszakModalHide: function () {
    this.setState({ ujIdoszakModalShow: false });
  },

  ujIdoszakNevHandle: function (e) {
    this.setState({ ujIdoszakNev: e.target.value });
  },

  ujIdoszakMent: function () {
    var { store } = this.context;
    var obj = this;

    this.refs.uj_idoszak_form.submit();

    if (this.state.valid_form) {
      var formData = this.refs.uj_idoszak_form.getModel();

      Ajax.call('uj_idoszak/' + localStorage.token, store, {
        method: "POST",
        data: {
          megnevezes: formData.ujidoszak_nev,
          felev_kezdete: formData.felev_kezdete,
          felev_vege: formData.felev_vege
        },
        success: function (data) {
          if (data.errors.length > 0) {
            data.errors.map(function (error, i) {
              obj.refs.uj_idoszak_form.updateInputsWithError(error);
            });
          } else {
            obj.setState({
              idoszakok: [
                ...obj.state.idoszakok,
                data.idoszak
              ]
            });
            obj.ujIdoszakModalHide();
            swal("Sikeres!", "Új időszak felvitelre került!", "success");
          }
        }
      });
    }
  },

  onUjIdoszakFormValid: function () {
    this.setState({
      ujIdoszakFormValid: true
    });
  },

  onUjIdoszakFormInvalid: function () {
    this.setState({
      ujIdoszakFormValid: false
    });
  },

  showUjOrarendModal: function () {
    var van_kivalasztott_idoszak = false;

    this.state.idoszakok.map(function (idoszak, i) {
      if (idoszak.kivalasztott == 1)
        van_kivalasztott_idoszak = true;
    });

    if (!van_kivalasztott_idoszak) {
      swal('Hiba!', 'Új órarend létrehozásához ki kell választania egy időszakot.');
    } else {
      this.refs.uj_orarend_modal.show();
    }
  },

  showLevelezoNapModal: function (id) {
    this.refs.levelezonap_modal.show(id);
  },

  orarendTorol: function (value) {
    var _this = this;
    var { store } = this.context;

    var aktiv_orarend_id = 0;
    this.state.orarendek.map(function (orarend, i) {
      if (orarend.aktiv == 1)
        aktiv_orarend_id = orarend.id;
    });

    if (aktiv_orarend_id == value) {
      swal('Hiba!', 'Az órarend nem törölhető, mert aktív');
      return false;
    }

    swal({
      title: "Biztos hogy törli az órarendet?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true,
      animation: false
    },
      function () {
        Ajax.call('orarend_torles/' + localStorage.token + '/' + value, store, {
          success: function (data) {
            if (data.success) {
              swal('Siker!', 'Órarend törlése sikeres.', 'success');

              var kivalasztott_idoszak_id = 0;
              _this.state.idoszakok.map(function (idoszak, i) {
                if (idoszak.kivalasztott == 1)
                  kivalasztott_idoszak_id = idoszak.id;
              });

              _this.idoszakValasztasClick(kivalasztott_idoszak_id);
              _this.forceUpdate();

            } else {
              swal('Hiba!', 'Órarend törlése sikertelen. (' + data.hiba + ')', 'error');
            }
          }
        });
      });
  },

  orarendMasol: function (orarend_id) {
    var _this = this;
    var { store } = this.context;

    swal({
      title: "Lemásolja az órarendet?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true,
      showLoaderOnConfirm: true,
      animation: false
    },
      function () {
        var kivalasztott_idoszak_id = 0;
        _this.state.idoszakok.map(function (idoszak, i) {
          if (idoszak.kivalasztott == 1)
            kivalasztott_idoszak_id = idoszak.id;
        });

        Ajax.call('uj_orarend/' + localStorage.token, store, {
          method: "POST",
          data: {
            orarend_id: orarend_id,
            masol: 1,
            idoszak_id: kivalasztott_idoszak_id,
            ora_elrakasok: 1,
            feltetelek: 1,
            mentesitesek: 1
          },
          success: function (data) {
            if (data.success) {
              _this.idoszakValasztasClick(kivalasztott_idoszak_id);
              swal('Siker!', 'Új órarend létrehozása sikeres.', 'success');
            } else {
              swal('Hiba!', 'Nem sikerült az órarendet létrehozni. (' + data.hiba + ')', 'error');
            }
          }
        });
      });
  },

  orarendAlapertelmezettreClick: function (orarend_id) {
    var _this = this;
    var { store } = this.context;

    swal({
      title: "Beállítja alapértelmezettnek?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: false,
      animation: false
    },
      function (isConfirm) {
        if (isConfirm) {
          Ajax.call('alapertelm_orarend_beallitasa/' + localStorage.token + '/' + orarend_id, store, {
            success: function (data) {
              if (data.success) {
                _this.idoszakValasztasClick(_this.state.kivalasztott_idoszak_id);
                swal('Siker!', 'Órarend beállítva alapértelmezettre.', 'success');
              } else {
                swal('Hiba!', 'Nem sikerült az órarendet beállítani alapértelmezettre. (' + data.hiba + ')', 'error');
              }
            }
          });
        }
      });
  },

  idoszakLathatosagClick: function (idoszak_id, lathatosag) {
    var _this = this;
    var { store } = this.context;

    if (lathatosag == "0") {
      lathatosag = 1;
    } else {
      lathatosag = 0;
    }

    swal({
      title: "Megváltoztatja az időszak láthatóságát?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: false,
      animation: false
    },
      function () {
        Ajax.call('idoszak_lathatosag/' + localStorage.token + '/' + idoszak_id + '/' + lathatosag, store, {
          success: function (data) {
            if (data.success) {
              _this.getIdoszakok();
              swal('Siker!', 'Az időszak láthatósága megváltozott.', 'success');
            } else {
              swal('Hiba!', 'Az időszak láthatóságát nem sikerült megváltoztatni. (' + data.hiba + ')', 'error');
            }
          }
        });
      });
  },

  orarendOktatoknakAktivraClick: function (orarend_id) {
    var _this = this;
    var { store } = this.context;

    swal({
      title: "Beállítja oktatóknak aktívra?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: false,
      animation: false
    },
      function () {
        Ajax.call('oktatonak_aktiv_orarend_beallitasa/' + localStorage.token + '/' + orarend_id, store, {
          success: function (data) {
            if (data.success) {
              _this.idoszakValasztasClick(_this.state.kivalasztott_idoszak_id);
              swal('Siker!', 'Órarend beállítva oktatóknak aktívra.', 'success');
            } else {
              swal('Hiba!', 'Nem sikerült az órarendet beállítani oktatóknak aktívra. (' + data.hiba + ')', 'error');
            }
          }
        });
      });
  },

  importalas: function () {
    var _this = this;
    var { store } = this.context;

    swal({
      title: "Elindítja a Neptun adatok importálását?",
      type: "info",
      showCancelButton: true,
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      showLoaderOnConfirm: true
    },
      function () {
        // Ellenőrzi, hogy fut -e a szerviz
        Ajax.call('check_service_running', store, {
          method: 'GET',
          async: false,
          success: function (data) {
            // Fut a szerviz
            if (data.success) {
              setTimeout(function () {
                _this.importalasStart();
              }, 500);

              // Nem fut a szervíz
            } else {
              Ajax.call('orarend/orkd_start', store, {
                method: 'GET',
                async: false,
                success: function (data) {
                  if (data) {
                    store.dispatch({ type: 'SET_CONFIG_SERVICE_RUN', run: true });
                    setTimeout(function () {
                      _this.importalasStart();
                    }, 500);

                  } else {
                    setTimeout(function () {
                      swal('Hiba!', 'Az importálás nem indult el, mert nem sikerült elindítani a szolgáltatást.', 'error');
                    }, 500);
                  }
                }
              });
            }
          }
        });
      });
  },

  importalasStart: function () {
    var _this = this;
    var { store } = this.context;

    Ajax.call('idoszakok/importalas/', store, {
      method: 'POST',
      data: {
        token: localStorage.token,
        orarend_id: _this.props.prop_loggedUser.aktiv_orarend.id
      },
      success: function (data) {
        if (data || data == -6) {
          _this.refs.ImportalasStatusz.statuszLekerdezes();
        } else if (data === false) {
          swal("Hiba", "Nem sikerült az importálás elindítása. Ellenőrizze a szerviz státuszát!", "error");
        }
      }
    });
  },

  checkUserRule: function (rule_alias) {
    var r = false;
    this.props.prop_loggedUser.rules.map(function (rule) {
      if (rule == rule_alias)
        r = true;
    });
    return r;
  },

  render: function () {
    var obj = this;

    return (
      <div>
        <Header {...this.props} />
        <LeftPanel {...this.props} />
        <div style={{ 'paddingLeft': this.props.prop_config.menuOpen ? 220 : 0 }} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              {this.checkUserRule('idoszakok/uj') ? (<button onClick={this.ujIdoszakModalShow} className="btn btn-primary" type="button"><i className="fa fa-plus"></i>Új időszak</button>) : ''}
              {this.checkUserRule('idoszakok/uj_orarend') ? (<button onClick={this.showUjOrarendModal} className="btn btn-primary" type="button"><i className="fa fa-plus"></i>Új órarend</button>) : ''}
              {this.checkUserRule('idoszakok/importalas') ? (<button onClick={this.importalas} className="btn btn-primary" type="button"><i className="fa fa-sign-in"></i>Importálás</button>) : ''}
              {this.checkUserRule('idoszakok/exportalas') ? (<button onClick={this.exportalas} className="btn btn-primary" type="button"><i className="fa fa-sign-out"></i>Exportálás</button>) : ''}
            </div>
          </div>
        </div>

        <div style={{ 'paddingLeft': this.props.prop_config.menuOpen ? 220 : 0 }} ref="main_panel" className="main-panel">

          <ImportalasStatusz loggedUser={this.props.prop_loggedUser} ref="ImportalasStatusz" />

          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Időszakok, órarendek
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Időszakok, órarendek</h1>
            </div>
            <div className="content-panel-top-btn-group">
              {this.checkUserRule('idoszakok/uj') ? (<button onClick={this.ujIdoszakModalShow} className="btn btn-primary" type="button"><i className="fa fa-plus"></i>Új időszak</button>) : ''}
              {this.checkUserRule('idoszakok/uj_orarend') ? (<button onClick={this.showUjOrarendModal} className="btn btn-primary" type="button"><i className="fa fa-plus"></i>Új órarend</button>) : ''}
              {this.checkUserRule('idoszakok/importalas') ? (<button onClick={this.importalas} className="btn btn-primary" type="button"><i className="fa fa-sign-in"></i>Importálás</button>) : ''}
              {this.checkUserRule('idoszakok/exportalas') ? (<button onClick={this.exportalas} className="btn btn-primary" type="button"><i className="fa fa-sign-out"></i>Exportálás</button>) : ''}
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Időszakok, órarendek</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-6">
                    <div className="panel-sub-heading">
                      <h4>Időszakok</h4>
                    </div>
                    {this.state.idoszakok.length > 0 ? (
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Név</th>
                            <th style={{ 'textAlign': 'center' }}>Órarendek száma</th>
                            <th style={{ 'textAlign': 'center' }}>Oktatóknak látható</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.idoszakok.map(function (idoszak, i) {
                            var classname = '';

                            if (idoszak.aktiv == 1) {
                              classname = 'warning';
                            } else if (idoszak.kivalasztott == 1) {
                              classname = 'active';
                            }

                            return (
                              <tr key={idoszak.id} className={classname}>
                                <td>{idoszak.megnevezes}</td>
                                <td style={{ 'textAlign': 'center' }}>{idoszak.orarend_db}</td>
                                <td style={{ 'textAlign': 'center' }}>{idoszak.aktiv_orarend == 0 ? <i className="fa fa-remove"></i> : <i className="fa fa-check"></i>}</td>
                                <td style={{ 'textAlign': 'center' }}>
                                  <ul className="icon-list">
                                    <li className="dropdown"><a className="dropdown-toggle" href="javascript:void(0)"><i className="fa fa-gear"></i></a>
                                      <ul className="dropdown-menu">
                                        <li className="dropdown-header">Opciók</li>
                                        {obj.checkUserRule('idoszakok/idoszak_kivalaszt') ? (<li><a onClick={() => obj.idoszakValasztasClick(idoszak.id)} href="javascript:void(0)"><i className="fa fa-hand-o-up"></i>Kiválaszt</a></li>) : ''}
                                        {obj.checkUserRule('idoszakok/idoszak_szerkeszt') ? (<li><a onClick={() => obj.refs.idoszak_modal.show(idoszak.id)} href="javascript:void(0)"><i className="fa fa-pencil-square-o"></i>Szerkeszt</a></li>) : ''}
                                        {obj.checkUserRule('idoszakok/idoszak_szerkeszt') ? (<li><a onClick={() => obj.idoszakLathatosagClick(idoszak.id, idoszak.aktiv_orarend)} href="javascript:void(0)"><i className="fa fa-pencil-square-o"></i>{idoszak.aktiv_orarend == 0 ? "Láthatóvá tétel" : "Láthatatlanná tétel"}</a></li>) : ''}
                                      </ul>
                                    </li>
                                  </ul>
                                  {/*
                              <ul className="icon-list">
                                <li><a onClick={() => obj.idoszakValasztasClick(idoszak.id)} className="dropdown-toggle" href="javascript:void(0)"><i className="fa fa-angle-right"></i></a></li>
                              </ul>
                              */}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    ) : (<p>Nem található időszak.</p>)}
                  </div>

                  <div className="col-xs-6">
                    <div className="panel-sub-heading">
                      <h4>Órarendek</h4>
                    </div>

                    {this.state.orarendek.length > 0 ? (
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Név</th>
                            <th>Elrakott órák</th>
                            {/*<th>Feltétel sértések</th>*/}
                            <th>Órarend megnevezés</th>
                            <th>Alapértelmezett</th>
                            <th>Oktatóknak aktív</th>
                            <th>Nappali órarend publikus</th>
                            <th>Levelezős órarend publikus</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.orarendek.map(function (orarend, i) {
                            if (orarend.torolt == 0) {
                              return (
                                <tr key={orarend.id} className={orarend.aktiv ? "warning" : ""}>
                                  <td>{orarend.megnevezes}</td>
                                  <td>{orarend.elrakott_orak_szazalek ? orarend.elrakott_orak_szazalek + "%" : "-"}</td>
                                  {/*<td>{orarend.feltetel_sertesek}</td>*/}
                                  <td>{orarend.adatbazis_megnevezes}</td>
                                  <td>{orarend.alapertelmezett == '1' ? "igen" : "nem"}</td>
                                  <td>{orarend.felevben_oktatoknak_aktiv == '1' ? "igen" : "nem"}</td>
                                  <td>{orarend.nappali_orarend_publikus == '1' ? "igen" : "nem"}</td>
                                  <td>{orarend.levelezos_orarend_publikus == '1' ? "igen" : "nem"}</td>
                                  <td>
                                    <ul className="icon-list">
                                      <li className="dropdown"><a className="dropdown-toggle" href="javascript:void(0)"><i className="fa fa-gear"></i></a>
                                        <ul className="dropdown-menu">
                                          <li className="dropdown-header">Opciók</li>
                                          {obj.checkUserRule('idoszakok/orarend_kivalaszt') ? (<li><a onClick={() => obj.orarendValasztasClick(orarend.id)} href="javascript:void(0)"><i className="fa fa-hand-o-up"></i>Kiválaszt</a></li>) : ''}
                                          {obj.checkUserRule('idoszakok/orarend_szerkeszt') ? (<li><a onClick={() => obj.orarendSzerkesztClick(orarend.id)} href="javascript:void(0)"><i className="fa fa-pencil-square-o"></i>Szerkeszt</a></li>) : ''}
                                          {obj.checkUserRule('idoszakok/orarend_alapertelmezett') ? (<li><a onClick={() => obj.orarendAlapertelmezettreClick(orarend.id)} href="javascript:void(0)"><i className="fa fa-pencil-square-o"></i>Alapértelmezettre</a></li>) : ''}
                                          {obj.checkUserRule('idoszakok/orarend_oktatoknak_aktiv') ? (<li><a onClick={() => obj.orarendOktatoknakAktivraClick(orarend.id)} href="javascript:void(0)"><i className="fa fa-pencil-square-o"></i>Oktatóknak aktívra</a></li>) : ''}
                                          {obj.checkUserRule('idoszakok/orarend_levnapok') ? (<li><a onClick={() => obj.showLevelezoNapModal(orarend.id)} href="javascript:void(0)"><i className="fa fa-eraser"></i>Levelező napok</a></li>) : ''}
                                          {obj.checkUserRule('idoszakok/orarend_specbeall') ? (<li><a onClick={() => obj.refs.specialis_beallitasok_modal.show(orarend.id, obj.state.kivalasztott_idoszak_id)} href="javascript:void(0)"><i className="fa fa-cog"></i>Speciális beállítások</a></li>) : ''}
                                          {obj.checkUserRule('idoszakok/orarend_masolas') ? (<li><a onClick={() => obj.orarendMasol(orarend.id)} href="javascript:void(0)"><i className="fa fa-copy"></i>Másolás</a></li>) : ''}
                                          {obj.checkUserRule('idoszakok/orarend_torol') ? (<li><a onClick={() => obj.orarendTorol(orarend.id)} href="javascript:void(0)"><i className="fa fa-trash"></i>Töröl</a></li>) : ''}
                                          {obj.checkUserRule('idoszakok/orarend_export') ? (<li><a href="javascript:void(0)"><i className="fa fa-file-pdf-o"></i> Export</a></li>) : ''}
                                        </ul>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </tbody>
                      </table>
                    ) : (<p>Nem található órarend.</p>)}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <Modal show={this.state.ujIdoszakModalShow} onHide={this.ujIdoszakModalHide}>
          <Modal.Header closeButton>
            <Modal.Title>Új időszak</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form ref="uj_idoszak_form" onValid={() => { this.setState({ valid_form: true }) }} onInvalid={() => { this.setState({ valid_form: false }) }}>
              <Input name="ujidoszak_nev" value="" title="Új időszak megnevezése" validationError="Megnevezés kötelező" required />
              <Input date={true} name="felev_kezdete" value="" title="Félév kezdete" validationError="Kötelező" required />
              <Input date={true} name="felev_vege" value="" title="Szorgalmi időszak vége" validationError="Kötelező" required />
            </Form>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.ujIdoszakModalHide} type="button">Mégsem</Button>
            <Button onClick={this.ujIdoszakMent} bsStyle="primary">Mentés</Button>
          </Modal.Footer>
        </Modal>
        <UjOrarendModal idoszakok={this.state.idoszakok} idoszakValasztasClick={this.idoszakValasztasClick} loggedUser={this.props.prop_loggedUser} ref="uj_orarend_modal" />
        <IdoszakModal ref="idoszak_modal" />
        <LevelezoNapModal ref="levelezonap_modal" />
        <SpecialisBeallitasokModal idoszakValasztasClick={this.idoszakValasztasClick} ref="specialis_beallitasok_modal" />
      </div>
    );
  }
});

Idoszakok.contextTypes = {
  store: React.PropTypes.object
}

export default Idoszakok;