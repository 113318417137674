import React from 'react';
import Iframe from 'react-iframe';
import { withRouter, hashHistory } from 'react-router';
import Auth from './Auth.js';
import Ajax from './Ajax.js';

var Login = React.createClass({
    getInitialState() {
      return {
        error: false,
        shib: true,
        shibLogged: false,
        showLogin: false,
        loginMessage: null
      }
    },

    componentWillMount: function() {
      var {store} = this.context;
      var obj = this;
      Ajax.call('users/shib_login', store, {
        success: function(data) {
          if(data.loggedin) {
            obj.setState({
              shibLogged: true,
              showLogin: false
            });
            obj.handleSubmit();
          } else {
            Ajax.call('/login_message', store, {
              success: function(response) {
                obj.setState({
                  showLogin: true,
                  loginMessage: response
                });
              }
            });
          }
        }
      });
    },

    handleSubmit(event) {
      var {store} = this.context;

      if(event) {
        event.preventDefault();
      }

      var obj = this;

      $('#loginbtn').removeClass('with-preloader');

      var email = "null";
      var password = "null";

      if (this.refs.email) {
        email = this.refs.email.value;
        password = this.refs.pass.value;
      }

      Auth.login(email, password, this.state.shibLogged, function(response) {
        window.console.log(response);
        if (response.status == "NOACCESS") {
          obj.setState({
            error: response.message,
            showLogin: true,
            shib: false,
            shibLogged: false
          });
        } else if (response.status == "OK") {
          var userdata = response.data.user;

          // State belépett user adatok frissítése
          obj.props.login(userdata.id, userdata.username, userdata.group_id, userdata.user_group, userdata.avatar, userdata.shib_avatar, userdata.neptun, userdata.rules);

          // Ha volt korábban kiválasztott órarendje, akkor beállítja a state-be
          if (userdata.orarend_id != null && userdata.orarend_id != '') {
            obj.props.userSetIdoszak(userdata.idoszak_id, userdata.idoszak_megnevezes, userdata.felev_kezdete, userdata.felev_vege);
            obj.props.userSetOrarend(userdata.orarend_id, userdata.orarend_megnevezes, userdata.adatbazis_megnevezes);

          // Ha nincs kiválasztott órarendje, akkor beállítja az alapértelmezett órarendet
          } else {
            Ajax.call('alapertelm_orarend/' + response.token, store, {
              async: false,
              success: function(data) {
                if (data != false) {
                  obj.props.userSetIdoszak(data.idoszak_id, data.idoszak_megnevezes, data.felev_kezdete, data.felev_vege);
                  obj.props.userSetOrarend(data.orarend_id, data.orarend_megnevezes, data.adatbazis_megnevezes);
                }
              }
            });
          }

          // Token beállítása
          Auth.setToken(response.token);

          // Elnavigálás
          const { location } = obj.props
          if (location.state && location.state.nextPathname) {
            obj.props.router.replace(location.state.nextPathname)
          } else {
            var kezdooldal = 'kiirando_kurzusok';
            Ajax.call('kezdooldal/' + userdata.group_id, store, {
              async: false,
              success: function(data) {
                if (data) {
                  kezdooldal = data.kezdooldal;
                  obj.props.router.replace('/'+kezdooldal);
                }
              }, error: function() {
                obj.props.router.replace('/'+kezdooldal);
              }
            });
          }
        }
      });
    },

    shibLogin: function() {
      var shib = this.state.shib;

      this.setState({
       shib: !shib
      });
    },

    render() {
      var loginBodyClass = "";
      if(this.state.showLogin == true) {
        loginBodyClass = "login-body";
      }
      var alertClass = "alert loginalert";
      if(this.state.loginMessage != null) {
        alertClass = this.state.loginMessage.type + " " + alertClass;
      }
      return (
        <section className={loginBodyClass}>
        {(this.state.shibLogged == false && this.state.showLogin == true && this.state.loginMessage != undefined && this.state.loginMessage != false) ? (
        <div className={alertClass}>{this.state.loginMessage.message}</div>
        ):("")}
        {(this.state.error) ? (
          <Iframe url={location.protocol+"//"+location.host+"/Shibboleth.sso/Logout"} width="0px" height="0px" />
        ) : ("")}
        {(this.state.shib == true && this.state.showLogin == true) ? (
          <section className="shib_login-panel">
            <div className="shib_load"></div>
            <Iframe url={location.protocol+"//"+location.host+"/Shibboleth.sso/Login?target="+location.protocol+"//"+location.host+"/api/users/shib_login"} width="800px" height="655px" />
            <div className="clearfix"></div>
            <button id="shib_loginbtn" className="btn btn-primary pull-right shibback" onClick={this.shibLogin} type="button">Címtár nélküli bejelentkezés</button>
          </section>
        ) : ("")}
        {(this.state.shib == false && this.state.showLogin == true) ? (
          <form onSubmit={this.handleSubmit}>
          <div className="login-panel">
            <div className="login-panel-head">
              <h1><i className="material-icons">access_time</i>RKa<span> - Órarendkészítő </span><span className="smalltext">alkalmazás</span></h1>
              <p>A Széchenyi István Egyetem órarendkészítő felülete.</p>
            </div>
            <div className="login-panel-body">
              <h2>Bejelentkezés</h2>
              {this.state.error && (
                <div className="alert alert-danger"> <strong>Hiba!</strong> {this.state.error}</div>
              )}
              <div className="form-group">
                <label className="form-label">Felhasználó név</label>
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="fa fa-user"></i>
                  </span>
                  <input type="text" ref="email" name="email" className="form-control" placeholder="felhasználó név"/>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">Jelszó</label>
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="fa fa-key"></i>
                  </span>
                  <input type="password" ref="pass" name="password" className="form-control" placeholder="jelszó"/>
                </div>
              </div>
              <div className="form-actions clearfix">
                <div className="checkbox checkbox-inline pull-left">
                  <input id="megjegyzes" type="checkbox" name="megjegyzes" value="1"/>
                  <label htmlFor="megjegyzes">Bejelentkezés megjegyzése</label>
                </div>
                <button id="loginbtn" className="btn btn-primary pull-right" type="submit">Bejelentkezés</button>
                <button style={{"marginRight": "5px"}} id="shib_loginbtn" className="btn btn-primary pull-right" onClick={this.shibLogin} type="button">Bejelentkezés címtárral</button>
              </div>
            </div>
          </div>
          </form>
        ) : ("")}
        </section>
      )
    }
});

Login.contextTypes = {
  store: React.PropTypes.object
}

export default withRouter(Login);
