var base_url = 'api/';

module.exports = {
  login: function(username, password, shib, cb) {
    $.ajax({
      method: "POST",
      url: base_url + 'users/login',
      data: {
        username: username,
        password: password,
        shib: shib
      },
      dataType: "json",
      success: function(response) {
        if ($.isFunction(cb)) {
          cb(response);
        }
      }
    });
  },

  setToken: function(token) {
    if (token == '') {
      this.logout();
    } else {
      localStorage.token = token;
    }
  },

  getToken: function() {
    return localStorage.token
  },

  logout: function() {
    this.deleteToken();
  },

  deleteToken: function() {
    delete localStorage.token
  },

  loggedIn: function() {
    return !!localStorage.token
  },

  checkToken: function(cb) {
    $.ajax({
      method: "GET",
      async: false,
      url: base_url + 'users/check_token/' + localStorage.token,
      dataType: "json",
      success: function(response) {
        cb(response);
      }
    });
  }
};
