import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button, Tabs, Tab} from 'react-bootstrap';
import { Form } from 'formsy-react';
import Select from './form/Select';
import Select2 from './form/Select2';
import Toggle from './form/Toggle';
import Input from './form/Input';
import jQueryHelper from './jQueryHelper';
import ArrayHelper from './ArrayHelper';

var MentesitesekLevelezo = React.createClass({
  getInitialState() {
    return {
      mentesitesek: [],
      oktatok: [{}],
      oktato: {oktato_id: 0},
      activeTab: 1,
      jovahagyasShow: false
    }
  },

  componentWillMount: function() {
    var obj = this;
    var {store} = this.context;
    var activeTab = this.state.activeTab;
    var jovahagyasShow = false;

    if(!obj.props.prop_loggedUser.aktiv_idoszak) {
      store.dispatch({type: 'SET_OLDAL_UZENET', tipus: 'alert alert-danger', uzenet: 'Először válasszon időszakot!'});
      hashHistory.push('/uzenet');
    } else {
      Ajax.call('users/checkruleajax', store, {
        method: 'POST',
        async: false,
        data: {
          user_id: obj.props.prop_loggedUser.id,
          rule: "mentesitesek/jovahagyas"
        },
        success: function(data) {
          if(data.access == 'OK') {
            jovahagyasShow = true;
          }
        }
      });

      Ajax.call('mentesitesek_levelezo/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_idoszak.id + '/' + obj.props.params.tanar_id + '/2', store, {
        async: false,
        success: function(data) {

          obj.setState({
             mentesitesek: data.mentesitesek,
             oktatok: data.tanarok,
             jovahagyasShow: jovahagyasShow
          });
          obj.state.oktato.oktato_id = obj.props.params.tanar_id;

          jQueryHelper.setDropDownToggle(obj);
        }
      });
    }
  },

  componentDidMount: function() {
   jQueryHelper.setFixedMenu(this);
   jQueryHelper.setDropDownToggle(this);
  },

  componentDidUpdate: function() {
    jQueryHelper.setDropDownToggle(this);
  },

  handleSelect: function (activeTab) {

    this.setState({
      activeTab: activeTab
    });

    var obj = this;
    var {store} = this.context;
    var tanszek = this.state.tanszek;
    var oktato = this.state.oktato;

    if(activeTab == 1 || activeTab == 2) {
      var oktato_id = oktato.oktato_id;
      Ajax.call('mentesitesek_levelezo/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_orarend.id + '/' + oktato_id + '/2', store, {
        async: false,
        success: function(data) {
          obj.setState({mentesitesek:data.mentesitesek,oktatok: data.tanarok});
        }
      });
    }
  },

  onChangeOktato: function(val) {
    if(val != this.state.oktato.oktato_id) {
      var obj = this;
      var {store} = this.context;
      this.state.oktato.oktato_id = val;

      Ajax.call('mentesitesek_levelezo/' + localStorage.token + '/' + obj.props.prop_loggedUser.aktiv_orarend.id + '/' + val + '/2', store, {
        async: false,
        success: function(data) {
          obj.setState({mentesitesek:data.mentesitesek});
        }
      });
    }
  },

  toggleClick: function(value,mentesites_id) {

    var obj = this;
    var {store} = this.context;

    Ajax.call('mentesites_jovahagyas', store, {
      method: "POST",
              data: {
                ertek: value,
                mentesites_id: mentesites_id,
                token: localStorage.token
              },
      success: function() {

      }, error: function() {

      }
    });
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillUpdate: function() {
  },

  mentesitesTorol: function(id) {
    var obj = this;
    var {store} = this.context;

    swal({
      title: "Biztos hogy törli a mentesítést?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true
    },
    function() {
      Ajax.call('mentesites/torles/' + localStorage.token + '/' + id, store, {
        success: function(data) {
          var tmp = ArrayHelper.removeItemByFieldAndValue(obj.state.mentesitesek, 'mentesites_id', id);

          obj.setState({
            mentesitesek: tmp
          });
        }
      });
    });
  },

  xlsGeneral: function(){
    var obj = this;
    var {store} = this.context;

    swal({
      title: "XLS fájl generálása",
      text: "Ez a művelet több másodpercet is igénybe vehet!",
      type: "info",
      showCancelButton: true,
      closeOnConfirm: false,
      confirmButtonText: "Folytatás",
      cancelButtonText: "Mégse",
      showLoaderOnConfirm: true
    }, function (){
      Ajax.call('mentesitesek_xlsGeneral/' + localStorage.token + '/' + obj.props.prop_loggedUser.id + '/2/' + obj.props.prop_loggedUser.aktiv_idoszak.felev_kezdete, store, {
        success: function(data){
          if(data.url != ''){
          swal({
            title: "Sikeres generálás!",
            text: "A fájl letöltéséhez kattintson a gombra!",
            type: "success",
            showCancelButton: true,
            confirmButtonClass: "btn-primary",
            confirmButtonText: "Letöltés",
            cancelButtonText: "Bezárás",
            closeOnConfirm: false,
            closeOnCancel: true
          },
          function(isConfirm){
            if (isConfirm) {
              window.open("/api/" + data.url);
            } else {
              Ajax.call('mentesitesek_xlsDelete', store, {
                method: "POST",
                data: {
                  file: data.url,
                  token: localStorage.token,
                  user_id: obj.props.prop_loggedUser.id
                },
                success: function() {

                }, error: function() {

                }
              });
            }
          });
        }
        else{
          swal('Hiba', 'Nincs tárolt mentesítés!', 'error');
        }
        },
        error: function(){
          swal("A generálás nem sikerült!", "Váratlan hiba történt, kérem próbálja újból!", "error");
        }
      });
    });
  },

	render: function() {
    var obj = this;

		return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        {this.state.activeTab == 3 ? ("") : (
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
                <Link className="btn btn-primary" to={"/mentesitesek_levelezo_uj/"+obj.state.oktato.oktato_id}><i className="fa fa-plus"></i>Új mentesítés</Link>
                <button className="btn btn-primary" onClick={() => this.xlsGeneral()}><i className="fa fa-file-excel-o"></i>XLS generálás</button>
            </div>
          </div>
        </div>
        )}
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal / Mentesítések / Levelező mentesítések
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Levelező mentesítések</h1>
            </div>
            <div className="content-panel-top-btn-group">
              {this.state.activeTab == 3 ? ("") : (
                <div>
                  <Link className="btn btn-primary" to={"/mentesitesek_levelezo_uj/"+obj.state.oktato.oktato_id}><i className="fa fa-plus"></i>Új mentesítés</Link>
                  <button className="btn btn-primary" onClick={() => this.xlsGeneral()}><i className="fa fa-file-excel-o"></i>XLS generálás</button>
                </div>
              )}
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Levelező mentesítések</h4>
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-xs-12">
                    <Tabs defaultActiveKey={1} id="tabs" activeKey={this.state.activeTab} onSelect={this.handleSelect}>
                      <Tab eventKey={1} title={<span>Mentesítés rögzítés</span>}>
                        <MentesitesLista {...this.state} mentesitesTorol={this.mentesitesTorol} onChangeOktato={this.onChangeOktato} dropdownIconClick={this.dropdownIconClick} />
                      </Tab>
                      {obj.state.jovahagyasShow == true ? (
                        <Tab eventKey={2} title={<span>Mentesítés jóváhagyás</span>}>
                          <MentesitesLista {...this.state} eventKey={2} onChangeFelvetelTipus={this.onChangeFelvetelTipus} onChangeOktato={this.onChangeOktato} dropdownIconClick={this.dropdownIconClick} toggleClick={this.toggleClick} />
                        </Tab>
                      ) : ("")}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
	}
});

MentesitesekLevelezo.contextTypes = {
  store: React.PropTypes.object
}

var MentesitesLista = React.createClass({
  getInitialState() {
    return {}
  },
  render() {
    var obj = this;

    return (
      <section>
        <div className="row">
          <div className="col-xs-12">
            <div className="panel-sub-heading">
              <h4>Oktató neve</h4>
            </div>
          </div>
        </div>
      <Form ref="form">
        <div className="form-group">
            <div className="row">
              <div className="col-xs-12">
                <Select2 placeholder="Keresés" name="oktato_id" value={this.props.oktato.oktato_id} options={this.props.oktatok} onChange={this.props.onChangeOktato} />
              </div>
            </div>
        </div>
      </Form>
      <div className="panel-sub-heading">
        <h4>Mentesítések listája</h4>
      </div>
      {this.props.mentesitesek.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Mentesítés jogcíme</th>
              <th>Mentesítés tárgya</th>
              <th></th>
            </tr>
          </thead>

          {this.props.mentesitesek.map(mentesites => (
              <Mentesites eventKey={obj.props.eventKey} key={mentesites.id} mentesites={mentesites} mentesitesTorol={this.mentesitesTorol} dropdownIconClick={this.dropdownIconClick} toggleClick={this.toggleClick} {...this.props} />
          ))}

        </table>
      ) : (<div>Nem található a szűrésnek megfelelő egyetlen mentesítés sem.</div>)}
      </section>
    )
  }
});

var Mentesites = React.createClass({
  getInitialState() {
    return {}
  },
  render() {
    var obj = this;

    return (
      <tbody>
      <tr>
        <td>{this.props.mentesites.nev}</td>
        <td>{this.props.mentesites.nap}</td>
        <td style={{"textAlign": "center"}}>
          <ul className="icon-list">
            {obj.props.eventKey !=2 ? (
            <li className="dropdown"><a className="dropdown-toggle" href="javascript:void(0)"><i className="fa fa-gear"></i></a>
              <ul className="dropdown-menu">
                <li className="dropdown-header">Opciók</li>
                <li><Link to={`/mentesites_levelezo/${this.props.mentesites.mentesites_id}`}><i className="fa fa-pencil-square-o"></i>Szerkeszt</Link></li>
                <li><a onClick={() => this.props.mentesitesTorol(this.props.mentesites.mentesites_id)} href="javascript:void(0)"><i className="fa fa-trash"></i>Töröl</a></li>
              </ul>
            </li>
            ) : (
              <Form>
                <Toggle name={"mentesites.aktiv"+this.props.mentesites.id} toggleClick={this.toggleClick} onClick={(value) => this.props.toggleClick(value, this.props.mentesites.mentesites_id)} value={this.props.mentesites.aktiv}/>
              </Form>
            )}
          </ul>
        </td>
      </tr>
      </tbody>
    )
  }
});

export default MentesitesekLevelezo;
