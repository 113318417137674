import React from 'react';
import Ajax from './../../Ajax.js';
import {Form} from 'formsy-react';
import Pagination from './../../Pagination';
import Input from './../../form/Input';

var Teremlista = React.createClass({
  getInitialState() {
    return {
      termek: [],
      lap: 1,
      db: 0,
      limit: 50,
      kerkif: ''
    }
  },

  componentDidMount: function() {
    this.termekLekerese();
  },

  componentDidUpdate: function() {
    $('#teremlista-tabla tbody tr').eq(0).find('td').each(function(i, td) {
        $(td).width( $('#teremlista-tabla thead').find('tr').find('th').eq(i).width() );
    });
  },

  beallitasAlaphelyzetre: function() {
    this.setState({
      termek: [],
      lap: 1,
      db: 0,
      kerkif: ''
    });
  },

  termekLekerese: function(id) {
    var {store} = this.context;
    var _this = this;

    Ajax.call('orarend_szerk/teremlista/' + localStorage.token, store, {
      method: "POST",
      async: false,
      data: {
        lap: _this.state.lap,
        limit: _this.state.limit,
        kerkif: _this.state.kerkif,
        kurzus_id: id
      },
      success: function(data) {
        _this.setState({
          termek: data.termek,
          db: data.db
        });
      }
    });
  },

  render() {
    var _this = this;

    return (
      <div>
      {/*<div className="panel-sub-heading"><h4>Termek</h4></div>*/}

        <div className="row">
          <div className="col-xs-10">
            <Form ref="form">
              <Input intervalTypingCallback={(value) => { _this.setState({lap:1, kerkif:value}, _this.termekLekerese); }} ref="kerkif" name="kerkif" title="Keresés" value={this.state.kerkif}/>
            </Form>
          </div>
          <div className="col-xs-2">
            <button style={{'padding':'10px','margin-top':'15px'}} ref="terem_reset_btn" onClick={()=>{_this.termekLekerese(null)}} className="btn btn-white only-icon" type="button"><i className="fa fa-undo"></i></button>
          </div>
        </div>

        {this.state.termek.length > 0 ? (
          <div className="orarendszerk-teremlista">
            <table style={{'width':'632px'}} id="teremlista-tabla" className="table table-bordered orarendszerk-kurzuslista-table fixed-head">
              <thead style={{'width':'610px'}}>
                <tr>
                  {/*<th style={{'width':'100px'}}>ID</th>*/}
                  <th style={{'width':'40px'}}></th>
                  <th style={{'width':'110px'}}>Kód</th>
                  <th style={{'width':'100px'}}>Kapacítás</th>
                  <th style={{'width':'270px'}}>Telephely</th>
                  <th style={{'width':'270px'}}>Elhelyezkedés</th>
                  <th style={{'width':'100px'}}>Eszközök</th>
                </tr>
              </thead>
              <tbody>
            {this.state.termek.map(function(terem, i) {
                var tr_class = (terem.terem_id == _this.props.terem_id) ? "aktiv" : "";
                tr_class = tr_class + " " + ((terem.tullepes == 1) ? "terem_kapacitas_tullepes" : "");

                return (
                 <tr className={tr_class} key={terem.terem_id}>
                    {/*<td>{terem.terem_id}</td>*/}
                    <td style={{'textAlign':'center','width':'25px'}}><a onClick={()=>{_this.props.swapTeremLampazasModal(parseInt(terem.terem_id),_this.state.termek)}} className="lampazas" href="javascript:void(0)"><i className="fa fa-lightbulb-o"></i></a></td>
                    <td onClick={()=>{_this.props.teremSorClick(parseInt(terem.terem_id), terem.kod)}}>{terem.kod}</td>
                    <td className="kapacitas" onClick={()=>{_this.props.teremSorClick(parseInt(terem.terem_id), terem.kod)}}>{terem.kapacitas}</td>
                    <td className="elhelyezkedes" onClick={()=>{_this.props.teremSorClick(parseInt(terem.terem_id), terem.kod)}}>{terem.telephely}</td>
                    <td className="elhelyezkedes" onClick={()=>{_this.props.teremSorClick(parseInt(terem.terem_id), terem.kod)}}>{terem.elhelyezkedes}</td>
                    <td className="eszkozok" onClick={()=>{_this.props.teremSorClick(parseInt(terem.terem_id), terem.kod)}}>{terem.eszkozok_db}</td>
                  </tr>
                );
            })}
              </tbody>
            </table>
          </div>
          ) : <div>Nem található terem.</div>}

      {/*<div className="clearfix">
          <div style={{'float':'right'}}>
            <Pagination infoHide={true} cb={(lap) => { _this.setState({lap:lap}, _this.termekLekerese); }} db={this.state.db} page={this.state.lap} limit={this.state.limit} />
          </div>
        </div>*/}
      </div>
    )
  }
});

Teremlista.contextTypes = {
  store: React.PropTypes.object
};

export default Teremlista;