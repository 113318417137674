import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import { Form } from 'formsy-react';
import Input from './form/Input';
import InputHidden from './form/InputHidden';
import Select2 from './form/Select2';
import Toggle from './form/Toggle';
import Pagination from './Pagination';

var TeremTarsitas = React.createClass({
  getInitialState() {
    return {
      termek: [],
      szotar_termek: [],
      lap: 1,
      lap_db: 0,
      loading: false,
      limit: 40,
      db: 0
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {
  },

  componentDidMount: function() {
    var _this = this;
    this.teremLekerdezes(1);
  },

  teremLekerdezes: function(elso) {
    var _this = this;
    var {store} = this.context;

    /*if (this.state.loading == false) {
      this.setState({
        loading: true
      });

      $.fn.spin.presets.preloader = {lines:13, length:0, width:3, radius:10, scale:.75, corners:1, opacity:.25, speed:1};
      $(this.refs.preloader).spin('preloader');
      $(this.refs.preloader).show();*/

      //Ajax.call('terem_tarsitas/' + localStorage.token + '/' + this.state.lap + '/' + elso, store, {
      Ajax.call('terem_tarsitas/' + localStorage.token + '/' + this.state.limit + '/' + this.state.lap , store, {
        async: false,
        success: function(data) {
          //$(_this.refs.preloader).hide();

          _this.setState({termek: data.termek, db: data.db, szotar_termek: data.szotar_termek});

          /*if (elso == 1) {
            _this.setState({
              loading: false,
              termek: data.termek,
              szotar_termek: data.szotar_termek,
              lap_db: data.lap_db
            });
          } else {
            var termek = _this.state.termek;
            termek = $.merge(termek, data.termek);
            _this.setState({loading: false, termek:termek});
          }*/
        }
      });
    //}
  },

  onMent: function() {
    var _this = this;
    var {store} = this.context;

    var formData = this.refs.form.getModel();

    Ajax.call('terem_tarsitas/ment/' + localStorage.token, store, {
      method: 'POST',
      data: formData,
      success: function(data) {
        if (data.success) {
          swal("Sikeres!", "Társítások elmentve!", "success");
        }
      }
    });
  },

  szotarTeremValt: function() {

  },

  render: function() {
    var _this = this;

      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Terem társítás
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Terem társítás</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Társítás</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                    <Pagination cb={(lap) => { _this.setState({lap:lap}, _this.teremLekerdezes); }} db={this.state.db} page={this.state.lap} limit={this.state.limit} />
                    <Form ref="form">
                      <div className="row">
                      {this.state.termek.length > 0 && this.state.termek.map(function(terem, i) {
                          return (
                              <section>
                              <div key={i} className="col-xs-3 teremlista">
                                <h5>{terem.nev}</h5>
                                <InputHidden name={`tarsitas[${i}][id]`} value={terem.id}/>
                                <Select2 placeholder="Keresés" onChange={_this.szotarTeremValt} name={`tarsitas[${i}][szotar_id]`} title='Terem neve' value={terem.szotar_id} options={_this.state.szotar_termek} />
                                <Toggle name={`tarsitas[${i}][generalashoz]`} title='Beoszt.' value={terem.generalashoz_hasznalhato}/>
                                <Toggle name={`tarsitas[${i}][fiktiv]`} title='Fiktív' value={terem.fiktiv}/>
                              </div>
                              </section>
                          )
                      })}
                      </div>
                    </Form>
                    <Pagination cb={(lap) => { _this.setState({lap:lap}, _this.teremLekerdezes); }} db={this.state.db} page={this.state.lap} limit={this.state.limit} />
                  </div>
                </div>
                <div ref="more"></div>
                <div style={{'position':'relative','marginBottom':'50px'}}>
                  <div style={{'display':'none'}} className="preloader clearfix" ref="preloader"><div className="txt">Termek töltése...</div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

TeremTarsitas.contextTypes = {
  store: React.PropTypes.object
}

export default TeremTarsitas;