import React from 'react';
import Select from './../form/Select';

var Het = React.createClass({
  getInitialState() {
    return {
      opciok: [
        {
          value: -1,
          label: 'Páros és páratlan'
        },
        {
          value: 0,
          label: 'Csak páratlan'
        },
        {
          value: 1,
          label: 'Csak páros'
        }
      ]
    }
  },

  render: function() {
    return (
      <Select name={`feltetel[ertek][p_${this.props.id}]`} value={this.props.ertek} title={this.props.label} options={this.state.opciok}/>
    );
  }
});

Het.contextTypes = {
  store: React.PropTypes.object
}

export default Het;