import React from 'react';
import Formsy from 'formsy-react';

const Textarea = React.createClass({
  mixins: [Formsy.Mixin],

  getInitialState: function(){
      return {currentValue: this.props.children}
  },

  changeValue(event) {
    this.setValue(event.currentTarget.value);

    if (this.props.onChange != undefined)
      this.props.onChange(event.currentTarget.value);
  },

  render() {
    var className = 'form-group' + (this.props.className || ' ') +
      (this.showRequired() ? 'required' : this.showError() ? 'has-error' : '');

    var errorMessage = this.getErrorMessage();

    if (this.isFormSubmitted()) {
      if (!this.isValid()) {
        className = 'form-group has-error';
        errorMessage = this.getErrorMessage() ? this.getErrorMessage() : this.props.validationError;
      }
    }

    return (
      <div className={className}>
        <label className="form-label" htmlFor={this.props.name}>{this.props.title} {this.isRequired() ? '*' : null}</label>
        <textarea
          disabled={this.props.disabled ? true : false}
          className="form-control"
          name={this.props.name}
          onChange={this.changeValue}
          value={this.getValue()}
        />
        <span className="validation-error">{errorMessage}</span>
      </div>
    );
  }
});

export default Textarea;
