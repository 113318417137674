import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import {Form} from 'formsy-react';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import Select2 from './form/Select2';
import Select from './form/Select';
import Toggle from './form/Toggle';
import {Button} from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import arrayHelper from './ArrayHelper';
import OrarendGeneralasStatusz from './orarend/OrarendGeneralasStatusz';
import Naptar from './sajat_orarend/Naptar';

var SajatOrarend = React.createClass({
  getInitialState() {
    return {
      idopontok: [],
      termek: [],
      terem_id: [],
      oktatok: [],
      oktato_id: [],
      szakok: [],
      szakok_id: 0,
      szakiranyok: [{value:0, label:'- válassz -'}],
      szakirany_id: 0,
      felevek: [],
      felev_id: 0,
      feltetel: 0,
      szakossal: 0,
      megtekintes: true,
      idoszakok: [],
      idoszak_id: 0
    }
  },

  componentDidMount: function() {
    this.getComboAdatok();
  },

  getComboAdatok: function() {
    var _this = this;
    var {store} = this.context;
    var idoszak_id;

    // Első oldalbetöltés
    if (this.state.idoszak_id == 0) {
      // Lekéri, hogy a felhasználóhoz be van -e állítva időszak
      Ajax.call('get_user_idoszak_id/' + parseInt(this.props.prop_loggedUser.id), store, {
        async: false,
        success: function(data) {
          // Nincs beállítva időszak, akkor a loggedUser-ből veszi az időszakot
          if (data.idoszak_id == 0) {
            idoszak_id = _this.props.prop_loggedUser.aktiv_idoszak.id;
          } else {
            idoszak_id = data.idoszak_id;
          }
        }
      });

    // Már van érték az időszak combóban
    } else {
      idoszak_id = this.state.idoszak_id;
    }

    // Felhasználóhoz lementi az időszak combóban kiválasztott ID-t
    Ajax.call('idoszak_id_mentes_felhasznalohoz/' + parseInt(idoszak_id) + '/' + parseInt(this.props.prop_loggedUser.id), store, {
      async: false
    });

    // Átmenetileg beállítja tokenbe a kiválasztott időszakhoz tartozó órarend adatbázis nevet
    Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben_idoszakidbol/' + localStorage.token + '/' + idoszak_id, store, {
      async: false
    });

    this.setState({
      felev_id: 0,
      felev_megn: "",
      oktato_id: [],
      szakirany_id: 0,
      szakirany_megn: "",
      szak_megn: "",
      szak_id: 0,
      szakok_id: 0,
      terem_id: [],
      terem_megn: ""
    });

    Ajax.call('orarend/oktatok/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        _this.setState({
          oktatok: data.oktatok
        }, function() {
          _this.getOktatoIdFromNeptun();
        });
      }
    });

    Ajax.call('orarend/termek/' + localStorage.token, store, {
      async: false,
      method: 'POST',
      success: function(data) {
        _this.setState({
          termek: data.termek
        });
      }
    });

    Ajax.call('orarend/szakok/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        _this.setState({
          szakok: data.szakok
        });
      }
    });

    Ajax.call('orarend/felevek/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        _this.setState({
          felevek: data.felevek
        });
      }
    });

    // Visszaállítja az átmenetileg beállított adatbázis nevet az eredetire
    Ajax.call('adatbazis_megnevezes_valtoztatas_tokenben/' + localStorage.token + '/' + this.props.prop_loggedUser.aktiv_orarend.adatbazis, store, {
      async: false
    });

    // Időszakok combó feltöltése
    Ajax.call('idoszakok_orarend/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        var tmp = [];
        data.idoszakok.map(function(idoszak) {
          tmp.push({
            label: idoszak.megnevezes,
            value: parseInt(idoszak.id)
          });
        });
        _this.setState({
          idoszakok: tmp,
          idoszak_id: idoszak_id
        }, function() {
          _this.updateCalendar();
        });
      }
    });
  },

  getComboItemLabel: function(combo_name, value) {
    var _this = this;
    var label = '';

    if ($.isArray(value)) {
      var tmp = [];
      value.map(function(v) {
        _this.state[combo_name].map(function(item) {
          if (parseInt(item.value) == parseInt(v)) {
            tmp.push(item.label);
          }
        });
      });
      label = tmp.join(', ');

      if (value.length == 0)
        label = '';

    } else {
      this.state[combo_name].map(function(item) {
        if (parseInt(item.value) == parseInt(value)) {
          label = item.label;
        }
      });
      if (value == 0 || value == '')
        label = '';
    }

    return label;
  },

  onSzakComboChange: function(value) {
    var _this = this;
    var {store} = this.context;

    this.setState({
      szak_id: value,
      feltetel: 0,
      szak_megn: this.getComboItemLabel("szakok", value),
      szakirany_id: 0,
      szakirany_megn: ''
    }, function() {
      Ajax.call('orarend/szakiranyok/' + localStorage.token + '/' + value, store, {
        async: false,
        success: function(data) {
          var szakiranyok = [{value:0, label:'- válassz -'}, ...data.szakiranyok];

          _this.setState({
            szakiranyok: szakiranyok
          });
        }
      });
    });
  },

  /**
   * Időpontok lekérdezése
   *
   */
  getIdopontok: function() {
    var _this = this;
    var {store} = this.context;
    var formData = this.refs.form.getModel();

    var felevKezdet = _this.props.prop_loggedUser.aktiv_idoszak.felev_kezdete;
    var felevVege = _this.props.prop_loggedUser.aktiv_idoszak.felev_vege;

    formData.aktiv_orarend_id = this.props.prop_loggedUser.aktiv_orarend.id;
    formData.group_id = this.props.prop_loggedUser.group_id;
    formData.felev_kezdete = this.props.prop_loggedUser.aktiv_idoszak.felev_kezdete;
    formData.idoszak_id = this.state.idoszak_id; // kiválasztott idoszak_id
    formData.rule = "sajat_orarend";

    Ajax.call('idoszak/token_nelkul/' + this.state.idoszak_id, store, {
      method: 'GET',
      async: false,
      success: function(data) {
        felevKezdet = formData.felev_kezdete = data.felev_kezdete;
        felevVege = data.felev_vege;
      }
    });

    if (formData.szak_id == undefined)
      formData.szak_id = 0;

    Ajax.call('orarend/orak/' + localStorage.token, store, {
      method: 'POST',
      async: false,
      data: formData,
      success: function(data) {
        var orak = [];
        data.orak.map(function(ora, i) {
          orak.push({
            tanar: ora.tanar,
            nap: ora.nap,
            periodus: ora.periodus,
            hossz: ora.hossz,
            kurzus_azonosito: ora.kurzus_azonosito,
            tantargy: ora.tantargy,
            kethetente: ora.kethetente,
            terem: ora.terem,
            het: ora.het,
            mentesites: ora.mentesites,
            mentesites_megn: ora.mentesites_megn
          });
        });
        _this.setState({ajax_orak: orak});

        felevKezdet = moment(felevKezdet, "YYYY-MM-DD");
        felevVege = moment(felevVege + ' 23:59:59', "YYYY-MM-DD HH:mm:ss");

        var felevKezdet_orig = felevKezdet;

        var orak = [];

        // A dátumtól beállítódik az időszak kezdő hetének hétfő napjára
        var d = felevKezdet.day() - 1;
        var dt = moment(felevKezdet_orig, "YYYY-MM-DD").subtract(d, 'days');
        var het;

        data.orak.map(function(ora, i) {
          het = 1;
          var datum_tol = moment(dt.format("YYYY-MM-DD") + " " + ora.kezdete, "YYYY-MM-DD HH:mm:ss");
          var datum_ig = moment(dt.format("YYYY-MM-DD") + " " + ora.vege, "YYYY-MM-DD HH:mm:ss");

          // A hétfőhöz viszonyítva hozzáadja azt, hogy melyik napon van az óra
          datum_tol = datum_tol.add(parseInt(ora.nap), 'd');
          datum_ig = datum_ig.add(parseInt(ora.nap), 'd');

          // Félévvége dátumáig rakható ki óra
          while(datum_tol.isBefore(felevVege)) {
            // Félévkezdet utáni a dátum
            if (datum_tol.isAfter(felevKezdet)) {
              var szin = '#6AA4C1';
              var szoveg_szin = '#ffffff';
              var keret_szin = '#6AA4C1';
              var class_name = '';
              var osszevont_kurzusok = '';

              // sértés típus alapján beállított
              if (ora.sertes != undefined) {
                keret_szin = ora.sertes.szin;
                szoveg_szin = ora.sertes.szoveg_szin;
                class_name = 'fc-event-sertes';
              }

              if (ora.egy_szakon_kotelezo != undefined) {
                szin = keret_szin = '#0080FF';

              } else if (ora.tobb_szakon_kotelezo != undefined) {
                szin = keret_szin = '#BF00FF';
              }

              if (ora.egy_szakiranyon_kotelezo != undefined) {
                szin = keret_szin = '#FF8000';

              } else if (ora.tobb_szakiranyon_kotelezo != undefined) {
                szin = keret_szin = '#FFFF00';
                szoveg_szin = '#000000';
              }

              if (ora.tobb_szakon_kotelezo != undefined && ora.egy_szakiranyon_kotelezo != undefined) {
                szin = keret_szin = '#FFFF00';
                szoveg_szin = '#000000';
              }

              if ($.inArray(parseInt(ora.jelleg_id), [63012,63017,63026,63002,63003,63006]) > 0) {
                szin = keret_szin = '#00FFFF';
                szoveg_szin = '#000000';
              }

              // felhasználó által beállított egyedi
              if (ora.hatterszin != '') {
                szin = ora.hatterszin;
                keret_szin = ora.hatterszin;
                class_name = '';
              }

              if (ora.szovegszin != '') // felhasználó által beállított egyedi
                szoveg_szin = ora.szovegszin;

              // Dobozban a feliratok
              var title = ora.kurzus_azonosito + ', ' + ora.tantargy + ', ' + ora.terem + ', (' + ora.max_korlat + ')';
              var tooltip_title = ora.tantargy + "\n" + ora.kurzus_azonosito + "\n" + datum_tol.format("YYYY-MM-DD") + "\n" + datum_tol.format("HH:mm:ss") + " - " + datum_ig.format("HH:mm:ss") + "\n" + ora.terem;

              if (ora.felev != undefined) {
                title = title + ', Félév:' + ora.felev;
              }

              if (ora.szakok_kod != undefined) {
                title = title + '(' + ora.szakok_kod +')';
              }

              if (ora.tanar != undefined && ora.tanar != '')
                title = title + ' (' + ora.tanar + ')';

              if (ora.mentesites != undefined) {
                tooltip_title = title = ora.mentesites_megn;
              }

              var s_ora = {
                //id: ora.ora_id,
                title: title,
                tooltip_title: tooltip_title,
                start: datum_tol.format("YYYY-MM-DD HH:mm:ss"),
                end: datum_ig.format("YYYY-MM-DD HH:mm:ss"),
                editable: false,
                durationEditable: false,
                adatok: ora,
                backgroundColor: szin,
                borderColor: keret_szin,
                textColor: szoveg_szin,
                className: class_name
              };

              if (ora.datum_timestamp != undefined) {
                if (ora.felhasznalt == undefined) {
                  var md = moment.unix(ora.datum_timestamp);

                  s_ora.start = moment(md).format('Y-MM-DD') + ' ' + ora.kezdete;
                  s_ora.end = moment(md).format('Y-MM-DD') + ' ' + ora.vege;
                  orak.push(s_ora);

                  ora.felhasznalt = true;
                }

              } else {
                // Minden héten van óra
                if (ora.kethetente == 0) {
                  orak.push(s_ora);

                } else if (ora.kethetente == 1) {
                  // Egyezik az óra páros/páratlan beállításával, akkor megjelenhet az óra
                  if (parseInt(ora.het) == het) {
                    orak.push(s_ora);
                  }
                }
              }
            }

            datum_tol = datum_tol.add(1, 'w');
            datum_ig = datum_ig.add(1, 'w');
            het = (het + 1) % 2;
          }
        });

        _this.setState({
          idopontok: orak
        });
      }
    });
  },

  generatePDF: function() {
    var _this = this;
    var {store} = this.context;

    $.fn.spin.presets.preloader = {lines:13, length:0, width:3, radius:10, scale:.75, corners:1, opacity:.25, speed:1};
    $(this.refs.pdf_btn).addClass('with-preloader');
    $(this.refs.pdf_btn).spin('preloader');

    Ajax.call('nappali_orarend_pdf/' + localStorage.token, store, {
      method: 'POST',
      data: {
        szak_id: this.state.szak_id,
        szak_megn: this.state.szak_megn != undefined ? this.state.szak_megn : '',
        szakirany_id: this.state.szakirany_id,
        szakirany_megn: this.state.szakirany_megn != undefined ? this.state.szakirany_megn : '',
        felev: this.state.felev_megn != undefined ? this.state.felev_megn : '',
        orak: this.state.ajax_orak,
        oktato_megn: this.state.oktato_megn != undefined ? this.state.oktato_megn : '',
        terem_megn: this.state.terem_megn != undefined ? this.state.terem_megn : '',
        felev_id: this.state.felev_id
      },
      success: function(data) {
        $(_this.refs.pdf_btn).spin(false).removeClass('with-preloader');
        if (data.file != '') {
          window.open("api/libraries/mpdf60/tmp/"+data.file);
        }
        if (data.msg != '') {
          swal('Hiba', data.msg, 'error');
        }
      }
    });
  },

  getOktatoIdFromNeptun: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('get_oktato_id/' + localStorage.token, store, {
      method: 'POST',
      async: false,
      data: {
        neptun: this.props.prop_loggedUser.neptun,
        megtekintes: _this.state.megtekintes
      },
      success: function(data) {
        if(data.oktatoId) {
          _this.setState({
            oktato_id: [parseInt(data.oktatoId)]
          }, function() {
            _this.getIdopontok();
          });
        }
      }
    });
  },

  updateCalendar: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('idoszak/token_nelkul/' + this.state.idoszak_id, store, {
      method: 'GET',
      async: false,
      success: function(data) {
        _this.refs.naptar.updateCalendar(data.felev_kezdete, data.felev_vege);
      }
    });
  },

  render: function() {
    var _this = this;

    if (typeof(this.props.prop_loggedUser.aktiv_orarend) == "undefined")
      return false;

      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">

        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">

        <OrarendGeneralasStatusz loggedUser={this.props.prop_loggedUser} ref="OrarendGeneralasStatusz"/>

          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Órarendek
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Órarendek</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <button ref="pdf_btn" onClick={this.generatePDF} className="btn btn-primary" type="button"><i className="fa fa-file-pdf-o"></i>PDF generálás</button>
            </div>
            <div className="panel">

              <div className="panel-heading">
                <h4>Nappali órarendek</h4>
              </div>
              <div className="panel-body">

                <Form ref="form">
                  <div className="row">
                    <div className="col-xs-3">
                      <Select onChange={(value)=>{_this.setState({'idoszak_id':value, 'idopontok': []}, function() { _this.getComboAdatok(); })}} name="idoszak_id" value={this.state.idoszak_id} title="Időszak" options={this.state.idoszakok}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-3">
                      <Select2 placeholder="Keresés" onChange={(value)=>{_this.setState({'terem_id':value, feltetel: 1, 'terem_megn': this.getComboItemLabel("termek", value)});}} multi={true} name="terem_id" value={this.state.terem_id} title="Terem" options={this.state.termek}/>
                    </div>

                    <div className="col-xs-3">
                      <Select2 placeholder="Keresés" onChange={(value)=>{_this.setState({'oktato_id':value, feltetel: 1, 'oktato_megn': this.getComboItemLabel("oktatok", value)})}} multi={true} name="oktato_id" value={this.state.oktato_id} title="Oktató" options={this.state.oktatok}/>
                    </div>
                    <div className="col-xs-1">
                      <Select ref="feltetel" onChange={(val)=>{_this.setState({feltetel:val})}} name="feltetel" value={this.state.feltetel} title="&nbsp;" options={[{value:0, label:"szűkítés"},{value:1, label:"és"}]}/>
                    </div>
                  </div>
                  <div style={{'marginBottom':'10px'}} className="row">
                    <div className="col-xs-3">
                      <Select2 onChange={this.onSzakComboChange} name="szak_id" value={this.state.szak_id} title="Szak" options={this.state.szakok}/>
                    </div>
                    <div className="col-xs-3">
                      <Select onChange={(value)=>{_this.setState({'szakirany_id':value, 'feltetel': 0, 'szakirany_megn': this.getComboItemLabel("szakiranyok", value)});}} name="szakirany_id" value={this.state.szakirany_id} title="Szakirány" options={this.state.szakiranyok}/>
                    </div>
                    <div className="col-xs-1">
                      <Select onChange={(value)=>{_this.setState({'felev_id':value, 'felev_megn': this.getComboItemLabel("felevek", value)})}} name="felev_id" value={this.state.felev_id} title="Félév" options={this.state.felevek}/>
                    </div>
                    <div className="col-xs-1">
                      <Toggle onChange={(val)=>{_this.setState({szakossal:val})}} name="szakossal" title="Szakos órák" value={this.state.szakossal}/>
                    </div>
                    <div className="col-xs-3">
                      <button ref="nezet_frissit_btn" style={{'marginTop':'12px'}} onClick={this.getIdopontok} className="btn btn-white" type="button"><i className="fa fa-refresh"></i>Frissít</button>
                      <button ref="pdf_btn" style={{'marginTop':'12px','marginLeft':'10px'}} onClick={this.generatePDF} className="btn btn-white" type="button"><i className="fa fa-file-pdf-o"></i>PDF</button>
                    </div>
                    <div className="col-xs-2">
                      <div className="pull-right">
                      </div>
                    </div>
                  </div>
                </Form>

                <div className="row">
                  <div className="col-xs-12">
                  {this.props.prop_loggedUser != undefined ?
                      <Naptar ref="naptar" idopontok={this.state.idopontok} loggedUser={this.props.prop_loggedUser}/> : null
                  }
                  </div>
                </div>
                <div className="color-info">
                  <div className="item"><div style={{'backgroundColor':'#0080FF'}} className="box"></div>Csak egy szaknak kötelező</div>
                  <div className="item"><div style={{'backgroundColor':'#BF00FF'}} className="box"></div>Több szaknak kötelező</div>
                  <div className="item"><div style={{'backgroundColor':'#FF8000'}} className="box"></div>Csak egy szakirányon kötelező</div>
                  <div className="item"><div style={{'backgroundColor':'#FFFF00'}} className="box"></div>Több szakirányon kötelező</div>
                  <div className="item"><div style={{'backgroundColor':'#00FFFF'}} className="box"></div>Szabadon választható</div>
                  <div className="item"><div style={{'backgroundColor':'#00FFFF'}} className="box"></div>Kötelezően választott</div>
                  <div className="item"><div style={{'backgroundColor':'#F7ACAC'}} className="box"></div>Mentesítés</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

SajatOrarend.contextTypes = {
  store: React.PropTypes.object
}

export default SajatOrarend;