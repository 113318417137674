import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import Select from './form/Select';
import Toggle from './form/Toggle';

import Tanar from './feltetel/Tanar';
import Nap from './feltetel/Nap';
import Het from './feltetel/Het';
import Terem from './feltetel/Terem';
import Datum from './feltetel/Datum';
import Epulet from './feltetel/Epulet';
import Kurzus from './feltetel/Kurzus';
import Mintatanterv from './feltetel/Mintatanterv';
import Periodus from './feltetel/Periodus';
import Szam from './feltetel/Szam';
import Eszkoz from './feltetel/Eszkoz';
import Kethetente from './feltetel/Kethetente';
import LevelezosNap from './feltetel/LevelezosNap';
import Szak from './feltetel/Szak';
import Felev from './feltetel/Felev';
import Ismetlodes from './feltetel/Ismetlodes';
import IgenNem from './feltetel/IgenNem';

var Feltetel = React.createClass({
  getInitialState() {
    return {
      feltetel: {
        id: '',
        feltetel_fuggveny_id: '',
        sertes_tipus_id: 2,
        suly: 1,
        megnevezes: '',
        parameter_ertekek: []
      },
      tipusok: [
        {
          value: "",
          label: '- válassz -'
        }
      ],
      fuggvenyek: [
        {
          value: "",
          label: '- válassz -'
        }
      ],
      sertes_tipusok: [
        {
          value: "",
          label: '- válassz -'
        }
      ],
      parameterek: [

      ],
      formIsValid: false,
      kurzus_megjegyzes: '',
      tanarok: [],
      levelezosNap: 'undefined',
      ismetlodes: 0
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {
    var obj = this;
    var {store} = this.context;

    if (obj.props.params.id != undefined) {
      Ajax.call('feltetel/' + localStorage.token + '/' + obj.props.params.id, store, {
        async: false,
        success: function(data) {
          obj.setState({feltetel: data.feltetel});
          obj.tipusokTolt(function() {
            obj.sertesTipusokTolt(function() {
              obj.fuggvenyekTolt(function() {
                obj.parameterBeallitas(data.feltetel.feltetel_fuggveny_id);
              });
            });
          });
        }
      });
    } else {
      obj.tipusokTolt(function() {
        obj.sertesTipusokTolt(function() {
          obj.fuggvenyekTolt(obj.state.feltetel.feltetel_tipus_id);
        });
      });
    }

    if(obj.props.params.redirected == 1) {
      var tanarok = '';
      Ajax.call('/feltetel/kurzusoktato/' + localStorage.token + '/' + obj.props.prop_loggedUser.kivalasztott_kurzus.id, store, {
        async: false,
        success: function(data) {
          tanarok = data.tanarok
        }
      });

      obj.setState({kurzus_megjegyzes: obj.props.prop_loggedUser.kivalasztott_kurzus.megjegyzes, tanarok: tanarok});
    }
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
  },

  tipusokTolt: function(cb) {
    var obj = this;
    var {store} = this.context;

    Ajax.call('feltetel_tipusok/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        data.tipusok = [
          {
            value: "",
            label: '- válassz -'
          },
          ...data.tipusok
        ];
        obj.setState({tipusok: data.tipusok});

        if (cb != undefined && typeof cb == "function") {
          cb();
        }
      }
    });
  },

  sertesTipusokTolt: function(cb) {
    var obj = this;
    var {store} = this.context;

    Ajax.call('sertes_tipusok/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        data.sertes_tipusok = [
          {
            value: "",
            label: '- válassz -'
          },
          ...data.sertes_tipusok
        ];
        obj.setState({sertes_tipusok: data.sertes_tipusok});

        if (cb != undefined && typeof cb == "function") {
          cb();
        }
      }
    });
  },

  fuggvenyekTolt: function(cb) {
    var obj = this;
    var {store} = this.context;

    Ajax.call('feltetel_fuggvenyek/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        data.fuggvenyek = [
          {
            value: "",
            label: '- válassz -'
          },
          ...data.fuggvenyek
        ];
        obj.setState({fuggvenyek: data.fuggvenyek});

        if (cb != undefined && typeof cb == "function") {
          cb();
        }
      }
    });
  },

  onMent: function() {
    var self = this;

    this.refs.form.submit();

    if (this.state.formIsValid) {
      var formData = this.refs.form.getModel();

      var obj = this;
      var {store} = this.context;

      Object.assign(formData.feltetel, {
        id: this.state.feltetel.id
      });

      Ajax.call('/feltetel_ment/' + localStorage.token, store, {
        method: "POST",
        async: false,
        data: {
          feltetel: formData.feltetel
        },
        success: function(data) {
          if(obj.props.params.redirected == 1) {
            hashHistory.push('/kiirt_kurzusok/1');
          } else {
            hashHistory.push('/feltetelek');
          }
        }
      });
    }
  },

  onBack: function() {
    hashHistory.push('/kiirt_kurzusok/1');
  },

  parameterBeallitas: function(fuggveny_id) {
    var obj = this;
    var {store} = this.context;
    var feltetel = obj.state.feltetel;
    feltetel.feltetel_fuggveny_id = fuggveny_id;

    Ajax.call('/feltetel_fuggveny_parameterek/' + localStorage.token + '/' + fuggveny_id, store, {
      async: false,
      success: function(data) {
        obj.setState({
          parameterek: data.parameterek
        });
      }
    });
  },

  parameterNullazas: function() {
    var feltetel = this.state.feltetel;
    feltetel.parameter_ertekek = [];

    this.setState({
      feltetel: feltetel,
      parameterek: [],
      levelezosNap: 'undefined'
    });
  },

  fuggvenyValt: function(value) {
    this.parameterNullazas();
    this.parameterBeallitas(value);
  },

  sertesValt: function(value) {
    var feltetel = this.state.feltetel;
    feltetel.sertes_tipus_id = value;
    this.setState({
      feltetel: feltetel
    });
  },

  levelezosNapValt: function(value) {
    this.setState({
      levelezosNap: value
    });
  },

  parameterErtek: function(param_id) {
    var ertek = '';

    if (this.state.feltetel.parameter_ertekek.length > 0) {
      this.state.feltetel.parameter_ertekek.map(function(parameter_ertek, i) {
        if (parameter_ertek.feltetel_param_id == param_id)
          ertek = parameter_ertek.ertek;
      });
    }

    return ertek;
  },

  onValid: function() {
    this.setState({
      formIsValid: true
    });
  },

  onInvalid: function() {
    this.setState({
      formIsValid: false
    });
  },

  render: function() {
    var self = this;

      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              {this.props.params.redirected == 1 ? (
                <button onClick={this.onBack} className="btn btn-primary" type="button"><i className="fa fa-arrow-left"></i>Vissza a kurzushoz</button>
              ):("")}
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Feltétel
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Feltétel</h1>
            </div>
            <div className="content-panel-top-btn-group">
              {this.props.params.redirected == 1 ? (
                <button onClick={this.onBack} className="btn btn-primary" type="button"><i className="fa fa-arrow-left"></i>Vissza a kurzushoz</button>
              ):("")}
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>{this.props.params.id == undefined ? "Új feltétel" : "Feltétel"}</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                    {this.state.kurzus_megjegyzes || this.state.tanarok.db > 1 ? (
                      <div className="kurzus_megjegyzes_box">
                          {this.state.kurzus_megjegyzes ? (
                            <section>
                            <b>Órarendi igény:</b><br/>
                            <i>{this.state.kurzus_megjegyzes}</i><br/>
                            </section>
                          ):("")}
                          {this.state.tanarok.db > 1 ? (
                            <section>
                            <b>Kurzushoz több oktató tartozik:</b><br/>
                            <i>{this.state.tanarok.nev}</i>
                            </section>
                          ):("")}
                      </div>
                    ):("")}
                    <Form ref="form" onValid={this.onValid} onInvalid={this.onInvalid}>
                      <Input name="feltetel.megnevezes" title="Megnevezés" value={this.state.feltetel.megnevezes}/>
                      <Select onChange={this.fuggvenyValt} name="feltetel.feltetel_fuggveny_id" title="Függvény" value={this.state.feltetel.feltetel_fuggveny_id} validations="isNumeric" options={this.state.fuggvenyek} validationError="Függvény kötelező" required />
                      <Select onChange={this.sertesValt} name="feltetel.sertes_tipus_id" title="Sértés" value={this.state.feltetel.sertes_tipus_id} validations="isNumeric" options={this.state.sertes_tipusok} validationError="Sértés kötelező" required />
                      <Input name="feltetel.suly" title="Súly" value={this.state.feltetel.suly} validations="isNumeric" validationError="Súly kötelező" required />
                      {this.state.parameterek.length > 0 ?
                      <div className="panel-sub-heading">
                        <h4>Paraméterek</h4>
                      </div>
                      : null}
                      {this.state.parameterek.length > 0 && this.state.parameterek.map(function(parameter, i) {
                          var elem;
                          var kurzusErtek;
                          var ertek = self.parameterErtek(parameter.id);

                          switch(parameter.feltetel_tipus) {
                            case "Oktató":
                            if(ertek == '' && (self.state.tanarok.db && self.state.tanarok.db == 1) ) {
                                ertek = parseInt(self.state.tanarok.id);
                              }
                              elem = <Tanar id={parameter.id} key={i} label={parameter.label} ertek={ertek}/>
                              break;
                            case "Nap":
                              elem = <Nap id={parameter.id} key={i} label={parameter.label} ertek={ertek} altipus={parameter.altipus} aktiv_idoszak_id={self.props.prop_loggedUser.aktiv_idoszak.id}/>
                              break;
                            case "Hét":
                              elem = <Het id={parameter.id} key={i} label={parameter.label} ertek={ertek}/>
                              break;
                            case "Dátum":
                              elem = <Datum id={parameter.id} key={i} label={parameter.label} ertek={ertek}/>
                              break;
                            case "Terem":
                              elem = <Terem id={parameter.id} key={i} label={parameter.label} ertek={ertek}/>
                              break;
                            case "Épület":
                              elem = <Epulet id={parameter.id} key={i} label={parameter.label} ertek={ertek}/>
                              break;
                            case "Kurzus":
                              if(ertek == '' && self.props.prop_loggedUser.kivalasztott_kurzus) {
                                ertek = parseInt(self.props.prop_loggedUser.kivalasztott_kurzus.id);
                              }
                              elem = <Kurzus id={parameter.id} key={i} label={parameter.label} ertek={ertek}/>
                              break;
                            case "Mintatanterv":
                              elem = <Mintatanterv id={parameter.id} key={i} label={parameter.label} ertek={ertek}/>
                              break;
                            case "Periódus":
                              elem = <Periodus ismetlodes={self.state.ismetlodes} id={parameter.id} key={i} label={parameter.label} altipus={parameter.altipus} fgv_id={self.state.feltetel.feltetel_fuggveny_id} ertek={ertek} levelezos_nap={self.state.levelezosNap} />
                              break;
                            case "Eszköz":
                              elem = <Eszkoz id={parameter.id} key={i} label={parameter.label} ertek={ertek}/>
                              break;
                            case "Szám":
                              elem = <Szam id={parameter.id} key={i} label={parameter.label} ertek={ertek}/>
                              break;
                            case "Kéthetente":
                              elem = <Kethetente id={parameter.id} key={i} label={parameter.label} ertek={ertek}/>
                              break;
                            case "Levelezős Nap":
                              elem = <LevelezosNap ismetlodes={self.state.ismetlodes} id={parameter.id} key={i} aktiv_orarend_id={self.props.prop_loggedUser.aktiv_orarend.id} label={parameter.label} cb={self.levelezosNapValt} ertek={ertek}/>
                              break;
                            case "Szak":
                              elem = <Szak id={parameter.id} key={i} label={parameter.label} fgv_id={self.state.feltetel.feltetel_fuggveny_id} ertek={ertek}/>
                              break;
                            case "Félév":
                              elem = <Felev id={parameter.id} key={i} label={parameter.label} ertek={ertek}/>
                              break;
                            case "Ismétlődés":
                              elem = <Ismetlodes id={parameter.id} key={i} label={parameter.label} ertek={ertek} onChange={(val)=>{self.setState({ismetlodes:val})}} />
                              break;
                            case "Igen/Nem":
                              elem = <IgenNem id={parameter.id} key={i} label={parameter.label} ertek={ertek}/>
                              break;
                          }

                          return elem;
                      })}
                    </Form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

Feltetel.contextTypes = {
  store: React.PropTypes.object
}

export default Feltetel;