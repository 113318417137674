import React from 'react';
import Ajax from './../../Ajax.js';
import {Form} from 'formsy-react';
import { Modal, Button } from 'react-bootstrap';
import Select2 from './../../form/Select2';
import Input from './../../form/Input';

var SzakszuroCsoportModal = React.createClass({
  getInitialState() {
    return {
      csoportok: [],
      szakok: [],
      kivalasztott_szakok: [],
      aktiv_csoport_id: 0,
      uj_csoport_nev: ''
    }
  },

  componentDidMount: function() {
    $('#szakszuro_csoport_modal').draggable({
      start: function( event, ui ) {
        $('#szakszuro_csoport_modal').addClass('startdrag');
      }
    });
    this.ablakPozicio();
    this.props.getKurzuslista();
  },

  ablakPozicio: function() {
    $(this.refs.szakszuro_csoport_modal).css({
      'left': $(window).innerWidth() / 2 - parseInt($('.main-panel').css('padding-left')),
      'top': $(window).scrollTop() + $(window).innerHeight() / 2 - parseInt($('.main-panel').css('padding-top')) - 200
    });
  },

  ment: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('/orarend_szerk/szak_csoport_szuro_ment/' + localStorage.token + '/' + this.props.loggedUser.id + '/0', store, {
      async: false,
      method: 'POST',
      data: {
        csoportok: JSON.stringify(this.state.csoportok),
        user_id: _this.props.loggedUser.id
      },
      success: function(data) {
        swal("Siker!", "Módosítások mentve.", "success");
        _this.getCsoportokEsSzakok();
      }
    });
  },

  getCsoportokEsSzakok: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('/orarend/szakok/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        if (data.szakok.length > 0) {
          _this.setState({
            szakok: data.szakok
          });
        }
      }
    });

    // Lekéri a felhasználó alapértelmezett szűrő csoportID-t
    var alapertelmezett_csoport_id = 0;
    Ajax.call('orarend_szerk/get_alapertelmezett_szuro/' + localStorage.token + '/' + _this.props.loggedUser.id + '/0', store, {
      async: false,
      success: function(data) {
        alapertelmezett_csoport_id = data.aktiv_csoport_id;
      }
    });

    Ajax.call('/orarend_szerk/szak_csoport_szuro/' + localStorage.token + '/' + this.props.loggedUser.id + '/0', store, {
      async: false,
      success: function(data) {
        var aktiv_csoport_id = 0;
        var kivalasztott_szakok = [];

        if (alapertelmezett_csoport_id != 0) {
          aktiv_csoport_id = alapertelmezett_csoport_id;
        } else {
          aktiv_csoport_id = data.csoportok.length > 0 ? data.csoportok[0].id : 0;
          kivalasztott_szakok = data.csoportok.length > 0 ? data.csoportok[0].szakok : [];
        }

        data.csoportok.map(function(csoport, i) {
          if (csoport.id == aktiv_csoport_id)
            kivalasztott_szakok = csoport.szakok;
        });

        _this.setState({
          csoportok: data.csoportok,
          aktiv_csoport_id: aktiv_csoport_id,
          kivalasztott_szakok: kivalasztott_szakok
        });
      }
    });
  },

  csoportValt: function(csoport_id) {
    var _this = this;

    var kivalasztott_szakok = [];
    this.state.csoportok.map(function(csoport, i) {
      if (csoport.id == csoport_id) {
        kivalasztott_szakok = csoport.szakok;
      }
    });

    _this.setState({
      kivalasztott_szakok: kivalasztott_szakok,
      aktiv_csoport_id: csoport_id
    });
  },

  szakChange: function(value) {
    var _this = this;

    var kivalasztott_szakok = [];
    this.state.csoportok.map(function(csoport, i) {
      if (csoport.id == _this.state.aktiv_csoport_id) {
        csoport.szakok = value;
        kivalasztott_szakok = value;
      }
    });

    this.setState({
      csoportok: this.state.csoportok,
      kivalasztott_szakok: kivalasztott_szakok
    });
  },

  csoportTorles: function(csoport_id) {
    var _this = this;

    var csoportok = [];
    this.state.csoportok.map(function(csoport, i) {
      if (csoport.id != csoport_id) {
        csoportok.push(csoport);
      }
    });

    this.setState({
      csoportok: csoportok,
      aktiv_csoport_id: csoportok.length == 0 ? 0 : this.state.aktiv_csoport_id,
      kivalasztott_szakok: csoportok.length == 0 ? [] : this.state.kivalasztott_szakok
    }, function() {
      // Ha a kiválasztott csoportot törli, akkor beállítja az első csoportra az aktív csoportot
      if (_this.state.csoportok.length > 0 && csoport_id == _this.state.aktiv_csoport_id) {
        _this.csoportValt(_this.state.csoportok[0].id);
      }
    });
  },

  ujCsoport: function() {
    var _this = this;

    this.setState({
      csoportok: [...this.state.csoportok, {
         id: Math.floor((Math.random() * 1000000) + 1),
         levelezo: "0",
         nev: _this.state.uj_csoport_nev,
         user_id: this.props.loggedUser.id,
         szakok: []
      }],
      uj_csoport_nev: ""
    }, function() {
      _this.csoportValt(_this.state.csoportok[(_this.state.csoportok.length - 1)].id);
    });
  },

  csoportNevModosit: function(csoport_id, nev) {
    this.state.csoportok.map(function(csoport, i) {
      if (csoport.id == csoport_id) {
        csoport.nev = nev;
      }
    });

    this.setState({
      csoportok: this.state.csoportok
    });
  },

  szurobe: function() {
    var _this = this;
    var {store} = this.context;

    if (this.state.kivalasztott_szakok.length == 0) {
      swal("Hiba!", "Válasszon szakot a csoporthoz.", "error");
    } else {
      var arr = [{
          'label': 'Összes',
          'value': null
      }];
      this.state.szakok.map(function(szak, i) {
        if ($.inArray(szak.value, _this.state.kivalasztott_szakok) != -1) {
          arr.push({
            'label': szak.label,
            'value': szak.value
          });
        }
      });

      Ajax.call('/orarend_szerk/csoport_szuro_alapertelmezettre/' + localStorage.token + '/' + this.props.loggedUser.id + '/' + _this.state.aktiv_csoport_id + '/0', store, {
        async: false,
        method: 'POST',
        data: {
        },
        success: function(data) {
          _this.props.setFelhasznaloSzakok(arr, _this.state.aktiv_csoport_id, true, function() {
            swal("Siker!", "Szűrőbe beállítódtak a szakok.", "success");
            _this.props.getKurzuslista();
          });
        }
      });
    }
  },

  szuroki: function() {
    var {store} = this.context;
    var _this = this;

    Ajax.call('/orarend_szerk/csoport_szuro_alapertelmezett_torles/' + localStorage.token + '/' + this.props.loggedUser.id + '/0', store, {
      async: false,
      method: 'POST',
      data: {
      },
      success: function(data) {
        _this.props.setFelhasznaloSzakok(_this.state.szakok, 0, false, function() {
          _this.props.hide();
          _this.props.getKurzuslista();
        });
      }
    });
  },

  render: function() {
    var _this = this;

    return (
      <Modal className="szak-szuro-modal" show={this.props.szakszuro_csoport_modal} onHide={this.props.hide}>
        <Modal.Header closeButton>
          <Modal.Title>Szak csoport előszűrő</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref="szak_szuro_csoport_form">
            <div className="row">
              <div className="col-xs-7">
              {this.state.aktiv_csoport_id != 0 ? (<Select2 onChange={this.szakChange} name="szakok" value={this.state.kivalasztott_szakok} multi={true} title="Szakok" options={this.state.szakok} />) : 'Hozzon létre csoportot.'}
              </div>
              <div className="col-xs-5">
                {this.state.csoportok.length > 0 && this.state.csoportok.map(function(csoport, i) {
                  return (
                    <div className={(csoport.id == _this.state.aktiv_csoport_id) ? 'group-filter clearfix selected' : 'group-filter clearfix'} key={i}>
                      <div className="name"><Input onChange={(val)=>{_this.csoportNevModosit(csoport.id, val)}} name="csoportnev[]" value={csoport.nev}/></div>
                      <div className="buttons">
                        <button onClick={()=>{_this.csoportValt(csoport.id)}} className="btn btn-white only-icon small" type="button"><i className="fa fa-hand-pointer-o"></i></button>
                        <button onClick={()=>{_this.csoportTorles(csoport.id)}} className="btn btn-white only-icon small" type="button"><i className="fa fa-trash"></i></button>
                      </div>
                    </div>
                  );
                })}
                <div className="new-group-filter clearfix">
                  <Input onChange={(val)=>{_this.setState({uj_csoport_nev: val})}} name="uj_csoport_nev" value={this.state.uj_csoport_nev}/>
                  <div className="buttons">
                    <button onClick={()=>{_this.ujCsoport()}} className="btn btn-white only-icon small" type="button"><i className="fa fa-plus"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.hide} type="button">Bezár</Button>
          <button onClick={this.szurobe} className="btn btn-primary">Kiválasztott csoport szűrőbe</button>
          <button onClick={this.szuroki} className="btn btn-primary">Szűrő kikapcsol</button>
          <button onClick={this.ment} className="btn btn-primary">Ment</button>
        </Modal.Footer>
      </Modal>
    )
  }
});

SzakszuroCsoportModal.contextTypes = {
  store: React.PropTypes.object
};

export default SzakszuroCsoportModal;