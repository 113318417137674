import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button, Tabs, Tab} from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import InputHidden from './form/InputHidden';
import Select from './form/Select';
import Select2 from './form/Select2';
import Textarea from './form/Textarea';
import Checkbox from './form/Checkbox';
import Toggle from './form/Toggle';

var MentesitesNappali = React.createClass({
  getInitialState() {
    return {
      jogcimek: [],
      jogcim_adatok: [],
      mentesites: [],
      oktatok: [],
      mentesites_id: '',
      nap_id: '',
      periodus_id: '',
      myform: {},
      checkedBoxes: [],
      add10ment: true,
      kisgyermek: false,
      gyermek_modal: false,
      akt_oktato: 0,
      gyermekek: [],
      egyedulnevelo: 0,
      napok: [
        { value: '', label: '- válassz -' },
        { value: '0', label: 'Hétfő' },
        { value: '1', label: 'Kedd' },
        { value: '2', label: 'Szerda' },
        { value: '3', label: 'Csütörtök' },
        { value: '4', label: 'Péntek' },
      ],
      doktorinapok: [
        { value: '', label: '- válassz -' },
        { value: '7', label: 'Kedd és Szerda (9-13. óra)' },
        { value: '4', label: 'Péntek (1-5. óra)' },
      ],
      felnapok: [
        { value: '', label: '- válassz -' },
        { value: '1', label: '1-6. óra' },
        { value: '7', label: '7-13. óra' },
      ],
      idokezd: [],
      idoveg: [],
      jogcim: {
        id: '',
        nev: '',
        ido_tipus: '',
        alkalom: ''
      },
      formIsValid: false
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {
    var obj = this;
    var {store} = this.context;

    this.state.mentesites.tanar_id = '';

    if (obj.props.params.id == undefined) {
      obj.props.params.id = 0;
    }

    var akt_oktato = 0;
    if(this.state.mentesites.tanar_id) {
      akt_oktato = this.state.mentesites.tanar_id;
    } else if(parseInt(this.props.params.tanar_id)) {
      akt_oktato = parseInt(this.props.params.tanar_id);
    }

    if (obj.props.params.id != 0) {
      Ajax.call('mentesites_nappali/tanar_id_mentesites_id/' + localStorage.token + '/' + obj.props.params.id, store, {
        method: "GET",
        async: false,
        success: function(data) {
          akt_oktato = data.tanar_id
        }
      });
    }

    Ajax.call('mentesites_nappali/' + localStorage.token + '/' + obj.props.params.id + '/1/' + akt_oktato + '/' + obj.props.prop_loggedUser.aktiv_idoszak.id + '/' + obj.props.prop_loggedUser.id + '/' + obj.props.prop_loggedUser.group_id, store, {
      async: false,
      success: function(data) {
        data.jogcimek = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.jogcimek
        ];
        data.idokezd = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.idokezd
        ];
        data.idoveg = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.idoveg
        ];

        var add10ment = false;
        var kisgyermek = data.kisgyermek
        if(data.kisgyermek) {
          add10ment = true;
        }

        obj.setState({jogcimek: data.jogcimek, jogcim_adatok: data.jogcim_adatok, idokezd: data.idokezd, idoveg: data.idoveg, oktatok: data.oktatok, kisgyermek: kisgyermek, add10ment: add10ment, akt_oktato: akt_oktato, egyedulnevelo: data.egyedulnevelo}, function() {
          if (data.mentesites != undefined) {
            var jogcim = obj.state.jogcim_adatok[data.mentesites.mentesites_jogcim_id];
                                
            if(jogcim != undefined && jogcim.id == 5) {
              var name = '';
              for(var j=1;j<=4;j++) {
                if(data.mentesites.idopontok["napja"+j] != undefined) {
                  //for(var k=0;k<=1;k++) {
                    if(data.mentesites.idopontok["orak"+j] != undefined) {
                      name = data.mentesites.idopontok["napja"+j] + '_' + data.mentesites.idopontok["orak"+j];
                      obj.checkboxClick(name,1);
                    }
                  //}
                }
              }
            }

            obj.setState({mentesites: data.mentesites, mentesites_id: data.mentesites.id, jogcim: jogcim});
          }
        });
      }
    });
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
  },

  onChangeJogcim: function(val) {
    var obj = this;
    if(val.length > 0) {
      var jogcim = this.state.jogcim_adatok[val];
      this.setState({
       jogcim: jogcim
      });
    } else {
      this.setState({
        jogcim: {
          id: '',
          nev: '',
          ido_tipus: '',
          alkalom: ''
        }
      });
    }
  },

  onChangeOktato: function(val) {
    var obj = this;
    var {store} = this.context;

    if(this.state.mentesites.id === undefined || this.state.mentesites.id == 0) {

      Ajax.call('mentesites_jogcimek/' + localStorage.token + '/1/' + val, store, {
        async: false,
        success: function(data) {
          data.jogcimek = [
            {
              value: '',
              label: '- válassz -'
            },
            ...data.jogcimek
          ];

          obj.setState({jogcimek: data.jogcimek, jogcim_adatok: data.jogcim_adatok, akt_oktato: val});

          if (data.mentesites != undefined) {
            var jogcim = obj.state.jogcim_adatok[data.mentesites.mentesites_jogcim_id];
            obj.setState({mentesites: data.mentesites, mentesites_id: data.mentesites.id, jogcim: jogcim});
          }

          //console.log(data.jogcimek[obj.state.jogcim.id]);

          if(data.jogcimek[obj.state.jogcim.id] == undefined) {
            obj.setState({
              jogcim: {
                id: '',
                nev: '',
                ido_tipus: '',
                alkalom: ''
              }
            });
          }

          obj.checkKisgyermek(val);
        }
      });

    }
  },

  checkKisgyermek: function(val) {
    var obj = this;
    var {store} = this.context;

    Ajax.call('mentesites_nappali/' + localStorage.token + '/' + obj.props.params.id + '/1/' + val + '/' + obj.props.prop_loggedUser.aktiv_idoszak.id + '/' + obj.props.prop_loggedUser.id + '/' + obj.props.prop_loggedUser.group_id, store, {
      async: false,
      success: function(data) {
        var add10ment = false;
        var kisgyermek = data.kisgyermek
        if(data.kisgyermek) {
          add10ment = true;
        }

        obj.setState({kisgyermek: kisgyermek, add10ment: add10ment, egyedulnevelo: data.egyedulnevelo});

        if (data.mentesites != undefined) {
          if(jogcim.id == 5) {
            var name = '';
            for(var j=1;j<=4;j++) {
              if(data.mentesites.idopontok["napja"+j] != undefined) {
                for(var k=0;k<=1;k++) {
                  if(data.mentesites.idopontok["orak"+k] != undefined) {
                    name = data.mentesites.idopontok["napja"+j] + '_' + data.mentesites.idopontok["orak"+k];
                    obj.checkboxClick(name,1);
                  }
                }
              }
            }
          }
        }
      }
    });
  },

  onMent: function() {
    var self = this;
    var akt_oktato = 0;
    var darab = 2;
    if(this.state.egyedulnevelo) {
      darab = 4;
    }

    if(this.state.jogcim.id == 5 && (!this.state.kisgyermek || !this.state.checkedBoxes.length)) {
      if(!this.state.kisgyermek) {
        swal("Nem rögzíthet ilyen mentesítést!", "A kiválasztott oktatóhoz nincs kisgyermek rögzítve!", "error");
      } else if(!this.state.checkedBoxes.length) {
        swal("Nincs mit rögzteni!", "Nem adott meg egy időpontot sem!", "warning");
      }
    } else if(this.state.jogcim.id == 5 && this.state.checkedBoxes.length > darab) {
      swal("Nem rögzíthet ennyi alkalmat!", "Több alkalmatt adott meg, mint amennyit a kiválasztot oktató kaphat!", "error");
    } else {

      if(this.state.mentesites.tanar_id) {
        akt_oktato = this.state.mentesites.tanar_id;
      } else if(parseInt(this.props.params.tanar_id)) {
        akt_oktato = parseInt(this.props.params.tanar_id);
      }

      this.refs.form.submit();

      if (this.state.formIsValid) {
        var formData = this.refs.form.getModel();
        formData.loggedUser_group = this.props.prop_loggedUser.group_id;

        var obj = this;
        var {store} = this.context;

        Ajax.call('mentesites_nappali/ment/' + localStorage.token, store, {
          method: "POST",
          async: false,
          data: {
            data: formData
          },
          success: function(data) {
            if(data.error_msg) {
              swal("Nem rögzíthet ilyen mentesítést!", data.error_msg, "error");
              obj.setState({
                jogcim: {
                  id: '',
                  nev: '',
                  ido_tipus: '',
                  alkalom: ''
                }
              });
            } else {
              hashHistory.push('/mentesitesek_nappali/' + akt_oktato);
            }
          }
        });
      }
    }
  },

  onBack: function() {
    var akt_oktato = 0;
    if(this.state.mentesites.tanar_id) {
      akt_oktato = this.state.mentesites.tanar_id;
    } else if(parseInt(this.props.params.tanar_id)) {
      akt_oktato = parseInt(this.props.params.tanar_id);
    }

    hashHistory.push('/mentesitesek_nappali/' + akt_oktato);
  },

  onValid: function() {
    this.setState({
      formIsValid: true
    });
  },

  onInvalid: function() {
    this.setState({
      formIsValid: false
    });
  },

  checkboxClick: function(name,load) {

    if(this.state.checkedBoxes.indexOf(name) < 0) {
      if(this.state.kisgyermek) {
        if(this.state.add10ment) {
          this.state.checkedBoxes.push(name);
        } else {
          swal("Nem adhat meg több időpontot!", "", "warning");
        }
      } else {
        if(load) {
          this.state.checkedBoxes.push(name);
        }
        swal("Nem adhat meg időpontot!", "Nincs 10 év alatti gyermek rögzítve!", "warning");
      }
    } else {
      var index = this.state.checkedBoxes.indexOf(name);
      this.state.checkedBoxes.splice(index, 1);
    }

    var enabled = false;
    var darab = 2;
    if(this.state.egyedulnevelo) {
      darab = 4;
    }
    if(this.state.checkedBoxes.length < darab && this.state.kisgyermek) {
      enabled = true;
    }

    this.setState({
      add10ment: enabled
    });

  },

  checkChecked: function(name) {

    var aktname = '';
    var checked = false;
    for(var j=0;j<=4;j++) {
      if(this.state.mentesites !== undefined && this.state.mentesites.idopontok !== undefined) {
        aktname = this.state.mentesites.idopontok["napja"+j] + '_' + this.state.mentesites.idopontok["orak"+j];
        if(aktname == name) {
          checked = true;
          break;
        }
      }
    }

    if(checked) {
      return 1;
    } else {
      return 0;
    }

  },

  gyermekModalShow: function() {
    this.getGyermekek();
  },

  gyermekModalHide: function() {
    var _this = this;

    this.checkKisgyermek(this.state.akt_oktato);

    this.setState({
      gyermek_modal: false
    });
  },

  getGyermekek: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('mentesites_nappali/gyermekek/' + localStorage.token + '/' + obj.state.akt_oktato, store, {
      method: "GET",
      async: false,
      success: function(data) {
        obj.setState({
          gyermek_modal: true,
          gyermekek: data.gyermekek
        });
      }
    });
  },

  ujGyermek: function() {
    var obj = this;
    var {store} = this.context;

    var formData = this.refs.gyermekek_form.getModel();

    if(!formData.uj_gyermek_nev || !formData.uj_gyermek_szulido) {
      swal("Hiányzó adat!", "Minden adat megadása kötelező!", "warning");
    } else {
      Ajax.call('mentesites_nappali/gyermek_add/' + localStorage.token + '/' + obj.state.akt_oktato, store, {
        method: "POST",
        async: false,
        data: {
          data: formData
        },
        success: function(data) {
          obj.getGyermekek();
          obj.refs.uj_gyermek_nev.clearValue();
          obj.refs.uj_gyermek_szulido.clearValue();
        }
      });
    }
  },

  delGyermek: function(id) {
    var obj = this;
    var {store} = this.context;

    Ajax.call('mentesites_nappali/gyermek_del/' + localStorage.token + '/' + id, store, {
      method: "GET",
      async: false,
      success: function(data) {
        obj.getGyermekek();
      }
    });
  },

  onChangeEgyedulnevelo: function(val) {
    var obj = this;
    var {store} = this.context;

    Ajax.call('mentesites_nappali/egyedulnevelo/' + localStorage.token + '/' + obj.state.akt_oktato + '/' + obj.props.prop_loggedUser.aktiv_idoszak.id, store, {
      method: "POST",
      async: false,
      data: {
        egyedulallo: val
      },
      success: function(data) {
        var enabled = false;
        var darab = 2;
        if(val) {
          darab = 4;
        }
        if(obj.state.checkedBoxes.length < darab && obj.state.kisgyermek) {
          enabled = true;
        }

        obj.setState({
          egyedulnevelo: val, add10ment: enabled
        });
      }
    });
  },

  render: function() {
      var inputMezok = []
      if(this.state.jogcim.id == 10) {
        inputMezok.push(
          <section key={"megnevezes"}>
            <Input title="Mentesítés oka" key={"mentesites.megnevezes"} name={"mentesites.megnevezes"} value={this.state.mentesites.megnevezes} validationError="Ok kötelező" required />
          </section>
        )
      } else {
        inputMezok.push(
          <section key={"megnevezes"}>
            <InputHidden key={"mentesites.megnevezes"} name={"mentesites.megnevezes"} value={this.state.jogcim.nev}/>
          </section>
        )
      }
      for(var i=1;i<=this.state.jogcim.alkalom;i++) {
        if(this.state.jogcim.alkalom == 1) {
          if(this.state.jogcim.id == 2 || this.state.jogcim.id == 3) {
            inputMezok.push(
              <section key={"sec"+i}>
              <Select key={"mentesites.napja_dis"+i} name={"mentesites.napja_dis"+i} title="Nap" value={0} options={this.state.napok} validations="isNumeric" validationError="Nap kötelező" disabled />
              <InputHidden key={"mentesites.napja_dis_h"+i} name={"mentesites.napja"+i} value={0}/>
              </section>
            )
          } else if (this.state.jogcim.id == 4) {
            if(this.state.mentesites.idopontok !== undefined && this.state.mentesites.idopontok["napja"+i] == 1) {
              this.state.mentesites.idopontok["napja"+i] = 7;
            }
            inputMezok.push(
                <section key={"sec"+i}>
                  <Select key={"mentesites.napja"+i} name={"mentesites.napja"+i} title="Időpont" value={this.state.mentesites.idopontok !== undefined ? this.state.mentesites.idopontok["napja"+i] : ''} options={this.state.doktorinapok} validations="isNumeric" validationError="Időpont kötelező" required />
                </section>
              )
          } else if (this.state.jogcim.id == 11) {
            inputMezok.push(
                <section key={"sec"+i}>
                  <Select key={"mentesites.napja"+i} name={"mentesites.napja"+i} title="Nap" value={this.state.mentesites.idopontok !== undefined ? this.state.mentesites.idopontok["napja"+i] : ''} options={this.state.napok} validations="isNumeric" validationError="Nap kötelező" required />
                  <Select key={"mentesites.idokezd_veg"+i} name={"mentesites.idokezd_veg"+i} title="Időpont" value={this.state.mentesites.idopontok !== undefined ? this.state.mentesites.idopontok["idokezd"+i] : ''} options={this.state.felnapok} validations="isNumeric" validationError="Időpont kötelező" required />
                </section>
              )
          } else {
            if(this.state.jogcim.ido_tipus == 1) {
              inputMezok.push(
                <Select key={"mentesites.napja"+i} name={"mentesites.napja"+i} title="Nap" value={this.state.mentesites.idopontok !== undefined ? this.state.mentesites.idopontok["napja"+i] : ''} options={this.state.napok} validations="isNumeric" validationError="Nap kötelező" required />
              )
            } else {
              if(this.state.jogcim.fix == 1) {
                inputMezok.push(
                  <section key={"sec"+i}>
                  <Select key={"mentesites.napja_dis"+i} name={"mentesites.napja_dis"+i} title="Nap" value={this.state.jogcim.nap} options={this.state.napok} validations="isNumeric" validationError="Nap kötelező" disabled />
                  <InputHidden key={"mentesites.napja_dis_h"+i} name={"mentesites.napja"+i} value={this.state.jogcim.nap}/>
                  <Select key={"mentesites.idokezd_dis"+i} name={"mentesites.idokezd_dis"+i} title="Időpont kezdete" value={this.state.jogcim.kezd} options={this.state.idokezd} validations="isNumeric" validationError="Időpont kötelező" disabled />
                  <InputHidden key={"mentesites.idokezd_dis_h"+i} name={"mentesites.idokezd"+i} value={this.state.jogcim.kezd}/>
                  <Select key={"mentesites.idoveg_dis"+i} name={"mentesites.idoveg_dis"+i} title="Időpont vége" value={this.state.jogcim.veg} options={this.state.idoveg} validations="isNumeric" validationError="Időpont kötelező" disabled />
                  <InputHidden key={"mentesites.idoveg_dis_h"+i} name={"mentesites.idoveg"+i} value={this.state.jogcim.veg}/>
                  </section>
                )
              } else {
                inputMezok.push(
                  <section key={"sec"+i}>
                    <Select key={"mentesites.napja"+i} name={"mentesites.napja"+i} title="Nap" value={this.state.mentesites.idopontok !== undefined ? this.state.mentesites.idopontok["napja"+i] : ''} options={this.state.napok} validations="isNumeric" validationError="Nap kötelező" required />
                    <Select key={"mentesites.idokezd"+i} name={"mentesites.idokezd"+i} title="Időpont kezdete" value={this.state.mentesites.idopontok !== undefined ? this.state.mentesites.idopontok["idokezd"+i] : ''} options={this.state.idokezd} validations="isNumeric" validationError="Időpont kötelező" required={i == 1 || this.state.myform["mentesites.napja"+i] != "" ? true : false} />
                    <Select key={"mentesites.idoveg"+i} name={"mentesites.idoveg"+i} title="Időpont vége" value={this.state.mentesites.idopontok !== undefined ? this.state.mentesites.idopontok["idoveg"+i] : ''} options={this.state.idoveg} validations="isNumeric" validationError="Időpont kötelező" required={i == 1 || this.state.myform["mentesites.napja"+i] != "" ? true : false} />
                  </section>
                )
              }
            }
          }
        } else {
          if(this.state.jogcim.id == 5) {
            //console.log(this.state.myform["mentesites.napja"+i]);
            /*inputMezok.push(
              <section className="fieldset" key={i}>
                <legend>{i}. alkalom</legend>
                <Select key={"mentesites.napja"+i} name={"mentesites.napja"+i} title="Nap" value={this.state.mentesites.idopontok !== undefined ? this.state.mentesites.idopontok["napja"+i] : ''} options={this.state.napok} validations="isNumeric" validationError="Nap kötelező" required={i == 1 ? true : false} />
                <Select key={"mentesites.orak"+i} name={"mentesites.orak"+i} title="Órák" value={this.state.mentesites.idopontok !== undefined ? this.state.mentesites.idopontok["orak"+i] : ''} options={[{ value: '', label: '- válassz -' },{ value: '0', label: 'első 2 óra' },{ value: '1', label: 'utolsó 4 óra' }]} validations="isNumeric" validationError="Időpont kötelező" required={i == 1 || (this.state.myform["mentesites.napja"+i] != "" && this.state.myform["mentesites.napja"+i] != undefined) ? true : false} />
              </section>
            )*/
          }
        }
      }

      var akt_oktato = 0;
      if(this.state.mentesites.tanar_id) {
        akt_oktato = this.state.mentesites.tanar_id;
      } else if(parseInt(this.props.params.tanar_id)) {
        akt_oktato = parseInt(this.props.params.tanar_id);
      }

      var inputattr = '';
      if(this.state.mentesites.id !== undefined && this.state.mentesites.id != 0) {
        inputattr = 'disabled';
      } else {
        inputattr = 'required';
      }

      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <button onClick={this.onBack} className="btn btn-primary" type="button"><i className="fa fa-arrow-left"></i>Vissza</button>
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Mentesítések
              </li>
              <li>
                Nappali mentesítések
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Mentesítsések</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <button onClick={this.onBack} className="btn btn-primary" type="button"><i className="fa fa-arrow-left"></i>Vissza</button>
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Mentesítés</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                    <Form ref="form" onValid={this.onValid} onInvalid={this.onInvalid} onChange={(e)=>{this.state.myform=e;}}>
                      <Select2 key="tanar_id" title="Oktató" placeholder="Keresés" name="tanar_id" value={akt_oktato} options={this.state.oktatok} onChange={this.onChangeOktato} required={inputattr == 'required' ? true : false} disabled={inputattr == 'disabled' ? true : false} />
                      <Select key="jogcim" name="mentesites.jogcim" title="Jogcím" value={this.state.jogcim.id} options={this.state.jogcimek} validations="isNumeric" validationError="Jogcím kötelező" onChange={this.onChangeJogcim} required={inputattr == 'required' ? true : false} disabled={inputattr == 'disabled' ? true : false} />
                        {this.state.jogcim.id == 5 ? (
                          <section>
                          {inputMezok}
                          
                          <button style={{'marginBottom': '15px'}} onClick={this.gyermekModalShow} className="btn btn-primary" type="button"><i className="fa fa-child"></i>Gyermekek adatai</button>
                          {this.state.kisgyermek ? (
                            this.props.prop_loggedUser.rules.indexOf("mentesitesek_nappali/egyedulallo_beallit") >= 0 ? (
                              <Toggle onChange={this.onChangeEgyedulnevelo} title='Gyermekét egyedül nevelő szülő' name='egyedulnevelo' value={this.state.egyedulnevelo} />
                            ):(
                              <section>
                                <p style={{'marginBottom': '15px'}}>Gyermekét egyedül nevelő szülő: {this.state.egyedulnevelo ? 'Igen (max. 4 alkalom' : 'Nem (max. 2 alkalom)'} </p>
                              </section>
                            )
                          ) : (
                            <section>
                              <p style={{'marginBottom': '15px'}}>Nincs 10 év alatti kisgyermek rögzítve.</p>
                            </section>
                          )}
                          <table className="table mentesitesTable table-bordered">
                            <thead>
                              <tr>
                                <th style={{'width': '20%'}}></th>
                                <th style={{'width': '16%'}}>Hétfő</th>
                                <th style={{'width': '16%'}}>Kedd</th>
                                <th style={{'width': '16%'}}>Szerda</th>
                                <th style={{'width': '16%'}}>Csütörtök</th>
                                <th style={{'width': '16%'}}>Péntek</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/*<tr>
                                <td style={{'verticalAlign': 'middle'}}>Első 2 óra</td>
                                <td><Checkbox className="xed" name="nap0_0" onClick={() => this.checkboxClick('0_0',0)} boxClickable={this.state.add10ment} value={this.checkChecked('0_0')} /></td>
                                <td><Checkbox className="xed" name="nap1_0" onClick={() => this.checkboxClick('1_0',0)} boxClickable={this.state.add10ment} value={this.checkChecked('1_0')} /></td>
                                <td><Checkbox className="xed" name="nap2_0" onClick={() => this.checkboxClick('2_0',0)} boxClickable={this.state.add10ment} value={this.checkChecked('2_0')} /></td>
                                <td><Checkbox className="xed" name="nap3_0" onClick={() => this.checkboxClick('3_0',0)} boxClickable={this.state.add10ment} value={this.checkChecked('3_0')} /></td>
                                <td><Checkbox className="xed" name="nap4_0" onClick={() => this.checkboxClick('4_0',0)} boxClickable={this.state.add10ment} value={this.checkChecked('4_0')} /></td>
                              </tr>*/}
                              <tr>
                                <td style={{'verticalAlign': 'middle'}}>Utolsó 5 óra</td>
                                <td><Checkbox className="xed" name="nap0_1" onClick={() => this.checkboxClick('0_1',0)} boxClickable={this.state.add10ment} value={this.checkChecked('0_1')} /></td>
                                <td><Checkbox className="xed" name="nap1_1" onClick={() => this.checkboxClick('1_1',0)} boxClickable={this.state.add10ment} value={this.checkChecked('1_1')} /></td>
                                <td><Checkbox className="xed" name="nap2_1" onClick={() => this.checkboxClick('2_1',0)} boxClickable={this.state.add10ment} value={this.checkChecked('2_1')} /></td>
                                <td><Checkbox className="xed" name="nap3_1" onClick={() => this.checkboxClick('3_1',0)} boxClickable={this.state.add10ment} value={this.checkChecked('3_1')} /></td>
                                <td style={{'verticalAlign': 'middle'}}>---</td>
                              </tr>
                            </tbody>
                          </table>
                          </section>
                        ) : (
                          inputMezok
                        )}
                      <InputHidden key="mentesites.alkalom" name="mentesites.alkalom" value={this.state.jogcim.alkalom}/>
                      <InputHidden key="mentesites.ido_tipus" name="mentesites.ido_tipus" value={this.state.jogcim.ido_tipus}/>
                      <InputHidden key="mentesites.id" name="mentesites.id" value={this.state.mentesites_id}/>
                      <InputHidden key="loggedUser" name="loggedUser" value={this.props.prop_loggedUser.id}/>
                    </Form>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <Modal show={this.state.gyermek_modal} onHide={this.gyermekModalHide}>
          <Modal.Header closeButton>
            <Modal.Title>Gyermekek adatai</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.gyermekek.length > 0 ? (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Név</th>
                    <th>Születési idő</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {this.state.gyermekek.map(gyermek => (
                   <tr key={gyermek.id}>
                      <td style={{'verticalAlign': 'middle'}}>{gyermek.nev}</td>
                      <td style={{'verticalAlign': 'middle'}}>{gyermek.szulido}</td>
                      <td style={{"textAlign": "center"}}>
                        <div className="buttons">
                          {/*<button style={{'marginRight': '2px','marginLeft': '2px'}} onClick={()=>{_this.ujGyermek()}} className="btn btn-white only-icon small" type="button"><i className="fa fa-pencil"></i></button>*/}
                          <button style={{'marginRight': '2px','marginLeft': '2px','minWidth': '25px'}} onClick={()=>{this.delGyermek(gyermek.id)}} className="btn btn-white only-icon small" type="button"><i className="fa fa-trash"></i></button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              ): "Nincs gyermek rögzítve."}
            <Form style={{'marginTop': '20px'}} ref="gyermekek_form">
              <div className="row">
                <div className="col-xs-7">
                    <Input ref="uj_gyermek_nev" name="uj_gyermek_nev" title="Név" validationError="Név kötelező" value='' required />
                </div>
                <div className="col-xs-4">
                    <Input datemy={true} yearRangePast="20" ref="uj_gyermek_szulido" name="uj_gyermek_szulido" title="Születési idő" value='' validationError="Születési idő kötelező" required />
                </div>
                <div className="col-xs-1">
                  <div className="new-group-filter clearfix">
                    <div className="buttons">
                      <button onClick={()=>{this.ujGyermek()}} className="btn btn-white only-icon small" type="button"><i className="fa fa-floppy-o"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.gyermekModalHide} type="button">Bezár</Button>
          </Modal.Footer>
        </Modal>

      </div>

    )
  }
});

MentesitesNappali.contextTypes = {
  store: React.PropTypes.object
}

export default MentesitesNappali;