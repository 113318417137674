function configReducer(state = [], action) {
  switch (action.type) {
    case "SET_CONFIG":
      return {
        apiUrl: action.apiUrl
      };
    break;

    case "SET_CONFIG_MENU_OPEN":
      return $.extend({}, state, {
         menuOpen: action.menu_open
      })
    break;

    case "SET_CONFIG_SERVICE_RUN":
      return $.extend({}, state, {
         serviceRun: action.run
      })
    break;

    case "SET_AJAX_PRELOADER":
      return $.extend({}, state, {
         ajaxPreloaderShow: action.show
      })
    break;

    default:
      return state;
  }
}

export default configReducer;