import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button, Tabs, Tab} from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import arrayHelper from './ArrayHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import Select2 from './form/Select2';

import Nezet from './orarend_levelezo/Nezet';
import OraAdatokModal from './orarend_levelezo/OraAdatokModal';
import Naptar from './orarend_levelezo/Naptar';
import ElrakatlanOrakModal_ from './orarend_levelezo/ElrakatlanOrakModal_';
import OrarendGeneralasStatusz from './orarend_levelezo/OrarendGeneralasStatusz';
import TeremValasztoModal from './orarend_levelezo/TeremValasztoModal';
import SertesekModal from './orarend_levelezo/SertesekModal';
import Szerkeszto from './orarend_levelezo/szerkeszto/Szerkeszto';

Formsy.addValidationRule('isSelect2Empty', function (values, value) {
  return ((value == undefined || value.length == 0) ? false : true);
});

var OrarendLevelezo = React.createClass({
  getInitialState() {
    return {
      nezetek: [
        {
          id: 0,
          megnevezes: 'Nézet 1',
          idopontok:[],
          terem_id: [],
          oktato_id: [],
          oktatok: [],
          termek: [],
          tantervek: [{
            label: '- válassz -',
            value: 0
          }],
          tanterv_id: 0,
          szak_id: 0,
          szakok: [],
          szakirany_id: 0,
          szakiranyok: [{
            label: '- válassz -',
            value: 0
          }],
          szakossal: 1,
          felevek: [{
            label: '- válassz -',
            value: 0
          }],
          felev_id: 1,
          felev_megn: 1,
          napok: {
            hetfo: 1,
            kedd: 1,
            szerda: 1,
            csutortok: 1,
            pentek: 1,
            szombat: 1
          },
          levelezo_nezet: 0,
          generaciok: [{
            label: '- válassz -',
            value: 0
          }],
          genomok: [{
            label: '- válassz -',
            value: 0
          }]
        }
      ],
      aktiv_nezet_id: 0,
      uj_nezet_form_valid: false,
      uj_nezet_modal: false,
      orarendGeneralasModalShow: false,
      tagozatok: [
          { value: 1, label: 'nappali' },
          { value: 2, label: 'levelező' }
      ],

      elrakatlan_orak_modal: false,
      elrakatlan_orak_modal_: false,
      teremvalaszto_modal: false,
      sertesek_modal: false,
      formIsValid: false,

      // Az elrakatlan óra elrakásához szükséges adatok tárolódnak itt
      // Ide rögzíti le a kiválasztott óra, időpont, és terem adatokat
      ora_mentes: {
        ora_id: 0,
        terem_id: 0,
        idopont: '',
        terem_nev: '',
        mentes: true  // vezérli, hogy terem választás után mentsen -e azonnal
      },

      // Óra adatok modalhoz
      ora_adatok_modal: false,
      ora_adatok: {
        datum: "",
        hatterszin: "",
        het: "",
        hossz: "",
        kethetente: "",
        kezdete: "",
        kurzus_azonosito: "",
        kurzus_id: "",
        kurzus_kod: "",
        megjegyzes: "",
        nap: "",
        ora_id: "",
        ora_tanar: "",
        periodus: "",
        szovegszin: "",
        tanar: "",
        tanar_id: "",
        tantargy: "",
        terem: "",
        terem_id: "",
        vege: ""
      }
    }
  },

  /**
   * Véletlen ID generálás
   *
   */
  generateRandomID: function() {
    return Math.floor(Math.random() * 10000000) + 1
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {
    var {store} = this.context;

    if (typeof(this.props.prop_loggedUser.aktiv_orarend) == "undefined") {
      store.dispatch({type: 'SET_OLDAL_UZENET', tipus: 'alert alert-danger', uzenet: 'Nincs órarend kiválasztva.'});
      hashHistory.push("/uzenet");
    }
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
  },

  componentWillReceiveProps: function(nextProps) {
    console.log("Órarend: componentWillReceiveProps");
  },

  componentDidUpdate: function() {
    console.log("Órarend: componentDidUpdate");


  },

  /**
   * Elrakatlan modal ablak elrejtés
   *
   */
  elrakatlanOrakModalHide: function() {
    this.setState({
      elrakatlan_orak_modal: false
    });
  },

  /**
   * Elrakatlan modal ablak megjelenítés
   *
   */
  elrakatlanOrakModalShow: function() {
    this.setState({
      elrakatlan_orak_modal: true
    });
  },

  /**
   * Elrakatlan órák modal ablak elrejtés
   *
   */
  elrakatlanOrakModalHide_: function(cb) {
    this.setState({
      elrakatlan_orak_modal_: false
    }, function() {
      if ($.isFunction(cb)) {
        cb();
      };
    });
  },

  getAktivNezetAdatok: function() {
    var _this = this;
    var aktiv_nezet;

    this.state.nezetek.map(function(nezet, i) {
      if (nezet.id == _this.state.aktiv_nezet_id)
        aktiv_nezet = nezet;
    });

    return aktiv_nezet;
  },

  /**
   * Elrakatlan órák modal ablak megjelenítés
   *
   */
  elrakatlanOrakModalShow_: function() {
    var _this = this;

    var adatok = this.getAktivNezetAdatok();

    this.setState({
      elrakatlan_orak_modal_: true
    }, function() {
      _this.refs.elrakatlan_orak_modal_.keresoFeltetelekBeallitasa(adatok.szak_id, adatok.szakiranyok, adatok.szakirany_id, adatok.felev_id, adatok.oktato_id);
    });
  },

  /**
   * Teremválasztó modal elrejtése
   *
   */
  teremValasztoModalHide: function(cb) {
    this.setState({
      teremvalaszto_modal: false
    }, function() {
      if ($.isFunction(cb)) {
        cb();
      };
    });
  },

  /**
   * Teremválasztó modal megjelenítése
   *
   */
  teremValasztoModalShow: function(cb) {
    this.setState({
      teremvalaszto_modal: true
    }, function() {
      if ($.isFunction(cb)) {
        cb();
      };
    });
  },

  /**
   * Új nézet modal ablak elrejtés
   *
   */
  ujNezetModalHide: function() {
    this.setState({
      uj_nezet_modal: false
    });
  },

  /**
   * Új nézet modal ablak megjelenítés
   *
   */
  ujNezetModalShow: function() {
    this.setState({
      uj_nezet_modal: true
    });
  },

  /**
   * Új nézet modal ablakon belüli űrlap valid beállítása
   *
   */
  ujNezetFormValid: function() {
    this.setState({
      uj_nezet_form_valid: true
    });
  },

  /**
   * Új nézet modal ablakon belüli űrlap invalid beállítása
   *
   */
  ujNezetFormInvalid: function() {
    this.setState({
      uj_nezet_form_valid: false
    });
  },


  /**
   * Óra adatok modal ablak elrejtése
   *
   */
  oraAdatokModalHide: function() {
    this.setState({
      ora_mentes: {
        idopont: '',
        mentes: false,
        ora_id: 0,
        terem_id: 0,
        terem_nev: ''
      },
      ora_adatok_modal: false
    });
  },

  oraAdatokBeallit: function(adatok) {
    this.setState({
      ora_adatok: adatok
    });
  },

  /**
   * Ütközések modal ablak elrejtése
   *
   */
  sertesekModalHide: function() {
    this.setState({
      sertesek_modal: false
    });
  },

  /**
   * Ütközések modal ablak megjelenítése
   *
   */
  sertesekModalShow: function() {
    var _this = this;
    var adatok = this.getAktivNezetAdatok();

    this.setState({
      sertesek_modal: true
    }, function() {
      _this.refs.sertesmodal.getOrak(adatok.szak_id, adatok.szakirany_id, adatok.felev_id, adatok.oktato_id);
    });
  },

  /**
   * Óra adatok modal ablak megjelenítése
   *
   */
  oraAdatokModalShow: function(ora_adatok) {
    this.setState({
      ora_adatok_modal: true,
      ora_adatok: ora_adatok
    });
  },

  /**
   * Az új nézet létrehozása
   *
   */
  ujNezetLetrehozas: function() {
    var self = this;

    this.refs.uj_nezet_form.submit();

    if (this.state.uj_nezet_form_valid) {
      var formData = this.refs.uj_nezet_form.getModel();

      var nezetek = [ ...this.state.nezetek,
        {
          id: this.generateRandomID(),
          megnevezes: formData.megnevezes,
          idopontok:[],
          terem_id: [],
          oktato_id: [],
          oktatok: [],
          termek: [],
          napok: {hetfo: 1, kedd: 1, szerda: 1, csutortok: 1, pentek: 1, szombat:1},
          tantervek: [{
            label: '- válassz -',
            value: 0
          }],
          szakossal: 1,
          tanterv_id: 0,
          szak_id: 0,
          szakok: [],
          szakirany_id: 0,
          szakiranyok: [{
            label: '- válassz -',
            value: 0
          }],
          felevek: [{
            label: '- válassz -',
            value: 0
          }],
          generaciok: [{
            label: '- válassz -',
            value: 0
          }],
          genomok: [{
            label: '- válassz -',
            value: 0
          }],
          felev_id: 1,
          felev_megn: 1,
          levelezo_nezet: 0
        }
      ];

      this.setState({
        nezetek: nezetek,
        aktiv_nezet_id: (nezetek[nezetek.length - 1].id)
      });

      this.ujNezetModalHide();
    }
  },

  /**
   * Egy nézet törlése, a fülön lévő kuka ikonra kattintással
   *
   */
  nezetTorles: function(id) {
    var self = this;

    if (self.state.nezetek.length == 1)
      return false;

    swal({
      title: "Biztos hogy törli a nézetet?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true,
      animation: false
    },
    function() {
      var nezetek = self.state.nezetek;

      nezetek = arrayHelper.removeItemByFieldAndValue(nezetek, 'id', id);

      self.setState({
        nezetek: nezetek,
      }, function() {
        self.setState({
          aktiv_nezet_id: nezetek[0].id
        });
      });
    });
  },

  /**
   * Adott nézetnél a FRISSÍT nyomógombon történő kattintás után az időpontok frissítése
   */
  nezetIdopontokFrissites: function(id, idopontok) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.idopontok = idopontok;
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Aktív nézet időpontjainak beállítása
   */
  aktivNezetIdopontokBeallitas: function(idopontok) {
    var nezetek = this.state.nezetek;
    var aktiv_nezet_id = this.state.aktiv_nezet_id;

    nezetek.map(function(nezet, i) {
      if (nezet.id == aktiv_nezet_id) {
        nezet.idopontok = idopontok;
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Időpontok hozzáadása a naptárhoz
   */
  aktivNezethezIdopontokHozzadasa: function(idopontok) {
    var nezetek = this.state.nezetek;
    var aktiv_nezet_id = this.state.aktiv_nezet_id;

    nezetek.map(function(nezet, i) {
      if (nezet.id == aktiv_nezet_id) {
        nezet.idopontok = $.merge(nezet.idopontok, idopontok);
      }
    });

    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Sértés időpontok eltávolítása
   */
  aktivNezetbolSertesIdopontokEltavolitasa: function() {
    var aktiv_nezet_id = this.state.aktiv_nezet_id;
    var nezetek = this.state.nezetek;

    nezetek.map(function(nezet, i) {
      if (nezet.id == aktiv_nezet_id) {
        nezet.idopontok = $.grep(nezet.idopontok, function( idopont, i ) {
          return idopont.sertes == undefined ? true : false;
        });
      }
    });

    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Aktív nézet időpontjainak lekérdezése
   */
  aktivNezetIdopontokLekerese: function() {
    var _this = this;
    var idopontok = [];

    this.state.nezetek.map(function(nezet, i) {
      if (nezet.id == _this.state.aktiv_nezet_id) {
        idopontok = nezet.idopontok;
      }
    });

    return idopontok;
  },

  /**
   * Megadott nézethez a terem combó feltöltése
   */
  nezetTermekBeallitas: function(id, termek) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.termek = termek;
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez az oktató combó feltöltése
   */
  nezetOktatokBeallitas: function(id, oktatok) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.oktatok = oktatok;
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez a tantervek combó feltöltése
   */
  nezetTantervekBeallitas: function(id, tantervek) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.tantervek = tantervek;
        nezet.tanterv_id = 0;
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez a szak combó feltöltése
   */
  nezetSzakokBeallitas: function(id, szakok) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.szakok = szakok;
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez a szak combó feltöltése
   */
  nezetFelevekBeallitas: function(id, felevek) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.felevek = felevek;
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez a szak combó feltöltése
   */
  nezetGeneraciokBeallitas: function(id, generaciok) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.generaciok = generaciok;
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez a szak combó feltöltése
   */
  nezetGenomokBeallitas: function(id, genomok) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.genomok = genomok;
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez a felevID beállítása (melyik van kiválasztva)
   */
  felevIDBeallitas: function(id, value) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.felev_id = value;
      }

      nezet.felevek.map(function(felev, j) {
        if (parseInt(felev.value) == parseInt(value))
          nezet.felev_megn = felev.label;
      });
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez a szakirány combó feltöltése
   */
  nezetSzakiranyokBeallitas: function(id, szakiranyok) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.szakiranyok = szakiranyok;
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez az oktatoID beállítása (melyik van kiválasztva)
   */
  nezetOktatokIDBeallitas: function(id, value) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.oktato_id = value;
        nezet.oktato_megn = '';
        var tmp = [];
        value.map(function(v) {
          nezet.oktatok.map(function(oktato, j) {
            if (parseInt(oktato.value) == parseInt(v))
              tmp.push(oktato.label);
          });
        });
        nezet.oktato_megn = tmp.join(', ');
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez a teremID beállítása (melyik van kiválasztva)
   */
  nezetTeremIDBeallitas: function(id, value) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.terem_id = value;
        nezet.terem_megn = '';

        var tmp = [];
        value.map(function(v) {
          nezet.termek.map(function(terem, j) {
            if (parseInt(terem.value) == parseInt(v))
              tmp.push(terem.label);
          });
        });
        nezet.terem_megn = tmp.join(', ');
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez a tantervID beállítása (melyik van kiválasztva)
   */
  tantervIDBeallitas: function(id, value) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.tanterv_id = value;
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez a szak_id beállítása (melyik van kiválasztva)
   */
  szakIDBeallitas: function(id, value) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.szak_id = value;
        nezet.szakirany_id = 0;
        nezet.szak_megn = '';
        nezet.szakirany_megn = '';

        nezet.szakok.map(function(szak, j) {
          if (parseInt(szak.value) == parseInt(value))
            nezet.szak_megn = szak.label;
        });
      }
    });

    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez a szakirany_id beállítása (melyik van kiválasztva)
   */
  szakiranyIDBeallitas: function(id, value) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.szakirany_id = value;
        nezet.szakirany_megn = '';

        nezet.szakiranyok.map(function(szakirany, j) {
          if (parseInt(szakirany.value) == parseInt(value))
            nezet.szakirany_megn = szakirany.label;
        });
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Megadott nézethez a nap kiválasztás frissítése
   */
  nezetNapBeallitas: function(nezet_id, nap, value) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == nezet_id) {
        nezet.napok[nap] = value;
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },


  /**
   * Megadott nézethez a teremID beállítása (melyik van kiválasztva)
   */
  nezetKeresoResetEsTeremIDBeallitas: function(id, value, cb) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.tanterv_id = [];
        nezet.oktato_id = [];
        nezet.szak_id = 0;
        nezet.szakirany_id = 0;
        nezet.terem_id = value;
      }
    });
    this.setState({
      nezetek: nezetek
    }, function() {
      if ($.isFunction(cb)) {
        cb();
      }
    });
  },

  /**
   * Egy adott óra törlése a naptárból
   * és az összes nézetben frissíti az időpontokat
   */
  oraTorles: function(ora_id) {
    var _this = this;
    var {store} = this.context;

    swal({
      title: "Biztos hogy törli az órát?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true,
      animation: false
    },
    function() {
      Ajax.call('orarend/ora_torles/' + localStorage.token + '/' + ora_id, store, {
        success: function(data) {
          _this.nezetekNaptarFrissitese();
        }
      });
    });
  },

  /**
   * Megadott nézethez a szakossal beállítása
   */
  szakossalBeallitas: function(id, value) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.szakossal = value;
      }
    });

    this.setState({
      nezetek: nezetek
    });
  },

  /**
   *  Levelező nézet beállítás
   */
  levelezoNezetBeallitas: function(id, value) {
    var nezetek = this.state.nezetek;
    nezetek.map(function(nezet, i) {
      if (nezet.id == id) {
        nezet.levelezo_nezet = value;
      }
    });
    this.setState({
      nezetek: nezetek
    });
  },

  /**
   * Kívülről egy drag&drop-al behúzott elem felvitele
   */
  ujIdopont: function(ora_id, datum_tol, datum_ig) {
    var self = this;
    var {store} = this.context;

    Ajax.call('orarend/uj_idopont/' + localStorage.token, store, {
      method: 'POST',
      data: {
        ora_id: ora_id,
        datum_tol: datum_tol,
        datum_ig: datum_ig
      },
      success: function(data) {
        var uj_idopont = data.idopont;

        var nezetek = self.state.nezetek;
        nezetek.map(function(nezet, i) {
          var idopontok = nezet.idopontok;
          var idopont = {
            id: uj_idopont.id,
            title: uj_idopont.tantargy + '\n' + uj_idopont.terem + '\n' + uj_idopont.terem_id,
            start: uj_idopont.datum_tol,
            end: uj_idopont.datum_ig,
            editable: true,
            durationEditable: false,
            adatok: uj_idopont
          };
          idopontok.push(idopont);
        });
        self.setState({
          nezetek: nezetek
        });
      }
    });
  },

  /**
   * Nézet váltás a nézet fülön történő kattintással
   */
  nezetValt: function(id) {
    this.setState({
      aktiv_nezet_id: id
    });
  },

  /**
   * Az összes létező nézet naptár elemeinek frissítése
   * A nézet szűrési feltételeinek megfelelő óra lekérések és naptár elemek frissítése
   */
  nezetekNaptarFrissitese: function() {
    var _this = this;
    this.state.nezetek.map(function(nezet, i) {
      _this.refs['nezet'+i].naptarFrissit();
    });
  },

  /**
   * Az aktív nézet naptárjának frissítése
   */
  aktivNezetNaptarFrissitese: function() {
    this.refs['nezet' + this.state.aktiv_nezet_id].naptarFrissit();
  },

  /**
   * Az aktív nézet naptár hét kezdő dátumának lekérdezése
   */
  getAktivNezetNaptarHetKezdetDatum: function() {
    var datum = this.getAktivNezetNaptar().fullCalendar('getDate');
    return moment(datum).format("YYYY-MM-DD");
  },

  /**
   * Az aktív nézet fullcalendar objektumának lekérdezése
   */
  getAktivNezetNaptar: function() {
    var aktiv_nezet = this.getAktivNezet();
    return $(aktiv_nezet.refs.naptarobj.refs.naptar);
  },

  /**
   * Aktív nézet lekérdezése
   */
  getAktivNezet: function() {
    return this.refs['nezet' + this.state.aktiv_nezet_id];
  },

  setOraMentes: function(valtozok, cb) {
    var ora_mentes = this.state.ora_mentes;

    if (valtozok.ora_id != undefined)
      ora_mentes.ora_id = valtozok.ora_id;

    if (valtozok.terem_id != undefined)
      ora_mentes.terem_id = valtozok.terem_id;

    if (valtozok.idopont != undefined)
      ora_mentes.idopont = valtozok.idopont;

    if (valtozok.mentes != undefined)
      ora_mentes.mentes = valtozok.mentes;

    if (valtozok.terem_nev != undefined)
      ora_mentes.terem_nev = valtozok.terem_nev;

    this.setState({
      ora_mentes: ora_mentes
    }, function() {
      if ($.isFunction(cb)) {
        cb();
      }
    });
  },

  /**
   * Óra adatok modal ablakhoz egy változó megváltoztatása
   */
  oraAdatokValtoztatas: function(valtozo, ertek) {
    var ora_adatok = this.state.ora_adatok;
    ora_adatok[valtozo] = ertek;
  },

  /**
   * Elrakatlan óra mentése
   */
  elrakatlanOraMentes: function(ora_id, terem_id, datum, nap, cb) {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/elrakatlan_ora_mentes_levelezo/' + localStorage.token, store, {
      method: 'POST',
      data: {
        ora_id: ora_id,
        terem_id: terem_id,
        datum: datum,
        nap: nap
      },
      success: function(data) {
        if (data.success) {
          if ($.isFunction(cb)) {
            cb(data);
          }
        }
      }
    });
  },

  orarendGeneralasModalShow: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/levelezos_szakok/' + localStorage.token, store, {
      success: function(data) {
        var szakok = data.szakok;

        _this.setState({orarendGeneralasModalShow: true, szakok: szakok});
      }
    });
  },

  orarendGeneralasModalHide: function() {
    this.setState({orarendGeneralasModalShow: false});
  },

  orarendGeneralas: function() {
    var _this = this;
    var {store} = this.context;

    /*swal({
      title: "Elindítja az órarend generálást?",
      type: "info",
      showCancelButton: true,
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      showLoaderOnConfirm: true
    },
    function() {*/

    if(this.state.formIsValid) {
      // Ellenőrzi, hogy fut -e a szerviz
      Ajax.call('check_service_running', store, {
        method: 'GET',
        async: true,
        success: function(data) {
          // Fut a szerviz
          if (data.success) {
            setTimeout(function() {
              _this.orarendGeneralasStart();
            }, 500);

          // Nem fut a szervíz
          } else {
            Ajax.call('orarend/orkd_start', store, {
              method: 'GET',
              async: false,
              success: function(data) {
                if (data) {
                  store.dispatch({type:'SET_CONFIG_SERVICE_RUN', run: true});
                  setTimeout(function() {
                    _this.orarendGeneralasStart();
                  }, 500);

                } else {
                  setTimeout(function() {
                    _this.orarendGeneralasModalHide();
                    swal('Hiba!', 'Órarend generálás nem indult el, mert nem sikerült elindítani a szolgáltatást.', 'error');
                  }, 500);
                }
              }
            });
          }
        }
      });
    }

    //});
  },

  orarendGeneralasStart: function() {
    var _this = this;
    var {store} = this.context;

    var formData = this.refs.orarendgen_form.getModel();
    this.orarendGeneralasModalHide();

    Ajax.call('orarend/generalas/', store, {
      method: 'POST',
      data: {
        token: localStorage.token,
        orarend_id: _this.props.prop_loggedUser.aktiv_orarend.id,
        tagozat_idk: formData.tagozat_id,
        szak_idk: formData.szak_id
      },
      success: function(data) {
        if (data || data == -6) {
          _this.refs.OrarendGeneralasStatusz.statuszLekerdezes();
        } else if (data === false) {
          swal("Hiba", "Nem sikerült az órarend generálás elindítása. Ellenőrizze a szerviz státuszát!", "error");
        }
      }
    });
  },

  formValid: function() {
    this.setState({
      formIsValid: true
    });
  },

  formInvalid: function() {
    this.setState({
      formIsValid: false
    });
  },

  render: function() {
    if (typeof(this.props.prop_loggedUser.aktiv_orarend) == "undefined")
      return false;

    var self = this;
    var tmp = [];

      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
        {/*<div className="fixed-top-menu">
            <div className="pull-right">
              <button onClick={this.ujNezetModalShow} className="btn btn-primary" type="button"><i className="fa fa-plus"></i>Új nézet</button>
              <button onClick={this.elrakatlanOrakModalShow_} className="btn btn-primary" type="button"><i className="fa fa-hand-rock-o"></i>Elrakatlan órák</button>
              <button onClick={this.sertesekModalShow} className="btn btn-primary" type="button"><i className="fa fa-compress"></i>Sértések</button>
              <button onClick={this.orarendGeneralas} className="btn btn-primary" type="button"><i className="fa fa-cogs"></i>Órarend generálás</button>
            </div>
          </div>*/}
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">

        <OrarendGeneralasStatusz loggedUser={this.props.prop_loggedUser} ref="OrarendGeneralasStatusz"/>

          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Órarend levelező
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Órarend levelező</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <button onClick={this.ujNezetModalShow} className="btn btn-primary" type="button"><i className="fa fa-plus"></i>Új nézet</button>
              {/*<button onClick={this.elrakatlanOrakModalShow_} className="btn btn-primary" type="button"><i className="fa fa-hand-rock-o"></i>Elrakatlan órák</button>*/}
              {/*<button onClick={this.sertesekModalShow} className="btn btn-primary" type="button"><i className="fa fa-compress"></i>Sértések</button>*/}
              <button onClick={this.orarendGeneralasModalShow} className="btn btn-primary" type="button"><i className="fa fa-cogs"></i>Órarend generálás</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Órarend</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                      <Tabs onSelect={this.nezetValt} activeKey={this.state.aktiv_nezet_id} defaultActiveKey={this.state.aktiv_nezet_id} id="tabs">
                        <Tab key="szn" eventKey="szn" title={<span>Órarend szerkesztés</span>}>
                          <Szerkeszto loggedUser={self.props.prop_loggedUser}/>
                        </Tab>
                      {this.state.nezetek.map(function(nezet, i) {
                        return (
                          <Tab key={nezet.id} eventKey={nezet.id} title={<span>{nezet.megnevezes}<i onClick={() => self.nezetTorles(nezet.id)} style={{"marginLeft":"10px", "cursor": "pointer"}} className="fa fa-trash"></i></span>}>
                            <Nezet
                              adatok={nezet}
                              ujIdopont={self.ujIdopont}
                              oraTorles={self.oraTorles}
                              nezetTeremIDBeallitas={self.nezetTeremIDBeallitas}
                              nezetOktatokIDBeallitas={self.nezetOktatokIDBeallitas}
                              szakIDBeallitas={self.szakIDBeallitas}
                              szakiranyIDBeallitas={self.szakiranyIDBeallitas}
                              nezetOktatokBeallitas={self.nezetOktatokBeallitas}
                              nezetTermekBeallitas={self.nezetTermekBeallitas}
                              nezetSzakokBeallitas={self.nezetSzakokBeallitas}
                              nezetIdopontokFrissites={self.nezetIdopontokFrissites}
                              oraAdatokModalShow={self.oraAdatokModalShow}
                              nezetSzakiranyokBeallitas={self.nezetSzakiranyokBeallitas}
                              nezetFelevekBeallitas={self.nezetFelevekBeallitas}
                              nezetGeneraciokBeallitas={self.nezetGeneraciokBeallitas}
                              nezetGenomokBeallitas={self.nezetGenomokBeallitas}
                              nezetNapBeallitas={self.nezetNapBeallitas}
                              nezetTantervekBeallitas={self.nezetTantervekBeallitas}
                              tantervIDBeallitas={self.tantervIDBeallitas}
                              felevIDBeallitas={self.felevIDBeallitas}
                              levelezoNezetBeallitas={self.levelezoNezetBeallitas}
                              nezetekNaptarFrissitese={self.nezetekNaptarFrissitese}
                              elrakatlanOrakModalHide={self.elrakatlanOrakModalHide}
                              elrakatlanOrakModalHide_={self.elrakatlanOrakModalHide_}
                              elrakatlanOrakModalShow_={self.elrakatlanOrakModalShow_}
                              teremValasztoModalHide={self.teremValasztoModalHide}
                              teremValasztoModalShow={self.teremValasztoModalShow}
                              aktivNezetIdopontokBeallitas={self.aktivNezetIdopontokBeallitas}
                              aktivNezetIdopontokLekerese={self.aktivNezetIdopontokLekerese}
                              aktivNezethezIdopontokHozzadasa={self.aktivNezethezIdopontokHozzadasa}
                              szakossalBeallitas={self.szakossalBeallitas}
                              setOraMentes={self.setOraMentes}
                              key={nezet.id}
                              loggedUser={self.props.prop_loggedUser}
                              i={i}
                              ref={'nezet'+i}/>
                          </Tab>
                        )
                      })}
                      </Tabs>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <Modal show={this.state.uj_nezet_modal} onHide={this.ujNezetModalHide}>
          <Modal.Header closeButton>
            <Modal.Title>Új nézet létrehozása</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form ref="uj_nezet_form" onValid={this.ujNezetFormValid} onInvalid={this.ujNezetFormInvalid}>
              <Input name="megnevezes" title="Új nézet megnevezése" validationError="Megnevezés kötelező" required />
            </Form>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.ujNezetModalHide} type="button">Mégsem</Button>
            <Button onClick={this.ujNezetLetrehozas} bsStyle="primary">Létrehoz</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.orarendGeneralasModalShow} onHide={this.orarendGeneralasModalHide}>
          <Modal.Header closeButton>
            <Modal.Title>Órarend generálás</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form ref="orarendgen_form" onValid={this.formValid} onInvalid={this.formInvalid}>
              <h5>Válassza ki mely tagozatok órarendjét szeretné generáltatni!</h5>
              <Select2 name="tagozat_id" value={[1,2]} multi={true} title="Tagozatok" options={this.state.tagozatok}/>
              <h5>Válassza ki mely szakok órarendjét szeretné generáltatni!<br/>Ha üresen hagyja, akkor az összes szak számára készül órared.</h5>
              <Select2 name="szak_id" value={0} multi={true} title="Szakok" options={this.state.szakok}/>
            </Form>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.orarendGeneralasModalHide} type="button">Mégsem</Button>
            <Button onClick={this.orarendGeneralas} bsStyle="primary">Indítás</Button>
          </Modal.Footer>
        </Modal>

        {this.state.ora_adatok_modal ?
        <OraAdatokModal
          ora_adatok={this.state.ora_adatok}
          oraAdatokModalShow={this.oraAdatokModalShow}
          oraAdatokModalHide={this.oraAdatokModalHide}
          ora_adatok_modal={this.state.ora_adatok_modal}
          nezetekNaptarFrissitese={this.nezetekNaptarFrissitese}
          teremValasztoModalShow={this.teremValasztoModalShow}
          setOraMentes={this.setOraMentes}
          oraAdatokBeallit={this.oraAdatokBeallit}
          loggedUser={this.props.prop_loggedUser}/> : null
        }

        {this.state.elrakatlan_orak_modal ?
        <ElrakatlanOrakModal
          aktivNezetNaptarFrissitese={this.aktivNezetNaptarFrissitese}
          aktiv_nezet_id={this.state.aktiv_nezet_id}
          nezetKeresoResetEsTeremIDBeallitas={this.nezetKeresoResetEsTeremIDBeallitas}
          getAktivNezetNaptarHetKezdetDatum={this.getAktivNezetNaptarHetKezdetDatum}
          modalHide={this.elrakatlanOrakModalHide}
          elrakatlan_orak_modal={this.state.elrakatlan_orak_modal}
          getAktivNezetNaptar={this.getAktivNezetNaptar}
          getAktivNezet={this.getAktivNezet}
          aktivNezetIdopontokBeallitas={this.aktivNezetIdopontokBeallitas}
          loggedUser={this.props.prop_loggedUser}/> : null
        }

        {this.state.elrakatlan_orak_modal_ ?
        <ElrakatlanOrakModal_
          aktivNezetNaptarFrissitese={this.aktivNezetNaptarFrissitese}
          aktiv_nezet_id={this.state.aktiv_nezet_id}
          getAktivNezetNaptarHetKezdetDatum={this.getAktivNezetNaptarHetKezdetDatum}
          modalHide={this.elrakatlanOrakModalHide_}
          elrakatlan_orak_modal={this.state.elrakatlan_orak_modal}
          getAktivNezetNaptar={this.getAktivNezetNaptar}
          getAktivNezet={this.getAktivNezet}
          aktivNezetIdopontokBeallitas={this.aktivNezetIdopontokBeallitas}
          aktivNezethezIdopontokHozzadasa={this.aktivNezethezIdopontokHozzadasa}
          aktivNezetbolSertesIdopontokEltavolitasa={this.aktivNezetbolSertesIdopontokEltavolitasa}
          ref="elrakatlan_orak_modal_"
          loggedUser={this.props.prop_loggedUser}/> : null
        }

        {this.state.teremvalaszto_modal ?
          <TeremValasztoModal
            modalHide={this.teremValasztoModalHide}
            terem_modal={this.state.teremvalaszto_modal}
            setOraMentes={this.setOraMentes}
            elrakatlanOraMentes={this.elrakatlanOraMentes}
            ora_mentes={this.state.ora_mentes}
            nezetekNaptarFrissitese={this.nezetekNaptarFrissitese}
            oraAdatokValtoztatas={this.oraAdatokValtoztatas}
            loggedUser={this.props.prop_loggedUser}/> : null
        }

        {this.state.sertesek_modal ?
        <SertesekModal ref="sertesmodal" hide={this.sertesekModalHide} loggedUser={this.props.prop_loggedUser} /> : null
        }

      </div>
    )
  }
});

OrarendLevelezo.contextTypes = {
  store: React.PropTypes.object
}

export default OrarendLevelezo;