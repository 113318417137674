import React from 'react';
import Formsy from 'formsy-react';

var timeout;

const Select = React.createClass({
  mixins: [Formsy.Mixin],

  changeValue(event) {
    this.setValue(event.currentTarget.value);

    if (this.props.onChange != undefined)
      this.props.onChange(event.currentTarget.value);
  },

  changeValue_(value) {
    this.setValue(value);

    if (this.props.onChange != undefined)
      this.props.onChange(value);
  },

  onKeyUp: function() {
    if (timeout != undefined)
      clearTimeout(timeout);

    var _this = this;
    var search = this.refs.inputs.value.toLowerCase();

    $(this.refs.select).empty();

    this.props.options.map(function(opt, i) {
      if (((opt.label ? opt.label : "").toLowerCase()).indexOf(search) >= 0) {
        $(_this.refs.select).append(
          $('<option>', {
              value: opt.value,
              text: opt.label
          }, '</option>'));
      }
    });

    timeout = setTimeout(function() {
      _this.changeValue_($(_this.refs.select).val());
    }, 200);
  },

  shouldComponentUpdate(nextProps, nextState) {
    if (this.getValue() != nextState._value || nextProps.options != this.props.options || nextState._formSubmitted || this.props.value != nextProps.value)
      return true;
    else
      return false;
  },

  render() {
    var className = 'form-group' + (this.props.className || ' ') +
      (this.showRequired() ? 'required' : this.showError() ? 'has-error' : '');

    var errorMessage = this.getErrorMessage();

    if (this.isFormSubmitted()) {
      if (!this.isValid()) {
        className = 'form-group has-error';
        errorMessage = this.getErrorMessage() ? this.getErrorMessage() : this.props.validationError;
      }
    }

    const options = this.props.options.map((option, i) => (
      <option key={option.label+option.value} value={option.value}>
        {option.label}
      </option>
    ));

    return (
      <div className={className}>
        <label className="form-label" htmlFor={this.props.name}>{this.props.title} {this.isRequired() ? '*' : null}</label>
        {this.props.search ? (<div className="form-group"><div className="input-group"><input className="form-control" ref="inputs" type="text" onKeyUp={this.onKeyUp}/><span className="input-group-addon"><i className="fa fa-search"></i></span></div></div>):null}
        <select ref="select" disabled={this.props.disabled ? true : false} className="form-control" name={this.props.name} onChange={this.changeValue} value={this.getValue()} required>
          {options}
        </select>

        <span className="validation-error">{errorMessage}</span>
      </div>
    );
  }

});

export default Select;


