import React from 'react';
import Ajax from './../Ajax.js';
import {Form} from 'formsy-react';
import Select from './../form/Select';
import Select2 from './../form/Select2';
import Toggle from './../form/Toggle';
import Naptar from './Naptar';
import ArrayHelper from './../ArrayHelper';

var Nezet = React.createClass({
  getInitialState() {
    return {
      feltetel: 0
    }
  },

  naptarFrissit: function() {
    var _this = this;

    $.fn.spin.presets.preloader = {lines:13, length:0, width:3, radius:10, scale:.75, corners:1, opacity:.25, speed:1};
    $(this.refs.nezet_frissit_btn).addClass('with-preloader');
    $(this.refs.nezet_frissit_btn).spin('preloader');

    setTimeout(function() {
      _this.getIdopontok(function(idopontok) {
        $(_this.refs.nezet_frissit_btn).spin(false).removeClass('with-preloader');
        _this.props.nezetIdopontokFrissites(_this.props.adatok.id, idopontok);
      });
    }, 100);
  },

  /**
   * Render előtti esemény
   *
   */
  componentDidMount: function() {
    var _this = this;
    var {store} = this.context;

    console.log("Nezet "+this.props.i+": componentDidMount");

    _this.getOktatok(function(oktatok) {
      _this.props.nezetOktatokBeallitas(_this.props.adatok.id, oktatok);

      _this.getTermek(function(termek) {
        _this.props.nezetTermekBeallitas(_this.props.adatok.id, termek);

        _this.getSzakok(function(szakok) {
          _this.props.nezetSzakokBeallitas(_this.props.adatok.id, szakok);

          _this.getFelevek(function(felevek) {
            _this.props.nezetFelevekBeallitas(_this.props.adatok.id, felevek);
          });
        });
      });
    });
  },

  componentDidUpdate: function() {
    console.log("Nezet "+this.props.i+": componentDidUpdate");

  },

  /**
   * Időpontok lekérdezése
   *
   */
  getIdopontok: function(cb) {
    console.log("IDŐPONTOK lekérdezése");

    var _this = this;
    var {store} = this.context;
    var formData = this.refs.form.getModel();

    formData.aktiv_orarend_id = this.props.loggedUser.aktiv_orarend.id;
    formData.felev_kezdete = this.props.loggedUser.aktiv_idoszak.felev_kezdete;

    Ajax.call('orarend/orak/' + localStorage.token, store, {
      method: 'POST',
      async: false,
      data: formData,
      success: function(data) {
        var orak = [];
        data.orak.map(function(ora, i) {
          orak.push({
            tanar: ora.tanar,
            nap: ora.nap,
            periodus: ora.periodus,
            hossz: ora.hossz,
            kurzus_azonosito: ora.kurzus_azonosito,
            tantargy: ora.tantargy,
            kethetente: ora.kethetente,
            terem: ora.terem,
            mentesites: ora.mentesites,
            mentesites_megn: ora.mentesites_megn,
            het: ora.het
          });
        });
        _this.setState({ajax_orak: orak});

        var felevKezdet = moment(_this.props.loggedUser.aktiv_idoszak.felev_kezdete, "YYYY-MM-DD");
        var felevVege = moment(_this.props.loggedUser.aktiv_idoszak.felev_vege + ' 23:59:59', "YYYY-MM-DD HH:mm:ss");

        var orak = [];

        // A dátumtól beállítódik az időszak kezdő hetének hétfő napjára
        var d = felevKezdet.day() - 1;
        var dt = moment(_this.props.loggedUser.aktiv_idoszak.felev_kezdete, "YYYY-MM-DD").subtract(d, 'days');
        var het;

        data.orak.map(function(ora, i) {
          het = 1;
          var datum_tol = moment(dt.format("YYYY-MM-DD") + " " + ora.kezdete, "YYYY-MM-DD HH:mm:ss");
          var datum_ig = moment(dt.format("YYYY-MM-DD") + " " + ora.vege, "YYYY-MM-DD HH:mm:ss");

          // A hétfőhöz viszonyítva hozzáadja azt, hogy melyik napon van az óra
          datum_tol = datum_tol.add(parseInt(ora.nap), 'd');
          datum_ig = datum_ig.add(parseInt(ora.nap), 'd');

          // Félévvége dátumáig rakható ki óra
          while(datum_tol.isBefore(felevVege)) {
            // Félévkezdet utáni a dátum
            if (datum_tol.isAfter(felevKezdet)) {
              var szin = '#6AA4C1';
              var szoveg_szin = '#ffffff';
              var keret_szin = '#6AA4C1';
              var class_name = '';
              var osszevont_kurzusok = '';

              // sértés típus alapján beállított
              if (ora.sertes != undefined) {
                keret_szin = ora.sertes.szin;
                szoveg_szin = ora.sertes.szoveg_szin;
                class_name = 'fc-event-sertes';
              }

              if (ora.egy_szakon_kotelezo != undefined) {
                szin = keret_szin = '#0080FF';

              } else if (ora.tobb_szakon_kotelezo != undefined) {
                szin = keret_szin = '#BF00FF';
              }

              if (ora.egy_szakiranyon_kotelezo != undefined) {
                szin = keret_szin = '#FF8000';

              } else if (ora.tobb_szakiranyon_kotelezo != undefined) {
                szin = keret_szin = '#FFFF00';
                szoveg_szin = '#000000';
              }

              if (ora.tobb_szakon_kotelezo != undefined && ora.egy_szakiranyon_kotelezo != undefined) {
                szin = keret_szin = '#FFFF00';
                szoveg_szin = '#000000';
              }

              if ($.inArray(parseInt(ora.jelleg_id), [63012,63017,63026,63002,63003,63006]) > 0) {
                szin = keret_szin = '#00FFFF';
                szoveg_szin = '#000000';
              }

              // felhasználó által beállított egyedi
              if (ora.hatterszin != '') {
                szin = ora.hatterszin;
                keret_szin = ora.hatterszin;
                class_name = '';
              }

              if (ora.szovegszin != '') // felhasználó által beállított egyedi
                szoveg_szin = ora.szovegszin;

              // Dobozban a feliratok
              var title = ora.kurzus_azonosito + ', ' + ora.tantargy + ', ' + ora.terem + ', (' + ora.max_korlat + ')';

              if (ora.felev != undefined) {
                title = title + ', Félév:' + ora.felev;
              }

              if (ora.szakok_kod != undefined) {
                title = title + '(' + ora.szakok_kod +')';
              }

              if (ora.tanar != undefined && ora.tanar != '')
                title = title + ' (' + ora.tanar + ')';

              if (ora.mentesites != undefined)
                title = ora.mentesites_megn;

              if (typeof ora.feltetelek != "undefined") {
                class_name = 'fc-event-kurzus-feltetel';
                keret_szin = "#000";
                var arr = [];
                ora.feltetelek.map(function(feltetel) {
                  arr.push(feltetel.megnevezes);
                });
                title = title + '\n' + arr.join('\n');
              }

              var s_ora = {
                //id: ora.ora_id,
                title: title,
                start: datum_tol.format("YYYY-MM-DD HH:mm:ss"),
                end: datum_ig.format("YYYY-MM-DD HH:mm:ss"),
                editable: (ora.csak_megjelenes == undefined) ? true : false,
                durationEditable: false,
                adatok: ora,
                backgroundColor: szin,
                borderColor: keret_szin,
                textColor: szoveg_szin,
                className: class_name
              };

              // Konkrét dátum
              if (ora.datum_timestamp != undefined) {
                if (ora.felhasznalt == undefined) {
                  var md = moment.unix(ora.datum_timestamp);

                  s_ora.start = moment(md).format('Y-MM-DD') + ' ' + ora.kezdete;
                  s_ora.end = moment(md).format('Y-MM-DD') + ' ' + ora.vege;
                  orak.push(s_ora);

                  ora.felhasznalt = true;
                }

              } else {
                // Minden héten van óra
                if (ora.kethetente == 0) {
                  orak.push(s_ora);

                } else if (ora.kethetente == 1) {
                  // A datum_tol időpontból megállapítja, hogy a hét páros vagy páratlan. páros = 0, 1 = páratlan
                  var week = datum_tol.week() % 2 == 0 ? 0 : 1;

                  // Egyezik az óra páros/páratlan beállításával, akkor megjelenhet az óra
                  if (parseInt(ora.het) == het) {
                    orak.push(s_ora);
                  }
                }
              }
            }

            datum_tol = datum_tol.add(1, 'w');
            datum_ig = datum_ig.add(1, 'w');
            het = (het + 1) % 2;
          }
        });

        if (cb != undefined && typeof cb == "function")
          cb(orak);

      }
    });
  },

  /**
   * Oktatók lekérdezése
   *
   */
  getOktatok: function(cb) {
    var obj = this;
    var {store} = this.context;

    Ajax.call('orarend/oktatok/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        var oktatok = [{value:0, label:'- válassz -'}, ...data.oktatok];

        if (cb != undefined && typeof cb == "function")
          cb(oktatok);
      }
    });
  },

  /**
   * Termek lekérdezése
   *
   */
  getTermek: function(cb) {
    var obj = this;
    var {store} = this.context;

    Ajax.call('orarend/termek/' + localStorage.token, store, {
      async: false,
      method: 'POST',
      data: {
      },
      success: function(data) {
        var termek = data.termek;

        if (cb != undefined && typeof cb == "function")
          cb(termek);
      }
    });
  },

  /**
   * Szakos tantervek lekérdezése
   *
   */
  getTantervekSzak: function(cb) {
    var {store} = this.context;

    Ajax.call('orarend/tantervek/szak/' + localStorage.token + '/' + this.props.adatok.szak_id, store, {
      async: false,
      success: function(data) {
        var tantervek = [{value:0, label:'- válassz -'}, ...data.tantervek];

        if (cb != undefined && typeof cb == "function")
          cb(tantervek);
      }
    });
  },

  /**
   * Szakirányos tantervek lekérdezése
   *
   */
  getTantervekSzakirany: function(cb) {
    var {store} = this.context;

    Ajax.call('orarend/tantervek/szakirany/' + localStorage.token + '/' + this.props.adatok.szakirany_id, store, {
      async: false,
      success: function(data) {
        var tantervek = [{value:0, label:'- válassz -'}, ...data.tantervek];

        if (cb != undefined && typeof cb == "function")
          cb(tantervek);
      }
    });
  },

  /**
   * Tantervek lekérdezése
   *
   */
  getTantervek: function(cb) {
    var {store} = this.context;

    Ajax.call('orarend/tantervek/' + localStorage.token + '/' + this.props.adatok.szak_id + '/' + this.props.adatok.szakirany_id, store, {
      async: false,
      success: function(data) {
        var tantervek = [{value:0, label:'- válassz -'}, ...data.tantervek];

        if (cb != undefined && typeof cb == "function")
          cb(tantervek);
      }
    });
  },

  /**
   * Terem combó változtatás
   *
   */
  teremCombo: function(value) {
    var self = this;
    this.props.nezetTeremIDBeallitas(self.props.adatok.id, value);
    this.setState({
      feltetel: 1
    });
  },

  /**
   * Oktató combó változtatás
   *
   */
  oktatoCombo: function(value) {
    var self = this;
    self.props.nezetOktatokIDBeallitas(self.props.adatok.id, value);
    this.setState({
      feltetel: 1
    });
  },

  napValt: function(value, nap) {
    this.props.nezetNapBeallitas(this.props.adatok.id, nap, value);
  },


  /**
   * Szakok lekérdezése
   *
   */
  getSzakok: function(cb) {
    var {store} = this.context;

    Ajax.call('orarend/szakok/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        var szakok = [{value:0, label:'- válassz -'}, ...data.szakok];

        if (cb != undefined && typeof cb == "function")
          cb(szakok);
      }
    });
  },

  /**
   * Félévek lekérdezése
   *
   */
  getFelevek: function(cb) {
    var {store} = this.context;

    Ajax.call('orarend/felevek/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        //var felevek = [{value:0, label:'- válassz -'}, ...data.felevek];
        var felevek = data.felevek;

        if (cb != undefined && typeof cb == "function")
          cb(felevek);
      }
    });
  },

  /**
   * Szakirányok lekérdezése
   *
   */
  getSzakiranyok: function(szak_id, cb) {
    var {store} = this.context;

    Ajax.call('orarend/szakiranyok/' + localStorage.token + '/' + szak_id, store, {
      async: false,
      success: function(data) {
        var szakiranyok = [{value:0, label:'- válassz -'}, ...data.szakiranyok];

        if (cb != undefined && typeof cb == "function")
          cb(szakiranyok);
      }
    });
  },

  /**
   * Szak combó változtatás
   *
   */
  onSzakComboChange: function(value) {
    var _this = this;

    _this.props.szakIDBeallitas(_this.props.adatok.id, value);

    // Szakirányok lekérdezése
    this.getSzakiranyok(value, function(szakiranyok) {
      _this.props.nezetSzakiranyokBeallitas(_this.props.adatok.id, szakiranyok);
    });

    this.setState({
      feltetel: 0
    });
  },

  /**
   * Szakirány combó változtatás
   *
   */
  onSzakiranyComboChange: function(value) {
    var _this = this;
    this.props.szakiranyIDBeallitas(this.props.adatok.id, value);

    this.setState({
      feltetel: 0
    });
  },

  /**
   * Félév combó változtatás
   *
   */
  onFelevComboChange: function(value) {
    this.props.felevIDBeallitas(this.props.adatok.id, value);
  },

  levelezoNezetValt: function(value) {
    this.props.levelezoNezetBeallitas(this.props.adatok.id, value);
    this.props.nezetekNaptarFrissitese();
  },

  undo: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('get_last_undo/' + localStorage.token + '/' + _this.props.loggedUser.id, store, {
      success: function(data) {
        if (data.muveletek.length == 0)
          return false;

        swal({
          title: "Visszavonja a műveletet?",
          type: "warning",
          html: true,
          text: data.text,
          customClass: 'undo-info',
          showCancelButton: true,
          confirmButtonText: "Igen",
          cancelButtonText: "Mégsem",
          closeOnConfirm: true,
          animation: false
        },
        function() {
          Ajax.call('undo/' + localStorage.token + '/' + _this.props.loggedUser.id, store, {
            success: function(data) {
              _this.props.nezetekNaptarFrissitese();
            }
          });
        });
      }
    });
  },

  redo: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('get_last_redo/' + localStorage.token + '/' + _this.props.loggedUser.id, store, {
      success: function(data) {
        if (data.muveletek.length == 0)
          return;

        swal({
          title: "Visszaállítja?",
          type: "warning",
          text: data.text,
          html: true,
          showCancelButton: true,
          customClass: 'undo-info',
          confirmButtonText: "Igen",
          cancelButtonText: "Mégsem",
          closeOnConfirm: true,
          animation: false
        },
        function() {
          Ajax.call('redo/' + localStorage.token + '/' + _this.props.loggedUser.id, store, {
            success: function(data) {
              _this.props.nezetekNaptarFrissitese();
            }
          });
        });
      }
    });
  },

  generationChange: function(value) {
    alert(value);
  },

  generatePDF: function() {
    var _this = this;
    var {store} = this.context;

    $.fn.spin.presets.preloader = {lines:13, length:0, width:3, radius:10, scale:.75, corners:1, opacity:.25, speed:1};
    $(this.refs.pdf_btn).addClass('with-preloader');
    $(this.refs.pdf_btn).spin('preloader');

    Ajax.call('nappali_orarend_pdf/' + localStorage.token, store, {
      method: 'POST',
      data: {
        szak_id: this.props.adatok.szak_id,
        szak_megn: this.props.adatok.szak_megn,
        szakirany_id: this.props.adatok.szakirany_id,
        szakirany_megn: this.props.adatok.szakirany_megn != undefined ? this.props.adatok.szakirany_megn : '',
        felev: this.props.adatok.felev_megn,
        orak: this.state.ajax_orak,
        oktato_megn: this.props.adatok.oktato_megn != undefined ? this.props.adatok.oktato_megn : '',
        terem_megn: this.props.adatok.terem_megn != undefined ? this.props.adatok.terem_megn : '',
        felev_id: this.props.adatok.felev_id
      },
      success: function(data) {
        $(_this.refs.pdf_btn).spin(false).removeClass('with-preloader');
        if (data.file != '') {
          window.open("api/libraries/mpdf60/tmp/"+data.file);
        }
        if (data.msg != '') {
          swal('Hiba', data.msg, 'error');
        }
      }
    });
  },

  render() {
    var self = this;

    return (
      <div>
        <Form ref="form">
          <div style={{'marginBottom':'10px'}} className="row">
            <div className="col-xs-3">
              <Select2 placeholder="Keresés" onChange={this.teremCombo} multi={true} name="terem_id" value={this.props.adatok.terem_id} title="Terem" options={this.props.adatok.termek}/>
            </div>
            <div className="col-xs-3">
              <Select2 placeholder="Keresés" onChange={this.oktatoCombo} multi={true} name="oktato_id" value={this.props.adatok.oktato_id} title="Oktató" options={this.props.adatok.oktatok}/>
            </div>
            <div className="col-xs-1">
              <Select ref="feltetel" onChange={(val)=>{self.setState({feltetel:val})}} name="feltetel" value={this.state.feltetel} title="&nbsp;" options={[{value:0, label:"szűkítés"},{value:1, label:"és"}]}/>
            </div>
          </div>

          <div style={{'marginBottom':'10px'}} className="row">
            <div className="col-xs-3">
              <Select2 onChange={this.onSzakComboChange} name="szak_id" value={this.props.adatok.szak_id} title="Szak" options={this.props.adatok.szakok}/>
            </div>
            <div className="col-xs-3">
              <Select onChange={this.onSzakiranyComboChange} name="szakirany_id" value={this.props.adatok.szakirany_id} title="Szakirány" options={this.props.adatok.szakiranyok}/>
            </div>
            <div className="col-xs-1">
              <Select onChange={this.onFelevComboChange} name="felev_id" value={this.props.adatok.felev_id} title="Félév" options={this.props.adatok.felevek}/>
            </div>
            <div className="col-xs-1">
              <Toggle onChange={(value)=>{self.props.szakossalBeallitas(self.props.adatok.id, value)}} name="szakossal" title="Szakos órák" value={this.props.adatok.szakossal}/>
            </div>
            <div className="col-xs-3">
              <button ref="nezet_frissit_btn" style={{'marginTop':'12px'}} onClick={this.naptarFrissit} className="btn btn-white" type="button"><i className="fa fa-refresh"></i>Frissít</button>
              <button ref="pdf_btn" style={{'marginTop':'12px','marginLeft':'10px'}} onClick={this.generatePDF} className="btn btn-white" type="button"><i className="fa fa-file-pdf-o"></i>PDF</button>
            </div>
            <div className="col-xs-2">
              <div className="pull-right">
                {/*<button ref="undo_btn" style={{'marginTop':'12px'}} onClick={()=>{this.undo()}} className="btn btn-white only-icon" type="button"><i className="fa fa-undo"></i></button>
                <button ref="redo_btn" style={{'marginTop':'12px','marginLeft':'5px'}} onClick={()=>{this.redo()}} className="btn btn-white only-icon" type="button"><i className="fa fa-repeat"></i></button>*/}
              </div>
            </div>
          </div>
  {/*
          <div className="row" style={{'marginBottom':'20px'}}>
            <div className="col-xs-1">
              <Toggle onChange={(value) => this.napValt(value, 'hetfo')} name="nap[hetfo]" title="Hétfő" value={this.props.adatok.napok.hetfo}/>
            </div>
            <div className="col-xs-1">
              <Toggle onChange={(value) => this.napValt(value, 'kedd')} name="nap[kedd]" title="Kedd" value={this.props.adatok.napok.kedd}/>
            </div>
            <div className="col-xs-1">
              <Toggle onChange={(value) => this.napValt(value, 'szerda')} name="nap[szerda]" title="Szerda" value={this.props.adatok.napok.szerda}/>
            </div>
            <div className="col-xs-1">
              <Toggle onChange={(value) => this.napValt(value, 'csutortok')} name="nap[csutortok]" title="Csütörtök" value={this.props.adatok.napok.csutortok}/>
            </div>
            <div className="col-xs-1">
              <Toggle onChange={(value) => this.napValt(value, 'pentek')} name="nap[pentek]" title="Péntek" value={this.props.adatok.napok.pentek}/>
            </div>
            <div className="col-xs-1">
              <Toggle onChange={(value) => this.napValt(value, 'szombat')} name="nap[szombat]" title="Szombat" value={this.props.adatok.napok.szombat}/>
            </div>
          </div>*/}
        </Form>
        <div className="row">
          <div className="col-xs-12">
            <h4 style={{'margin':'10px 0px 30px 0px','textAlign':'center'}} className="view-only-print">{this.props.adatok.szak_megn} ({this.props.adatok.felev_megn}). félév</h4>
            <div className="panel-sub-heading">
              <h4>Naptár</h4>
            </div>
            <Naptar
              ujIdopont={self.props.ujIdopont}
              oraAdatokModalShow={self.props.oraAdatokModalShow}
              idopontValtoztatas={self.props.idopontValtoztatas}
              oraTorles={self.props.oraTorles}
              oraAthelyezesFrissites={self.props.oraAthelyezesFrissites}
              nezetekNaptarFrissitese={self.props.nezetekNaptarFrissitese}
              elrakatlanOrakModalHide={self.props.elrakatlanOrakModalHide}
              elrakatlanOrakModalHide_={self.props.elrakatlanOrakModalHide_}
              elrakatlanOrakModalShow_={self.props.elrakatlanOrakModalShow_}
              teremValasztoModalHide={self.props.teremValasztoModalHide}
              teremValasztoModalShow={self.props.teremValasztoModalShow}
              getIdopontok={self.getIdopontok}
              setOraMentes={self.props.setOraMentes}
              aktivNezetIdopontokBeallitas={self.props.aktivNezetIdopontokBeallitas}
              aktivNezetIdopontokLekerese={self.props.aktivNezetIdopontokLekerese}
              aktivNezethezIdopontokHozzadasa={self.props.aktivNezethezIdopontokHozzadasa}
              i={this.props.i}
              loggedUser={self.props.loggedUser}
              idopontok={this.props.adatok.idopontok}
              levelezo_nezet={this.props.adatok.levelezo_nezet}
              ref="naptarobj"
              />
              <div className="color-info">
                <div className="item"><div style={{'backgroundColor':'#0080FF'}} className="box"></div>Csak egy szaknak kötelező</div>
                <div className="item"><div style={{'backgroundColor':'#BF00FF'}} className="box"></div>Több szaknak kötelező</div>
                <div className="item"><div style={{'backgroundColor':'#FF8000'}} className="box"></div>Csak egy szakirányon kötelező</div>
                <div className="item"><div style={{'backgroundColor':'#FFFF00'}} className="box"></div>Több szakirányon kötelező</div>
                <div className="item"><div style={{'backgroundColor':'#00FFFF'}} className="box"></div>Szabadon választható</div>
                <div className="item"><div style={{'backgroundColor':'#00FFFF'}} className="box"></div>Kötelezően választott</div>
                <div className="item"><div style={{'backgroundColor':'#F7ACAC'}} className="box"></div>Mentesítés</div>
              </div>
          </div>
        </div>
      </div>
    );
  }
});

Nezet.contextTypes = {
  store: React.PropTypes.object
}

export default Nezet;