//var React = require('react');
import React from 'react';

const Home = React.createClass({
	render() {
		return (
      <div className="panel">
        <div className="panel-heading">
          <h4>Home</h4>
        </div>
        <div className="panel-body">
          Főoldal
        </div>
      </div>
    );
	}
});

//module.exports = Home;
export default Home;