import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button, Tabs, Tab} from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import InputHidden from './form/InputHidden';
import Select from './form/Select';
import Select2 from './form/Select2';
import Textarea from './form/Textarea';

var MentesitesLevelezo = React.createClass({
  getInitialState() {
    return {
      jogcimek: [],
      jogcim_adatok: [],
      mentesites: [],
      oktatok: [],
      levelezos_napok: [],
      mentesites_id: '',
      nap_id: '',
      periodus_id: '',
      myform: {},
      napok: [
        { value: '', label: '- válassz -' },
        { value: '0', label: 'Hétfő' },
        { value: '1', label: 'Kedd' },
        { value: '2', label: 'Szerda' },
        { value: '3', label: 'Csütörtök' },
        { value: '4', label: 'Péntek' },
      ],
      idokezd: [],
      idoveg: [],
      jogcim: {
        id: '',
        nev: '',
        ido_tipus: '',
        alkalom: ''
      },
      formIsValid: false
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {
    var obj = this;
    var {store} = this.context;

    this.state.mentesites.tanar_id = '';

    if (obj.props.params.id == undefined) {
      obj.props.params.id = 0;
    }

    Ajax.call('mentesites_nappali/' + localStorage.token + '/' + obj.props.params.id + '/2/' + obj.state.mentesites.tanar_id, store, {
      success: function(data) {
        data.jogcimek = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.jogcimek
        ];
        data.idokezd = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.idokezd
        ];
        data.idoveg = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.idoveg
        ];
         data.levelezos_napok = [
          {
            value: '',
            label: '- válassz -'
          },
          ...data.levelezos_napok
        ];

        obj.setState({jogcimek: data.jogcimek, jogcim_adatok: data.jogcim_adatok, idokezd: data.idokezd, idoveg: data.idoveg, oktatok: data.oktatok, levelezos_napok: data.levelezos_napok});

        if (data.mentesites != undefined) {
          var jogcim = obj.state.jogcim_adatok[data.mentesites.mentesites_jogcim_id];
          obj.setState({mentesites: data.mentesites, mentesites_id: data.mentesites.id, jogcim: jogcim});
        }
      }
    });
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
  },

  onChangeJogcim: function(val) {
    var obj = this;
    if(val.length > 0) {
      var jogcim = this.state.jogcim_adatok[val];
      this.setState({
       jogcim: jogcim
      });
    } else {
      this.setState({
        jogcim: {
          id: '',
          nev: '',
          ido_tipus: '',
          alkalom: ''
        }
      });
    }
  },

  onMent: function() {
    var self = this;
    var akt_oktato = 0;
    if(this.state.mentesites.tanar_id) {
      akt_oktato = this.state.mentesites.tanar_id;
    } else if(parseInt(this.props.params.tanar_id)) {
      akt_oktato = parseInt(this.props.params.tanar_id);
    }

    this.refs.form.submit();

    if (this.state.formIsValid) {
      var formData = this.refs.form.getModel();
      formData.loggedUser_group = this.props.prop_loggedUser.group_id;

      var obj = this;
      var {store} = this.context;

      Ajax.call('mentesites_nappali/ment/' + localStorage.token, store, {
        method: "POST",
        data: {
          data: formData
        },
        success: function(data) {
          hashHistory.push('/mentesitesek_levelezo/' + akt_oktato);
        }
      });
    }
  },

  onBack: function() {
    var akt_oktato = 0;
    if(this.state.mentesites.tanar_id) {
      akt_oktato = this.state.mentesites.tanar_id;
    } else if(parseInt(this.props.params.tanar_id)) {
      akt_oktato = parseInt(this.props.params.tanar_id);
    }
    
    hashHistory.push('/mentesitesek_levelezo/' + akt_oktato);
  },

  onValid: function() {
    this.setState({
      formIsValid: true
    });
  },

  onInvalid: function() {
    this.setState({
      formIsValid: false
    });
  },

  render: function() {
      var i = 1;
      var akt_oktato = 0;

      if(this.state.mentesites.tanar_id) {
        akt_oktato = this.state.mentesites.tanar_id;
      } else if(parseInt(this.props.params.tanar_id)) {
        akt_oktato = parseInt(this.props.params.tanar_id);
      }

      var inputattr = '';
      if(this.state.mentesites.id !== undefined && this.state.mentesites.id != 0) {
        inputattr = 'disabled';
      } else {
        inputattr = 'required';
      }
      
      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <button onClick={this.onBack} className="btn btn-primary" type="button"><i className="fa fa-arrow-left"></i>Vissza</button>
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Mentesítések
              </li>
              <li>
                Levelező mentesítések
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Mentesítsések</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <button onClick={this.onBack} className="btn btn-primary" type="button"><i className="fa fa-arrow-left"></i>Vissza</button>
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Mentesítés</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">

                    <Form ref="form" onValid={this.onValid} onInvalid={this.onInvalid} onChange={(e)=>{this.state.myform=e;}}>
                      <Select2 title="Oktató" placeholder="Keresés" name="tanar_id" value={akt_oktato} options={this.state.oktatok} required={inputattr == 'required' ? true : false} disabled={inputattr == 'disabled' ? true : false} />
                      <Select name="mentesites.jogcim" title="Típus" value={this.state.jogcim.id} options={this.state.jogcimek} validations="isNumeric" validationError="Jogcím kötelező" onChange={this.onChangeJogcim} required={inputattr == 'required' ? true : false} disabled={inputattr == 'disabled' ? true : false} />
                      <Select key={"mentesites.napja"+i} name={"mentesites.napja"+i} title="Nap" value={this.state.mentesites.idopontok !== undefined ? this.state.mentesites.idopontok["napja"+i] : ''} options={this.state.levelezos_napok} validations="isNumeric" validationError="Nap kötelező" required />
                      <InputHidden name={"mentesites.megnevezes"} value={this.state.jogcim.nev}/>
                      <InputHidden name="mentesites.alkalom" value={this.state.jogcim.alkalom}/>
                      <InputHidden name="mentesites.ido_tipus" value={this.state.jogcim.ido_tipus}/>
                      <InputHidden name="mentesites.id" value={this.state.mentesites_id}/>
                      <InputHidden name="loggedUser" value={this.props.prop_loggedUser.id}/>
                    </Form>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

MentesitesLevelezo.contextTypes = {
  store: React.PropTypes.object
}

export default MentesitesLevelezo;