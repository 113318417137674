import React from 'react';
import Formsy from 'formsy-react';
import utils from 'formsy-react/lib/utils';

const Toggle = React.createClass({
  mixins: [Formsy.Mixin],

  changeValue(event) {
    if (this.props.onChange != undefined)
      this.props.onChange(event.currentTarget.value);
  },

  onClick() {
    this.getValue() == 1 ? this.setValue(0) : this.setValue(1);

    if (this.props.onClick != undefined)
      this.props.onClick(this.getValue());
  },

  componentDidUpdate: function(prevProps, prevState) {
    if (!utils.isSame(prevState, this.state) && this.props.onChange != undefined) {
      this.props.onChange(this.getValue());
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    if (this.getValue() != nextState._value || nextState._formSubmitted || this.props.value != nextProps.value)
      return true;
    else
      return false;
  },

  render() {
    var toggle_class = this.state._value == 1 ? "toggle active" : "toggle";

    return (
      <div className="form-group toggle">
        {this.props.title != '' ? (<label className="form-label" htmlFor={this.props.name}>{this.props.title} {this.isRequired() ? '*' : null}</label>) : null}
        <input onChange={this.changeValue} className="toggle-input" checked={this.state._value == 1 ? true : false} type="checkbox" name={this.props.name} value={this.getValue()} />
        <div onClick={this.onClick} className="toggle-wrapper">
          <div className={toggle_class}>
            <div className="toggle-slide">
              <div className="toggle-inner">
                <div className="toggle-on">Igen</div>
                <div className="toggle-blob"></div>
                <div className="toggle-off">Nem</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default Toggle;