import React from 'react';
import Ajax from './../Ajax.js';

var timeout;

var ImportalasStatusz = React.createClass({
  getInitialState() {
    return {
      latszik: false,
      szazalek: 0,
      maxProgress: 0,
      progress: 0
    }
  },

  componentDidMount: function() {
    this.statuszLekerdezes();
  },

  leallit: function() {
    var _this = this;
    var {store} = this.context;

    swal({
      title: "Leállítja az importálást?",
      type: "info",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
    },
    function() {
      setTimeout(function() {
        Ajax.call('idoszakok/importalas_leallitas/' + _this.props.loggedUser.aktiv_orarend.id, store, {
          method: 'GET',
          async: false,
          success: function(data) {
            if (data.success === false) {
              swal('Hiba!', data.error, 'error');

            } else {
              _this.setState({
                latszik: false,
                szazalek: '0%',
                fitness: 15000,
              });

              if (timeout != undefined)
                clearTimeout(timeout);

              swal('Siker!', 'Az adatok rögzítésre kerültek. ', 'success');
            }
          }
        });
      }, 500);
    });
  },

  serviceStart: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/orkd_start', store, {
      method: 'GET',
      async: false,
      success: function(data) {
        if (data) {
          swal('Siker!', 'Szolgáltatás elindult.', 'success');
        } else {
          swal('Hiba!', 'Nem sikerült elindítani a szolgáltatást.', 'error');
        }
      }
    });
  },

  serviceStop: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/orkd_stop', store, {
      method: 'GET',
      async: false,
      success: function(data) {
        if (data) {
          swal('Siker!', 'Szolgáltatás leállt.', 'success');
        } else {
          swal('Hiba!', 'Nem sikerült leállítani a szolgáltatást.', 'error');
        }
      }
    });
  },

  serviceRestart: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/orkd_restart', store, {
      method: 'GET',
      async: false,
      success: function(data) {
        if (data) {
          swal('Siker!', 'Szolgáltatás újraindult.', 'info');
        } else {
          swal('Hiba!', 'Nem sikerült újraindítani a szolgáltatást.', 'error');
        }
      }
    });
  },

  statuszLekerdezes: function () {
    var _this = this;
    var {store} = this.context;

    Ajax.call('idoszakok/importalas_lekerdezese', store, {
      method: 'GET',
      async: false,
      success: function(data) {
        if (data !== false) {
          if (data.state == "importing") {
            var szazalek;
            if(data.data.progress == data.data.maxProgress) {
              szazalek = 0;
            } else {
              szazalek = ((data.data.progress / data.data.maxProgress) * 100);
            }
            _this.setState({
              latszik: true,
              progress: data.data.progress,
              maxProgress: data.data.maxProgress,
              szazalek: szazalek + '%'
            });

            timeout = setTimeout(_this.statuszLekerdezes, 1000);

          } else if (data.state == "finished" || data.state == "idle") {
            if (timeout != undefined)
              clearTimeout(timeout);

            if(_this.state.latszik) {
              swal('Siker!', 'Az adatok rögzítésre kerültek. ', 'success');
            }

            _this.setState({
              latszik: false,
              szazalek: 0,
              maxProgress: 0,
              progress: 0
            });
          }

        } else if (data === false) {
          if (timeout != undefined)
            clearTimeout(timeout);

          if(_this.state.latszik) {
            swal('Hiba!', 'Hiba történt az importálás közben.', 'error');
          }

          _this.setState({
            latszik: false,
            szazalek: 0,
            maxProgress: 0,
            progress: 0
          });
        }
      }
    });
  },

  render() {
    return (
      <div>
        {this.state.latszik ?
        <div className="overlayer">
          <div className="overlayer-progress-outer">
            <h1>Importálás folyamatban...</h1>
            <div className="progress">
              <div className="progress-bar progress-bar-success progress-bar-striped" role="progressbar" style={{"width": this.state.szazalek}}></div>
            </div>
            <div style={{'textAlign':'center'}}>
              {/*<button onClick={this.leallit} className="btn btn-primary" type="button"><i className="fa fa-hand-paper-o"></i>Importálás leállítása</button>*/}
              {/*<button style={{'marginLeft':'20px'}} onClick={this.serviceStart} className="btn btn-white" type="button"><i className="fa fa-play-circle"></i>START</button>
              <button style={{'marginLeft':'5px'}} onClick={this.serviceStop} className="btn btn-white" type="button"><i className="fa fa-stop-circle"></i>STOP</button>
              <button style={{'marginLeft':'5px'}} onClick={this.serviceRestart} className="btn btn-white" type="button"><i className="fa fa-refresh"></i>RESTART</button>*/}
            </div>
          </div>
        </div> : null
        }
      </div>
    )
  }
});

ImportalasStatusz.contextTypes = {
  store: React.PropTypes.object
}

export default ImportalasStatusz;