import React from 'react';
import Ajax from './../Ajax.js';
import Select from './../form/Select';
import Toggle from './../form/Toggle';
import InputHidden from './../form/InputHidden';

var Nap = React.createClass({
  getInitialState() {
    return {
      opciok: [{
            value: '',
            label: '- válassz -'
          }],
      mindennap: 0,
      adottnap: 0,
      ertek: '',
      mounted: 0
    }
  },

  getNapok: function(adottnap,cb) {
    var obj = this;
    var {store} = this.context;

    if (adottnap == 1) {
      Ajax.call('idoszak/' + localStorage.token + '/' + this.props.aktiv_idoszak_id, store, {
        async: false,
        success: function(data) {
          var felev_kezdete = data.felev_kezdete;
          var felev_vege = data.felev_vege;
          var tmp = [];

          var akt_nap = moment(felev_kezdete).format('YYYY-MM-DD 12:00:00');
          var akt_nap_ts = moment(akt_nap).unix();
          felev_vege = moment(felev_vege).format('YYYY-MM-DD 12:00:00');
          var felev_vege_ts = moment(felev_vege, "YYYY-MM-DD 12:00:00").unix();

          while(akt_nap_ts < felev_vege_ts) {
            akt_nap = moment(akt_nap, "YYYY-MM-DD 12:00:00");
            akt_nap_ts = moment(akt_nap).unix();

            if(moment(akt_nap).format('d') != 6 && moment(akt_nap).format('d') != 0) {
              tmp.push({
                value: akt_nap_ts,
                label: akt_nap.format('YYYY-MM-DD')
              })
            }
            akt_nap = moment(akt_nap, "YYYY-MM-DD 12:00:00").add(1, 'days');
          }
          obj.setState({opciok: tmp},function(){
            if($.isFunction(cb))
              cb();
          });
        }
      });
    } else {
      obj.setState({
        opciok: [
          {
            value: 0,
            label: 'Hétfő'
          },
          {
            value: 1,
            label: 'Kedd'
          },
          {
            value: 2,
            label: 'Szerda'
          },
          {
            value: 3,
            label: 'Csütörtök'
          },
          {
            value: 4,
            label: 'Péntek'
          }
        ]
      },function(){
        if($.isFunction(cb))
          cb()
      });
    }
  },

  componentDidMount: function() {
    var obj = this;
    var mindennap = 0;
    var adottnap = 0;
    var ertek = this.props.ertek?this.props.ertek:this.state.opciok[0].value;
    if(this.props.ertek == null) {
      mindennap = 1;
    } else if (this.props.ertek > 6) {
      adottnap = 1;
    }
    this.setState({mindennap: mindennap, adottnap: adottnap, ertek: ertek});
    this.getNapok(adottnap, function() {
      obj.setState({mounted: 1});
    });
  },

  onChange: function(val) {
    var adottnap = this.state.adottnap;
    if(val == 1) {
      adottnap = 0;
    }
    this.setState({mindennap: val, adottnap: adottnap, ertek: this.state.opciok[0].value});
  },

  onChangeAdottnap: function(val) {
    if (this.state.mounted == 0)
      return;

    var obj = this;
    var mindennap = this.state.mindennap;
    if(val == 1) {
      mindennap = 0;
    }
    this.setState({adottnap: val, mindennap: mindennap});
    this.getNapok(val,function(){obj.setState({ertek: obj.state.opciok[0].value})});
  },

  render: function() {
    return (
      <section>
        {(this.props.altipus == 1 || this.props.altipus == 2)  ? (
          <Toggle onChange={this.onChange} title='Minden nap' name='mindennap' value={this.state.mindennap} />
        ):("")}
        {(this.props.altipus == 2 && this.state.mindennap == 0) ? (
          <Toggle onChange={this.onChangeAdottnap} title='Adott nap' name='adottnap' value={this.state.adottnap} />
        ):("")}
        {((this.props.altipus == 1 || this.props.altipus == 2) && this.state.mindennap == 0) || this.props.altipus == 0 || this.props.altipus == null ? (
          <Select name={`feltetel[ertek][p_${this.props.id}]`} value={this.state.ertek} title={this.state.adottnap ? 'Dátum' : this.props.label} options={this.state.opciok}/>
        ):(
          <InputHidden name={`feltetel[ertek][p_${this.props.id}]`} value={""}/>
        )}
      </section>
    );
  }
});

Nap.contextTypes = {
  store: React.PropTypes.object
}

export default Nap;