import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';

var Contact = React.createClass({
  componentWillMount: function() {
  },

  handleClick: function() {
    this.props.gyumolcs('körte');
  },

  handleClick2: function() {
    this.props.motor('Aprilia');
  },

	render: function() {
    //console.log(this.props);

		return (
      <div>
        <Header/>
        <LeftPanel {...this.props}/>
        <div id="main-panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Kapcsolat
              </li>
              <li><a href="#" className="active">Form Elements</a></li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Kapcsolat</h1>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Contact</h4>
              </div>
              <div className="panel-body">
                Kapcsolat rész
                <button className="btn btn-white" type="button" onClick={this.handleClick}>Gyümölcs módosítása</button>
                <button className="btn btn-white" type="button" onClick={this.handleClick2}>Autó módosítása</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
	}
});

export default Contact;