module.exports = {
  setFixedMenu: function(obj) {
    $(window).scroll(function() {
      if ($(window).scrollTop() > 100) {
        $(obj.refs.fixed_top_menu_outer).addClass('active');
      } else {
        $(obj.refs.fixed_top_menu_outer).removeClass('active');
      }
    });
  },
  setDropDownToggle: function(obj) {
    $(obj.refs.main_panel).find('.dropdown-toggle').click(function(e) {
      e.preventDefault();
      $('.dropdown').not($(obj).parent('.dropdown')).removeClass('open');
      $(this).parent('.dropdown').toggleClass('open');
    });
    $(document).click(function(e) {
      if (!$(e.target).hasClass('fa') && !$(e.target).hasClass('dropdown-toggle')) {
        $('.dropdown').removeClass('open');
      }
    });
  },
}