import React from 'react';
import Ajax from './../Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import { Form } from 'formsy-react';
import Input from './../form/Input';
import InputHidden from './../form/InputHidden';
import Select from './../form/Select';
import Toggle from './../form/Toggle';
import MultiDate from './../form/MultiDate';
import utils from 'formsy-react/lib/utils';

var LevelezoNapModal = React.createClass({
  getInitialState() {
    return {
      show: false,
      valid_form: false,
      napok: [],
      orarend_id: 0
    }
  },

  componentDidUpdate: function() {

  },

  ment: function() {
    var {store} = this.context;
    var _this = this;

    var formData = this.refs.form.getModel();

    Ajax.call('levelezos_napok_beall/' + localStorage.token, store, {
      method: "POST",
      data: {
        napok: formData.napok,
        orarend_id: _this.state.orarend_id
      },
      success: function(data) {
        if (data.result == true) {
          _this.hide();
          swal('Siker!', 'Levelezős nap beállítások módosultak.', 'success');
        } else {
          swal('Hiba!', data.hiba, 'error');
        }
      }
    });
  },

  show: function(id) {
    var {store} = this.context;
    var _this = this;

    this.setState({
      show: true,
      orarend_id: id
    }, function() {
      Ajax.call('levelezos_napok/' + localStorage.token + '/' + _this.state.orarend_id, store, {
        success: function(data) {
          if (data.result) {
            _this.setState({
              napok: data.napok
            }, function() {
              _this.refs.napok.addDates(data.napok);
            });

          } else {
            swal('Hiba!', data.hiba, 'error');
          }
        }
      });
    });
  },

  hide: function() {
    this.setState({
      show: false,
      napok: [],
      orarend_id: 0
    });
  },

  render: function() {
    return (
    <div>
      {this.state.show &&
      <Modal show={this.state.show} onHide={this.hide}>
        <Modal.Header closeButton>
          <Modal.Title>Levelező napok</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref="form" onValid={() => {this.setState({valid_form: true})}} onInvalid={() => {this.setState({valid_form: false})}}>
            <MultiDate name="napok" ref="napok" value={this.state.napok}/>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.hide} type="button">Mégsem</Button>
          <button ref="preloader" onClick={this.ment} className="btn btn-primary">Módosít</button>
        </Modal.Footer>
      </Modal>
      }
    </div>)
  }
});

LevelezoNapModal.contextTypes = {
  store: React.PropTypes.object
}

export default LevelezoNapModal;