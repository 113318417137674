import React from 'react';
import Ajax from './../Ajax.js';
import Select2 from './../form/Select2';

var LevelezosNap = React.createClass({
  getInitialState() {
    return {
      opciok: []
    }
  },

  componentWillReceiveProps: function(nextProps) {
    if (nextProps.ismetlodes != this.props.ismetlodes)
      this.getNapok(nextProps.ismetlodes);
  },

  getNapok: function(ismetlodes) {
    var obj = this;
    var {store} = this.context;

    if (ismetlodes == 0 || ismetlodes == null) {
      Ajax.call('levelezos_napok/' + localStorage.token + '/' + this.props.aktiv_orarend_id + '/true', store, {
        async: false,
        success: function(data) {
          var tmp = [];
          data.napok_result.map(function(nap, i) {
            tmp.push({
              value: parseInt(nap.id),
              label: nap.datum
            })
          });
          obj.setState({opciok: tmp});
        }
      });
    } else {
      obj.setState({
        opciok: [
          {
            value: 4,
            label: 'Péntek'
          },
          {
            value: 5,
            label: 'Szombat'
          }
        ]
      });
    }
  },

  componentDidMount: function() {
    this.getNapok(this.props.ismetlodes);
    this.props.cb(this.props.ertek);
  },

  onChangeNap: function(val) {
    this.props.cb(val);
  },

  render: function() {
    return (
      <Select2 placeholder="- válassz -" name={`feltetel[ertek][p_${this.props.id}]`} value={this.props.ertek} title={this.props.label} options={this.state.opciok} validationError="Kötelező" onChange={this.onChangeNap} required/>
    );
  }
});

LevelezosNap.contextTypes = {
  store: React.PropTypes.object
}

export default LevelezosNap;