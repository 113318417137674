import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import ArrayHelper from './ArrayHelper';
import Pagination from './Pagination';
import { Form } from 'formsy-react';
import Input from './form/Input';

var EroforrasokEszkozok = React.createClass({
  getInitialState() {
    return {
      eszkozok: [],
      eszkozokSzama: 0,
      lap: 1,
      db: 0,
      limit: 100,
      kereso_megnevezes: ''
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {

  },

  getEszkozok: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('eroforrasok/eszkozok/' + localStorage.token, store, {
      method: "POST",
      data: {
        nev: this.refs.form.getModel().kereso_megnevezes,
        limit: this.state.limit,
        lap: this.state.lap
      },
      success: function(data) {
        obj.setState({eszkozok: data.eszkozok, db: data.eszkozokDb});
        jQueryHelper.setDropDownToggle(obj);
      }
    });
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
    jQueryHelper.setDropDownToggle(this);
    this.getEszkozok();
  },

  eszkozTorol: function(id) {
    var obj = this;
    var {store} = this.context;

    swal({
      title: "Biztos hogy törli az eszközt?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true
    },
    function() {
      Ajax.call('eroforrasok/eszkoz/torles/' + localStorage.token + '/' + id, store, {
        success: function(data) {
          var tmp = ArrayHelper.removeItemByFieldAndValue(obj.state.eszkozok, 'id', id);

          obj.setState({
            eszkozok: tmp
          });
        }
      });
    });
  },

  render: function() {
    var _this = this;

      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        {/*
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <Link className="btn btn-primary" to="/eroforrasok_uj_eszkoz"><i className="fa fa-plus"></i>Új eszköz</Link>
            </div>
          </div>
        </div>
        */}

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Erőforrások
              </li>
              <li>
                Eszközök
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Erőforrások, eszköz lista</h1>
            </div>
    {/*
            <div className="content-panel-top-btn-group">
              <Link className="btn btn-primary" to="/eroforrasok_uj_eszkoz"><i className="fa fa-plus"></i>Új eszköz</Link>
            </div>
            */}
            <div className="panel">
              <div className="panel-heading">
                <h4>Eszköz lista</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                  <Form ref="form">
                    <Input intervalTypingCallback={() => { _this.setState({lap:1}, _this.getEszkozok); }} ref="kereso_megnevezes" name="kereso_megnevezes" title="Megnevezés" value={this.state.kereso_megnevezes}/>
                  </Form>
                  {this.state.eszkozok.length > 0 ? (
                    <section>
                    <Pagination cb={(lap) => { _this.setState({lap:lap}, _this.getEszkozok); }} db={this.state.db} page={this.state.lap} limit={this.state.limit} />
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Név</th>
                          <th>Termek</th>
                          <th>Jó</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.eszkozok.map(eszkoz => (
                         <tr key={eszkoz.id}>
                            <td>{eszkoz.megnevezes}</td>
                            <td>{eszkoz.termek}</td>
                            <td>{eszkoz.score == 0 ? <i className="fa fa-check"></i> : <i className="fa fa-close"></i>}</td>
                            <td style={{"textAlign": "center"}}>
                              <ul className="icon-list">
                                <li className="dropdown"><a className="dropdown-toggle" href="javascript:void(0)"><i className="fa fa-gear"></i></a>
                                  <ul className="dropdown-menu">
                                    <li className="dropdown-header">Opciók</li>
                                    <li><Link to={`/eroforras_eszkoz/${eszkoz.id}`}><i className="fa fa-pencil-square-o"></i>Szerkeszt</Link></li>
                                    <li><a onClick={() => this.eszkozTorol(eszkoz.id)} href="javascript:void(0)"><i className="fa fa-trash"></i>Töröl</a></li>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))
                      }
                      </tbody>
                    </table>
                    <Pagination cb={(lap) => { _this.setState({lap:lap}, _this.getEszkozok); }} db={this.state.db} page={this.state.lap} limit={this.state.limit} />
                    </section>
                    ) : (<div>Nem található felszereltség.</div>)}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

EroforrasokEszkozok.contextTypes = {
  store: React.PropTypes.object
}

export default EroforrasokEszkozok;