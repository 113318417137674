import React from 'react';
import Input from './../form/Input';

var Datum = React.createClass({
  getInitialState() {
    return {
    }
  },

  render: function() {
    return (
      <Input date={1} name={`feltetel[ertek][p_${this.props.id}]`} value={this.props.ertek} title={this.props.label} validationError="Kötelező" required/>
    );
  }
});

Datum.contextTypes = {
  store: React.PropTypes.object
}

export default Datum;