//var React = require('react');
import React from 'react';

var NotFound = React.createClass({
	render: function() {
		return (
      <div className="panel">
        <div className="panel-heading">
          <h4>404 Error</h4>
        </div>
        <div className="panel-body">
          Az oldal nem található...
        </div>
      </div>
    );
	}
});

//module.exports = NotFound;
export default NotFound;