import React from 'react';
import Ajax from './../../Ajax.js';
import {Form} from 'formsy-react';
import Input from './../../form/Input';

var KurzusAdatokModal = React.createClass({
  getInitialState() {
    return {
      kurzus: '',
      aktiv_mintatantervek: ''
    }
  },

  componentDidMount: function() {
    $('#kurzus_adatok_modal').draggable({
      start: function( event, ui ) {
        $('#kurzus_adatok_modal').addClass('startdrag');
      }
    });
    this.ablakPozicio();
    this.getKurzusAdatok();
  },

  componentDidUpdate: function() {

  },

  ablakPozicio: function() {
    $(this.refs.kurzus_adatok_modal).css({
      'left': $(window).innerWidth() / 2 - parseInt($('.main-panel').css('padding-left')),
      'top': $(window).scrollTop() + $(window).innerHeight() / 2 - parseInt($('.main-panel').css('padding-top')) - 200
    });
  },

  getKurzusAdatok: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend_szerk/kurzus_adatok/' + localStorage.token + '/' + this.props.kurzus_id + '/' + this.props.idoszak_kezdete, store, {
      async: false,
      success: function(data) {
        _this.setState({
          kurzus: data.kurzusAdatok,
          aktiv_mintatantervek: data.kurzusAdatok.aktiv_mintatantervek
        });
      }
    });
  },

  render() {
    var _this = this;

    if (this.state.kurzus != '') {
      var tantargy = this.state.kurzus.kurzus_alapadatok.tantargy + '(' + this.state.kurzus.kurzus_alapadatok.tantargy_kod + ')';
      var orak = this.state.kurzus.kurzus_alapadatok.elrakott_orak + ' / ' + this.state.kurzus.kurzus_alapadatok.osszes_ora;
    }

    return (
      <div ref="kurzus_adatok_modal" id="kurzus_adatok_modal" className="modal-content no-overlay">
        <div className="modal-header">
          <button onClick={this.props.hide} type="button" className="close"><span aria-hidden="true">×</span></button>
          <h4 className="modal-title">Kurzus adatok</h4>
        </div>
        {this.state.kurzus != '' && (
        <Form ref="form">
          <div className="modal-body">
            <div className="row">
              <div className="col-xs-12">
                <div className="form-group">
                  <label className="form-label">Azonosító</label>
                  <div className="form-control-text">{this.state.kurzus.kurzus_alapadatok.kurzus_azonosito}</div>
                </div>
              </div>
              <div className="col-xs-12">
                <div className="form-group">
                  <label className="form-label">Tantárgy</label>
                  <div className="form-control-text">{tantargy}</div>
                </div>
              </div>
              <div className="col-xs-6">
                <div className="form-group">
                  <label className="form-label">Kurzus típus</label>
                  <div className="form-control-text">{this.state.kurzus.kurzus_alapadatok.kurzus_tipus}</div>
                </div>
              </div>
              <div className="col-xs-6">
                <div className="form-group">
                  <label className="form-label">Órák</label>
                  <div className="form-control-text">{orak}</div>
                </div>
              </div>
              <div className="col-xs-12">
                <div className="form-group">
                  <label className="form-label">Oktatók</label>
                  <div className="form-control-text">{this.state.kurzus.kurzus_alapadatok.oktatok}</div>
                </div>
              </div>
              <div className="col-xs-12">
                <div className="form-group">
                  <label className="form-label">Szakok</label>
                  <div className="form-control-text">{this.state.kurzus.kurzus_alapadatok.szakok}</div>
                </div>
              </div>
              <div className="col-xs-12">
                <div className="form-group">
                  <label className="form-label">Mintatantervek</label>
                  <div className="form-control-text">{this.state.aktiv_mintatantervek}</div>
                </div>
              </div>

              {this.state.kurzus.kurzus_feltetelek.length > 0 ? (
              <div className="col-xs-12">
                <div className="form-group">
                  <label className="form-label">Feltételek</label>
                  <div className="form-control-text">
                  {this.state.kurzus.kurzus_feltetelek.map(function(f, i) {
                      return (<span className="orarend-szerk-kurzus-adatok-feltetel-elem"><i className="fa fa-exclamation-triangle"></i>{f.megnevezes}</span>)
                  })}
                  </div>
                </div>
              </div>
              ) : null}
            </div>
          </div>
        </Form>
        )}
        <div className="modal-footer">
          <button onClick={this.props.hide} type="button" className="btn btn-default">Bezár</button>
        </div>
      </div>
     )
  }
});

KurzusAdatokModal.contextTypes = {
  store: React.PropTypes.object
};

export default KurzusAdatokModal;