import React from 'react';
import Formsy from 'formsy-react';
import ReactSelect from 'react-select';
import utils from 'formsy-react/lib/utils';

var timeout;

const Select2 = React.createClass({
  mixins: [Formsy.Mixin],

  changeValue(value) {
    if (this.props.multi) {
      var a = [];

      if (value != undefined) {
        value.map(function(item, i) {
          if (item.value != 0)
            a.push(item.value);
        });
      }

      this.setValue(a);

    } else {
        if (typeof value != undefined && value != null) {
          this.setValue(value.value);
        } else {
          this.setValue('');
        }

//      if (value == undefined) {
//        this.setValue('');
//      } else if (value.value != 0) {
//        this.setValue(value.value);
//      }
    }
  },

  onInputChange(value) {
    var _this = this;
    if (_this.props.onInputChange != undefined)
      _this.props.onInputChange(value);

//    clearTimeout(timeout);
//    timeout = setTimeout(function() {
//      if (_this.props.onInputChange != undefined) {
//        _this.props.onInputChange(value);
//      }
//    }, 1000);
  },

  componentDidUpdate: function(prevProps, prevState) {
    if (!utils.isSame(prevState, this.state) && this.props.onChange != undefined) {
      this.props.onChange(this.getValue());
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    if (this.getValue() !== nextState._value || nextProps.options != this.props.options || nextState._formSubmitted || this.props.value !== nextProps.value)
      return true;
    else
      return false;
  },

  render() {
    var className = 'form-group' + (this.props.className || ' ') +
      (this.showRequired() ? 'required' : this.showError() ? 'has-error' : '');

    var errorMessage = this.getErrorMessage();


    if (this.isFormSubmitted()) {
      if (!this.isValid()) {
        className = 'form-group has-error';
        errorMessage = this.getErrorMessage() ? this.getErrorMessage() : this.props.validationError;
      }
    }

    if (this.props.inpopup != undefined) {
      className = className + ' select2-in-popup';
    }

    return (
      <div className={className}>
        <label className="form-label" htmlFor={this.props.name}>{this.props.title} {this.isRequired() ? '*' : null}</label>
        <ReactSelect matchProp="label" placeholder={this.props.placeholder} onInputChange={this.onInputChange} multi={this.props.multi} value={this.getValue()} options={this.props.options} onChange={this.changeValue} disabled={this.props.disabled ? true : false} />
        <span className="validation-error">{errorMessage}</span>
      </div>
    );
  }

});

export default Select2;
