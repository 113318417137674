import React from 'react';
import Select from './form/Select';
import utils from 'formsy-react/lib/utils';

var Pagination = React.createClass({
  getInitialState() {
    return {
      page: 1,
      ossz: 0,
      limit: 100
    }
  },

  componentWillUpdate: function() {
  },

  componentWillMount: function() {
  },

  componentDidMount: function() {
    var limit = this.state.limit
    if(parseInt(this.props.limit)) {
      limit = parseInt(this.props.limit);
    }
    this.setState({ossz: this.props.db, page: this.props.page, limit: limit});
  },

  componentWillReceiveProps: function(nextProps) {
    var limit = this.state.limit
    if(parseInt(this.props.limit)) {
      limit = parseInt(this.props.limit);
    }
    this.setState({ossz: nextProps.db, page: nextProps.page, limit: limit});
  },

  comboChange: function(event) {
    this.paginationClick(parseInt(event.currentTarget.value));
  },

  paginationClick: function(page) {
    if(page < 1) page = 1;
    if(page > Math.ceil(this.state.ossz/this.state.limit)) page = Math.ceil(this.state.ossz/this.state.limit);
    this.props.cb(page);
  },

  shouldComponentUpdate(nextProps, nextState) {
    if (utils.isSame(nextProps, this.props) || utils.isSame(nextState, this.state))
      return false;
    else
      return true;
  },

  render: function() {
    if(this.state.ossz == 0) {
      return null;
    }

    var pages = [];
    var db = 2;
    var limit = this.state.limit;

    var all = Math.ceil(this.state.ossz/limit);
    var start = Math.max(this.state.page - db, 1);
    var end = Math.min(this.state.page + db, all);
    if(all > db) {
      if(this.state.page - db < 1) {
        end = end + ((this.state.page - db - 1) * -1);
      }
      if(this.state.page + db > all) {
        start = start - ((this.state.page + db) - all);
      }
    }
    if(start < 1) {
      start = 1
    }
    if(end > all) {
      end = all;
    }
    for (let i = start; i <= end; i++) {
      pages.push(<li key={'pagi_'+i} className={this.state.page == i ? ("active"):("")}><a href="javascript:void(0)" onClick={() => this.paginationClick(i)}>{i}</a></li>);
    }

    var options = [];
    for (var i = 1; i <= Math.ceil(this.state.ossz / this.state.limit); i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }

    if (this.props.comboWithNav != undefined) {
      return (
        <section>
        {this.props.infoHide == undefined ? <div style={{'paddingBottom':'10px'}}>Összesen {this.props.db} találat, {all} lapon.</div> : null}
        <ul className="pagination" style={this.props.style}>
          <li>
            <a href="javascript:void(0)" onClick={() => this.paginationClick(1)} aria-label="Első">
              <span aria-hidden="true"><i className="fa fa-angle-double-left"></i></span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" onClick={() => this.paginationClick(this.state.page-1)} aria-label="Előző">
              <span aria-hidden="true"><i className="fa fa-angle-left"></i></span>
            </a>
          </li>
          <li>
            <select className="form-control" onChange={this.comboChange} value={this.state.page}>
              {options}
            </select>
          </li>
          <li>
            <a href="javascript:void(0)" onClick={() => this.paginationClick(this.state.page+1)} aria-label="Következő">
              <span aria-hidden="true"><i className="fa fa-angle-right"></i></span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" onClick={() => this.paginationClick(all)} aria-label="Utolsó">
              <span aria-hidden="true"><i className="fa fa-angle-double-right"></i></span>
            </a>
          </li>
        </ul>
        </section>
       );
    } else {
      return (
        <section>
        {this.props.infoHide == undefined ? <div style={{'paddingBottom':'10px'}}>Összesen {this.props.db} találat, {all} lapon.</div> : null}
        <ul className="pagination" style={this.props.style}>
        {this.props.withCombo != undefined ? (
          <li>
            <select className="form-control" onChange={this.comboChange} value={this.state.page}>
              {options}
            </select>
          </li>
          ): null}
          <li>
            <a href="javascript:void(0)" onClick={() => this.paginationClick(1)} aria-label="Első">
              <span aria-hidden="true"><i className="fa fa-angle-double-left"></i></span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" onClick={() => this.paginationClick(this.state.page-1)} aria-label="Előző">
              <span aria-hidden="true"><i className="fa fa-angle-left"></i></span>
            </a>
          </li>
          {pages}
          <li>
            <a href="javascript:void(0)" onClick={() => this.paginationClick(this.state.page+1)} aria-label="Következő">
              <span aria-hidden="true"><i className="fa fa-angle-right"></i></span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" onClick={() => this.paginationClick(all)} aria-label="Utolsó">
              <span aria-hidden="true"><i className="fa fa-angle-double-right"></i></span>
            </a>
          </li>
        </ul>
        </section>
      );
    }
    }
});

Pagination.contextTypes = {
  store: React.PropTypes.object
}

export default Pagination;
