import React from 'react';
import Ajax from './../Ajax.js';
import utils from 'formsy-react/lib/utils';

var drag_ora_id = 0;

var Naptar = React.createClass({
  getInitialState() {
    return {
      periodusok: [],
      napok: []
    }
  },

  componentWillReceiveProps: function(nextProps) {
    console.log("Naptar "+this.props.i+": componentWillReceiveProps");
  },

  componentDidUpdate: function(prevProps, prevState) {
   if (!utils.isSame(prevState, this.state)) {
    console.log("Naptar "+this.props.i+": componentDidUpdate state különbözik");
   } else {
    console.log("Naptar "+this.props.i+": componentDidUpdate state egyezik");
   }

    $(this.refs.naptar).fullCalendar('render');
    $(this.refs.naptar).fullCalendar('removeEvents');
    $(this.refs.naptar).fullCalendar('addEventSource', this.props.idopontok);
    $(this.refs.naptar).fullCalendar('rerenderEvents');

    if (!utils.isSame(prevState, this.state)) {
      $(this.refs.naptar).fullCalendar('changeView', 'felev');
    }
  },

  componentDidMount: function() {
    var self = this;

    console.log("Naptar "+this.props.i+": componentDidMount");

    this.initFullCalendar();

    this.periodusokEsLevelezosNapokLekerdezese();
  },

  /**
   * Periódusok és napok lekérdezése
   *
   */
  periodusokEsLevelezosNapokLekerdezese: function () {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/levelezo_periodusok_es_napok/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        _this.setState({
          periodusok: data.periodusok,
          napok: data.napok
        });
      }
    });
  },

  /**
   * Nap és kezdő dátum alapján visszaadja, hogy létezik -e a periódus
   *
   */
  periodusEllenorzes: function(nap, kezdete) {
    var talalat = false;

    this.state.periodusok.map(function(periodus, i) {
      if (parseInt(periodus.nap) == parseInt(nap) && periodus.kezdete == kezdete)
        talalat = true;
    });

    return talalat;
  },

  /**
   * Levelezős nap ellenőrzés
   *
   */
  napEllenorzes: function(datum) {
    var talalat = false;

    this.state.napok.map(function(nap, i) {
      if (nap.datum == datum && nap.generalasbol_kizarva == 0)
        talalat = true;
    });

    return talalat;
  },

  getSertesek: function(event, view) {
    var _this = this;
    var {store} = this.context;

    var het_kezdo_datum = moment(view.start).format("YYYY-MM-DD");

    Ajax.call('orarend/sertesek/' + localStorage.token + '/' + _this.props.loggedUser.aktiv_orarend.id + '/' + event.adatok.ora_id + '/' + event.adatok.terem_id, store, {
      success: function(data) {
        // Átmenetileg localhost teszt miatt
        //data = {"sertesek":[{"day":0,"fit":10,"hard":true,"per":0,"week":-1,"kezdete":"08:00:00","vege":"09:40:00"},{"day":4,"fit":10,"hard":true,"per":9,"week":-1,"kezdete":"16:15:00","vege":"17:00:00"}]};

        if (data.sertesek.length > 0) {
          var elemek = [];
          $(data.sertesek).each(function(i, sertes) {
            var s = het_kezdo_datum + ' ' + sertes.kezdete;
            var v = het_kezdo_datum + ' ' + sertes.vege;

            if (sertes.hard == true) {
              var sertes_segmens = {
                id: Math.floor((Math.random() * 10000000000) + 1),
                start: moment(s).add(sertes.day, 'd'),
                end: moment(v).add(sertes.day, 'd'),
                overlap: true,
                rendering: 'background',
                color: '#ff9f89',
                editable: false,
                title: 'Kemény sértés<br>Fitness:' + sertes.fit,
                sertes: true
              };
              elemek.push(sertes_segmens);
            }
          });
          $(_this.refs.naptar).fullCalendar('addEventSource', elemek);

          // Húzandó elem opacity-je 0-ra
          event.opacity = 0;
          $(_this.refs.naptar).fullCalendar('updateEvent', event);
        }
      }
    });
  },

  pad: function(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  },

  /**
   * Drag&Drop-al óra áthelyezése másik időpontra
   */
  oraAthelyezes: function(ora_id, nap, datum_s, datum_v, cb) {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/ora_athelyzes_levelezo/' + localStorage.token, store, {
      method: 'POST',
      data: {
        ora_id: ora_id,
        nap: nap,
        datum_s: datum_s,
        datum_v: datum_v
      },
      success: function(data) {
        if ($.isFunction(cb)) {
          cb(data);
        }
      }
    });
  },

  /**
   * Elrakatlan órák modal-ból a naptárba húzva egy óra
   */
  elrakatlanOraMentes: function(ora_id, terem_id, datum, nap, cb) {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/elrakatlan_ora_mentes_levelezo/' + localStorage.token, store, {
      method: 'POST',
      data: {
        ora_id: ora_id,
        terem_id: terem_id,
        datum: datum,
        nap: nap
      },
      success: function(data) {
        if (data.success) {
          if ($.isFunction(cb)) {
            cb(data);
          }
        }
      }
    });
  },

  /**
   * Óra vége időpont meghatározása a kezdő időpont és óra hossza alapján
   */
  oraVegeKalkulacio: function (kezdo_idopont, ora_hossz, nap, cb) {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend/ora_vege_kalk_levelezo/' + localStorage.token, store, {
      method: 'POST',
      data: {
        kezdo_idopont: kezdo_idopont,
        ora_hossz: ora_hossz,
        nap: nap
      },
      success: function(data) {
        if (data != false) {
          if ($.isFunction(cb)) {
            cb(data);
          }
        }
      }
    });
  },

  nl2br: function(str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  },

  initFullCalendar: function() {
    var self = this;
    var {store} = this.context;

    console.log(this.props.idopontok);
    console.log("Naptar "+this.props.i+": initFullCalendar");

    $(this.refs.naptar).fullCalendar({
      hiddenDays: [ 0, 1, 2, 3, 4 ],
      dayNames: ['Va','Hé','Ke','Sze','Csü','Pé','Szo'],
      dayNamesShort: ['V','H','K','SZ','CS','P','SZ'],
      views: {
          agendaWeek: {
            columnFormat: 'dddd MM.DD'
          },
          honap: {
              type: 'agenda',
              duration: {weeks: 4},
              buttonText: 'Hónap',
              columnFormat: 'dddd MM.DD'
          },
          felev: {
              type: 'agenda',
              duration: {weeks: 14},
              buttonText: 'Félév',
              columnFormat: 'ddd MM.DD'
          }
      },
      defaultView: 'felev',
      minTime: "08:30:00",
      maxTime: "20:45:00",
      slotLabelInterval: { days:0, hours:0, minutes:5 },
      slotLabelFormat: "HH:mm",
      slotDuration: { days:0, hours:2, minutes:30 },
      snapDuration: { days:0, hours:2, minutes:30 },
      firstDay: 1,
      lang: 'hu',
      height: 'auto',
      validRange: {
        start: self.props.loggedUser.aktiv_idoszak.felev_kezdete,
        end: moment(self.props.loggedUser.aktiv_idoszak.felev_vege).add(1, 'days').format('YYYY-MM-DD')
      },
      header: {
        left: 'prev,next,today',
        center: 'title',
        right: 'agendaDay,agendaWeek,honap,felev'
      },
      droppable: true,
      dropAccept: '.elrakatlan-ora',
      eventMouseover: function(event, jsEvent, view) {
        if (typeof(event.adatok.megjegyzes) != "undefined" && event.adatok.megjegyzes != '' && event.adatok.megjegyzes != null) {
          var tooltip = $('<div class="calendar-tooltip">'+self.nl2br(event.adatok.megjegyzes)+'</div>');
          $('body').append(tooltip);
          $('body').mousemove(function(event) {
            $(tooltip).css({
              left: event.pageX - $(tooltip).width() / 2 - 7,
              top: event.pageY - ($(tooltip).height() + 30),
            });
          });
        }
      },
      eventMouseout: function(event, jsEvent, view) {
        $('.calendar-tooltip').remove();
      },
      eventDragStart: function(event, jsEvent, ui, view) {
        self.getSertesek(event, view);
      },

      eventDragStop: function(event, jsEvent, ui, view) {
        Ajax.abort();

        event.opacity = 1;
        $(self.refs.naptar).fullCalendar('updateEvent', event);

        // Összes "háttér" esemény eltüntetése
        $(self.refs.naptar).fullCalendar('removeEvents', function(event) {
          return event.rendering == "background" ? true: false;
        });
      },

      // Belső esemény drop
      eventDrop: function(event, delta, revertFunc, jsEvent, ui, view) {
        var datum_s = moment(event.start).format("YYYY-MM-DD HH:mm:ss");
        var datum_v = moment(event.end).format("YYYY-MM-DD HH:mm:ss");
        var nap = moment(datum_s).day() - 1;
        var ora_id = event.adatok.ora_id;
        var revertFunc = revertFunc;
        var hossz = event.adatok.hossz;

        if (moment(datum_s) < moment(self.props.loggedUser.aktiv_idoszak.felev_kezdete) || moment(datum_s) > moment(self.props.loggedUser.aktiv_idoszak.felev_vege + ' 23:59:59')) {
          revertFunc();
          return false;
        }

        if (!self.periodusEllenorzes(moment(datum_s).day() - 1, moment(datum_s).format("HH:mm:ss"))) {
          revertFunc();
          return false;
        }

        if (!self.napEllenorzes(moment(datum_s).format("YYYY-MM-DD"))) {
          revertFunc();
          return false;
        }

        self.oraAthelyezes(ora_id, nap, datum_s, datum_v, function(data) {
          if (!data.success) {
            self.props.nezetekNaptarFrissitese();
          } else {
            self.props.nezetekNaptarFrissitese();
          }
        });
      },

      // Külső elem drop
      drop: function(date, jsEvent, ui, resourceId) {
        if (!$(this).hasClass('droppable'))
          return false;

        var eventObject = $(this).data('eventObject');

        // Első verziós modal ablakból húzta be a naptárba
        // Először kiválasztotta a termet az órához, majd behúzta ide
        if (eventObject.drag_utan_terem_modal == undefined) {
          self.elrakatlanOraMentes(eventObject.adatok.ora_id, eventObject.adatok.terem_id, moment(date).format("YYYY-MM-DD HH:mm:ss"), moment(date).day()-1, function() {
            self.props.elrakatlanOrakModalHide();
            self.props.nezetekNaptarFrissitese();
          });
        }

        // Második verziós modal ablakból húzta be a naptárba
        // Az elrakatlan óra drop-olása után meg kell nyitni a teremválasztó modalt
        // El kell menteni a módosítandó ora_id-t, és az időpontot ahova behúzta
        if (eventObject.drag_utan_terem_modal == 1) {
            eventObject.start = moment(date);

            if (moment(date) < moment(self.props.loggedUser.aktiv_idoszak.felev_kezdete) || moment(date) > moment(self.props.loggedUser.aktiv_idoszak.felev_vege + ' 23:59:59')) {
              return false;
            }

            if (!self.periodusEllenorzes(moment(date).day() - 1, moment(date).format("HH:mm:ss"))) {
              return false;
            }

            if (!self.napEllenorzes(moment(date).format("YYYY-MM-DD"))) {
              return false;
            }

            // Az óra kezdetéből és hosszából meghatározza az óra végét, és elmenti az objektumba
            // naptár megjelenítéshez szükséges az óra végének meghatározása
            self.oraVegeKalkulacio(moment(eventObject.start).format("HH:mm:ss"), eventObject.adatok.hossz, moment(date).day() - 1, function(vege) {
              var vege = moment(eventObject.start).format("YYYY-MM-DD") + " " + vege;
              eventObject.end = moment(vege).format("YYYY-MM-DD HH:mm:ss");

              // naptárban megjeleníti
              self.props.aktivNezethezIdopontokHozzadasa([eventObject]);

              // Modal elrejtése
              self.props.elrakatlanOrakModalHide_();

              // Óra ID, és a kezdő időpontot lementi a state-be
              self.props.setOraMentes({mentes: true, terem_id: 0, terem_nev: '', ora_id: eventObject.adatok.ora_id, idopont:moment(date).format("YYYY-MM-DD HH:mm:ss")}, function() {
                // Terem választó modul megjelenítése
                self.props.teremValasztoModalShow();
              });
            });
        }
      },
      events: self.props.idopontok,
      allDaySlot: false,
      eventClick: function(event, jsEvent, view) {
        if (event.editable === true) {
          if ($(jsEvent.target).hasClass('delete')) {
            self.props.oraTorles(event.adatok.ora_id);

          } else if ($(jsEvent.target).hasClass('modify')) {
            event.adatok.datum = moment(event.start).format("YYYY-MM-DD");
            //self.props.oraAdatokModalShow(event.adatok);
            self.props.oraAdatokModalShow(JSON.parse(JSON.stringify(event.adatok))); // Másolt objektumot adja át, ne írja felül az infóablak

          } else if ($(jsEvent.target).hasClass('chain')) {
            event.adatok.datum = moment(event.start).format("YYYY-MM-DD");
            //self.props.oraAdatokModalShow(event.adatok);
            self.props.oraAdatokModalShow(JSON.parse(JSON.stringify(event.adatok))); // Másolt objektumot adja át, ne írja felül az infóablak

          } else {
            event.adatok.datum = moment(event.start).format("YYYY-MM-DD");
            //self.props.oraAdatokModalShow(event.adatok);
            self.props.oraAdatokModalShow(JSON.parse(JSON.stringify(event.adatok))); // Másolt objektumot adja át, ne írja felül az infóablak
          }
        }
      },
      eventAfterRender: function(event, element, view) {
        if (event.editable === true) {
          var c = $('<div class="calendar-event-btn delete"><i class="material-icons">close</i></div>');
          $(element).append(c);

          $(element).find('.fc-time').remove();

          $(c).mouseover(function() {
            $('.calendar-tooltip').remove();
          });

          if (event.adatok.osszevont_kurzusok != undefined && event.adatok.osszevont_kurzusok.length > 0) {
            var k = $('<div class="calendar-event-btn chain"><i class="fa fa-link"></i></div>');
            $(element).append(k);
          }
        }

        if (event.rendering == 'background') {
          element.append(event.title);
        }
      },
    });

    $(this.refs.naptar).fullCalendar('gotoDate', moment(self.props.loggedUser.aktiv_idoszak.felev_kezdete, "YYYY-MM-DD"));
  },

  componentWillUnMount: function() {
    //const {naptar} = this.refs;
    //$(naptar).fullCalendar('destroy');
  },

  render() {
    return (
      <div ref="naptar"></div>
    );
  }
});

Naptar.contextTypes = {
  store: React.PropTypes.object
}

export default Naptar;
