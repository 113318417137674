import React from 'react';
import Ajax from './../Ajax.js';
import Select2 from './../form/Select2';
import Select from './../form/Select';

var Tanar = React.createClass({
  getInitialState() {
    return {
      opciok: []
    }
  },

  componentDidMount: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('eroforrasok/oktatok/' + localStorage.token + '/', store, {
      async: false,
      success: function(data) {
        var tmp = [];
        data.oktatok.map(function(oktato, i) {
          tmp.push({
            value: parseInt(oktato.id),
            label: oktato.nev + ' (' + oktato.neptun + ')'
          })
        });
        obj.setState({opciok: tmp});
      }
    });
  },

  render: function() {
    return (
      <Select search={true} placeholder="- válassz -" name={`feltetel[ertek][p_${this.props.id}]`} value={this.props.ertek} title={this.props.label} options={this.state.opciok} validationError="Kötelező" required/>
    );
  }
});

Tanar.contextTypes = {
  store: React.PropTypes.object
}

export default Tanar;