import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button } from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import Select from './form/Select';

var EroforrasokEszkoz = React.createClass({
  getInitialState() {
    return {
      eszkoz: {
      },
      termek_szotar: [],
      felszereltsegek_szotar: [],
      formIsValid: false
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentDidMount: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('eroforrasok/eszkoz/' + localStorage.token + '/' + obj.props.params.id, store, {
      success: function(data) {
        data.felszereltsegek_szotar = [
          {
            value: "",
            label: '- válassz -'
          },
          ...data.felszereltsegek_szotar
        ];

        data.termek = [
          {
            value: "",
            label: '- válassz -'
          },
          ...data.termek
        ];

        obj.setState({eszkoz: data.eszkoz, felszereltsegek_szotar: data.felszereltsegek_szotar, termek_szotar: data.termek});

        jQueryHelper.setFixedMenu(obj);
      }
    });
  },

  felszereltsegTorol: function(felszereltseg_id) {
    var eszkoz = this.state.eszkoz;
    var tmp = [];
    eszkoz.felszereltsegek.map(function(felszereltseg, i) {
      if (parseInt(felszereltseg.id) != parseInt(felszereltseg_id))
        tmp.push(felszereltseg);
    });
    eszkoz.felszereltsegek = tmp;
    this.setState({
      eszkoz: eszkoz
    })
  },

  onMent: function() {
    var self = this;

    this.refs.form.submit();

    if (this.state.formIsValid) {
      var formData = this.refs.form.getModel();

      var obj = this;
      var {store} = this.context;

      formData.eszkoz.eszkoz_id = this.state.eszkoz.eszkoz_id;
      formData.eszkoz.felszereltsegek = this.state.eszkoz.felszereltsegek;

      Ajax.call('/eroforrasok/eszkoz/ment/' + localStorage.token, store, {
        method: "POST",
        data: {
          data: formData.eszkoz
        },
        success: function(data) {
          hashHistory.push('/eroforrasok_eszkozok');
        }
      });
    }
  },

  onValid: function() {
    this.setState({
      formIsValid: true
    });
  },

  onInvalid: function() {
    this.setState({
      formIsValid: false
    });
  },

  ujFelszereltseg: function() {
    var eszkoz = this.state.eszkoz;

    eszkoz.felszereltsegek.push({
      id: Math.floor((Math.random() * 1000000) + 1),
      terem_id: '',
      db: ''
    });

    this.setState({
      eszkoz: eszkoz
    })
  },

  teremValt: function(felszereltseg_id, value) {
    var eszkoz = this.state.eszkoz;
    eszkoz.felszereltsegek.map(function(felszereltseg, i) {
      if (parseInt(felszereltseg.id) == parseInt(felszereltseg_id))
        felszereltseg.terem_id = parseInt(value);
    });
  },

  dbValt: function(felszereltseg_id, value) {
    if (value == '')
      value = 0;

    var eszkoz = this.state.eszkoz;
    eszkoz.felszereltsegek.map(function(felszereltseg, i) {
      if (parseInt(felszereltseg.id) == parseInt(felszereltseg_id))
        felszereltseg.db = parseInt(value);
    });
  },

  render: function() {
      var _this = this;

      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Erőforrások
              </li>
              <li>
                Eszközök
              </li>
              <li>
                Eszköz
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Erőforrások, eszköz</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>{this.props.params.id == undefined ? "Új eszköz" : "Eszköz"}</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                    <Form ref="form" onValid={this.onValid} onInvalid={this.onInvalid}>
                      <Input name="eszkoz.megnevezes" title="Megnevezés" value={this.state.eszkoz.megnevezes} validationError="Megnevezés kötelező" required />
                      <Select value={this.state.eszkoz.szotar_id} name="eszkoz.szotar_id" title="Szótár érték" options={this.state.felszereltsegek_szotar} validationError="Szótár érték kötelező" required/>

                      {(this.state.eszkoz.felszereltsegek != undefined && this.state.eszkoz.felszereltsegek.length > 0) && this.state.eszkoz.felszereltsegek.map(function(felszereltseg, i) {
                        return (
                        <div key={i} className="row">
                          <div className="col-xs-5">
                          <Select onChange={(value)=>{_this.teremValt(felszereltseg.id, value)}} value={felszereltseg.terem_id} name={`felszereltsegek[${felszereltseg.id}][terem_id]`} title="Terem" options={_this.state.termek_szotar} validationError="Terem kötelező" required/>
                          </div>
                          <div className="col-xs-5">
                            <Input onChange={(value)=>{_this.dbValt(felszereltseg.id, value)}} value={felszereltseg.db} name={`felszereltsegek[${felszereltseg.id}][db]`} title="Darabszám" validations="isNumeric" validationError="Darabszám kötelező" required />
                          </div>
                          <div>
                            <div className="col-xs-2">
                              <ul style={{'paddingTop':20}} className="icon-list">
                                <li><a onClick={()=>{_this.felszereltsegTorol(felszereltseg.id)}}><i className="fa fa-trash"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        )
                      })}

                      <button onClick={this.ujFelszereltseg} className="btn btn-primary" type="button"><i className="fa fa-plus"></i>Új felszereltség</button>
                    </Form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

EroforrasokEszkoz.contextTypes = {
  store: React.PropTypes.object
}

export default EroforrasokEszkoz;