import React from 'react';
import Ajax from './../Ajax.js';
import utils from 'formsy-react/lib/utils';

var Naptar = React.createClass({
  getInitialState() {
    return {
      felev_kezdete: '',
      felev_vege: ''
    }
  },

  componentDidUpdate: function(prevProps, prevState) {
    $(this.refs.naptar).fullCalendar('render');
    $(this.refs.naptar).fullCalendar('removeEvents');
    $(this.refs.naptar).fullCalendar('addEventSource', this.props.idopontok);
    $(this.refs.naptar).fullCalendar('rerenderEvents');
  },

  componentDidMount: function() {
    this.state.felev_kezdete = this.props.loggedUser.aktiv_idoszak.felev_kezdete;
    this.state.felev_vege = this.props.loggedUser.aktiv_idoszak.felev_vege;
    this.initFullCalendar();
  },

  nl2br: function(str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  },

  updateCalendar: function(felev_kezdete, felev_vege) {
    var _this = this;

    this.setState({
      felev_kezdete: felev_kezdete,
      felev_vege: felev_vege
    }, function() {
      $(_this.refs.naptar).fullCalendar('destroy');
      _this.initFullCalendar();
    });
  },

  initFullCalendar: function() {
    var self = this;
    var {store} = this.context;

    $(this.refs.naptar).fullCalendar({
      hiddenDays: [ 0, 6 ],
      defaultView: 'agendaWeek',
      minTime: "07:00:00",
      maxTime: "20:00:00",
      slotLabelInterval: { days:0, hours:0, minutes:5 },
      slotLabelFormat: "HH:mm",
      slotDuration: { days:0, hours:0, minutes:60 },
      snapDuration: { days:0, hours:0, minutes:60 },
      firstDay: 1,
      lang: 'hu',
      height: 'auto',
      header: {
        left: 'prev,next,today',
        center: 'title',
        right: 'agendaWeek,agendaDay'
      },
      validRange: {
        start: this.state.felev_kezdete,
        end: moment(this.state.felev_vege).add(1, 'days').format('YYYY-MM-DD')
      },
      defaultDate: this.state.felev_kezdete,
      droppable: false,
      disableDragging: true,
      eventMouseover: function(event, jsEvent, view) {
        var tooltip = $('<div class="calendar-tooltip">'+self.nl2br(event.tooltip_title)+'</div>');
        $('body').append(tooltip);
        $('body').mousemove(function(event) {
          $(tooltip).css({
            left: event.pageX - $(tooltip).width() / 2 - 7,
            top: event.pageY - ($(tooltip).height() + 40),
          });
        });
      },
      eventMouseout: function(event, jsEvent, view) {
        $('.calendar-tooltip').remove();
      },
      events: self.props.idopontok,
      allDaySlot: false
    });
  },

  render() {
    return (
      <div ref="naptar"></div>
    );
  }
});

Naptar.contextTypes = {
  store: React.PropTypes.object
}

export default Naptar;