module.exports = {
  removeItemByFieldAndValue: function(arr, field, value) {
    var tmp = [];
    arr.map(function(item, i) {
      if (item[field] != value) {
        tmp.push(item);
      }
    });
    return tmp;
  },
  inArrayByKeyAndValue: function(arr, key, value) {
    var in_arr = false;
    arr.map(function(item, i) {
      if (item[key] == value)
        in_arr = true;
    });
    return in_arr;
  }
}