function userReducer(state = [], action) {
  switch (action.type) {
    case "USER_LOGIN":
      return {
        id: action.id,
        fullname: action.fullname,
        group_id: action.group_id,
        group_name: action.group_name,
        avatar: action.avatar,
        shib_avatar: action.shib_avatar,
        neptun: action.neptun
      };
    break;

    case "USER_SET_DATA":
      return $.extend(state, {
        id: action.id,
        fullname: action.fullname,
        group_id: action.group_id,
        group_name: action.group_name,
        avatar: action.avatar,
        shib_avatar: action.shib_avatar,
        neptun: action.neptun,
        rules: action.rules
      });
    break;

    case "USER_SET_IDOSZAK":
      return $.extend(state, {
        aktiv_idoszak: {
          id: action.id,
          megnevezes: action.megnevezes,
          felev_kezdete: action.felev_kezdete,
          felev_vege: action.felev_vege
        }
      });
    break;

    case "USER_SET_ORAREND":
      return $.extend(state, {
        aktiv_orarend: {
          id: action.id,
          megnevezes: action.megnevezes,
          adatbazis: action.adatbazis
        }
      });
    break;

    case "USER_SET_KURZUS":
      return $.extend(state, {
        kivalasztott_kurzus: {
          id: action.id,
          megjegyzes: action.megjegyzes,
          tantargy_id: action.tantargy_id,
          scroll: action.scroll,
          munkarend: action.munkarend,
          tanszek_id: action.tanszek_id,
          felvetel_id: action.felvetel_id,
          csak_megjegyzeses: action.csak_megjegyzeses,
          csak_orarend: action.csak_orarend,
          szak_id: action.szak_id,
          szakirany_id: action.szakirany_id,
          felev_id: action.felev_id,
          kerkif: action.kerkif
        }
      });
    break;

    case "USER_LOGOUT":
      return {
        id: '',
        fullname: '',
        group: '',
        group_name: '',
        avatar: ''
      };
    break;

    default:
      return state;
  }
}

export default userReducer;