/**
 * <Select2Ajax></Select2Ajax>
 *
 * Cím: title=""
 * Azonosító: id=""
 * Megnevezés: name=""
 * Többválasztós: multiple="multiple"
 * Ajax hívás url-je: url="teszt/teszt"
 * Alapértelmezett adatok: opts={[{id:0,text:""}]} Az opts egyválasztós és többválasztós módban is egy tömb
 * Ajax hívás kimenete: items[{id:0,text:""}]
 * Változás: onChange={(value) => {}}
 * Tiltás: disabled="1"
 *
 * Ha kötelező: validationError="Kiválasztás kötelező" required
 */
import React from 'react';
import Formsy from 'formsy-react';

const Select2Ajax = React.createClass({
  mixins: [Formsy.Mixin],
  getInitialState() {
    return {
      options: [],
      selected: []
    }
  },

  componentDidMount: function() {
    var _this = this;

    if (this.props.opts != undefined && this.props.opts.length > 0) {
      var tmp = [];
      this.props.opts.map(function(opt) {
        tmp.push(opt.id);
      });
      this.setState({
        options: this.props.opts,
        selected: tmp
      }, function() {
        if (_this.props.multiple)
          _this.setValue(_this.state.selected);
        else
          _this.setValue(_this.state.selected[0]);

        _this.setSelect2();
      });
    } else {
      this.setValue('');
      this.setSelect2();
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState._formSubmitted || this.getValue() != '')
      return true;
    else
      return false;
  },

  setSelect2: function() {
    var _this = this;

    $('#'+this.props.id).select2({
      placeholder: 'Kezdjen el írni...',
      ajax: {
        url: 'api/'+this.props.url,
        dataType: 'json',
        delay: 250,
        method: 'POST',
        data: function (params) {
          return {
            q: params.term,
            page: params.page
          };
        },
        processResults: function (data, params) {
          return {
            results: data.items
          };
        },
        cache: true
      },
      escapeMarkup: function (markup) {
        return markup;
      },
      minimumInputLength: 2
    }).on("select2:select", function (e) {
      _this.setValueAfterSelect(e.params.data.id);
    })
    .on("select2:unselect", function (e) {
      _this.removeValueSelect(e.params.data.id);
    })
  },

  removeValueSelect: function(id) {
    var _this = this;

    if (this.props.multiple) {
      var tmp = [];
      this.state.selected.map(function(s) {
        if (s != id)
          tmp.push(s);
      });
      this.setState({
        selected: tmp
      }, function() {
        if (tmp.length == 0) {
          _this.setValue('');
        } else {
          _this.setValue(_this.state.selected);
        }
        if (_this.props.onChange != undefined)
          _this.props.onChange(_this.state.selected);
      });
    } else {
      this.setState({
        selected: []
      }, function() {
        _this.setValue('');
        if (_this.props.onChange != undefined)
          _this.props.onChange('');
      });
    }
  },

  setValueAfterSelect: function(id) {
    var _this = this;

    if (this.props.multiple) {
      this.setState({
        selected: [...this.state.selected, id]
      }, function() {
        _this.setValue(_this.state.selected);

        if (_this.props.onChange != undefined)
            _this.props.onChange(_this.state.selected);
      });
    } else {
      this.setState({
        selected: [id]
      }, function() {
        _this.setValue(_this.state.selected[0]);

        if (_this.props.onChange != undefined)
          _this.props.onChange(_this.state.selected[0]);
      });
    }
  },

  render() {
    var className = 'form-group' + (this.props.className || ' ') + (this.showRequired() ? 'required' : this.showError() ? 'has-error' : '');

    var errorMessage = this.getErrorMessage();

    if (this.isFormSubmitted()) {
      if (!this.isValid()) {
        className = 'form-group has-error';
        errorMessage = this.getErrorMessage() ? this.getErrorMessage() : this.props.validationError;
      }
    }

    if (this.props.inpopup != undefined) {
      className = className + ' select2-in-popup';
    }

    var sv = this.props.multiple ? this.state.selected : this.state.selected[0];

    return (
      <div className={className}>
        <label className="form-label" htmlFor={this.props.name}>{this.props.title} {this.isRequired() ? '*' : null}</label>

        <select disabled={this.props.disabled ? true : false} onChange={()=>{}} value={sv} style={{width: "100%"}} multiple={this.props.multiple} className="form-control select2js" name={this.props.name} id={this.props.id}>
        {this.state.options.length > 0 && this.state.options.map(opt => (
          <option key={opt.id} value={opt.id}>{opt.text}</option>
        ))}
        </select>
        <span className="validation-error">{errorMessage}</span>
      </div>
    );
  }

});

export default Select2Ajax;
