import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Modal, Button, Tabs, Tab} from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import ArrayHelper from './ArrayHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import InputHidden from './form/InputHidden';
import Select from './form/Select';
import Textarea from './form/Textarea';

var KerelmekBejovo = React.createClass({
  getInitialState() {
    return {
      kerelmek: []
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('kerelmek/bejovo/' + localStorage.token, store, {
      success: function(data) {
        obj.setState({kerelmek: data.kerelmek});
        jQueryHelper.setDropDownToggle(obj);
      }
    });
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
    jQueryHelper.setDropDownToggle(this);
  },

  kerelemTorol: function(id) {
    var obj = this;
    var {store} = this.context;

    swal({
      title: "Biztos hogy törli a kérelmet?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Igen",
      cancelButtonText: "Mégsem",
      closeOnConfirm: true,
      animation: false
    },
    function() {
      Ajax.call('kerelmek/torol/' + localStorage.token + '/' + id, store, {
        success: function(data) {
          var tmp = ArrayHelper.removeItemByFieldAndValue(obj.state.kerelmek, 'id', id);

          obj.setState({
            kerelmek: tmp
          });
        }
      });
    });
  },

  render: function() {
    var self = this;
    var a = 1;

      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Kérelmek
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Kérelmek</h1>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Kérelmek</h4>
              </div>
              <div className="panel-body">

                <div className="row">
                  <div className="col-xs-12">
                  {this.state.kerelmek.length > 0 ? (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Tárgy</th>
                          <th>Feladó</th>
                          <th>Létrehozva</th>
                          <th style={{"textAlign": "center"}}>Státusz</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.kerelmek.map(function(kerelem, i) {
                        var statusz = '';

                        switch(parseInt(kerelem.statusz_id)) {
                          case 1:
                            statusz = <div style={{"color":"red"}}><i style={{"marginRight":"5px", "fontSize":"14px"}} className="fa fa-close"></i>{kerelem.statusz}</div>;
                            break;

                          case 2:
                            statusz = <div style={{"color":"orange"}}><i style={{"marginRight":"5px", "fontSize":"14px"}} className="fa fa-question"></i>{kerelem.statusz}</div>;
                            break;

                          case 3:
                            statusz = <div style={{"color":"green"}}><i style={{"marginRight":"5px", "fontSize":"14px"}} className="fa fa-check"></i>{kerelem.statusz}</div>;
                            break;
                        }

                         return (
                         <tr className={kerelem.olvasatlan == 1 ? "bold" : ""} key={kerelem.id}>
                            <td>{kerelem.targy}</td>
                            <td>{kerelem.fullname}</td>
                            <td>{kerelem.letrehozva}</td>
                            <td style={{"textAlign": "center"}}>{statusz}</td>
                            <td style={{"textAlign": "center"}}>
                              <ul className="icon-list">
                                <li className="dropdown"><a className="dropdown-toggle" href="javascript:void(0)"><i className="fa fa-gear"></i></a>
                                  <ul className="dropdown-menu">
                                    <li className="dropdown-header">Opciók</li>
                                    <li><Link to={`/kerelem/${kerelem.id}`}><i className="fa fa-pencil-square-o"></i>Szerkeszt</Link></li>
                                    <li><a onClick={() => self.kerelemTorol(kerelem.id)} href="javascript:void(0)"><i className="fa fa-trash"></i>Töröl</a></li>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                          )
                      })}
                      </tbody>
                    </table>
                    ) : (<div>Nem található kérelem.</div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

KerelmekBejovo.contextTypes = {
  store: React.PropTypes.object
}

export default KerelmekBejovo;