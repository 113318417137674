import React from 'react';
import Ajax from './../../Ajax.js';
import {Form} from 'formsy-react';
import Toggle from './../../form/Toggle';
import Button from 'react-bootstrap';
import Input from './../../form/Input';
import Select from './../../form/Select';

var LampazasModal = React.createClass({
  getInitialState() {
    return {
      kurzus: '',
      idopontok: [],
      tantargy: 1,
      oktato: 1,
      szakirany: 1,
      kapacitas: 0,
      kezdo_datum: '',
      zaro_datum: '',
      het: 1,
      max_het: 0,
      hetek: [{
        label: 'mindegyik',
        value: -1
      },{
        label: 'páratlan',
        value: 1
      },{
        label: 'páros',
        value: 0
      }],
      akthet: -1,
      kethetente: 0
    }
  },

  componentDidMount: function() {
    this.setState({
      kezdo_datum: this.props.loggedUser.aktiv_idoszak.felev_kezdete,
      zaro_datum: this.props.loggedUser.aktiv_idoszak.felev_vege,
      max_het: moment(this.props.loggedUser.aktiv_idoszak.felev_vege).diff(moment(this.props.loggedUser.aktiv_idoszak.felev_kezdete), 'weeks') + 1
    });

    $('#lampazas_modal').draggable({
      start: function( event, ui ) {
        $('#lampazas_modal').addClass('startdrag');
      }
    });

    this.ablakPozicio();
    this.getIdopontok();
    this.getKurzusAdatok();
  },

  toSmallSize: function() {
    $('#lampazas_modal').addClass('smallsize');
  },

  componentDidUpdate: function() {
    var _this = this;

    $('.foglalt').mouseover(function() {
      var nap_id = parseInt($(this).data('nap'));
      var periodus_id = parseInt($(this).data('periodus'));
      var p = '';

      _this.state.idopontok.map(function(nap, i) {
        if (nap.nap_id == nap_id) {
          nap.periodusok.map(function(periodus, i) {
            if (periodus.periodus == periodus_id) {
              p = periodus;
            }
          });
        }
      });

      if (p != '') {
        if ($('.calendar-tooltip').length == 0) {
          var text = '';

          if (p.foglaltsag.foglalt) {
            p.foglaltsag.utkozes.map(function(utkozes, i) {
              var text_arr = [];

              text_arr.push('Sértés: ' + utkozes.sertes.megnevezes);

              if (utkozes.kurzus_azon != undefined)
                text_arr.push('Kurzus azonosító: ' + utkozes.kurzus_azon);

              if (utkozes.szak != undefined)
                text_arr.push('Szak: ' + utkozes.szak.nev + (utkozes.felev?' (' + utkozes.felev + '. félév)':''));

              if (utkozes.tanterv != undefined)
                text_arr.push('Tanterv: ' + utkozes.tanterv.kod + ' (' + utkozes.tanterv.felev + '. félév)');

              if (utkozes.tanar != undefined)
                text_arr.push('Oktató: ' + utkozes.tanar.nev + ' (' + utkozes.tanar.neptun + ')');

              if (utkozes.feltetel_megnevezes != undefined)
                text_arr.push('Megjegyzés: ' + utkozes.feltetel_megnevezes);

              text += '<span>' + text_arr.join('<br>') + '</span>';
            });
          }

          var tooltip = $('<div class="calendar-tooltip">'+text+'</div>');
          $('body').append(tooltip);
          _this.tooltipPozicioBeallit($(tooltip));

          $('body').mousemove(function(event) {
            _this.tooltipPozicioBeallit($(tooltip));
          });
        }
      }
    });

    $('.foglalt').mouseout(function() {
      $('.calendar-tooltip').remove();
    });

    this.ablakPozicio();
  },

  tooltipPozicioBeallit: function(tooltip) {
    var top = (event.clientY - ($(tooltip).outerHeight() + 30)) + $(window).scrollTop();
    var left = event.pageX - $(tooltip).width() / 2 - 7;

    if ( (event.clientY - $(tooltip).outerHeight()) < 0 ) {
      top = $(window).scrollTop();
      left = event.pageX - $(tooltip).width() - 50;
    }

    $(tooltip).css({
      left: left,
      top: top
    });
  },

  hetNovelesClick: function() {
    var het = this.state.het;
    het++;

    if (het > this.state.max_het)
      het = this.state.max_het;

    this.setState({
      het: het
    }, this.getIdopontok);
  },

  hetCsokkentesClick: function() {
    var het = this.state.het;
    het--;
    if (het < 1)
      het = 1;

    this.setState({
      het: het
    }, this.getIdopontok);
  },

  utolsoHetClick: function() {
    this.setState({
      het: this.state.max_het
    }, this.getIdopontok);
  },

  elsoHetClick: function() {
    this.setState({
      het: 1
    }, this.getIdopontok);
  },

  hetBeallitas: function(het) {
    het = parseInt(het);

    if (isNaN(het))
      het = 1;

    if (het < 1)
      het = 1;

    if (het > this.state.max_het)
      het = this.state.max_het;

    this.setState({
      het: het
    }, this.getIdopontok);
  },

  ablakPozicio: function() {
    var top;

    if ($('#lampazas_modal').height() > $(window).innerHeight()) {
      top = -$('.panel-body').offset().top + $('#lampazas_modal').height() / 2;
    } else {
      top = Math.round($(window).scrollTop() + $(window).innerHeight() / 2 - parseInt($('.main-panel').css('padding-top')) - 200);
    }

    $(this.refs.lampazas_modal).css({
      'left': $(window).innerWidth() / 2 - parseInt($('.main-panel').css('padding-left')),
      'top': top
    });

    $(this.refs.lampazas_modal).removeClass('startdrag');
    $(this.refs.lampazas_modal).css({
      'height': 'auto'
    });
  },

  getIdopontok: function(value,tipus) {
    var _this = this;
    var {store} = this.context;

    var tantargy = this.state.tantargy;
    var oktato = this.state.oktato;
    var szakirany = this.state.szakirany;
    var kapacitas = this.state.kapacitas;

    if(tipus == 'tantargy') {
      tantargy = value;
    } else if(tipus == 'oktato') {
      oktato = value;
    } else if (tipus == 'szakirany') {
      szakirany = value;
    } else if (tipus == 'kapacitas') {
      kapacitas = value;
    }

    Ajax.call('orarend_szerk/lampazas_idopontok/' + localStorage.token + '/' + this.props.kurzus_id + '/' + tantargy + '/' + oktato + '/' + szakirany + '/' + this.props.idoszak_kezdete + '/' + this.state.kethetente + '/' + this.state.akthet + '/null/' + kapacitas, store, {
      async: false,
      success: function(data) {
        _this.setState({
          idopontok: data.idopontok
        });
      }
    });
  },

  getKurzusAdatok: function() {
    var _this = this;
    var {store} = this.context;

    Ajax.call('orarend_szerk/kurzus_adatok/' + localStorage.token + '/' + this.props.kurzus_id + '/' + this.props.idoszak_kezdete, store, {
      async: false,
      success: function(data) {
        _this.setState({
          kurzus: data.kurzusAdatok
        });
      }
    });
  },

  hetChange: function(val) {
    var kethetente = 0;
    if (val >= 0) {
      kethetente = 1;
    }

    this.setState({
      akthet: val,
      kethetente: kethetente
    }, this.getIdopontok);
  },

  render() {
    var _this = this;
    var tmp = '';

    if (this.state.idopontok.length > 0) {
      var max_nap = 5;
      var max_periodus = this.state.idopontok[0].periodusok.length;

      tmp = '<tr><td></td>';

      this.state.idopontok.map(function(nap, i) {
         tmp += '<td class="nap">'+nap.nap+'</td>';
      });
      tmp += '</tr>';

      for (var periodus_i = 0; periodus_i < max_periodus; periodus_i++) {
        tmp += '<tr><td style=\"padding-top: 10px; padding-bottom:10px;\" class="periodus">'+this.state.idopontok[0].periodusok[periodus_i].kezdete+'-'+this.state.idopontok[0].periodusok[periodus_i].vege+'</td>';
        for (var nap_i = 0; nap_i < max_nap; nap_i++) {
          var class_name = '';
          var text = '';
          var van_utkozes = false;

          if (this.state.idopontok[nap_i].periodusok[periodus_i].foglaltsag.foglalt == true) {
            class_name = " foglalt ";
            this.state.idopontok[nap_i].periodusok[periodus_i].foglaltsag.utkozes.map(function(utkozes, i) {
              if (van_utkozes && class_name.indexOf('tobbszoros') == -1)
                class_name += ' tobbszoros ';

              van_utkozes = true;

              if (class_name.indexOf(utkozes.sertes.class_definicio) == -1) {
                class_name += ' ' + utkozes.sertes.class_definicio + ' ';
                text +="<div class=\"info\">"+utkozes.sertes.megnevezes+"</div>";
              }
            });
          }

          tmp += "<td style=\"width: 184px; padding-top: 10px; padding-bottom:10px;\" data-nap=\""+this.state.idopontok[nap_i].nap_id+"\" data-periodus=\""+this.state.idopontok[nap_i].periodusok[periodus_i].periodus+"\" class=\"blokk "+class_name+"\">"+text+"</td>";
        }
        tmp += '</tr>';
      }
    }

    return (
      <div ref="lampazas_modal" id="lampazas_modal" className="modal-content no-overlay">
        <div className="modal-header">
        {/*<button onClick={this.toSmallSize} type="button" className="close"><span>-</span></button>*/}
          <button onClick={this.props.hide} type="button" className="close"><span aria-hidden="true">×</span></button>
          <h4 className="modal-title">Szabad időpontok megtekintése</h4>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-xs-3">
              <div className="row">
                <div className="col-xs-12">
                  <div className="form-group">
                    <label className="form-label">Azonosító</label>
                    <div className="form-control-text">{this.state.kurzus != '' && this.state.kurzus.kurzus_alapadatok.kurzus_azonosito}</div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <div className="form-group">
                    <label className="form-label">Megnevezés</label>
                    <div className="form-control-text">{this.state.kurzus != '' && this.state.kurzus.kurzus_alapadatok.tantargy + '(' + this.state.kurzus.kurzus_alapadatok.tantargy_kod + ')'}</div>
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label className="form-label">Létszám</label>
                    <div className="form-control-text">{this.state.kurzus != '' && this.state.kurzus.kurzus_alapadatok.max_korlat} fő</div>
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="form-group">
                    <label className="form-label">Órák</label>
                    <div className="form-control-text">{this.state.kurzus != '' && this.state.kurzus.kurzus_alapadatok.elrakott_orak + '/' + this.state.kurzus.kurzus_alapadatok.osszes_ora}</div>
                  </div>
                </div>
                {this.props.hossz ? (
                <div className="col-xs-4">
                  <div className="form-group">
                    <label className="form-label">Hossz</label>
                    <div className="form-control-text">{this.state.kurzus != '' && this.props.hossz}</div>
                  </div>
                </div>
                ):("")}
                <div className="col-xs-12">
                  <div className="form-group">
                    <label className="form-label">Oktatók</label>
                    <div className="form-control-text">{this.state.kurzus != '' && this.state.kurzus.kurzus_alapadatok.oktatok}</div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <div className="form-group">
                    <label className="form-label">Szakirányok</label>
                    <div className="form-control-text">{this.state.kurzus != '' && this.state.kurzus.kurzus_alapadatok.szakok}</div>
                  </div>
                </div>
              </div>
              <Form ref="form">
                <div className="row">
                  <div className="col-xs-4">
                    <Toggle onChange={(value)=>{this.setState({tantargy:value}, _this.getIdopontok(value,'tantargy'));}} name="tantargy" title="Tantárgy" value={this.state.tantargy}/>
                  </div>
                  <div className="col-xs-4">
                    <Toggle onChange={(value)=>{this.setState({oktato:value}, _this.getIdopontok(value,'oktato'));}} name="oktato" title="Oktató" value={this.state.oktato}/>
                  </div>
                  <div className="col-xs-4">
                    <Toggle onChange={(value)=>{this.setState({szakirany:value}, _this.getIdopontok(value,'szakirany'));}} name="szakirany" title="Szakirány" value={this.state.szakirany}/>
                  </div>
                </div>
              </Form>
            </div>
            <div className="col-xs-9">
              <div className="orarendszerk-naptar-het-valaszto" style={{'marginTop':'0px'}}>
                {/*<button onClick={this.elsoHetClick} className="btn btn-white only-icon small" style={{'marginRight':'5px'}} type="button"><i className="fa fa-angle-double-left"></i></button>
                <button onClick={this.hetCsokkentesClick} className="btn btn-white only-icon small" type="button"><i className="fa fa-angle-left"></i></button>
                <div className="hetkijelzo">
                  <Form>
                    <Input style={{'width':'50px'}} name="het" intervalTypingCallback={(value) => { _this.hetBeallitas(value); }} value={this.state.het}/>
                  </Form>
                </div>
                <button onClick={this.hetNovelesClick} className="btn btn-white only-icon small" type="button"><i className="fa fa-angle-right"></i></button>
                <button onClick={this.utolsoHetClick} className="btn btn-white only-icon small" style={{'marginLeft':'5px'}} type="button"><i className="fa fa-angle-double-right"></i></button>*/}
                <Form className="hetvalaszto" style={{'width':'200px','margin':'0 auto'}} >
                  <Select onChange={this.hetChange} name="het" value={this.state.akthet} title="Hét választás" options={this.state.hetek} />
                </Form>
              </div>
              <table className="orarend-szerk-lampazas-tabla">
                <tbody dangerouslySetInnerHTML={{__html: tmp}}></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
     )
  }
});

LampazasModal.contextTypes = {
  store: React.PropTypes.object
};

export default LampazasModal;