import React from 'react';
import Ajax from './../Ajax.js';
import Select2 from './../form/Select2';
import Select from './../form/Select';

var Terem = React.createClass({
  getInitialState() {
    return {
      opciok: [],
      value: ''
    }
  },

  componentDidMount: function() {
    var obj = this;
    var {store} = this.context;

    Ajax.call('eroforrasok/termek/' + localStorage.token, store, {
      async: false,
      success: function(data) {
        obj.setState({
          opciok: data.termek,
          value: obj.props.ertek
        })
      }
    });

    Ajax.call('eroforrasok/szotarIdToTeremId/' + localStorage.token + '/' + this.props.ertek, store, {
      async: false,
      success: function(data) {
        if(data.terem_id) {
          obj.setState({
            value: parseInt(data.terem_id)
          })
        }
      }
    });
  },

  render: function() {
    return (
      <Select search={true} placeholder="- válassz -" name={`feltetel[ertek][p_${this.props.id}]`} value={this.state.value} title={this.props.label} options={this.state.opciok} validationError="Kötelező" required/>
    );
  }
});

Terem.contextTypes = {
  store: React.PropTypes.object
}

export default Terem;