import React from 'react';
import Header from './Header.js';
import LeftPanel from './LeftPanel.js';
import { Link } from 'react-router';
import Auth from './Auth.js';
import { hashHistory, Router } from 'react-router';
import Ajax from './Ajax.js';
import { Button } from 'react-bootstrap';
import jQueryHelper from './jQueryHelper';
import ArrayHelper from './ArrayHelper';
import { Form } from 'formsy-react';
import Input from './form/Input';
import Textarea from './form/Textarea';
import Select from './form/Select';

var Kerelem = React.createClass({
  getInitialState() {
    return {
      kerelem: {},
      statuszok: [],
      formIsValid: false
    }
  },

  componentWillUpdate: function() {
    if (!Auth.loggedIn()) {
      hashHistory.push("/logout");
    }
  },

  componentWillMount: function() {
    var obj = this;
    var {store} = this.context;

    if (obj.props.params.id == undefined) {
      obj.props.params.id = 0;
    }

    Ajax.call('kerelem/' + localStorage.token + '/' + obj.props.params.id, store, {
      success: function(data) {
        obj.setState({kerelem: data.kerelem, statuszok: data.statuszok});
      }
    });
  },

  componentDidMount: function() {
    jQueryHelper.setFixedMenu(this);
  },

  onMent: function() {
    var self = this;

    this.refs.form.submit();

    if (this.state.formIsValid) {
      var formData = this.refs.form.getModel();

      var obj = this;
      var {store} = this.context;

      formData.kerelem.id = this.state.kerelem.id;

      Ajax.call('/kerelem/ment/' + localStorage.token, store, {
        method: "POST",
        data: formData,
        success: function(data) {
          hashHistory.push('/kerelmek_bejovo');
        }
      });
    }
  },

  onValid: function() {
    this.setState({
      formIsValid: true
    });
  },

  onInvalid: function() {
    this.setState({
      formIsValid: false
    });
  },

  render: function() {
      return (
      <div>
        <Header {...this.props}/>
        <LeftPanel {...this.props}/>
        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="fixed_top_menu_outer" className="fixed-top-menu-outer">
          <div className="fixed-top-menu">
            <div className="pull-right">
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
          </div>
        </div>

        <div style={{'paddingLeft':this.props.prop_config.menuOpen ? 220 : 0}} ref="main_panel" className="main-panel">
          <div className="content-panel">
            <ul className="breadcrumb">
              <li>
                Főoldal
              </li>
              <li>
                Bejövő kérelmek
              </li>
              <li>
                Kérelem
              </li>
            </ul>
            <div className="page-title">
              <h1><i className="material-icons">chevron_right</i>Kérelem</h1>
            </div>
            <div className="content-panel-top-btn-group">
              <button onClick={this.onMent} className="btn btn-primary" type="button"><i className="fa fa-save"></i>Mentés</button>
            </div>
            <div className="panel">
              <div className="panel-heading">
                <h4>Kérelem</h4>
              </div>

              <Form ref="form" onValid={this.onValid} onInvalid={this.onInvalid}>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-xs-4">
                      <Input disabled name="kerelem.fullname" title="Feladó" value={this.state.kerelem.fullname}/>
                    </div>
                    <div className="col-xs-4">
                      <Input disabled name="kerelem.email" title="Email" value={this.state.kerelem.email}/>
                    </div>
                    <div className="col-xs-4">
                      <Input disabled name="kerelem.letrehozva" title="Időpont" value={this.state.kerelem.letrehozva}/>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12">
                      <Input disabled name="kerelem.targy" title="Tárgy" value={this.state.kerelem.targy} />
                    </div>
                    <div className="col-xs-12">
                      <Textarea disabled name="kerelem.uzenet" title="Üzenet" value={this.state.kerelem.uzenet} />
                    </div>
                    <div className="col-xs-12">
                      <Textarea name="kerelem.megjegyzes" title="Megjegyzés" value={this.state.kerelem.megjegyzes} />
                    </div>
                    <div className="col-xs-12">
                      <Select name="kerelem.statusz_id" title="Elfogadva" value={this.state.kerelem.statusz_id} options={this.state.statuszok} />
                    </div>
                  </div>
                </div>
              </Form>

            </div>
          </div>
        </div>
      </div>
    )
  }
});

Kerelem.contextTypes = {
  store: React.PropTypes.object
}

export default Kerelem;